<svelte:options immutable="{true}" />

<script>
  import { createEventDispatcher } from 'svelte';
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  // @todo using MenuV2 seems to have an issue with nested tooltips.
  import { Button, ExternalLinkIcon, Menu, tooltip } from 'svelte-tailwind-components';
  import { deleteNoteFx } from './notes.controller.js';
  import NoteTitle from './NoteTitle.svelte';
  // import NoteSummary from './NoteSummary.svelte';
  import NoteTimeAgo from './NoteTimeAgo.svelte';
  import NotePicture from './NotePicture.svelte';
  import NoteMetaIndicators from './NoteMetaIndicators.svelte';
  import NoteAttachmentsList from './NoteAttachmentsList.svelte';
  import EditNoteButton from './buttons/EditNoteButton.svelte';
  import DeleteConfirmButton from '../button/DeleteConfirmButton.svelte';

  let className = '';
  export { className as class };
  export let note;
  export let ctx = {};
  export let targetItem = undefined;
  export let showAuthorTitle = false;
  export let expanded = false;
  export let isNoteEditorOpen = false;
  export let working = false;

  const dispatch = createEventDispatcher();
  const actions = ctx?.actions ?? ['edit', 'delete'];

  let isMenuOpen = false;
  let tooltipParams;
  let href;

  const getTargetLink = (n) => {
    const authorURL = !n.isAuthor ? n?.author?.url : undefined;
    const targetURL = n.targetURL;
    let url;
    let content;

    if (targetURL && targetURL !== window.location.href) {
      url = targetURL;
      content = n.forLesson ? 'Open Lesson' : n.forContact ? 'View Contact Profile' : undefined;
    } else if (authorURL) {
      url = authorURL;
      content = 'View Contact Profile';
    }

    return { url, content };
  };

  $: {
    const { url, content } = getTargetLink(note);
    href = url;
    tooltipParams = { content };
  }

  deleteNoteFx.watch(() => {
    dispatch('noteDeleted', note);
  });

  const onConfirmedDelete = (evt) => {
    evt.stopPropagation();
    isMenuOpen = false;
    deleteNoteFx({ noteId: note.id });
  };
</script>

<div
  id="lf-note--{note?.id}"
  class:lf-note-item="{true}"
  class="flex flex-col group {className}"
  {...excludeProps($$restProps, ['title$'])}
>
  <div class:lf-note-item__header="{true}" class="relative flex items-start">
    <NoteTimeAgo
      note="{note}"
      class="absolute top-4 left-12 -ml-1 flex justify-end items-center md:static md:justify-start text-xs md:text-sm leading-none text-gray-500 md:w-20"
    />
    <NotePicture note="{note}" class="ml-1 mr-4 md:ml-2 md:-mt-1" />
    <div
      class:lf-note-item__content="{true}"
      class="flex-1 min-w-0 max-w-screen-md space-y-6 md:space-y-3"
    >
      <div
        class:lf-note-item__title-wrapper="{true}"
        class="flex items-center space-x-2 text-sm leading-4 lg:text-base font-semibold text-gray-800"
      >
        <NoteTitle
          note="{note}"
          targetItem="{targetItem}"
          showAuthorTitle="{showAuthorTitle}"
          class="truncate"
          {...prefixFilter($$restProps, 'title$')}
        />
        {#if href}
          <Button
            use="{[[tooltip, tooltipParams]]}"
            href="{href}"
            class="min-w-0"
            usage="icon"
            size="sm"
            color="secondary"
          >
            <ExternalLinkIcon slot="leftIcon" class="w-4 h-4" />
          </Button>
        {/if}
        <div class="flex-1"></div>
        <NoteMetaIndicators note="{note}" class="hidden md:flex" />
      </div>
      <div
        class:lf-note-item__body="{true}"
        class:hidden="{!expanded || !note?.body}"
        class="text-base leading-5"
      >
        {@html note?.body}

        <NoteAttachmentsList note="{note}" class="elevation-2 max-w-full mt-4" />
      </div>
    </div>
    {#if $$slots.menu || (actions?.length > 0 && (note?.canEdit || note?.canDelete))}
      <Menu bind:isOpen="{isMenuOpen}" trigger$class="group-hover:bg-gray-50 p-1" class="md:-mt-1">
        <div class="flex flex-col space-y-1 p-1">
          <slot name="menu" />
          {#if actions.includes('edit') && note?.canEdit}
            <EditNoteButton
              note="{note}"
              working="{working}"
              on:editNote
              disabled="{isNoteEditorOpen}"
              label="Edit"
              on:click="{() => (isMenuOpen = false)}"
            />
          {/if}
          {#if actions.includes('delete') && note?.canDelete}
            <DeleteConfirmButton
              working="{working}"
              tooltipParams="{{ content: 'Delete note' }}"
              disabled="{isNoteEditorOpen}"
              on:cancelConfirm="{() => (isMenuOpen = false)}"
              on:confirmedDelete="{onConfirmedDelete}"
              label="Delete"
            />
          {/if}
        </div>
      </Menu>
    {/if}
  </div>
</div>

<style lang="postcss">.lf-note-item__body :global(p:last-of-type){margin-bottom:0}</style>
