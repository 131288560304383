<svelte:options immutable="{true}" />

<!--
  @component
  Handle date/time related input with the flatpickr library.
-->
<script>
  import flatpickr from 'flatpickr';
  import { onMount, onDestroy } from 'svelte';
  import { dnaProps, excludeProps } from 'svelte-utilities';

  import Button from '../buttons/Button.svelte';
  import FlatpickrTextfield from './FlatpickrTextfield.svelte';
  import { CalendarIcon, ClockIcon, CloseIcon } from '../icons';

  let className = '';
  export { className as class };

  export let value = undefined;
  export let selectedValues = [];
  export let options = {};
  export let instance = undefined;

  export let positionHack = false;

  export const openPicker = () => instance && instance.open();
  export const closePicker = () => instance && instance.close();
  export const togglePicker = () => instance && instance.toggle();
  export const clearPicker = () => instance && instance.clear();
  export const jumpToDate = (date, triggerChange) =>
    instance && instance.jumpToDate(date, triggerChange);
  export const setDate = (date, triggerChange, dateStrFormat) =>
    instance && instance.setDate(date, triggerChange, dateStrFormat);

  // @todo The 'enableTime' option cannot be toggled dynamically. Need to
  // destroy and re-create the instance.
  // @see https://github.com/flatpickr/flatpickr/issues/1108
  export const enableTime = (altFormat) => {
    if (!instance.config.enableTime) {
      altFormat = altFormat ?? _options.altFormat;
      altFormat = `${altFormat} - h:i K`;
      const newOptions = { ..._options, altFormat, enableTime: true };
      instance.destroy();
      instance = flatpickr(inputEl.parentElement, newOptions);
    }
  };
  export const disableTime = () => {
    if (instance.config.enableTime) {
      instance.destroy();
      initialize();
    }
  };
  export const toggleTime = (altFormat) => {
    instance.config.enableTime ? disableTime() : enableTime(altFormat);
  };

  // Dates coming from the db are often timestamps and may be a 0 value.
  if (value === 0) {
    value = undefined;
  }

  let inputEl;
  let parentTooltipOverflow;

  // If Flatpickr is inside of a Tooltip (tippy.js) then the calendar gets
  // cut-off by overflow:hidden. This helper alters that value when the
  // calendar opens and closes.
  const tooltipHelper = (_selectedDates, _dateStr, _instance) => {
    const parentTooltipEl = inputEl.closest('.tippy-box');

    if (parentTooltipEl) {
      if (instance.isOpen) {
        parentTooltipOverflow = parentTooltipEl.style.overflow;
        parentTooltipEl.style.overflow = null;
      } else if (parentTooltipOverflow !== undefined) {
        parentTooltipEl.style.overflow = parentTooltipOverflow;
        parentTooltipOverflow = undefined;
      }
    }
  };

  const _options = {
    altFormat: 'Y-m-d',
    altInput: true,
    allowInput: !options?.inline,
    defaultDate: value ?? null,
    minuteIncrement: 1,
    disableMobile: true,
    onOpen: [],
    onClose: [],
    onChange: [],
    ...options,
    dateFormat: 'Z',
    wrap: true,
  };

  _options.onOpen.push(tooltipHelper);
  _options.onClose.push(tooltipHelper);

  const initialize = () => {
    // Note: appending to parent of inputEl seems to cause positioning
    // issues, however appending to the form field wrapper so far hasn't.
    _options.appendTo =
      _options?.appendTo ?? inputEl.closest('.dna-form-field--textfield');

    // @todo When using flatpickr in a side drawer, the positioning gets
    // all messed up. Not exactly sure why yet or the best fix, but this gets
    // the calendar close to where it should be.
    if (positionHack) {
      _options.positionElement = inputEl;
    }

    // The 'static' option is supposed to be used when in a modal or otherwise
    // scrollable area... however given the current markup of the Textfield
    // this causes issues.
    // _options.static = true;
    // _options.onChange = onChange;
    _options.onChange.push(onChange);
    // _options.onValueUpdate = onValueUpdate;
    // We attach to the parent element to allow for the data-toggle and the
    // data-clear buttons to be automatically handled by flatpickr.
    inputEl.parentElement.classList.add('flatpickr');
    instance = flatpickr(inputEl.parentElement, _options);
  };

  let stateInfo;

  $: {
    stateInfo = {
      control: selectedValues.length === 0 ? 'data-toggle' : 'data-clear',
    };
    // console.log({ value, selectedValues, _options });
  }

  const onChange = (selectedDates) => {
    selectedValues = [...selectedDates];
  };
  // const onValueUpdate = (selectedDates) => {
  //   // @todo this gets triggered with onChange, but do we have a scenario
  //   // where this gets triggered without onChange?
  //   selectedValues = [...selectedDates];
  // };

  onMount(() => {
    initialize();
  });

  onDestroy(() => {
    instance && instance.destroy();
  });
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"
  />
</svelte:head>

<FlatpickrTextfield
  bind:inputEl
  bind:value
  class="dna-flatpickr {positionHack
    ? 'dna-flatpickr--position-hack relative'
    : ''} {className}"
  {...excludeProps($$restProps, [])}
>
  <svelte:fragment slot="inlineSuffix">
    <div
      class:dna-flatpickr-controls="{true}"
      class="flex items-center pointer-events-auto"
      {...dnaProps(stateInfo)}
    >
      <Button
        usage="icon"
        size="sm"
        color="secondary"
        tooltipContent="{options?.noCalendar
          ? ''
          : 'Open the calendar to select the date'}"
        data-toggle
      >
        {#if options?.enableTime && options?.noCalendar}
          <ClockIcon class="h-5 w-5" />
        {:else if !options.inline}
          <CalendarIcon class="h-5 w-5" />
        {/if}
      </Button>
      <Button usage="icon" size="sm" color="danger" data-clear>
        <CloseIcon class="w-5 h-5" />
      </Button>
    </div>
  </svelte:fragment>
</FlatpickrTextfield>

<style global>:global(.dna-flatpickr.dna-flatpickr--position-hack){position:relative}:global(.dna-flatpickr) :global(.dna-button__text){pointer-events:none}:global(.dna-flatpickr-controls[dna-control=data-toggle]) :global(.dna-button[data-clear]){display:none}:global(.dna-flatpickr-controls[dna-control=data-clear]) :global(.dna-button[data-toggle]){display:none}</style>
