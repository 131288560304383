<svelte:options immutable="{true}" />

<script>
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import { Button, Tooltip, tooltip, HelpAltIcon } from 'svelte-tailwind-components';
  import { feedbackAPI } from '../../../../components/feedback/feedback.api.js';
  import { meUser } from '../../../../models/user.model.js';

  let className = '';
  export { className as class };
  export let working = undefined;

  const feedbackQuestion = Drupal?.settings?.lf_admin?.feedback?.question;
  const feedbackThankyou = Drupal?.settings?.lf_admin?.feedback?.thankyou;
  const tooltipParams = { theme: 'lf-quick-feedback', placement: 'auto' };

  let emailEl;
  let closeTooltip;
  let tooltipInstance;

  let name;
  let email;
  let feedbackAnswer = '';

  let errorMessages = {
    name: 'Full name is required.',
    email: 'A valid email address is required.',
    feedbackAnswer: 'Message is required.',
  };
  let errors = {};
  let hasErrors;
  let validated;
  let submitted;

  const reset = (close = true) => {
    name = meUser?.fullName || '';
    email = meUser?.email || '';
    feedbackAnswer = '';
    hasErrors = false;
    validated = false;
    submitted = false;
    close && closeTooltip && closeTooltip();
  };

  const validateFeedback = async () => {
    name = name.trim();
    email = email.trim();
    feedbackAnswer = feedbackAnswer.trim();
    validated = true;
  };

  const submitFeedback = async () => {
    await feedbackAPI.submitResponse({
      ownerId: Drupal?.settings?.lf_svelte?.me?.id,
      name,
      email,
      question: feedbackQuestion,
      response: feedbackAnswer,
      url: window.location.href,
      userAgent: navigator.userAgent,
    });
  };

  $: {
    errors = {
      name: name.trim().length === 0,
      email: email.trim().length === 0 || (emailEl && emailEl.checkValidity() === false),
      feedbackAnswer: feedbackAnswer.trim().length === 0,
    };
    hasErrors = validated && Object.values(errors).filter((v) => v !== false).length > 0;
  }

  const onCancel = () => reset();

  const onSubmit = async () => {
    await validateFeedback();

    if (hasErrors) {
      return;
    }

    try {
      await submitFeedback();
      reset(false);
      submitted = true;
      tooltipInstance.popperInstance.update();
      // window.snacksQueue.add({
      //   label: 'Message Sent',
      //   message: feedbackThankyou,
      //   type: 'success',
      // });
    } catch (err) {
      window.snacksQueue.add({
        label: 'An Error Occurred',
        message:
          'An error occurred submitting your response. Please contact customer support for assistance.',
        type: 'danger',
      });
    }
  };

  reset();
</script>

<Tooltip tooltipParams="{tooltipParams}" class="{className}" bind:closeTooltip bind:tooltipInstance>
  <Button
    use="{[[tooltip, { content: 'Quick Contact' }]]}"
    class="lf-quick-feedback-trigger"
    working="{working}"
    usage="fab"
    color="secondary"
    size="lg"
    uppercase="{false}"
  >
    <HelpAltIcon
      slot="leftIcon"
      class="w-6 h-6 text-white"
      {...excludeProps(prefixFilter($$restProps, 'icon$'), ['class'])}
    />
    Quick Contact
  </Button>
  <div slot="tooltip">
    <div
      class="relative flex flex-col rounded-lg bg-white elevation-6 border-l-8 border-secondary-500 px-4 pt-4 pb-2 mb-8 max-w-sm"
    >
      <div class="text-base leading-5 font-semibold text-secondary-500">
        {!submitted ? 'Quick Contact' : 'Message Sent'}
      </div>
      {#if !submitted}
        {#if feedbackQuestion && feedbackQuestion.length > 0}
          <div class="text-sm leading-5 mt-1 text-gray-600">
            {@html feedbackQuestion}
          </div>
        {/if}
        <div class="flex flex-col mt-4">
          <div class="">
            <input
              bind:value="{name}"
              id="lf-quick-feedback-fullname"
              name="lf-quick-feedback-fullname"
              type="text"
              placeholder="Enter your full name"
              required
            />
            <div
              class:invisible="{!validated || !errors.name}"
              class="text-danger-700 text-sm ml-1"
            >
              {errorMessages.name}
            </div>
          </div>
          <div class="mt-1">
            <input
              bind:this="{emailEl}"
              bind:value="{email}"
              id="lf-quick-feedback-email"
              name="lf-quick-feedback-email"
              type="email"
              placeholder="Enter your email"
              required
            />
            <div
              class:invisible="{!validated || !errors.email}"
              class="text-danger-700 text-sm ml-1"
            >
              {errorMessages.email}
            </div>
          </div>
          <div class="mt-1">
            <textarea
              bind:value="{feedbackAnswer}"
              id="lf-quick-feedback-answer"
              name="lf-quick-feedback-answer"
              required
              cols="60"
              rows="5"></textarea>
            <div
              class:invisible="{!validated || !errors.feedbackAnswer}"
              class="text-danger-700 text-sm ml-1"
            >
              {errorMessages.feedbackAnswer}
            </div>
          </div>
        </div>
        <div class="flex justify-end space-x-4 py-1">
          <Button usage="link" color="danger" size="sm" on:click="{onCancel}">Cancel</Button>
          <Button working="{working}" usage="button" color="primary" size="sm" on:click="{onSubmit}"
            >Submit</Button
          >
        </div>
      {:else}
        <div class="mt-4 text-gray-600">
          {@html feedbackThankyou}
        </div>
        <div class="flex justify-end space-x-4 py-1 mt-2">
          <Button usage="link" color="secondary" size="sm" on:click="{onCancel}">Close</Button>
        </div>
      {/if}
    </div>
  </div>
</Tooltip>

<style lang="postcss">:global(.tippy-box[data-theme~=lf-quick-feedback] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-quick-feedback] .tippy-content){margin:1rem;padding:0}input[type=text]{width:100%}input[type=email]{width:100%}</style>
