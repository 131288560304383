<script>
  /*global Drupal*/
  import { onMount } from 'svelte';

  import {
    getPayments,
    getPendingPaymentItems,
    exportPaymentItems,
    exportPayments,
  } from '../../../../components/instructorPayments/instructorPayments.api.js';
  import {
    pendingColumnsDefault,
    pendingConfigDefault,
    paymentsColumnsDefault,
    paymentsConfigDefault,
  } from '../../../../components/instructorPayments/datatable/datatable.config.js';
  import { logger } from '../../../../utils/logger.js';

  import _findIndex from 'lodash-es/findIndex.js';
  import _find from 'lodash-es/find.js';
  import _filter from 'lodash-es/filter.js';
  import _get from 'lodash-es/get.js';

  import { Button } from 'svelte-tailwind-components';

  import DataTable from '../../../../components/datatable/DataTable.svelte';
  import GeneratePayments from '../../../../components/instructorPayments/GeneratePayments.svelte';
  import Autocomplete from '../../../../components/datatable/filters/Autocomplete.svelte';
  import Account from '../../../../components/reports/Account.svelte';
  import ManagePaymentItem from '../../../../components/instructorPayments/ManagePaymentItem.svelte';
  import UpdatePayment from '../../../../components/instructorPayments/UpdatePayment.svelte';
  import PayNow from '../../../../components/instructorPayments/PayNow.svelte';
  import Spinner from '../../../../components/spinner/Spinner.svelte';

  let className = '';
  export { className as class };

  let data = {};
  let isInitialized = false;
  let showGeneratePayments = false;

  let pendingColumns = [...pendingColumnsDefault];
  let pendingConfig = { ...pendingConfigDefault };
  let paymentsColumns = [...paymentsColumnsDefault];
  let paymentsConfig = { ...paymentsConfigDefault };

  let colInstructor = {
    name: 'instructor',
    title: 'Instructor',
    data: 'instructor',
    sortable: {
      data: 'instructor.fullname',
    },
    component: Account,
  };
  let colPaymentMethod = {
    name: 'payment_method',
    title: 'Payment Method',
    data: 'payment_method',
    sortable: true,
  };

  pendingColumns.unshift({ ...colInstructor });
  pendingColumns.splice(_findIndex(pendingColumns, { name: 'description' }) + 1, 0, {
    ...colPaymentMethod,
  });
  pendingConfig.rowActions = [
    {
      id: 'edit',
      label: 'Edit',
      params: {
        class: 'button--link',
      },
      onClick: onEditPaymentItem,
      conditions: editPaymentItemConditions,
    },
  ];

  paymentsColumns.splice(_findIndex(paymentsColumns, { name: 'payment_id' }) + 1, 0, {
    ...colInstructor,
  });
  paymentsColumns.splice(_findIndex(paymentsColumns, { name: 'description' }) + 1, 0, {
    ...colPaymentMethod,
  });
  paymentsConfig.rowActions = [
    ...paymentsConfig.rowActions,
    {
      id: 'update',
      label: 'Update',
      params: {
        class: 'button--link',
      },
      component: UpdatePayment,
    },
  ];
  paymentsConfig.rowActions = [
    ...paymentsConfig.rowActions,
    {
      id: 'paynow',
      label: 'Pay Now',
      params: {
        class: 'button--link',
      },
      component: PayNow,
    },
  ];

  let pendingFilters = [
    {
      name: 'instructor',
      label: 'Instructor',
      data: {
        value: 'instructor.id',
        label: 'instructor.fullname',
        type: 'number',
      },
      options: [],
      component: Autocomplete,
      componentOptions: {
        sortRules: ['text'],
      },
    },
    {
      name: 'payment_method',
      label: 'Payment Method',
      data: {
        value: 'payment_method',
      },
      options: [
        {
          value: 'stripe',
          label: 'Stripe',
        },
        {
          value: 'paypal',
          label: 'PayPal',
        },
        {
          value: 'mailed_check',
          label: 'Mailed Check',
        },
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'not_set',
          label: 'Not Set',
        },
      ],
      component: Autocomplete,
    },
  ];
  let paymentsFilters = [
    {
      name: 'instructor',
      label: 'Instructor',
      data: {
        value: 'instructor.id',
        label: 'instructor.fullname',
        type: 'number',
      },
      options: [],
      component: Autocomplete,
      componentOptions: {
        sortRules: ['text'],
      },
    },
    {
      name: 'payment_method',
      label: 'Payment Method',
      data: {
        value: 'payment_method',
      },
      options: [
        {
          value: 'stripe',
          label: 'Stripe',
        },
        {
          value: 'paypal',
          label: 'PayPal',
        },
        {
          value: 'mailed_check',
          label: 'Mailed Check',
        },
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'not_set',
          label: 'Not Set',
        },
      ],
      component: Autocomplete,
    },
    {
      name: 'status',
      label: 'Status',
      data: {
        value: 'status',
      },
      options: [
        {
          value: 'funds_pending',
          label: 'Funds Pending',
        },
        {
          value: 'in_progress',
          label: 'In-Progress',
        },
        {
          value: 'paid',
          label: 'Paid',
        },
        {
          value: 'manual',
          label: 'Manual',
        },
      ],
      component: Autocomplete,
    },
  ];

  let paymentItem = { _new: true };
  let isEditing = false;
  let isAdding = false;

  $: pendingPayments = _filter(data.pendingPayments, { payment_id: 0 });

  function updatePaymentItems(updatedPaymentItems) {
    updatedPaymentItems.forEach((updatedPaymentItem) => {
      let foundPaymentItemIndex = _findIndex(data.pendingPayments, {
        id: updatedPaymentItem.id,
      });

      if (foundPaymentItemIndex >= 0) {
        if (data.pendingPayments[foundPaymentItemIndex].payment_id == 0) {
          data.pendingPayments[foundPaymentItemIndex] = {
            ...data.pendingPayments[foundPaymentItemIndex],
            ...updatedPaymentItem,
          };
        } else {
          delete data.pendingPayments[foundPaymentItemIndex];
          data.pendingPayments = [...data.pendingPayments];
        }
      }
    });
  }

  function addPaymentItems(newPaymentItems) {
    data.pendingPayments = [...newPaymentItems, ...data.pendingPayments];
  }

  function addPayments(newPayments) {
    data.payments = [...newPayments, ...data.payments];
  }

  async function onPaymentsGenerated(evt) {
    try {
      logger.debug('onPaymentsGenerated', 'evt', evt);
      let payment = _get(evt, 'detail.data.payment', false);
      let items = _get(evt, 'detail.data.items', []);

      if (payment) {
        addPayments([payment]);
      }
      if (items) {
        updatePaymentItems(items);
      }

      return evt.detail;
    } catch (err) {
      logger.debug('onPaymentsGenerated', 'err', err);
      // @todo
      return false;
    } finally {
      showGeneratePayments = false;
    }
  }

  function onAddPaymentItem() {
    paymentItem = { _new: true };
    isAdding = true;
  }

  function editPaymentItemConditions(paymentItemToEdit) {
    return paymentItemToEdit.payment_id == 0;
  }

  function onEditPaymentItem(paymentItemToEdit) {
    paymentItem = { ...paymentItemToEdit };
    isEditing = true;
  }

  function onPaymentItemCancel() {
    isAdding = false;
    isEditing = false;
  }

  function onPaymentItemCreated(evt) {
    addPaymentItems(evt.detail.pendingPayments);
    addPayments(evt.detail.payments);
    // @todo trigger datatable update (filter values, etc);
    isAdding = false;
  }

  function onPaymentItemUpdated(evt) {
    updatePaymentItems(evt.detail.pendingPayments);
    updatePayments(evt.detail.payments);
    // @todo trigger datatable update (filter values, etc);
    isEditing = false;
  }

  function onShowGeneratePayments() {
    showGeneratePayments = true;
  }

  async function onExportPaymentItemsClick() {
    let params = {
      orderBy: [['created', 'DESC']],
    };
    let res = await exportPaymentItems(params);

    if (res.url && res.url.length > 0) {
      window.snacksQueue.add({
        label: 'CSV File',
        message: `${res.filename} has been generated.`,
        type: 'success',
        settings: {
          persistent: true,
        },
        actions: [
          {
            label: 'Download',
            onClick: () => (window.location.href = res.url),
          },
        ],
      });
    }
  }

  async function onExportPaymentsClick() {
    let params = {
      orderBy: [['created', 'DESC']],
    };
    let res = await exportPayments(params);

    if (res.url && res.url.length > 0) {
      window.snacksQueue.add({
        label: 'CSV File',
        message: `${res.filename} has been generated.`,
        type: 'success',
        settings: {
          persistent: true,
        },
        actions: [
          {
            label: 'Download',
            onClick: () => (window.location.href = res.url),
          },
        ],
      });
    }
  }

  async function initialize() {
    try {
      data.payments = await getPayments();
      data.pendingPayments = await getPendingPaymentItems();
      return Promise.resolve(data);
    } catch (err) {
      return false;
    } finally {
      isInitialized = true;
    }
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  class="
    lf-instructor-payments-report
    {className}
  "
>
  {#if isInitialized}
    <DataTable
      id="reportInstructorPendingPayments"
      title="Pending Payment Items"
      class="lf-instructor-payments-report__pending-payments hoverable-on"
      bind:data="{pendingPayments}"
      config="{pendingConfig}"
      columns="{pendingColumns}"
      bind:filters="{pendingFilters}"
    >
      <div slot="actions">
        <Button color="primary" size="xs" on:click="{onAddPaymentItem}">
          <i class="mr-2 icon-plus"></i>Add Payment Item
        </Button>
        {#if data.pendingPayments.length}
          <Button color="primary" size="xs" on:click="{onShowGeneratePayments}">
            <i class="mr-2 icon-bolt"></i>Generate Payments
          </Button>
        {/if}
        <Button color="primary" size="xs" on:click="{onExportPaymentItemsClick}">
          <i class="mr-2 icon-download-alt"></i>Generate CSV
        </Button>
      </div>
    </DataTable>

    <DataTable
      id="reportInstructorPayments"
      title="Payments (past 6 months)"
      class="lf-instructor-payments-report__payments hoverable-on"
      bind:data="{data.payments}"
      config="{paymentsConfig}"
      columns="{paymentsColumns}"
      bind:filters="{paymentsFilters}"
    >
      <div slot="actions">
        <Button color="primary" size="xs" on:click="{onExportPaymentsClick}">
          <i class="mr-2 icon-download-alt"></i>Generate CSV
        </Button>
      </div>
    </DataTable>

    {#if isEditing || isAdding}
      <ManagePaymentItem
        paymentItem="{paymentItem}"
        isOpen="{isEditing || isAdding}"
        on:paymentItemCancel="{onPaymentItemCancel}"
        on:paymentItemCreated="{onPaymentItemCreated}"
        on:paymentItemUpdated="{onPaymentItemUpdated}"
      />
    {/if}

    {#if showGeneratePayments}
      <GeneratePayments
        pendingPaymentItems="{data.pendingPayments}"
        bind:isOpen="{showGeneratePayments}"
        on:paymentsGenerated="{onPaymentsGenerated}"
      />
    {/if}
  {:else}
    <Spinner size="48" variation="dark" />
  {/if}
</div>

<style lang="postcss">:global(body.page-admin-reports-lessonface .lf-app__body){background-color:unset;box-shadow:none;padding:unset}.lf-instructor-payments-report{font-size:.875rem;min-height:64px}:global(.lf-instructor-payments-report__payments),:global(.lf-instructor-payments-report__pending-payments){background-color:#fff;margin-bottom:20px;padding:1em}:global(.lf-instructor-payments-report__payments .lf-datatable__column--description){max-width:500px}</style>
