<svelte:options immutable="{true}" />

<!--
  @component
  Menu.
-->
<script>
  import { excludeProps, prefixFilter, uuid } from 'svelte-utilities';

  import Tooltip from '../tooltip/Tooltip.svelte';
  import Button from '../buttons/Button.svelte';
  import { MenuIcon } from '../icons';

  // export let use = [];
  export let element = undefined;
  let className = '';
  export { className as class };

  export let id = uuid();
  export let tooltipParams = {};
  // Note: changing the Tooltip hideOnClick to true does not alter the
  // behavior when clicking on content. So we'll track that separately here.
  export let closeOnClick = true;

  export let isOpen = undefined;
  export let openMenu = undefined;
  export let closeMenu = undefined;

  const _defaultTooltipParams = {
    interactiveBorder: 2,
    placement: 'auto',
    theme: 'dna-menu',
    popperOptions: { strategy: 'absolute' },
  };

  // @todo styling attributes.
  // let styleInfo = {};
  // let stateInfo = {};

  const onContentClick = (evt) => closeOnClick && closeMenu(evt);
</script>

<Tooltip
  id="{id}"
  bind:element
  tooltipParams="{{ ..._defaultTooltipParams, ...tooltipParams }}"
  bind:isOpen
  bind:openToolip="{openMenu}"
  bind:closeTooltip="{closeMenu}"
  class="dna-menu {className}"
  {...excludeProps($$restProps, ['trigger$', 'icon$', 'content$'])}
>
  <slot name="trigger">
    <Button usage="icon" size="xs" {...prefixFilter($$restProps, 'trigger$')}>
      <slot name="icon">
        <MenuIcon class="w-5 h-5" {...prefixFilter($$restProps, 'icon$')} />
      </slot>
    </Button>
  </slot>
  <div
    slot="tooltip"
    on:click="{onContentClick}"
    class:dna-menu__content="{true}"
    class="bg-white elevation-6"
    {...prefixFilter($$restProps, 'content$')}
  >
    <slot />
  </div>
</Tooltip>

<style lang="postcss">:global(.dna-menu .dna-menu__content .dna-button){justify-content:flex-start}:global(.dna-menu .dna-menu__content .dna-button:hover){--bg-opacity:1;background-color:#f4f5f7;background-color:rgba(244,245,247,var(--bg-opacity))}:global(.tippy-box[data-theme~=dna-menu] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=dna-menu] .tippy-content){margin:1rem;padding:0}</style>
