<svelte:options immutable="{true}" />

<script>
  import _orderBy from 'lodash-es/orderBy.js';

  import { createEventDispatcher, onMount } from 'svelte';

  import { toJSON, formatDateTime } from 'svelte-utilities';
  import {
    AssignmentIcon,
    AttachmentsIcon,
    Button,
    Dialog,
    FilmIcon,
    NoteIcon,
    PlusIcon,
    Tooltip,
  } from 'svelte-tailwind-components';

  import { itemsListManager } from './lessons.controller.js';
  import { deleteLessonAttachmentFx } from './lessons.controller.js';
  import { getDateKey } from './lessons.utils.js';

  import { notesStore } from '../notes/notes.store.js';
  import { itemsListManager as assignmentsListManager } from '../assignments/assignments.controller.js';
  import { assignmentsStore } from '../assignments/assignments.store.js';
  import { filesStore } from '../files/files.store.js';
  import { me } from '../users/users.store.js';
  import { toAssignmentModel, toFileModel, toNoteModel } from '../../models';
  import { createNoteDrawerController } from '../notes/notes.controller.js';
  import LessonJournalFileLink from './LessonJournalFileLink.svelte';
  import LessonNote from './LessonNote.svelte';
  import LessonJournalAssignment from './LessonJournalAssignment.svelte';
  import NoteInPlaceDrawer from '../notes/NoteInPlaceDrawer.svelte';
  import AddLessonNoteButton from './buttons/AddLessonNoteButton.svelte';
  import AttachmentsButton from '../button/AttachmentsButton.svelte';
  import AddAssignmentButton from '../assignments/AddAssignmentButton.svelte';
  import AssignmentForm from '../assignments/AssignmentForm.svelte';

  import DebugMenu from '../app/DebugMenu.svelte';
  import DebugButton from '../button/DebugButton.svelte';

  export let lesson;

  export let working = false;
  export let tooltipParams = {
    getReferenceClientRect: null,
    theme: 'lf-lesson-detail-journal-actions',
  };

  const dispatch = createEventDispatcher();

  const ctx = {
    session: {
      user: me,
      pageUser: Drupal?.settings?.lf_svelte?.pageUserV2,
    },
    lesson,
    student: lesson?.student,
  };

  const noteDrawerController = createNoteDrawerController('lessonNotesTab');
  const filtersList = [
    {
      id: 'notes',
      label: 'Notes',
      type: 'note',
      icon: NoteIcon,
    },
    {
      id: 'attachments',
      label: 'Attachments',
      type: 'attachment',
      icon: AttachmentsIcon,
    },
    {
      id: 'recordings',
      label: 'Recordings',
      type: 'recording',
      icon: FilmIcon,
    },
    {
      id: 'assignments',
      label: 'Assignments',
      type: 'assignment',
      icon: AssignmentIcon,
    },
  ];
  const itemComponentMap = {
    note: LessonNote,
    assignment: LessonJournalAssignment,
    attachment: LessonJournalFileLink,
    recording: LessonJournalFileLink,
  };
  const noDateDate = new Date(2100, 1, 1).toISOString();

  let scrollWrapperEl;

  let isNoteEditorOpen;
  let sortDir = 'asc';
  let filterOn;
  let activeFilterInfo;

  let isAssignmentFormOpen;
  let activeAssignment;

  let noteItems = [];
  let assignmentItems = [];
  let attachmentItems = [];
  let recordingItems = [];
  let items = [];
  let typeCounts = {};

  let isAddMenuOpen = false;
  let closeAddMenu;

  const toggleFilter = (itemType) => {
    filterOn = itemType !== filterOn ? itemType : undefined;
    activeFilterInfo = filtersList.find((f) => f.type === filterOn);
  };

  const scrollToNote = (noteId) => {
    const noteEl = scrollWrapperEl.querySelector('#lf-note--' + noteId);
    noteEl &&
      noteEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  };

  const scrollToNoteDrawer = () => {
    const tiptapWrapperEl = scrollWrapperEl.querySelector('.lf-tiptap-note-wrapper');
    tiptapWrapperEl && tiptapWrapperEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  $: {
    // Using reactive variable instead of a derived store since the note IDs
    // attached to the lesson may change.
    noteItems = (lesson?.notes ?? []).map((note) => {
      const noteId = note?.id ?? note;
      const _note = $notesStore.find((n) => n.id === noteId) ?? note;
      const item = toNoteModel(_note);
      const datetime = item?.created ?? noDateDate;
      const icon = filtersList.find((f) => f.type === 'note')?.icon;

      return {
        id: `note:${noteId}`,
        type: 'note',
        item,
        datetime,
        icon,
      };
    });
    typeCounts = { ...typeCounts, notes: noteItems.length };

    assignmentItems = (lesson?.assignments ?? [])
      // @todo this removes any student made assignments attached to the store.
      .filter((assignment) => $assignmentsStore.find((a) => a.id === assignment.id))
      .map((assignment) => {
        const assignmentId = assignment?.id ?? assignment;
        const _assignment = $assignmentsStore.find((a) => a.id === assignmentId) ?? assignment;
        const item = toAssignmentModel(_assignment);
        const datetime = item?.createdAt ?? noDateDate;
        const icon = filtersList.find((f) => f.type === 'assignment')?.icon;

        return {
          id: `assignment:${assignmentId}`,
          type: 'assignment',
          item,
          datetime,
          icon,
        };
      });
    typeCounts = { ...typeCounts, assignments: assignmentItems.length };

    attachmentItems = (lesson?.attachments ?? [])
      .map((file) => {
        // If the file contains a url, then return the file object to avoid
        // issues where the fileStore is not updated.
        const fileId = file?.id ?? file;
        return file?.url ? file : $filesStore.find((f) => f.id === fileId);
      })
      .filter((f) => f?.id > 0)
      .map((file) => {
        const fileId = file?.id ?? file;
        const item = toFileModel(file);
        const datetime = file?.created ?? noDateDate;
        const icon = filtersList.find((f) => f.type === 'attachment')?.icon;

        return {
          id: `file:${fileId}`,
          type: 'attachment',
          item,
          datetime,
          icon,
        };
      });
    typeCounts = { ...typeCounts, attachments: attachmentItems.length };

    recordingItems = (lesson?.recordings ?? [])
      .map((file) => {
        // If the file contains a url, then return the file object to avoid
        // issues where the fileStore is not updated.
        const fileId = file?.id ?? file;
        return file?.url ? file : $filesStore.find((f) => f.id === fileId);
      })
      .filter((f) => f?.id > 0)
      .map((file) => {
        const fileId = file?.id ?? file;
        const item = toFileModel(file);
        const datetime = file?.created ?? noDateDate;
        const icon = filtersList.find((f) => f.type === 'recording')?.icon;

        return {
          id: `file:${fileId}`,
          type: 'recording',
          item,
          datetime,
          icon,
        };
      });
    typeCounts = { ...typeCounts, recordings: recordingItems.length };

    items = _orderBy(
      [...noteItems, ...assignmentItems, ...attachmentItems, ...recordingItems].filter(
        (item) => !filterOn || item.type === filterOn
      ),
      ['datetime'],
      [sortDir]
    );
  }

  noteDrawerController.open.watch(() => scrollToNoteDrawer());

  const onOpenNewNote = (evt) => {
    evt.stopPropagation();
    closeAddMenu();
    noteDrawerController.open(evt.detail);
  };

  const onAddAssignmentNote = (evt) => onOpenNewNote(evt);

  const onEditNote = (evt) => {
    evt.stopPropagation();
    noteDrawerController.open({ note: toJSON(evt.detail) });
  };

  const onNoteDeleted = (evt) => {
    evt.stopPropagation();
    noteDrawerController.reset();
    itemsListManager.events.reloadItem({ data: toJSON(lesson), updater: true });
  };

  noteDrawerController.events.saved.watch(({ note, action }) => {
    // const isLessonNote =
    //   note?.targetType === 'lf_lesson' && note?.targetId === lesson.id;
    const isLessonAssignmentNote =
      note?.targetType === 'assignment' && lesson.assignmentIds.includes(note?.targetId);

    if (isLessonAssignmentNote) {
      const assignment = $assignmentsStore.find((a) => a.id === note.targetId);
      assignmentsListManager.events.reloadItem({
        data: assignment,
        updater: true,
      });
    }

    // if (isLessonNote || isLessonAssignmentNote) {
    //   itemsListManager.events.reloadItem({
    //     data: toJSON(lesson),
    //     updater: true,
    //   });
    // }
    itemsListManager.events.reloadItem({
      data: toJSON(lesson),
      updater: true,
    });
    note?.id && action === 'updated' && scrollToNote(note.id);
  });

  const onAddAssignment = (evt) => {
    evt.stopPropagation();
    closeAddMenu();
    activeAssignment = {};
    isAssignmentFormOpen = true;
  };

  const onEditAssignment = (evt) => {
    evt.stopPropagation();
    const { item } = evt.detail;
    activeAssignment = item;
    isAssignmentFormOpen = true;
  };

  const onAssignmentSaved = () => {
    isAssignmentFormOpen = false;
    activeAssignment = undefined;
    itemsListManager.events.reloadItem({ data: toJSON(lesson), updater: true });
  };

  const onAssignmentDeleted = (evt) => {
    evt.stopPropagation();
    itemsListManager.events.reloadItem({ data: toJSON(lesson), updater: true });
  };

  const onAssignmentFormCancel = () => {
    isAssignmentFormOpen = false;
    activeAssignment = undefined;
  };

  const onAddAttachments = (evt) => {
    evt.stopPropagation();
    closeAddMenu();
    dispatch('openAttachments');
  };

  const onDeleteFile = (evt) => {
    const { file } = evt.detail;
    const fileId = file?.id;
    const message = 'File {{ file.name }} has been removed from lesson {{ lesson.title }}';
    const ctx = { file: toJSON(file), snack: { message } };
    deleteLessonAttachmentFx({ lessonId: lesson.id, fileId, ctx });
  };

  onMount(() => {
    scrollWrapperEl = document.querySelector('.lf-lesson-detail__content-wrapper');
  });
</script>

<div
  class:lf-lesson-detail__journal="{true}"
  class="flex-1 flex flex-col border-t border-gray-100 pt-1"
>
  <div class="">
    <slot name="working" />
  </div>
  <NoteInPlaceDrawer
    bind:isOpen="{isNoteEditorOpen}"
    on:noteSaved
    class="lf-lesson-detail__journal-content flex-1 min-h-0 mb-4"
    innerWrapper$class="flex-1 flex items-start overflow-visible overflow-y-visible"
    drawerController="{noteDrawerController}"
  >
    <div
      class:lf-lesson-detail__journal__filters-wrapper="{true}"
      class="sticky flex-shrink-0 flex flex-col items-center space-y-2 pt-2 px-2 md:px-3 lg:pl-5"
    >
      {#each filtersList as filterItem (filterItem.id)}
        <div
          class:lf-lesson-detail__journal__filter="{true}"
          class:lf-lesson-detail__journal__filter--selected="{filterOn === filterItem.type}"
          class="border border-transparent
            {filterOn !== filterItem.type ? 'hover:elevation-2 hover:bg-gray-50' : ''}
            {filterOn === filterItem.type
            ? 'bg-secondary-600 text-white elevation-2 hover:text-secondary-600'
            : ''}
            "
        >
          <Button
            usage="icon"
            color="secondary"
            colorScale="{600}"
            shape="tile"
            flat
            tooltipContent="{filterItem.label}"
            class=""
            on:click="{() => toggleFilter(filterItem.type)}"
          >
            <div class:lf-lesson-detail__journal__filter__icon="{true}" class="p-2">
              <svelte:component this="{filterItem.icon}" class="w-6 h-6" />
              {#if typeCounts[filterItem.id] > 0}
                <span
                  class:lf-lesson-detail__journal__filter__count="{true}"
                  class:text-secondary-600="{filterOn === filterItem.type}"
                  class="absolute right-1 bottom-1 text-xs leading-none rounded-full bg-white px-1 py-0.5 elevation-2"
                >
                  {typeCounts[filterItem.id]}
                </span>
              {/if}
            </div>
            <span class:lf-lesson-detail__journal__filter__label="{true}" class="hidden">
              {filterItem.label}
            </span>
          </Button>
        </div>
      {/each}
    </div>
    <div class:lf-lesson-detail__journal__content-wrapper="{true}" class="flex-1">
      {#if activeFilterInfo}
        <div
          class:lf-lesson-detail__journal__filter-info="{true}"
          class="flex items-center space-x-4 px-2 py-2 md:py-2.5 xl:py-3 lg:pl-0 xl:pl-2 border-b border-gray-100"
        >
          <div
            class:lf-lesson-detail__journal__filter-info__label="{true}"
            class="flex items-center space-x-2"
          >
            <svelte:component this="{activeFilterInfo.icon}" class="w-6 h-6 text-gray-500" />
            <span class="text-lg font-headings leading-none text-gray-600"
              >{activeFilterInfo.label}</span
            >
          </div>
          {#if filterOn === 'note'}
            <AddLessonNoteButton
              on:addNote="{onOpenNewNote}"
              lesson="{lesson}"
              working="{working}"
              usage="fab"
              size="xs"
              color="primary"
              disabled="{isNoteEditorOpen}"
            >
              <PlusIcon slot="leftIcon" class="w-4 h-4" />
            </AddLessonNoteButton>
          {:else if filterOn === 'attachment'}
            <AttachmentsButton
              on:click="{onAddAttachments}"
              working="{working}"
              usage="fab"
              size="xs"
              color="primary"
            >
              <PlusIcon slot="leftIcon" class="w-4 h-4" />
            </AttachmentsButton>
          {:else if filterOn === 'assignment'}
            <AddAssignmentButton
              on:click="{onAddAssignment}"
              working="{working}"
              usage="fab"
              size="xs"
              tooltipContent="Add assignment to the lesson"
              color="primary"
            >
              <PlusIcon slot="leftIcon" class="w-4 h-4" />
            </AddAssignmentButton>
          {/if}
        </div>
      {/if}
      <div
        class:lf-lesson-detail__journal__timeline="{true}"
        class="flex flex-col pl-2 lg:pl-0 xl:pl-2 pr-4 divide-y divide-gray-100"
      >
        {#each items as item, i (item.id)}
          <div class:lf-lesson-detail__journal__item="{true}" class="max-w-screen-md py-3 md:py-4">
            {#if i === 0 || getDateKey(item.datetime) !== getDateKey(items[i - 1].datetime)}
              <div
                class:lf-lesson-detail__journal__item-date-header="{true}"
                class="flex items-center pb-2 text-xs uppercase leading-none text-gray-500"
              >
                {#if false && item?.icon}
                  <span class="hidden md:inline mr-2" class:hidden="{filterOn}">
                    <svelte:component this="{item.icon}" class="w-4 h-4" />
                  </span>
                {/if}
                <span>
                  Lesson updated on <span class="text-gray-600"
                    >{formatDateTime(item.datetime, {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}</span
                  >
                </span>
              </div>
            {/if}

            <div
              class:lf-lesson-detail__journal__item-content="{true}"
              class="md:ml-2 md:pl-4 md:border-l md:border-dotted md:border-gray-100"
            >
              {#if itemComponentMap[item.type]}
                <svelte:component
                  this="{itemComponentMap[item.type]}"
                  lesson="{lesson}"
                  item="{item.item}"
                  ctx="{{ ...ctx, type: item.type, isNoteEditorOpen }}"
                  working="{working}"
                  on:editNote="{onEditNote}"
                  on:addAssignmentNote="{onAddAssignmentNote}"
                  on:noteDeleted
                  on:noteDeleted="{onNoteDeleted}"
                  on:editAssignment="{onEditAssignment}"
                  on:assignmentSaved="{onAssignmentSaved}"
                  on:assignmentDeleted="{onAssignmentDeleted}"
                  on:deleteFile
                  on:deleteFile="{onDeleteFile}"
                  class="w-full"
                />
              {:else}
                @todo item component ({item.id})
              {/if}
            </div>
          </div>
        {:else}
          {#if !filterOn && items?.length === 0}
            <div
              class:lf-lesson-detail__journal__empty="{true}"
              class="inline-flex self-start flex-col space-y-4 p-8"
            >
              <AddLessonNoteButton
                on:addNote="{onOpenNewNote}"
                lesson="{lesson}"
                working="{working}"
                disabled="{isNoteEditorOpen}"
                label="Add Note"
                class="justify-start"
                icon$class="w-6 h-6"
              />
              <AttachmentsButton
                on:click="{onAddAttachments}"
                working="{working}"
                label="Add Attachments"
                color="secondary"
                class="justify-start"
                icon$class="w-6 h-6"
              />
              <AddAssignmentButton
                on:click="{onAddAssignment}"
                working="{working}"
                label="Add Assignment"
                tooltipContent="Add assignment to the lesson"
                class="justify-start"
                icon$class="w-6 h-6"
              />
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </NoteInPlaceDrawer>
  <div
    class:lf-lesson-detail__controls-wrapper="{true}"
    class="flex-shrink-0 bg-white elevation-4 relative sticky bottom-0"
  >
    <div
      class:lf-lesson-detail__controls-inner-wrapper="{true}"
      class="relative max-w-screen-lg h-12 flex items-center justify-center space-x-4 px-4 md:px-8 lg:pl-6 2xl:pr-10"
    >
      <slot name="controlsLeft" />
      <Tooltip
        tooltipParams="{tooltipParams}"
        disableBodyScroll="{!window.matchMedia('(min-width: 768px)').matches}"
        class="lf-lesson-detail__journal__add-actions"
        bind:isOpen="{isAddMenuOpen}"
        bind:closeTooltip="{closeAddMenu}"
      >
        <Button
          usage="fab"
          color="secondary"
          size="xs"
          disabled="{isNoteEditorOpen}"
          tooltipContent="Add to lesson..."
        >
          <PlusIcon class="w-6 h-6" />
        </Button>
        <svelte:fragment slot="tooltip">
          <div
            class:lf-lesson-detail__journal__add-actions-wrapper="{true}"
            class="flex flex-col space-y-1 py-2 px-4 bg-white elevation-4"
          >
            <AddLessonNoteButton
              on:addNote="{onOpenNewNote}"
              lesson="{lesson}"
              working="{working}"
              disabled="{isNoteEditorOpen}"
              label="Add Note"
              class="justify-start"
              icon$class="w-6 h-6"
            />
            <AttachmentsButton
              on:click="{onAddAttachments}"
              working="{working}"
              label="Add Attachments"
              color="secondary"
              class="justify-start"
              icon$class="w-6 h-6"
            />
            <AddAssignmentButton
              on:click="{onAddAssignment}"
              working="{working}"
              label="Add Assignment"
              tooltipContent="Add assignment to the lesson"
              class="justify-start"
              icon$class="w-6 h-6"
            />
          </div>
        </svelte:fragment>
      </Tooltip>
      <slot name="controlsRight" />
      <DebugMenu class="">
        <svelte:fragment slot="items">
          <DebugButton usage="link" eventKey="LESSON" on:click="{() => console.log(lesson)}">
            Debug Lesson
          </DebugButton>
          <DebugButton usage="link" eventKey="LESSONITEMS" on:click="{() => console.log(items)}">
            Debug Lesson Items
          </DebugButton>
        </svelte:fragment>
      </DebugMenu>
    </div>
  </div>
</div>

<Dialog id="lf-lesson-detail-assignment-form-dialog" isModal bind:isOpen="{isAssignmentFormOpen}">
  <AssignmentForm
    assignment="{activeAssignment}"
    ctx="{ctx}"
    working="{working}"
    on:assignmentSaved="{onAssignmentSaved}"
    on:cancel="{onAssignmentFormCancel}"
  />
</Dialog>

<style lang="postcss">.lf-lesson-detail__journal+:global(.lf-lesson-detail__journal){display:none}.lf-lesson-detail__journal__filters-wrapper{top:9rem}@media (min-width:768px){.lf-lesson-detail__journal__filters-wrapper{top:1rem}}.lf-lesson-detail__journal__filter--selected :global(.dna-button__label){--text-opacity:1;color:#fff;color:rgba(255,255,255,var(--text-opacity))}.lf-lesson-detail__journal__content-wrapper :global(.lf-picture-initials){display:none}.lf-lesson-detail__journal__content-wrapper :global(.lf-note-item__body){margin-top:.75rem}.lf-lesson-detail__journal__content-wrapper :global(.lf-note-item__timeago){display:none}.lf-lesson-detail__journal__content-wrapper :global(.lf-assignment-item .lf-note-item__timeago){display:flex;left:auto;right:2.5rem;top:auto}@media (min-width:768px){.lf-lesson-detail__journal__content-wrapper :global(.lf-assignment-item .lf-note-item__timeago){position:absolute;width:auto}}.lf-lesson-detail__journal__content-wrapper :global(.lf-assignment-item .lf-note-item__body){font-size:.875rem;margin-top:.25rem}.lf-lesson-detail__journal__content-wrapper :global(.lf-filelink){margin-left:-.625rem}:global(.deprecate__lf-lesson-detail__journal__add-actions){margin-left:calc(50% - 1rem)}:global(.tippy-box[data-theme~=lf-lesson-detail-journal-actions] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-lesson-detail-journal-actions] .tippy-content){margin:1rem;padding:0}</style>
