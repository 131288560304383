<svelte:options immutable={true} />

<script>
  // @see https://stackoverflow.com/questions/58832476/how-to-display-html5-video-tag-in-silhouette-shape
  import { fade } from 'svelte/transition';
  import { tooltip } from 'svelte-tailwind-components';

  export let id;
  export let videoSrc;
  export let canPlay = false;
  export let blurb = undefined;
  export let pos = undefined;
  export let size = undefined;
  export let mimeType = 'video/mp4';
  export let animation = undefined;
  export let imageSrc = undefined;
  export let grid = undefined;
  export let playCount;

  let video;
  let sizerStyle = '';
  let tooltipParams = { theme: 'lf-hero', content: blurb };

  $: {
    sizerStyle =
      pos && size
        ? `top: ${pos?.top}px; left: ${pos?.left}px; width: ${size?.diameter}px; height: ${size?.diameter}px`
        : undefined;
  }

  const onIntroStart = () => {
    tooltipParams.disabled = false;
  };

  const onOutroEnd = () => {
    tooltipParams.disabled = true;
  };
</script>

{#if canPlay && sizerStyle && animation}
  <div
    use:tooltip={tooltipParams}
    id="lf-hero-grid-{grid.col}:{grid.row}"
    data-play-count={playCount}
    class:lf-hero-video-sizer={true}
    class="absolute"
    style={sizerStyle}>
    <div
      class:lf-hero-video-animation={true}
      id="lf-hero-video--{id}"
      in:fade={animation !== undefined ? animation.in : {}}
      out:fade={animation !== undefined ? animation.out : {}}
      on:introstart
      on:introstart={onIntroStart}
      on:introend
      on:outrostart
      on:outroend
      on:outroend={onOutroEnd}>
      <div
        class:lf-hero-video-container={true}
        class="absolute overflow-hidden top-0 left-0 w-full h-full flex rounded-full">
        <video
          bind:this={video}
          autoplay
          loop
          muted
          poster={imageSrc}
          preload="auto"
          height={size.diameter}
          width={size.diameter}
          class:lf-hero-video={true}
          class="lf-hero-video absolute top-0 left-0 w-full h-full rounded-full">
          <source src={videoSrc} type={mimeType} />
        </video>
      </div>
    </div>
  </div>
{/if}

<style lang="postcss">:global(.tippy-box[data-theme~=lf-hero] .tippy-backdrop){--bg-opacity:1;background-color:#3f9d4b;background-color:rgba(63,157,75,var(--bg-opacity))}:global(.tippy-box[data-theme~=lf-hero] .tippy-content){--text-opacity:1;color:#fff;color:rgba(255,255,255,var(--text-opacity));font-size:1rem;padding:.5rem 1rem}video{height:inherit;max-width:unset}.lf-hero-video-animation{will-change:auto}.lf-hero-video-blurb--left{right:75%}.lf-hero-video-blurb--right{left:75%}.lf-hero-video-blurb--up{bottom:75%}.lf-hero-video-blurb--down{top:75%}.lf-hero-video-sizer:hover .lf-hero-video-blurb{opacity:1}</style>
