<script>
  // Note: This triggers payment via 3rd party (e.g. Stripe, Paypal) or will
  // mark a payment as paid.
  import { onMount, createEventDispatcher } from 'svelte';

  import { payNow, markPaid } from './instructorPayments.api.js';

  // @todo Dialog
  import Button from '../button/Button.svelte';
  import Overlay from '../overlay/Overlay.svelte';

  import { formatCurrency } from 'svelte-utilities';

  export let action = {};
  export let rowData = {};
  export let meta = {};

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  let isInitialized = false;
  let isOpen = false;
  let isWorking = false;
  let actionLabel = '';
  let workingActionLabel = '';

  function dispatchActionStatus() {
    dispatch('actionStatusChange', {
      id: 'update',
      dialog: true,
      isOpen: isOpen,
    })
  }

  $: canPayNow = isInitialized && rowData.status == 'funds_pending' && ['stripe', 'paypal'].includes(rowData.payment_method);
  $: canMarkPaid = isInitialized && !canPayNow && rowData.status !== 'paid';
  $: {
    if (canPayNow) {
      actionLabel = 'Pay Now';
      workingActionLabel = 'Paying';
    }
    else if (canMarkPaid) {
      actionLabel = 'Mark Paid';
      workingActionLabel = 'Marking Paid';
    }
    else {
      actionLabel = '';
      workingActionLabel = '';
    }
  }

  // @todo figure out why applying changes to attributes directly updates the
  // table (rowData) but simply setting the rowData object does not trigger the
  // update.
  function applyUpdates(payment) {
    Object.keys(payment).forEach(key => {
      if (key !== 'id') {
        rowData[key] = payment[key];
      }
    });
  }

  function onClick() {
    isOpen = !isOpen;
    dispatchActionStatus();
  }

  async function performAction() {
    isWorking = true;

    if (canPayNow) {
      await onPayNow();
    }
    else if (canMarkPaid) {
      await onMarkPaid();
    }
    else {
      // @todo
    }

    isWorking = false;
  }

  async function onPayNow() {
    try {
      const res = await payNow(rowData.id);
      applyUpdates(res.payment);
    }
    catch (err) {
      // @todo error.
    }
    finally {
      isOpen = false;
      isWorking = false;
      dispatchActionStatus();
    }
  }

  async function onMarkPaid() {
    try {
      const res = await markPaid(rowData.id);
      applyUpdates(res.payment);
    }
    catch (err) {
      // @todo error.
    }
    finally {
      isOpen = false;
      isWorking = false;
      dispatchActionStatus();
    }
  }

  function onCancel() {
    isOpen = false;
    isWorking = false;
    dispatchActionStatus();
  }

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

{#if isInitialized && actionLabel.length > 0}
  <Button
    on:click={onClick}
    class="{action.params.class}">
    {actionLabel}
  </Button>

  {#if isOpen}
    <div
      class="
        lf-instructor-payment-paynow-form-wrapper
        {className}
        ">
      <Overlay
        fullscreen
        isActive={isOpen}
        />
      <form
        class="lf-instructor-payment-paynow-form"
        on:submit|preventDefault
        >
        <div class="lf-instructor-payment-paynow-form__header">
          <h3 class="lf-instructor-payment-paynow-form__title">{actionLabel}</h3>
        </div>
        <div class="lf-instructor-payment-paynow-form__content">
          <div class="lf-instructor-payment-paynow-form__meta">
            <div><label>Payment ID:</label> #{rowData.id}</div>
            <div><label>Instructor:</label> {rowData.instructor.fullname || rowData.instructor.id || ''}</div>
            <div><label>Amount:</label> {formatCurrency(rowData.amount, rowData.currency_code)}</div>
            <div><label>Payment Method:</label> {rowData.payment_method}<br/>
              {#if rowData.payment_method == 'stripe'}
                {#if canPayNow}
                  <p>Choosing to Pay Now initiates the transfer from the Lessonface Stripe account to the instructor's Stripe account.</p>
                {/if}
              {:else if rowData.payment_method == 'paypal'}
                {#if canPayNow}
                  <p>Choosing to Pay Now initiates the payout from the Lessonface Paypal account to the instructor's Paypal account.</p>
                {/if}
              {/if}
            </div>
          </div>
        </div>

        <div class="lf-instructor-payment-paynow-form__footer">
          <Button
            on:click={performAction}
            bind:isWorking
            isWorkingLabel={workingActionLabel}
            class="button button--primary button--small">
            {actionLabel}
          </Button>
          <Button
            on:click={onCancel}
            class="button button--link button--small">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  {/if}
{/if}

<style lang="postcss">.lf-instructor-payment-paynow-form-wrapper{align-items:center;display:flex;height:100vh;justify-content:center;left:0;position:fixed;top:0;width:100vw}.lf-instructor-payment-paynow-form{background-color:#fff;padding:1em 1.5em;position:relative}.lf-instructor-payment-paynow-form__header{padding:.5em 0}.lf-instructor-payment-paynow-form__title{margin:0}.lf-instructor-payment-paynow-form__content{padding:.5em 0}.lf-instructor-payment-paynow-form__meta{max-width:400px}.lf-instructor-payment-paynow-form__meta label{color:#666;display:inline-block;font-weight:600;min-width:120px}.lf-instructor-payment-paynow-form__meta p{border:1px dashed #ccc;color:#666;margin:.5em 0;padding:4px 8px}.lf-instructor-payment-paynow-form__footer{padding:.5em 0}</style>
