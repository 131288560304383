<script>
  import { onMount, createEventDispatcher } from 'svelte';
  // @todo Dialog
  import Button from '../button/Button.svelte';
  import Overlay from '../overlay/Overlay.svelte';
  import {
    amountToDecimal,
    decimalToAmount,
    formatCurrency,
  } from 'svelte-utilities';
  import { savePaymentItem, getInstructors } from './instructorPayments.api.js';

  export let paymentItem = { _new: true };

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  let isInitialized = false;
  let isNew = paymentItem._new || paymentItem.id === 0 || false;
  let id = 'lf-instructor-payment-item--' + (isNew ? 'new' : paymentItem.id);
  let formTitle = isNew ? 'Add Payment Item' : 'Edit Payment Item';
  let itemData = !isNew ? paymentItem.data || {} : {};
  let adminNote = itemData.admin_note || '';

  let isSaving = false;

  paymentItem.amount = paymentItem.amount || 0;
  paymentItem.fee_percentage = paymentItem.fee_percentage || 0;
  paymentItem.fee_amount = paymentItem.fee_amount || 0;
  paymentItem.payment_amount = paymentItem.payment_amount || 0;

  let feePercentage = paymentItem.fee_percentage;

  let instructors = [];

  $: price = amountToDecimal(paymentItem.amount);
  $: feePrice = formatCurrency(paymentItem.fee_amount);
  $: paymentPrice = formatCurrency(paymentItem.payment_amount);
  $: instructorUid =
    paymentItem.uid ||
    (paymentItem.instructor ? paymentItem.instructor.id : '');

  function setPaymentItemPricingInfo(target) {
    if (['amount', 'fee_percentage', 'instructor'].includes(target.name)) {
      let value = target.value.replace(/[^0-9.]/g, '');

      if (target.name == 'amount') {
        value = validateMin(0, value);
        paymentItem.amount = decimalToAmount(value);
      } else if (target.name == 'fee_percentage') {
        value = validateMin(0, value);
        paymentItem.fee_percentage = parseFloat(value);
      } else if (target.name == 'instructor') {
        value = validateMin(1, value);
        paymentItem.uid = parseInt(value);
      }

      paymentItem.fee_amount = Math.round(
        paymentItem.amount * (parseFloat(paymentItem.fee_percentage) / 100)
      );
      paymentItem.payment_amount = paymentItem.amount - paymentItem.fee_amount;
    }
  }

  function validateMin(min, value) {
    if (parseFloat(value) < parseFloat(min)) {
      return min;
    }

    return value;
  }

  function validateMax(max, value) {
    if (parseFloat(value) > parseFloat(max)) {
      return max;
    }

    return value;
  }

  function validateRange(min, max, value) {
    if (parseFloat(min) > parseFloat(max)) {
      return value;
    }

    value = validateMin(min, value);
    value = validateMax(max, value);

    return value;
  }

  function onCancel(evt) {
    dispatch('paymentItemCancel');
  }

  function onInput(evt) {
    setPaymentItemPricingInfo(evt.target);
  }

  function onChange(evt) {
    setPaymentItemPricingInfo(evt.target);
  }

  async function onSave(evt) {
    isSaving = true;
    // @todo validation?
    let event = isNew ? 'paymentItemCreated' : 'paymentItemUpdated';

    try {
      const res = await savePaymentItem(paymentItem, adminNote);
      dispatch(event, res);
    } catch (err) {
      // @todo error
      console.warn(err);
    } finally {
      isSaving = false;
    }
  }

  async function initialize() {
    if (isNew) {
      instructors = await getInstructors();
    }

    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  class="
    lf-instructor-payment-item-form-wrapper
    {className}
    ">
  <Overlay fullscreen isActive />
  <form
    class="lf-instructor-payment-item-form"
    on:input={onInput}
    on:change={onChange}
    on:submit|preventDefault>
    <div class="lf-instructor-payment-item-form__header">
      <h3 class="lf-instructor-payment-item-form__title">{formTitle}</h3>
    </div>
    <div class="lf-instructor-payment-item-form__content">
      {#if isInitialized}
        <div class="form-item form-type-textfield">
          <label for="{id}--instructor"
            >Instructor UID <span
              class="form-required"
              title="This field is required.">*</span
            ></label>
          {#if !isNew}
            <input
              value={instructorUid}
              class="text-full form-text required"
              type="text"
              id="{id}--instructor"
              name="instructor"
              disabled
              size="60"
              maxlength="128" />
          {:else if false}
            <input
              value={instructorUid}
              class="text-full form-text required"
              type="text"
              id="{id}--instructor"
              name="instructor"
              list="instructors-list"
              size="60"
              maxlength="128" />
            <datalist id="instructors-list">
              {#each instructors as instructor (instructor.id)}
                <option data-value={instructor.id}
                  >{instructor.fullname} (#{instructor.id})</option>
              {/each}
            </datalist>
          {:else}
            <input
              value={instructorUid}
              class="text-full form-text required"
              type="text"
              id="{id}--instructor"
              name="instructor"
              required
              inputmode="numeric"
              pattern="[0-9]*"
              size="60"
              maxlength="128" />
          {/if}
        </div>
        <div class="form-item form-type-textfield">
          <label for="{id}--description">Description</label>
          <input
            bind:value={paymentItem.description}
            class="text-full form-text"
            type="text"
            id="{id}--description"
            name="description"
            size="60"
            maxlength="255" />
        </div>

        <div class="lf-instructor-payment-item-form__payment-info">
          <div class="form-item form-type-textfield">
            <label for="{id}--price"
              >Amount <span
                class="form-required"
                title="This field is required.">*</span
              ></label>
            <input
              value={price}
              class="text-full form-text required"
              type="text"
              id="{id}--price"
              name="amount"
              inputmode="numeric"
              pattern="[0-9]+([\.][0-9]+)?"
              size="5"
              maxlength="10" />
          </div>
          <div class="form-item form-type-textfield">
            <label for="{id}--fee-percentage"
              >Fee % <span class="form-required" title="This field is required."
                >*</span
              ></label>
            <input
              value={feePercentage}
              class="text-full form-text required"
              type="text"
              id="{id}--fee-percentage"
              name="fee_percentage"
              inputmode="numeric"
              pattern="[0-9]+([\.][0-9]+)?"
              size="5"
              maxlength="10" />
          </div>
          <div
            class="form-item form-type-textfield lf-instructor-payment-item-form__fee-amount">
            <label for="{id}--fee-price">Fee Amount</label>
            <div id="{id}--fee-price">{feePrice}</div>
          </div>
          <div
            class="form-item form-type-textfield lf-instructor-payment-item-form__payment-amount">
            <label for="{id}--payment-price">Payment Amount</label>
            <div id="{id}--payment-price">{paymentPrice}</div>
          </div>
        </div>

        <div class="form-item form-type-textarea">
          <label for="{id}--admin-notes">Admin Notes</label>
          <div class="form-textarea-wrapper resizable resizable-textarea">
            <textarea
              bind:value={adminNote}
              class="text-full form-textarea"
              id="{id}--admin-notes"
              name="admin_notes"
              cols="60"
              rows="3" />
            <div class="grippie" />
          </div>
          <div class="description">
            Admin notes are <strong>not</strong> visible to instructors.
          </div>
        </div>
      {/if}
    </div>
    <div class="lf-instructor-payment-item-form__footer">
      {#if isInitialized}
        <Button
          on:click={onSave}
          disabled={isSaving}
          class="button button--primary button--small">
          Save
        </Button>
        <Button on:click={onCancel} class="button button--link button--small">
          Cancel
        </Button>
      {/if}
    </div>
  </form>
</div>

<style lang="postcss">.lf-instructor-payment-item-form-wrapper{align-items:center;display:flex;height:100vh;justify-content:center;left:0;position:fixed;top:0;width:100vw}.lf-instructor-payment-item-form{background-color:#fff;padding:1em 1.5em;position:relative}.lf-instructor-payment-item-form__header{padding:.5em 0}.lf-instructor-payment-item-form__title{margin:0}.lf-instructor-payment-item-form__content{padding:.5em 0}.lf-instructor-payment-item-form__payment-info{align-items:center;display:flex;justify-content:flex-start}.lf-instructor-payment-item-form__payment-info>.form-item+.form-item{margin-left:1em}.lf-instructor-payment-item-form__fee-amount>div{border:1px solid transparent;line-height:24px;padding:4px 0;text-align:right}.lf-instructor-payment-item-form__payment-amount,.lf-instructor-payment-item-form__payment-amount label{font-weight:700}.lf-instructor-payment-item-form__payment-amount>div{background-color:#eee;border:1px solid transparent;line-height:24px;padding:4px 8px;text-align:right}.lf-instructor-payment-item-form__footer{padding:.5em 0}</style>
