<svelte:options immutable="{true}" />

<script>
  import {
    formatDateTime,
    excludeProps,
    prefixFilter,
    getTimezoneIdentifier,
  } from 'svelte-utilities';
  import { StatusPinger, tooltip } from 'svelte-tailwind-components';
  import LessonTitle from './LessonTitle.svelte';
  import LessonStatus from './LessonStatus.svelte';
  import LessonMetaIndicators from './LessonMetaIndicators.svelte';
  import LessonTimeBlock from './LessonTimeBlock.svelte';
  import LessonDateBlock from './LessonDateBlock.svelte';
  import JoinLessonButton from './buttons/JoinLessonButton.svelte';

  let className = '';
  export { className as class };
  export let lesson;

  export let selectable = true;
  export let isSelected = false;
  export let dateTooltipParams = undefined;
  export let displayDateBlock = true;
  export let inlineActions = [];

  let element;
  let showDateTimeBG;

  $: {
    dateTooltipParams = dateTooltipParams || {
      offset: [0, 0],
      placement: 'right',
      content:
        !lesson?.startTime || lesson?.isSchedulePending
          ? 'Not Scheduled'
          : formatDateTime(lesson.startTime, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour12: true,
              hour: 'numeric',
              minute: '2-digit',
            }) +
            ' ' +
            getTimezoneIdentifier(),
    };
    showDateTimeBG = isSelected || displayDateBlock;
  }
</script>

<div
  bind:this="{element}"
  class:lf-lesson-item="{true}"
  class="flex items-stretch space-x-2 {className}"
  {...excludeProps($$restProps, ['dateBlock$', 'title$'])}
>
  <div
    use:tooltip="{dateTooltipParams}"
    class:lf-lesson-item__timeblock-wrapper="{true}"
    class="min-w-0 flex justify-end items-center font-inter"
  >
    <div
      class:mt-4="{!displayDateBlock && lesson?.startTime}"
      class="p-1 relative flex items-center justify-end space-x-1"
    >
      <div
        class:lf-item-hover-indicator="{selectable && !isSelected}"
        class:opacity-0="{!showDateTimeBG}"
        class:scale-50="{!showDateTimeBG}"
        class:hover:opacity-100="{selectable && !showDateTimeBG}"
        class:hover:scale-100="{selectable && !showDateTimeBG}"
        class:bg-secondary-600="{isSelected || !displayDateBlock}"
        class:bg-gray-500="{!isSelected && displayDateBlock}"
        class:rounded="{true}"
        class="absolute top-0 right-0 bottom-0 left-0 transition ease-in-out duration-200 transform"
      ></div>
      <div
        class:lf-lesson-item-hover-indicator="{selectable && !isSelected}"
        class:opacity-50="{lesson.isCancelled}"
        class:line-through="{lesson.isCancelled}"
        class:text-gray-800="{!showDateTimeBG && !lesson.isCancelled && lesson.priority.high}"
        class:text-gray-500="{!showDateTimeBG && !lesson.isCancelled && !lesson.priority.high}"
        class:text-white="{showDateTimeBG}"
        class="relative flex flex-col justify-end w-12"
      >
        {#if displayDateBlock}
          <LessonDateBlock
            lesson="{lesson}"
            class="z-10"
            {...prefixFilter($$restProps, 'dateBlock$')}
          />
        {/if}
        <LessonTimeBlock
          lesson="{lesson}"
          class="z-10 pb-1"
          block$class="{lesson.priority.low ? '' : 'font-semibold'}"
        />
      </div>
      <div
        class:-mt-4="{!displayDateBlock && lesson.startTime}"
        class:-mt-1="{!displayDateBlock && !lesson.startTime}"
        class="w-3 flex justify-center items-center"
      >
        <StatusPinger
          pinging="{lesson.canJoin}"
          size="{lesson.priority.low ? 1 : 2}"
          color="{lesson.priority.low ? 'gray' : lesson.canJoin ? 'primary' : 'secondary'}"
          colorScale="{lesson.priority.high ? 500 : 300}"
        />
      </div>
    </div>
  </div>
  <div
    class:lf-lesson-item__content-wrapper="{true}"
    class:opacity-50="{lesson.isCancelled}"
    class="flex-1 min-w-0 flex items-center space-x-2 pl-4 pr-3 rounded-lg bg-white elevation-3 overflow-hidden"
  >
    <div class="flex-1 min-w-0 flex flex-col">
      <LessonStatus
        lesson="{lesson}"
        class="text-xs leading-4 uppercase flex flex-wrap items-center"
      >
        {#if lesson?.canJoin && inlineActions.includes('join')}
          <div class="absolute top-4 right-8">
            <JoinLessonButton lesson="{lesson}" size="xs" class="leading-none" />
          </div>
        {/if}
      </LessonStatus>
      <LessonTitle
        lesson="{lesson}"
        class="min-w-0 truncate text-base leading-5 text-gray-800 font-semibold font-inter"
        {...prefixFilter($$restProps, 'title$')}
      />
      <slot name="byline" lesson="{lesson}" />
    </div>
    <span
      class="absolute right-8 bottom-4 text-xs leading-none text-gray-600 opacity-50 hover:opacity-100"
    >
      #{lesson.id}
    </span>
    <LessonMetaIndicators lesson="{lesson}" />
  </div>
</div>

<style lang="postcss">:global(.lf-virtual-list-item-hover-wrapper:hover) .lf-lesson-item-hover-indicator{--text-opacity:1;color:#fff;color:rgba(255,255,255,var(--text-opacity))}</style>
