<svelte:options immutable="{true}" />

<script>
  import { derived } from 'svelte/store';
  import {
    deepGet,
    excludeProps,
    formatDateTime,
    formatPlural,
    getWeekday,
    getTimezoneIdentifier,
    stripHTML,
  } from 'svelte-utilities';
  import {
    AvatarIcon,
    Button,
    CalendarIcon,
    UpArrowIcon,
    Menu,
    SettingsIcon,
    StatusPinger,
    TimeIcon,
    TimeUntil,
  } from 'svelte-tailwind-components';
  import { isAdmin } from '../users/users.utils.js';
  import { actionComponents, formattedTime, statusMeta } from './lessons.utils.js';
  import { filesAPI } from '../files/files.api.js';
  import { isSmallDevice } from '../app/app.controller.js';
  import { addLessonAttachmentsFx, deleteLessonAttachmentFx } from './lessons.controller.js';
  import { lessonsStore } from './lessons.store.js';
  import { toLessonModel } from '../../models/lesson.model.js';
  import BackButton from '../app/BackButton.svelte';
  import UppyDashboard from '../uppy/UppyDashboard.svelte';
  import MediaAttachmentsMenu from './MediaAttachmentsMenu.svelte';
  import SchedulePendingIcon from './buttons/SchedulePendingIcon.svelte';
  import SoftDeleteLessonButton from './buttons/SoftDeleteLessonButton.svelte';
  import EditLessonButton from './buttons/EditLessonButton.svelte';
  import SendLessonReminderButton from './buttons/SendLessonReminderButton.svelte';
  import ViewLessonLogsButton from './buttons/ViewLessonLogsButton.svelte';
  import LessonDetailJournal from './LessonDetailJournal.svelte';
  import MiniContactCard from '../contacts/MiniContactCard.svelte';
  import PictureInitials from '../users/PictureInitials.svelte';
  import Fullname from '../users/Fullname.svelte';

  let className = '';
  export { className as class };
  export let lesson;
  export let app;
  export let sizerTargetEl = undefined;
  export let working;

  const { itemsListManager } = app;

  const otherPartyLessons = derived(
    [itemsListManager, lessonsStore],
    ([$itemsListManager, $lessonsStore]) => {
      const { selectedItem } = $itemsListManager;
      const selectedLesson = toLessonModel(selectedItem);
      const lessons =
        selectedLesson?.id > 0
          ? $lessonsStore
              .filter((l) => {
                const isSameParty = selectedLesson?.isInstructor
                  ? selectedLesson?.student?.userId === l?.student?.userId
                  : selectedLesson?.instructor?.userId === l?.instructor?.userId;
                const isScheduled =
                  l.startTime?.length > 0 &&
                  ['scheduled', 'acknowledged'].includes(l.scheduleStatus);
                return isSameParty && isScheduled;
              })
              .sort((a, b) => {
                return a.startTime === b.startTime ? 0 : a.startTime > b.startTime ? 1 : -1;
              })
          : [];
      const currentIndex = lessons.findIndex((l) => l.id === selectedLesson?.id);
      const prevLesson = currentIndex >= 0 ? lessons[currentIndex - 1] : undefined;
      const nextLesson = currentIndex >= 0 ? lessons[currentIndex + 1] : undefined;
      return { lessons, selectedLesson, prevLesson, nextLesson };
    }
  );

  let uppyWorking;
  let addFxState;
  let deleteFxState;

  let currentState;

  let { settings, description } = deepGet(Drupal.settings, 'lf_svelte.uppy.lesson.attachments', {});
  let uppy;
  let uppyConfig = {
    settings,
    handlers: {
      // @todo need error handling / error messages around these.
      getSignedUrl: filesAPI.getUppySignedURL,
      uploadSuccess: filesAPI.getUppyUploadSuccess,
    },
    plugins: {
      dashboard: {
        note: stripHTML(description),
      },
    },
  };
  let openDashboard;

  const getSchedulingActions = () =>
    lesson?.stateActions
      ? lesson.stateActions.filter((action) =>
          ['schedule', 'reschedule', 'unschedule', 'cancel'].includes(action)
        )
      : [];
  const getNonSchedulingActions = () =>
    lesson?.stateActions
      ? lesson.stateActions.filter(
          (action) => !['schedule', 'reschedule', 'unschedule', 'cancel'].includes(action)
        )
      : [];

  $: {
    uppyWorking = $uppy && $uppy.working;
    addFxState = addLessonAttachmentsFx.state;
    deleteFxState = deleteLessonAttachmentFx.state;
    working = uppyWorking || $addFxState || $deleteFxState;
  }

  const onOpenAttachments = () => uppy && openDashboard();

  // This is only for attachments directly on the lesson. Note attachments
  // are attached to notes and also sync'd to the lesson.
  const onUploadsCompleted = (evt) => {
    const uploadedFiles = evt.detail;
    const fileIds = uploadedFiles.map((f) => f.fid);
    const message = '{{ attachedCountLabel }} been add to lesson {{ lesson.title }}';
    const ctx = {
      attachedCountLabel: formatPlural(
        fileIds.length,
        '{{ count }} attachment has',
        '{{ count }} attachments have'
      ),
      files: uploadedFiles,
      snack: { message },
    };
    fileIds.length > 0 && addLessonAttachmentsFx({ lessonId: lesson.id, fileIds, ctx });
  };

  const onGotoLesson = (evt, item) => {
    evt.stopPropagation();
    itemsListManager.events.selectItem({ item, scroll: true });
  };
</script>

<article
  class:hidden="{!lesson?.id}"
  class:lf-lesson-detail="{true}"
  class="{className}"
  {...excludeProps($$restProps, [])}>
  <div
    class:lf-lesson-detail__header="{true}"
    class="sticky top-0 z-20 relative flex flex-col bg-secondary-100 elevation-4">
    <div
      bind:this="{sizerTargetEl}"
      class:lf-lesson-detail__header-content="{true}"
      class="relative px-4 max-w-screen-lg 2xl:ml-0 2xl:ml-8 pt-2 pb-3 sm:px-6 lg:pb-6 lg:px-8 lg:pt-4 xl:h-32 flex flex-col justify-end flex-shrink-0">
      <div class="-ml-2 lg:hidden">
        <BackButton on:viewList usage="link" color="primary" label="Lessons" />
      </div>
      <div>
        <span
          class="h-4 text-xs xl:text-sm leading-none uppercase text-gray-600 flex items-center space-x-4">
          {#if lesson?.isSubscription}
            <span class="text-gray-600">Subscription</span>
          {:else if lesson?.isInvite}
            <span class="text-gray-600">Invite</span>
          {/if}
        </span>
        <h1 class="m-0 text-xl leading-6 md:text-2xl md:leading-7 text-secondary-600 font-inter">
          {lesson?.title}
        </h1>
        {#if lesson?.subscription && lesson?.subscription?.frequency && lesson?.subscription?.startTime}
          <div class="text-sm text-gray-600 leading-6 whitespace-no-wrap">
            Occurs {lesson?.subscription?.frequency === 'weekly' ? 'every' : 'every other'}
            {getWeekday(lesson.subscription.startTime, 'long')}
          </div>
        {:else if lesson?.startTime}
          <div class="text-sm xl:text-base text-gray-600 leading-6 font-inter">
            {formatDateTime(lesson.startTime, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour12: true,
              hour: 'numeric',
              minute: '2-digit',
            })}
            {getTimezoneIdentifier()}
          </div>
        {/if}
      </div>
      <div
        class:lf-lesson-detail__header-controls="{true}"
        class="absolute top-1.5 right-4 lg:right-5 lg:top-2 flex items-center space-x-4">
        <MediaAttachmentsMenu
          lesson="{lesson}"
          uppy="{uppy}"
          working="{working}"
          label="Add Attachments"
          tooltipParams="{{ placement: 'bottom-start' }}"
          on:openAttachments="{onOpenAttachments}"
          icon$class="w-5 h-5" />
        {#if isAdmin()}
          <Menu openFrom="top right" trigger$size="lg" trigger$color="secondary">
            <SettingsIcon class="w-5 h-5" slot="triggerContent" />
            <div class="flex flex-col p-3 space-y-1">
              <SendLessonReminderButton lesson="{lesson}" ajax icon$class="w-5 h-5"
                >Send Reminder</SendLessonReminderButton>
              <EditLessonButton lesson="{lesson}" ajax icon$class="w-5 h-5">Edit</EditLessonButton>
              <SoftDeleteLessonButton lesson="{lesson}" ajax icon$class="w-5 h-5"
                >Soft Delete</SoftDeleteLessonButton>
              <ViewLessonLogsButton lesson="{lesson}" icon$class="w-5 h-5"
                >View Logs</ViewLessonLogsButton>
            </div>
          </Menu>
        {/if}
      </div>
      <div class="absolute right-6 top-12 text-xs leading-none text-secondary-600 opacity-50">
        #{lesson?.id}
      </div>
    </div>
  </div>

  <div
    class:lf-lesson-detail__content-wrapper="{true}"
    class="flex flex-col flex-1 w-full md:overflow-y-auto">
    <div
      class="relative flex items-start space-x-4 px-4 pt-5 pb-4 max-w-screen-lg lg:px-8 xl:ml-0 2xl:ml-8 bg-white">
      <div class="relative flex flex-col space-y-4 flex-1">
        {#if lesson?.canJoin || lesson?.needsAttention}
          <div
            class:text-primary-600="{lesson.canJoin}"
            class:text-danger-700="{lesson.needsAttention && !lesson.canJoin}">
            <div class="flex items-center space-x-2">
              <span class="font-semibold">{lesson.stateLabel}</span>
              {#if statusMeta[currentState]?.pingerProps}
                <StatusPinger size="{2}" {...statusMeta[currentState].pingerProps} />
              {/if}
            </div>
            {#if lesson.stateHelp}
              <div class="my-1 text-sm leading-4">
                {lesson.stateHelp}
              </div>
            {/if}
            {#key lesson?.id}
              {#if getNonSchedulingActions()}
                <div class="mt-4 flex items-center space-x-4">
                  {#each getNonSchedulingActions() as action, i (action)}
                    <svelte:component
                      this="{actionComponents[action].component}"
                      lesson="{lesson}"
                      usage="{i === 0 ? 'button' : 'link'}"
                      size="sm"
                      icon$class="w-4 h-4"
                      {...deepGet(actionComponents[action], 'props', {})} />
                  {/each}
                </div>
              {/if}
            {/key}
          </div>
        {/if}

        <div class="flex items-start">
          <div class="flex flex-col text-gray-800 pt-1" class:hidden="{true}">
            {#if lesson?.schedulePending}
              <span class="flex items-center space-x-4 leading-6 mt-px">
                <SchedulePendingIcon class="w-5 h-5" />
                <span class="text-sm uppercase">Pending</span>
              </span>
            {:else if lesson?.startTime}
              <span class="flex items-center space-x-4 leading-6 mt-px">
                <CalendarIcon class="w-5 h-5 text-gray-400" />
                <span class="uppercase whitespace-no-wrap">
                  {formatDateTime(lesson.startTime, {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </span>
              </span>
              <span class="leading-6 mt-2.5 flex items-center space-x-4">
                <TimeIcon class="w-5 h-5 text-gray-400" />
                <span class="whitespace-no-wrap">
                  {formattedTime(lesson.startTime, false)} - {formattedTime(lesson.endTime)}
                  {getTimezoneIdentifier()}
                </span>
              </span>
              {#key lesson?.startTime}
                <TimeUntil
                  datetime="{lesson.startTime}"
                  maxHours="{6}"
                  tokens="{{
                    hour: 'hr',
                    hours: 'hrs',
                    minute: 'min',
                    minutes: 'mins',
                  }}"
                  class="ml-8 pl-1 text-sm leading-none normal-case text-danger-700">
                  <span slot="prefix">Starts</span>
                </TimeUntil>
              {/key}
            {/if}

            {#if lesson?.student?.isChild}
              <span class="leading-6 mt-2.5 flex items-center space-x-4">
                <AvatarIcon class="w-5 h-5 text-gray-400" />
                <span class="whitespace-no-wrap">{lesson.student.firstName}</span>
              </span>
            {/if}
          </div>

          <div class="flex flex-wrap items-center space-x-0.5 -ml-2">
            {#key lesson?.id}
              {#if getSchedulingActions()}
                {#each getSchedulingActions() as action (action)}
                  <svelte:component
                    this="{actionComponents[action].component}"
                    lesson="{lesson}"
                    usage="link"
                    size="sm"
                    icon$class="w-4 h-4"
                    {...deepGet(actionComponents[action], 'props', {})} />
                {/each}
              {/if}
            {/key}
          </div>
        </div>

        {#if lesson?.otherParty?.isContact}
          <div class="flex items-center -ml-2">
            <MiniContactCard
              on:addNote="{(evt) => app.noteDrawerController.open(evt.detail)}"
              on:noteDeleted="{() => app.noteDrawerController.reset()}"
              on:sendMessage="{(evt) => app.messageDialogController.open(evt.detail)}"
              tooltipParams="{{
                placement: isSmallDevice() ? undefined : 'right',
              }}"
              contact="{lesson.otherParty}" />
          </div>
        {:else if lesson?.otherParty}
          <div class="flex items-center space-x-2">
            <PictureInitials contact="{lesson.otherParty}" size="sm" />
            <span class="text-base leading-none text-gray-800">
              <Fullname
                account="{lesson.otherParty}"
                defaultValue="{lesson?.invite?.email || 'Unknown'}" />
            </span>
          </div>
        {/if}
      </div>
    </div>

    {#if lesson?.id > 0}
      {#key lesson.id}
        <LessonDetailJournal
          lesson="{lesson}"
          working="{working}"
          on:openAttachments="{onOpenAttachments}">
          <slot name="working" slot="working" />
          <svelte:fragment slot="controlsLeft">
            {#if $otherPartyLessons?.prevLesson?.id > 0}
              <Button
                usage="link"
                size="sm"
                color="secondary"
                tooltipContent="{lesson?.otherParty?.fullName
                  ? `View previous lesson with ${lesson.otherParty.fullName}`
                  : undefined}"
                on:click="{(evt) => onGotoLesson(evt, $otherPartyLessons.prevLesson)}">
                <span class="flex items-center space-x-2">
                  <UpArrowIcon class="w-8 h-8 transform -rotate-90" />
                  <span>
                    {formatDateTime($otherPartyLessons.prevLesson.startTime, {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                  <span class="hidden md:inline">Lesson</span>
                </span>
              </Button>
            {/if}
          </svelte:fragment>
          <svelte:fragment slot="controlsRight">
            {#if $otherPartyLessons?.nextLesson?.id > 0}
              <Button
                usage="link"
                size="sm"
                color="secondary"
                tooltipContent="{lesson?.otherParty?.fullName
                  ? `View next lesson with ${lesson.otherParty.fullName}`
                  : undefined}"
                on:click="{(evt) => onGotoLesson(evt, $otherPartyLessons.nextLesson)}">
                <span class="flex items-center space-x-2">
                  <span
                    >{formatDateTime($otherPartyLessons.nextLesson.startTime, {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                  <span class="hidden md:inline">Lesson</span>
                  <UpArrowIcon class="w-8 h-8 transform rotate-90" />
                </span>
              </Button>
            {/if}
          </svelte:fragment>
        </LessonDetailJournal>
      {/key}
    {/if}
  </div>

  {#if lesson?.id > 0}
    <UppyDashboard
      bind:uppy
      bind:openDashboard
      on:uploadsCompleted="{onUploadsCompleted}"
      uppyConfig="{{
        ...uppyConfig,
        meta: { entityId: lesson.id, entityType: 'lf_lesson' },
      }}"
      identifier="LESSON:ATTACHMENTS" />
  {/if}
</article>

<style lang="postcss">.lf-lesson-detail{height:calc(100vh - var(--lf-header-height, 71px));overscroll-behavior-y:none}.lf-lesson-detail__content-wrapper{scroll-behavior:smooth}</style>
