<script>
  import { classNamer } from 'svelte-utilities';

  export let column = {};

  let className = '';
  export { className as class };

  let sortNextMap = {
    asc: 'desc',
    desc: 'none',
    none: 'asc',
  };
  let sortIconMap = {
    none: 'icon-sort',
    asc: 'icon-sort-down',
    desc: 'icon-sort-up',
  };

  $: nextSort = column.sortable && !!+column.sorted.length ? sortNextMap[column.sorted] : 'none';

  function onClick(evt) {
    if (column.sortable) {
      column.sorted = nextSort;
    }
  }
</script>

<th
  on:click={onClick}
  class:lf-datatable__column--sortable={column.sortable}
  class="
    lf-datatable__column--{classNamer(column.name)}
    lf-datatable__column-type--{classNamer(column.type)}
    {className}
  "
  title={column.sortable ? `Click to sort ${nextSort}` : ''}
  >
  <slot/>

  {#if column.sortable}
    <i class="{sortIconMap[column.sorted]}"></i>
  {/if}
</th>

<style lang="postcss">th{background-color:unset;border:unset}.lf-datatable__column--sortable{cursor:pointer}.lf-datatable__column--sortable:hover{color:#b94a48}.lf-datatable__column--sortable:hover i{color:#999}i{color:#ccc;margin-left:.5em}i.icon-sort-down,i.icon-sort-up{color:#b94a48}</style>
