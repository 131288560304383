<script>
  /*global Drupal*/
  import { onMount } from 'svelte';
  import parseFullName from 'parse-full-name';
  import PaymentsNav from '../../../components/instructorPayments/PaymentsNav.svelte';
  import Link from '../../../components/link/Link.svelte';
  import Textfield from '../../../components/textfield/Textfield.svelte';
  import Checkbox from '../../../components/checkbox/Checkbox.svelte';
  import Button from '../../../components/button/Button.svelte';
  import Spinner from '../../../components/spinner/Spinner.svelte';
  import { fetcher } from 'svelte-utilities';
  import { logger } from '../../../utils/logger.js';

  import _get from 'lodash-es/get.js';

  let className = '';
  export { className as class };

  let paymentMethod = '_none';
  let label = 'Choose Payment Method';
  let fullName = _get(Drupal.settings, 'lf_svelte.pageUser.fullname');
  let userName = parseFullName.parseFullName(fullName);
  let errorMessage = '';
  let isInitialized = false;
  let isChanging = false;
  let isSaving = false;

  let paypalInfo = {
    email: '',
    country: '',
  };

  let addressInfo = {
    formatted: '',
    components: [],
  };

  let labels = {
    stripe: 'Direct Deposit',
    paypal: 'Paypal',
    mailed_check: 'Mailed Check',
    none: 'None',
  };

  let stripeClientId = _get(Drupal.settings, 'lf_stripe.connect.clientId', '');
  let stripeUserEmail = _get(Drupal.settings, 'lf_svelte.pageUser.email', '');
  let stripeUserUrl = _get(
    Drupal.settings,
    'lf_svelte.pageUser.referralLink',
    'https://www.lessonface.com'
  );

  let countryHelpText = _get(Drupal.settings, 'lf_instructor_payments.paypal_payout_fee_info', '');

  $: existingPaymentMethod = _get(
    Drupal.settings,
    `lf_instructor_payments.paymentMethods[${paymentMethod}]`,
    false
  );

  function onStripeConnecting() {
    errorMessage = '';
  }

  function onChangePaymentMethod() {
    isChanging = true;
  }

  function onCancelChange() {
    isChanging = false;
    paymentMethod = _get(Drupal.settings, 'lf_instructor_payments.activePaymentMethod');
  }

  function onPaypalCountryChange(evt) {
    paypalInfo.country = evt.target.checked ? 'US' : 'Other';
  }

  async function onStripeSave() {
    await setPaymentMethod({});
  }

  async function onPaypalSave() {
    await setPaymentMethod({ paypal: paypalInfo });
  }

  async function onMailedCheckSave() {
    await setPaymentMethod({ address: addressInfo });
  }

  async function onNoneSave() {
    await setPaymentMethod({});
  }

  async function setPaymentMethod(data) {
    isSaving = true;

    try {
      const res = await fetcher('/api/v1/payment-method/set', {
        paymentMethod,
        data,
        accountId: Drupal.settings.lf_svelte.pageUser.uid,
      });
      paymentMethod = _get(res, 'data.payment_method', paymentMethod);

      logger.debug('setPaymentMethod', 'res', res);
      return res.data ? res.data : res;
    } catch (err) {
      logger.debug('setPaymentMethod', 'err', err);
      throw new Error(err.message || err);
    } finally {
      isSaving = false;
      isChanging = false;
    }
  }

  async function initialize() {
    paymentMethod = Drupal.settings.lf_instructor_payments.activePaymentMethod;
    paypalInfo = _get(Drupal.settings, 'lf_instructor_payments.paymentMethods.paypal.data.paypal', {
      email: _get(Drupal.settings, 'lf_svelte.pageUser.email', ''),
      country: '',
      locked: false,
    });

    if (paypalInfo.locked) {
      countryHelpText = 'Contact an administrator to change Country designation.';
    }

    addressInfo = _get(
      Drupal.settings,
      'lf_instructor_payments.paymentMethods.mailed_check.data.address',
      { formatted: '', components: {} }
    );
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  class="
    lf-instructor-payments-setup
    hoverable-on
    form-item
    form-type-select
    {className}
    "
>
  {#if isInitialized}
    <PaymentsNav tabId="setup" />
    {#if !isChanging && existingPaymentMethod}
      <div class="lf-instructor-payments-setup__existing">
        <div class="lf-instructor-payments-setup__existing__label">
          <span>Current Payment Method:</span>
          {labels[paymentMethod]}
        </div>
        <div class="lf-instructor-payments-setup__existing__details">
          {#if existingPaymentMethod.payment_method == 'paypal'}
            Email: {paypalInfo.email} ({paypalInfo.country == 'US'
              ? 'United States'
              : 'International'})
          {:else if existingPaymentMethod.payment_method == 'mailed_check'}
            Address: {addressInfo.formatted}
          {:else if existingPaymentMethod.payment_method == 'stripe'}
            {#if existingPaymentMethod && !!+existingPaymentMethod.connectLink.length}
              <Link
                on:click="{onStripeConnecting}"
                path="{existingPaymentMethod.connectLink}"
                isAbsolute
                attributes="{{
                  target: '_blank',
                }}"
              >
                Update Direct Deposit Information
              </Link>
            {/if}
          {/if}
        </div>
        <Button on:click="{onChangePaymentMethod}" class="button button--primary button--small">
          Change Payment Method
        </Button>
      </div>
    {:else}
      <label for="lf-instructor-payments-setup__payment-method" class="element-invisible">
        {label}
        <span class="form-required" title="This field is required.">*</span>
      </label>
      <select
        bind:value="{paymentMethod}"
        id="lf-instructor-payments-setup__payment-method"
        name="paymentMethod"
        required
        class="form-select required"
      >
        <option value="_none" disabled
          >{label.charAt(0).toUpperCase() + label.substring(1).toLowerCase()}...</option
        >
        <option value="stripe">Direct Deposit</option>
        <option value="paypal">Paypal</option>
        <option value="mailed_check">Mailed Check</option>
        <option value="none">None</option>
      </select>
      <div class="description">
        Please choose the method that you would like to receive payments from Lessonface.
      </div>

      {#if !!+errorMessage.length}
        <div class="lf-instructor-payments-setup__error">
          {errorMessage}
        </div>
      {/if}

      <div
        class="
          lf-instructor-payments-setup__payment-method
          lf-instructor-payments-setup__payment-method--{paymentMethod.replace('_', '-')}
          "
      >
        {#if paymentMethod == 'stripe'}
          <div
            class:form-actions="{existingPaymentMethod &&
              !!+existingPaymentMethod.connectLink.length}"
          >
            {#if existingPaymentMethod && !!+existingPaymentMethod.connectLink.length}
              <Button
                bind:isWorking="{isSaving}"
                isWorkingLabel="Saving"
                on:click="{onStripeSave}"
                class="button button--primary button--small"
              >
                Save
              </Button>
            {:else}
              <p>Connect your account to Stripe below for direct deposit.</p>
              <Link
                on:click="{onStripeConnecting}"
                path="https://connect.stripe.com/express/oauth/authorize"
                isAbsolute
                query="{{
                  'response_type': 'code',
                  'client_id': stripeClientId,
                  'redirect_uri': window.location.origin + '/payments/setup',
                  'suggested_capabilities[]': 'transfers',
                  'stripe_user[email]': stripeUserEmail,
                  'stripe_user[url]': stripeUserUrl,
                  'stripe_user[first_name]': userName.first,
                  'stripe_user[last_name]': userName.last,
                }}"
              >
                <img
                  src="/sites/all/modules/custom/lf_instructor_payments/images/stripe-connect.png"
                  alt="Connect with Stripe"
                />
              </Link>
            {/if}
            <Button on:click="{onCancelChange}" class="button button--link button--small">
              Cancel
            </Button>
          </div>
        {:else if paymentMethod == 'paypal'}
          <Textfield
            name="email"
            bind:value="{paypalInfo.email}"
            label="Paypal Email"
            required
            helpText="Enter your paypal email address."
            makeSpace="{false}"
          />
          <Checkbox
            id="country"
            name="country"
            label="I am based in the United States"
            checked="{paypalInfo.country == 'US'}"
            disabled="{paypalInfo.locked}"
            on:click="{onPaypalCountryChange}"
            helpText="{countryHelpText}"
          />
          <div class="form-actions">
            <Button
              bind:isWorking="{isSaving}"
              isWorkingLabel="Saving"
              on:click="{onPaypalSave}"
              class="button button--primary button--small"
            >
              Save
            </Button>
            <Button on:click="{onCancelChange}" class="button button--link button--small">
              Cancel
            </Button>
          </div>
        {:else if paymentMethod == 'mailed_check'}
          <div class="form-item form-type-textarea lf-instructor-payments-setup__address-wrapper">
            <label for="lf-instructor-payments-setup__address">
              Address
              <span class="form-required" title="This field is required.">*</span>
            </label>
            <div class="form-textarea-wrapper resizable resizable-textarea">
              <textarea
                bind:value="{addressInfo.formatted}"
                class="text-full form-textarea"
                id="lf-instructor-payments-setup__address"
                name="lf-instructor-payments-setup__address"
                required
                cols="60"
                rows="3"></textarea>
              <div class="grippie"></div>
            </div>
          </div>
          <div class="form-actions">
            <Button
              bind:isWorking="{isSaving}"
              isWorkingLabel="Saving"
              on:click="{onMailedCheckSave}"
              class="button button--primary button--small"
            >
              Save
            </Button>
            <Button on:click="{onCancelChange}" class="button button--link button--small">
              Cancel
            </Button>
          </div>
        {:else if paymentMethod == 'none'}
          <div class="form-actions">
            <Button
              bind:isWorking="{isSaving}"
              isWorkingLabel="Saving"
              on:click="{onNoneSave}"
              class="button button--primary button--small"
            >
              Save
            </Button>
            <Button on:click="{onCancelChange}" class="button button--link button--small">
              Cancel
            </Button>
          </div>
        {/if}
      </div>
    {/if}
  {:else}
    <Spinner size="48" variation="dark" />
  {/if}
</div>

<style lang="postcss">.lf-instructor-payments-setup{background-color:#fff;min-height:64px;padding:1em 2em}.lf-instructor-payments-setup__existing{font-size:.875rem}.lf-instructor-payments-setup__existing__label{font-weight:700}.lf-instructor-payments-setup__existing__label span{font-weight:400}.lf-instructor-payments-setup__existing__details{margin:.5em 0 1em}.lf-instructor-payments-setup__address-wrapper{max-width:400px}.lf-instructor-payments-setup__payment-method{padding:1em 0 0}.lf-instructor-payments-setup :global(.form-type-textfield){max-width:300px}.lf-instructor-payments-setup__error{border:1px dotted #b94a48;color:#b94a48;font-size:.875rem;margin:1em 0;padding:.5em 1em}</style>
