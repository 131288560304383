<svelte:options immutable="{true}" />

<script>
  import { excludeProps } from 'svelte-utilities';
  import { getVisibleFolderList } from '../items/items.utils.js';
  import ScrollingFolderButton from './buttons/ScrollingFolderButton.svelte';

  let className = '';
  export { className as class };
  export let state;
  export let folderComponents;
  export let folderCounts;

  let visibleFolderList;

  $: {
    visibleFolderList = getVisibleFolderList(folderComponents, $folderCounts);
  }
</script>

{#if visibleFolderList.length > 1}
  <div
    class:lf-app-scrolling-folders-wrapper="{true}"
    class="flex items-center overflow-x-auto rounded-lg"
  >
    <nav
      class:lf-app-scrolling-folders="{true}"
      class="flex items-center space-x-0.5 px-1 py-1 bg-gray-100 shadow-inner {className}"
      aria-label="Folders"
      {...excludeProps($$restProps, [])}
    >
      {#each visibleFolderList as folder (folder)}
        <div
          class:lf-app-scrolling-folders__item-wrapper="{true}"
          class:pointer-events-none="{folder === $state.currentFolder}"
          class:bg-opacity-0="{folder !== $state.currentFolder}"
          class:bg-transparent="{folder !== $state.currentFolder}"
          class:bg-opacity-100="{folder === $state.currentFolder}"
          class:elevation-4="{folder === $state.currentFolder}"
          class="flex-shrink-0 flex items-center py-0.5 rounded-md bg-white transition-colors ease-in-out duration-200 hover:bg-opacity-50"
        >
          <ScrollingFolderButton
            folder="{folder}"
            on:selectFolder
            displayLabel="{folder === $state.currentFolder}"
            folderComponent="{folderComponents[folder]}"
            folderCount="{$folderCounts[folder]}"
          />
        </div>
      {/each}
    </nav>
  </div>
{/if}

<style lang="postcss">.lf-app-scrolling-folders__item-wrapper{flex:0 0 auto}</style>
