<script>
  import { slide } from 'svelte/transition';
  import { deepMerge, excludeProps } from 'svelte-utilities';

  let className = '';
  export { className as class };
  export let color = 'primary';
  export let colorScale = 500;
  export let settings = {};
  export let working;

  let _settings = deepMerge(
    {
      indeterminate: true,
      transparentBg: false,
    },
    settings
  );
</script>

<div
  role="progressbar"
  class:dna-progress-linear="{true}"
  class:dna-progress-linear--indeterminate="{_settings.indeterminate}"
  class:bg-gray-200="{!_settings.transparentBg}"
  class:hidden="{!working}"
  class:relative="{!className.includes('absolute')}"
  class="w-full h-1 overflow-hidden {className}"
  transition:slide="{{ duration: 300 }}"
  {...excludeProps($$restProps, ['class', 'color', 'colorScale', 'settings'])}
>
  <div
    class:dna-progress-linear__line="{true}"
    class:dna-progress-linear--inc="{_settings.indeterminate}"
    class="absolute h-full bg-{color}-{colorScale}"
  ></div>
  <div
    class:dna-progress-linear__line="{true}"
    class:dna-progress-linear--dec="{_settings.indeterminate}"
    class:hidden="{!_settings.indeterminate}"
    class="absolute h-full bg-{color}-{colorScale}"
  ></div>
</div>

<style lang="postcss">.dna-progress-linear--inc{animation:increase 2s ease-in-out infinite}.dna-progress-linear--dec{animation:decrease 2s ease-in-out .9s infinite}@keyframes increase{0%{left:-5%;width:5%}to{left:130%;width:150%}}@keyframes decrease{0%{left:-90%;width:90%}to{left:110%;width:10%}}</style>
