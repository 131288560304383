<svelte:options immutable="{true}" />

<script>
  import { excludeProps, prefixFilter, prefixFilterItem } from 'svelte-utilities';
  import { mousetrap } from 'svelte-tailwind-components';

  let className = '';
  export { className as class };
  export let title = '';
  export let mousetrapParams = { disabled: true };
  export let navEl = undefined;
</script>

<nav
  bind:this="{navEl}"
  use:mousetrap="{mousetrapParams}"
  tabindex="0"
  class:lf-virtual-list-wrapper="{true}"
  class="relative flex-1 min-h-0 outline-none flex flex-col {className}"
  aria-label="{title} List"
  {...excludeProps($$restProps, ['use', 'inner$'])}
>
  <div
    class:lf-virtual-list-inner-wrapper="{true}"
    class="relative h-full bg-gray-50 rounded-lg shadow-inner border border-gray-100
    {prefixFilterItem($$restProps, 'class', 'inner$', '')}"
    {...excludeProps(prefixFilter($$restProps, 'inner$'), ['class'])}
  >
    <slot name="preInner" />
    <slot />
    <slot name="postInner" />
  </div>
</nav>

<style lang="postcss">.lf-virtual-list-wrapper:focus .lf-virtual-list-inner-wrapper{--bg-opacity:1;background-color:#dde6f1;background-color:rgba(221,230,241,var(--bg-opacity))}</style>
