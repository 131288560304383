<svelte:options immutable="{true}" />

<script>
  import { onMount, afterUpdate } from 'svelte';
  import {
    deepGet,
    excludeProps,
    prefixFilter,
    prefixFilterItem,
    timestamp,
  } from 'svelte-utilities';
  import { cleanHistoryState, getItemIdFromHash, setItemHistoryState } from './app.utils.js';
  import { hideChatWidget } from '../zendesk/zendesk.utils.js';
  import { isSmallDevice } from './app.controller.js';
  import { ajaxDone } from '../../utils/useAjax.js';
  import AjaxHandler from '../button/AjaxHandler.svelte';
  import MessageDialog from '../messages/MessageDialog.svelte';
  import NoteDrawer from '../notes/NoteDrawer.svelte';

  let className = '';
  export { className as class };
  export let app;

  export let messageSizerTargetEl = undefined;
  export let noteSizerTargetEl = undefined;

  const { itemsListManager } = app;
  const { itemsStore, searchedItemsStore } = itemsListManager;

  let wrapperEl;
  let asideEl;
  let navEl;
  let mainEl;

  // @todo getting a small flash using the contactsListManager working state
  // so using the delayedWorking approach here to avoid that.
  let delayedWorking = false;

  app.messageDialogController.open.watch(() => app.noteDrawerController.reset());
  app.noteDrawerController.open.watch(() => app.messageDialogController.reset());

  itemsListManager.events.selectItem.watch(({ item }) => {
    app.messageDialogController.reset();
    app.noteDrawerController.reset();
    // When the viewDetail event fires, if on a larger device then set the
    // focus back to the list to allow for keyboard navigation.
    !isSmallDevice() && navEl && navEl.focus();
    // Set the history state for the item.
    setItemHistoryState(item);
  });
  itemsListManager.events.clearSelectedItem.watch(() => {
    history.state !== null && history.back();
  });

  $: {
    setTimeout(() => {
      delayedWorking = $itemsListManager.initialized && $itemsListManager.working;
    }, 100);
  }

  const autoViewDetail = () => {
    if ($itemsListManager.initialized && !$itemsListManager.selectedItem) {
      // If the user is not currently viewing item details, then check if an item
      // is passed via the url and view that item.
      const itemId = getItemIdFromHash();
      itemId && cleanHistoryState();
      // If the URL hash contains an id then we want to viewDetail of that item,
      // however we have to wait until the itemsStore is initialized from the
      // loadData effect, otherwise the loadItem may finish first and that data
      // is overwritten. Since we always call loadData we can watch for that to
      // complete and then check the hash.
      if ($searchedItemsStore && $searchedItemsStore.length > 0) {
        const item = $itemsStore.find((item) => deepGet(item, 'id') === itemId);
        item && itemsListManager.events.selectItem({ item, scroll: true });
        // If there is not url-based item to view and the user is not on a small
        // device (where the list and detail are both now shown) then attempt to
        // load the first item.
        !isSmallDevice() &&
          !item &&
          $searchedItemsStore.length > 0 &&
          itemsListManager.events.selectItem({ item: $searchedItemsStore[0] });
      }
    }
  };

  const handleAjaxResponses = (evt) => {
    if (evt?.detail?.jsonItems.length > 0) {
      const ctx = {
        selectedItem: $itemsListManager.selectedItem,
      };
      ajaxDone({ data: evt.detail.jsonItems, ctx });
    }
  };

  afterUpdate(() => {
    setTimeout(() => autoViewDetail(), 500);
  });

  const setupSizeInfo = () => {
    const headerEl = document.querySelector('#lf-header');
    const headerHeight = headerEl.offsetHeight;
    const adminMenuHeight = document.body.classList.contains('adminimal-menu') ? 30 : 0;

    document.body.classList.add('lf-svelte-app');
    document.body.classList.add('lf-svelte-app--page');
    // Extra pixel is to avoid scroll bar.
    document.body.style.setProperty(
      '--lf-header-height',
      headerHeight + adminMenuHeight + 1 + 'px'
    );
    document.body.style.setProperty('--lf-admin-menu-height', adminMenuHeight + 'px');
  };

  const setupAutoRefresh = () => {
    document.addEventListener('visibilitychange', () => {
      // Wrapping in a try/catch to avoid breaking older browsers.
      try {
        const now = timestamp(new Date());
        const delay = 15 * 60;
        const isVisible = document.visibilityState === 'visible';

        if (isVisible && now - delay >= $app.lastRefreshedAt) {
          app.events.refreshItems({ state: $itemsListManager });
        }
      } catch (err) {
        console.warn('Unable to auto-refresh data. Most likely a browser support issue.');
      }
    });
  };

  onMount(() => {
    setupSizeInfo();
    isSmallDevice() && hideChatWidget();
    setupAutoRefresh();
  });
</script>

<AjaxHandler on:ajaxDone="{handleAjaxResponses}" />

<div
  bind:this="{wrapperEl}"
  class:lf-app-page-wrapper="{true}"
  class:lf-app-page-wrapper--small-device="{isSmallDevice()}"
  class:lf-app-page-wrapper--show-detail="{$itemsListManager.selectedItem}"
  class="h-full w-full flex flex-col lg:flex-row flex-1 min-w-0 relative z-0 bg-white border-t border-gray-100 {className}"
  {...excludeProps($$restProps, [
    'backButton$',
    'aside$',
    'main$',
    'nav$',
    'list$',
    'virtualListItemWrapper$',
  ])}
>
  <aside
    bind:this="{asideEl}"
    class:lf-app-page-list="{true}"
    class="relative h-full flex flex-col w-full transition ease-in-out duration-200 opacity-100 lg:max-w-sm xl:max-w-md lg:border-r border-gray-100 z-10
    {prefixFilterItem($$restProps, 'class', 'aside$', '')}"
    {...excludeProps(prefixFilter($$restProps, 'aside$'), ['class'])}
  >
    <slot name="listing" />
  </aside>

  <main
    bind:this="{mainEl}"
    class:lf-app-page-detail="{true}"
    class="absolute top-0 left-0 w-full lg:static lg:flex-1 lg:min-w-0 lg:h-full transition ease-in-out duration-300 transform translate-x-full lg:translate-x-0 focus:outline-none z-0
        {prefixFilterItem($$restProps, 'class', 'main$', '')}"
    {...excludeProps(prefixFilter($$restProps, 'main$'), ['class'])}
  >
    <slot name="detail" working="{delayedWorking}" />
  </main>
</div>

{#if messageSizerTargetEl}
  <MessageDialog
    dialogController="{app.messageDialogController}"
    {...prefixFilter($$restProps, 'messageDialog$')}
  />
{/if}

{#if noteSizerTargetEl}
  <NoteDrawer
    drawerController="{app.noteDrawerController}"
    {...prefixFilter($$restProps, 'noteDrawer$')}
    sizerTargetEl="{noteSizerTargetEl}"
  />
{/if}

<style lang="postcss">.lf-app-page-list{height:calc(100vh - var(--lf-header-height, 71px))}.lf-app-page-wrapper--show-detail .lf-app-page-list{opacity:0}@media (min-width:1024px){.lf-app-page-wrapper--show-detail .lf-app-page-list{opacity:1}}.lf-app-page-wrapper--show-detail .lf-app-page-detail{--transform-translate-x:0;z-index:10}@media (min-width:1024px){.lf-app-page-wrapper--show-detail .lf-app-page-detail{z-index:0}}</style>
