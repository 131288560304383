<script>
  import Button from '../button/Button.svelte';

  export let page = 1;
  export let pageSize = 20;
  export let totalItems = 1;
  export let miniPager = false;
  export let displayInfo = true;
  // @todo
  export let changeable = false;

  let className = '';
  export { className as class };

  $: lastPage = Math.ceil(totalItems / pageSize);

  function goFirst() {
    page = 1;
  }
  function goPrev() {
    page = page > 1 ? page - 1 : 1;
  }
  function goNext() {
    page = page < lastPage ? page + 1 : lastPage;
  }
  function goLast() {
    page = lastPage;
  }
</script>

<div
  class="
    lf-datatable__pager
    lf-datatable-pager-wrapper
    {className}
  ">
  {#if !miniPager}
    <Button
      on:click={goFirst}
      disabled={page == 1}
      class="button button--link">
      <i class="icon-double-angle-left"></i>
    </Button>
  {/if}
  <Button
    on:click={goPrev}
    disabled={page == 1}
    class="button button--link">
    <i class="icon-angle-left"></i>
  </Button>
  {#if displayInfo}
    <div class="lf-datatable__pager__info">
      Page {page} / {lastPage}
    </div>
  {/if}
  <Button
    on:click={goNext}
    disabled={page == lastPage}
    class="button button--link">
    <i class="icon-angle-right"></i>
  </Button>
  {#if !miniPager}
    <Button
      on:click={goLast}
      disabled={page == lastPage}
      class="button button--link">
      <i class="icon-double-angle-right"></i>
    </Button>
  {/if}
</div>

<style lang="postcss">.lf-datatable-pager-wrapper{--btn-padding-x:.5em;--btn-padding-y:.5em;--btn-font-size:1.5em;align-items:center;display:flex;justify-content:space-between}.lf-datatable-pager-wrapper .lf-datatable__pager__info{margin:0 1em}</style>
