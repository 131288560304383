<script>
  import _get from 'lodash-es/get.js';
  import {
    excludeProps,
    getDay,
    getMonth,
    getOrdinal,
    getTime,
    getTimezoneIdentifier,
    getWeekday,
    trimLeft,
  } from 'svelte-utilities';
  import {
    AddToGoogle,
    Button,
    Dialog,
    ICSEvent,
    LocationIcon,
    Menu,
  } from 'svelte-tailwind-components';
  import {
    getClassStartStatus,
    getGroupURL,
    getJoinURL,
    canJoinClass,
    isCurrentPage,
    // isClassFull,
  } from '../groupClasses/groupClasses.utils.js';
  import ClassDetailsCard from './ClassDetailsCard.svelte';
  import DebugDialog from '../dev/DebugDialog.svelte';

  let className = '';
  export { className as class };
  export let groupClass = {};
  export let settings = {};
  export let isWorking = false;

  const currentUser = _get(Drupal.settings, 'lf_svelte.currentUser', {});
  const isSuperAdmin = Object.values(_get(currentUser, 'roles', {})).includes('Super Admin');
  const isDebugMode = _get(Drupal.settings, 'lf_svelte.debug', false);
  const canEdit = _get(Drupal.settings, 'lf_lessongroups.adminControls.edit', false);

  const productDisplayTitle = _get(groupClass, 'product_displays.title');
  const productDisplayImageURL = _get(groupClass, 'product_displays.image.url');
  const title = _get(groupClass, 'title') || productDisplayTitle;
  const instructorId = _get(groupClass, 'instructor.id');
  const instructorName = _get(groupClass, 'instructor.fullname');
  const isInstructor =
    instructorId && instructorId > 0 && instructorId === _get(currentUser, 'uid', 0);
  const description = _get(groupClass, 'description', '');
  const categoryName = _get(groupClass, 'category.name');
  const classLocation =
    _get(groupClass, 'in_person', 0) > 0 ? _get(groupClass, 'location', '') : '';

  const nowDT = new Date();
  const timezoneShort = getTimezoneIdentifier(nowDT.toISOString());

  const classStart = _get(groupClass, 'datetime.start');
  const classEnd = _get(groupClass, 'datetime.end');
  const month = getMonth(classStart);
  const day = getDay(classStart);
  const dayOrdinal = getOrdinal(day);
  const startTime = getTime(classStart);
  const endTime = getTime(classEnd);
  const weekday = getWeekday(classStart);
  const isPast = getClassStartStatus(groupClass, true, true) == 'ended';

  const groupLesson = _get(groupClass, 'group_lesson');
  const groupLessonId = _get(groupLesson, 'id', groupLesson);
  const icsFilename = `lessonface-class-${groupLessonId}-${groupClass.id}`;
  const eventData = {
    location: 'Lessonface.com',
  };

  let isOpen = false;
  let showDetailsDialog = false;
  let showDebugDialog = false;
  let hasLicense = _get(groupClass, 'has_license', false);
  let hasAccess = isInstructor || hasLicense || canEdit;
  let canJoin = canJoinClass(groupClass, hasAccess);
  let joinURL = canJoin ? getJoinURL(groupClass) : '';
  let groupUrl = getGroupURL(groupClass, hasAccess);
  let calDescription;

  // Lazy load the image but keep hidden... so it quickly appears in the
  // dialog.
  let groupImageURL =
    hasAccess || !productDisplayImageURL
      ? _get(groupClass, 'group_lesson.image.url')
      : productDisplayImageURL;

  let recordings = hasAccess ? _get(groupClass, 'recordings', []) : [];
  let attachments = hasAccess ? _get(groupClass, 'attachments', []) : [];
  let showIndicators =
    (recordings && recordings.length > 0) || (attachments && attachments.length > 0);

  let itemEl;
  let props = excludeProps($$props, ['use', 'class', 'groupClass', 'settings', 'hasLicense']);

  $: {
    hasLicense = _get(groupClass, 'has_license', false);
    hasAccess = isInstructor || hasLicense || canEdit;
    canJoin = canJoinClass(groupClass, hasAccess);
    joinURL = canJoin ? getJoinURL(groupClass) : '';
    groupUrl = getGroupURL(groupClass, hasAccess);
    calDescription = [];

    if (joinURL?.length > 0) {
      calDescription = [joinURL];
    }

    if (classLocation?.length > 0) {
      calDescription = [...calDescription, classLocation];
    }

    if (description?.length > 0) {
      calDescription = [...calDescription, description];
    }

    calDescription = calDescription.join('\n\n');

    // Lazy load the image but keep hidden... so it quickly appears in the
    // dialog.
    groupImageURL =
      hasAccess || !productDisplayImageURL
        ? _get(groupClass, 'group_lesson.image.url')
        : productDisplayImageURL;

    recordings = hasAccess ? _get(groupClass, 'recordings', []) : [];
    attachments = hasAccess ? _get(groupClass, 'attachments', []) : [];
    showIndicators =
      (recordings && recordings.length > 0) || (attachments && attachments.length > 0);
  }

  function onItemClick(evt) {
    let controlsEl = itemEl.querySelector('.lf-class-navigator-item__controls');

    if (groupUrl && !isCurrentPage(groupUrl)) {
      window.location.href = groupUrl;
    }

    else if (!controlsEl.contains(evt.target)) {
      showDetailsDialog = true;
    }
  }
</script>

<div
  bind:this="{itemEl}"
  class="lf-class-navigator-item flex cursor-pointer {className}"
  on:click="{onItemClick}"
  {...props}
>
  <div class="flex flex-col items-start self-start flex-grow-0 flex-shrink-0 w-12 mr-4 group">
    <div
      class:bg-gray-400="{isPast}"
      class:bg-secondary-600="{!isPast && !canJoin}"
      class:bg-primary-600="{canJoin}"
      class="flex flex-col items-start flex-grow-0 flex-shrink-0 w-12 pr-2 text-white lf-class-navigator-item__date"
    >
      <div
        class="w-full h-6 pt-2 text-xs text-right uppercase leading-4 lf-class-navigator-item__month"
      >
        {month}
      </div>
      <div
        class="w-full h-6 pb-1 text-sm text-right leading-4 font-inter lf-class-navigator-item__day"
      >
        {day}{dayOrdinal}
      </div>
    </div>
    <div
      class="lf-class-navigator-item__id text-gray-300 group-hover:text-gray-500 text-xs text-center w-full"
    >
      #{groupClass.id}
    </div>
  </div>
  <div class="lf-class-navigator-item__content flex-grow flex flex-col">
    <div
      class="lf-class-navigator-item__meta flex items-center text-xs sm:text-sm leading-5 uppercase text-secondary-600"
    >
      <div class="lf-class-navigator-item__time font-inter flex items-center">
        <span class="hidden sm:inline md:hidden lg:inline mr-1 lf-class-navigator-item__weekday">
          {weekday},
        </span>
        <span class="mr-1">{startTime} - {endTime}</span>
        <span>{timezoneShort}</span>
        {#if groupClass?.in_person > 0}
          <LocationIcon title="In person event" class="w-4 h-4 ml-1 text-danger-700" />
        {/if}
      </div>
      {#if hasLicense && !isPast && !canJoin}
        <span
          class="inline-flex items-center ml-auto text-sm font-medium leading-4 uppercase px-1.5 sm:px-2.5 py-0.5 text-primary-600"
        >
          <span class="hidden sm:inline-block w-2 h-2 mr-2 bg-primary-500"></span>
          Enrolled
        </span>
      {:else if showIndicators && !canJoin}
        <div
          class="flex items-center ml-auto text-secondary-600 lf-class-navigator-item__indicators"
        >
          {#if recordings && recordings.length > 0}
            <svg class="w-4 h-4 sm:w-5 sm:h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path
                d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
              ></path></svg
            >
          {/if}
          {#if attachments && attachments.length > 0}
            <svg class="w-4 h-4 ml-2 sm:w-5 sm:h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path
                fill-rule="evenodd"
                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                clip-rule="evenodd"></path></svg
            >
          {/if}
        </div>
      {/if}
    </div>
    {#if groupClass?.in_person > 0}
      <div class="lf-class-navigator-item__meta text-xs uppercase leading-5">
        {@html classLocation}
      </div>
    {/if}
    <div
      class="relative overflow-hidden text-gray-600 text-sm font-semibold sm:text-base leading-5 lf-class-navigator-item__title"
    >

      {#if groupClass?.has_unread_discussions}
        <span class="flex items-center gap-2"><span class="inline-flex relative rounded-full w-2 h-2" title="New class discussion"><span class="relative inline-flex rounded-full bg-danger-500 w-2 h-2"></span></span>{title}</span>
      {:else}
        {title}
      {/if}
    </div>
    <div
      class="lf-class-navigator-item__meta flex flex-wrap flex-gap-x-2 items-center justify-start text-xs uppercase leading-5"
    >
      {#if instructorName}
        <div class="lf-class-navigator-item__instructor">{instructorName}</div>
      {/if}
      {#if categoryName}
        <div class="lf-class-navigator-item__category flex items-center justify-start">
          <span
            class="inline-block w-2 h-2 mr-2 bg-secondary-500 lf-class-navigator-item__category-square"
          ></span>
          {categoryName}
        </div>
      {/if}
    </div>
  </div>
  {#if canJoin}
    <div class="lf-class-navigator-item__controls flex items-center justify-end ml-auto">
      <Button usage="button" size="xs" color="primary" href="{joinURL}" target="_blank">Join</Button
      >
    </div>
  {:else}
    <Menu bind:isOpen class="flex items-center ml-4 lf-class-navigator-item__controls">
      <div class="flex flex-col py-1">
        <Button
          on:click="{() => (showDetailsDialog = true)}"
          on:click="{() => (isOpen = false)}"
          usage="link"
          size="sm"
        >
          <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
            ><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path
              fill-rule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clip-rule="evenodd"></path></svg
          >
          View Details
        </Button>
        <AddToGoogle
          on:click="{() => (isOpen = false)}"
          title="{title}"
          startDate="{classStart}"
          endDate="{classEnd}"
          description="{calDescription}"
          eventData="{eventData}"
          button$usage="link"
          button$size="sm"
        />
        <ICSEvent
          on:click="{() => (isOpen = false)}"
          filename="{icsFilename}"
          title="{title}"
          startDate="{classStart}"
          endDate="{classEnd}"
          description="{calDescription}"
          eventData="{eventData}"
          button$usage="link"
          button$size="sm"
        />

        {#if canEdit}
          <Button
            usage="link"
            size="sm"
            href="/classes/{groupClass.id}/edit"
            urlOptions="{{
              query: {
                destination: trimLeft(window.location.pathname, '/'),
              },
            }}"
          >
            <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path
                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
              ></path></svg
            >
            Edit
          </Button>

          {#if false && isPast}
            <Button
              usage="link"
              size="sm"
              href="/admin/config/lessonface/zoomapi/find-recordings"
              urlOptions="{{
                query: { entity_type: 'lf_class', entity_id: groupClass.id },
              }}"
            >
              <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
                ><path
                  d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
                ></path></svg
              >
              Find Recordings
            </Button>
          {/if}
        {/if}

        {#if isSuperAdmin || isDebugMode}
          <Button
            on:click="{() => (showDebugDialog = true)}"
            on:click="{() => (isOpen = false)}"
            usage="link"
            color="danger"
            size="sm"
          >
            <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path
                fill-rule="evenodd"
                d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"></path></svg
            >
            Debug Info
          </Button>
        {/if}
      </div>
    </Menu>
  {/if}

  {#if groupImageURL}
    <div class="lf-class-navigator-item__group-image hidden">
      <img
        src="{groupImageURL.replace('lf_course_medium', 'lesson_product_page')}"
        alt="{title}"
        loading="lazy"
      />
    </div>
  {/if}
</div>

<Dialog
  id="lf-class-navigator-item__dialog-details--{groupClass.id}"
  includeCloseButton="{false}"
  bind:isOpen="{showDetailsDialog}"
>
  <ClassDetailsCard
    class="h-full sm:h-auto sm:max-w-md sm:max-h-9/10"
    on:closeDialog="{() => (showDetailsDialog = false)}"
    on:sendReminderEmail
    on:enroll
    settings="{settings}"
    bind:isWorking
    bind:groupClass
  />
</Dialog>

<DebugDialog
  id="lf-class-navigator-item__debug-dialog--{groupClass.id}"
  data="{groupClass}"
  bind:isOpen="{showDebugDialog}"
/>

<style lang="postcss">.lf-class-navigator-item__title{--lh:1.25rem;--max-lines:2;max-height:calc(var(--lh)*var(--max-lines))}.lf-class-navigator-item__title:before{content:"";inset-block-end:0;inset-inline-end:0;position:absolute}.lf-class-navigator-item__title:after{content:""}</style>
