<svelte:options immutable="{true}" />

<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import { excludeProps, prefixFilter, stripHTML } from 'svelte-utilities';
  import { TipTap } from 'svelte-tailwind-components';
  import DeleteButton from '../button/DeleteButton.svelte';
  import AttachmentsButton from '../button/AttachmentsButton.svelte';
  import UppyDashboard from '../uppy/UppyDashboard.svelte';
  import FilesList from '../uppy/FilesList.svelte';
  import ElementErrorMessage from '../form/ElementErrorMessage.svelte';

  let className = '';
  export { className as class };
  export let value = '';
  export let files = [];
  export let invalid = undefined;
  export let autofocus = true;
  export let hideButtons = [];
  export let uppyIdentifier = 'TIPTAPEDITOR:ATTACHMENTS';
  export let uppyConfig;

  export let focusEl = undefined;
  export let invalidMessage = 'The body is required.';
  export let working = false;

  export const focusEditor = () => {
    setTimeout(() => {
      focusEl ? focusEl.focus() : editorEl.focus();
      scrollTargetEl && scrollTargetEl.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  export const resetTipTap = (clearFiles = false) => {
    const fileIds = files.map((file) => file.fid);
    resetContent();
    untrapUnload();
    clearFiles && fileIds && uppy.effects.removeFiles(fileIds);
    !clearFiles && fileIds && fileIds.forEach((file) => uppy.clearFile(file.fid));
  };

  export const trapUnload = () =>
    addEventListener('beforeunload', onBeforeUnload, { capture: true });
  export const untrapUnload = () =>
    removeEventListener('beforeunload', onBeforeUnload, { capture: true });

  const dispatch = createEventDispatcher();

  let _initialBody = value;
  let editorEl = undefined;
  let scrollTargetEl;

  let tiptap = undefined;
  let resetContent;
  let onBeforeUnloadAdded = false;

  let useUppy = uppyConfig && !hideButtons.includes('attachments');
  let uppy;
  let uppyWorking;
  let filesWorking;
  let openDashboard;

  $: {
    files = $uppy ? $uppy.files : files;
    uppyWorking = $uppy && $uppy.working;
    working = uppyWorking || filesWorking;
  }

  const onAttachmentsClick = (evt) => {
    evt.stopPropagation();
    openDashboard();
  };

  const onDiscard = () => {
    resetTipTap();
    dispatch('discard');
  };

  const onBeforeUnload = (evt) => {
    evt.preventDefault();
    return (evt.returnValue =
      'You have an unsaved content. Are you sure you wish to navigate away?');
  };

  const beforeUnloadAdder = (evt) => {
    const body = stripHTML(evt.detail);
    const dirty = body !== stripHTML(_initialBody);

    onBeforeUnloadAdded && !dirty && untrapUnload();
    !onBeforeUnloadAdded && dirty && trapUnload();
  };

  onMount(() => {
    autofocus && focusEditor();
  });

  onDestroy(() => {
    untrapUnload();
  });
</script>

<TipTap.Editor
  id="lf-tiptap-editor"
  bind:editorEl
  bind:value
  bind:tiptap
  bind:resetContent
  on:input="{beforeUnloadAdder}"
  class="lf-tiptap-editor md:py-2 {working ? 'pointer-events-none opacity-75' : ''} {className}"
  contentWrapper$class="mx-4"
  {...excludeProps($$restProps, ['id', 'cancelButton$', 'embedButton$', 'attachmentsButton$'])}
>
  <slot name="preContent" slot="preContent" />

  <div slot="errorMessage" class="ml-4 mb-2">
    <ElementErrorMessage makeSpace="{false}" errorMessage="{invalid ? invalidMessage : ''}" />
  </div>

  <slot name="controlsMenuStart" slot="controlsMenuStart" />
  <svelte:fragment slot="controlsMenuEnd">
    <slot name="controlsMenuInner" />
    {#if useUppy && uppy}
      <AttachmentsButton
        on:click="{onAttachmentsClick}"
        disabled="{$uppy.maxFilesReached}"
        working="{working}"
        usage="icon"
        size="sm"
        class="{files.length === 0
          ? 'text-gray-600'
          : 'text-secondary-500'} hover:text-black hover:bg-gray-50"
        icon$class="w-6 h-6"
        {...excludeProps(prefixFilter($$restProps, 'attachmentsButton$'), [])}
      />
    {/if}
    <DeleteButton
      on:click="{onDiscard}"
      disabled="{!tiptap}"
      tooltipParams="{{ content: 'Discard' }}"
      usage="icon"
      size="sm"
      class="text-gray-600 hover:text-danger-700 hover:bg-gray-50"
      icon$class="w-6 h-6"
      {...excludeProps(prefixFilter($$restProps, 'cancelButton$'), ['editor'])}
    />
    <span bind:this="{scrollTargetEl}"></span>
  </svelte:fragment>

  {#if useUppy && uppy && files.length > 0}
    <div class:lf-tiptap-message__files-wrapper="{true}" class="mx-3.5 my-2">
      <FilesList uppy="{uppy}" bind:working="{filesWorking}" />
    </div>
  {/if}
</TipTap.Editor>

{#if useUppy}
  <UppyDashboard
    bind:uppy
    bind:openDashboard
    on:uploadsCompleted
    uppyConfig="{uppyConfig}"
    identifier="{uppyIdentifier}"
  />
{/if}

<style lang="postcss">:global(.lf-tiptap-editor .dna-tiptap__content-wrapper .ProseMirror:focus){--border-opacity:1;border-color:#6b7280;border-color:rgba(107,114,128,var(--border-opacity))}</style>
