<svelte:options immutable="{true}" />

<script>
  // @todo screen-capture
  // @todo add try/catches.
  import { onMount, onDestroy } from 'svelte';
  import { writable } from 'svelte/store';
  import { Uppy } from '@uppy/core';
  import DashboardPlugin from '@uppy/dashboard';
  import S3Plugin from '@uppy/aws-s3';
  import WebcamPlugin from '@uppy/webcam';
  import ImageEditorPlugin from '@uppy/image-editor';
  import ImageCompressorPlugin from 'uppy-plugin-image-compressor';
  import ScreenCapturePlugin from '@uppy/screen-capture';
  import { deepGet } from 'svelte-utilities';
  import { Button, Cards, Dialog } from 'svelte-tailwind-components';
  import FileLink from '../link/FileLink.svelte';
  import CKEditorEmbed from '../ckeditorEmbed/CKEditorEmbed.svelte';
  import { embedFile } from '../ckeditorEmbed/ckeditor.utils.js';
  import {
    generateUppyOptions,
    generateDashboardOptions,
    generateWebcamOptions,
    generateS3Options,
    generateImageEditorOptions,
    generateImageCompressorOptions,
  } from './config.js';
  import { s3API } from '../../components/s3/s3.api.js';

  export let id;
  export let settings;
  export let fieldInfo;
  export let title;
  export let description;
  export let files = [];
  export let working = false;

  const restrictions = {
    maxFileSize: deepGet(settings, 'maxFileSize', null),
    minFileSize: deepGet(settings, 'minFileSize', null),
    maxTotalFileSize: deepGet(settings, 'maxTotalFileSize', null),
    maxNumberOfFiles: deepGet(settings, 'maxNumberOfFiles', null),
    minNumberOfFiles: deepGet(settings, 'minNumberOfFiles', null),
    allowedFileTypes: deepGet(settings, 'allowedFileTypes', null),
  };

  const uppyOptions = generateUppyOptions({
    id,
    restrictions,
    meta: { fieldInfo },
  });
  const uppy = new Uppy(uppyOptions);

  let wrapperId = 'lf-s3filefield-uppy-' + id.replace('_', '-');
  let wrapperEl;
  let uppyWrapperEl;
  let dashboardContainerEl;
  let loadedPlugins = [];
  let filesStore = writable(files);
  let maxFilesReached = false;
  let removeDialogOpen = false;

  let workingFile;

  const getSignedUrl = async (file) => {
    // Note: Working is covered by the dashboard dialog being open so we do
    // not need to handle it here.
    try {
      const res = await s3API.getSignedUrl(file, fieldInfo, settings);
      const { method, url, fields, header } = res;
      return { method, url, fields, header };
    } catch (err) {
      console.warn(err);
      window.snacksQueue.add({
        label: 'An error occurred uploading a file.',
        message: err,
        type: 'danger',
      });
    }
  };

  uppy.on('file-added', (file) => {
    const data = file.data;
    const url = URL.createObjectURL(data);
    const image = new Image();

    image.src = url;
    image.onload = () => {
      uppy.setFileMeta(file.id, {
        ...file.meta,
        width: image.width,
        height: image.height,
      });
      URL.revokeObjectURL(url);
    };
  });

  const onUploadSuccess = async (file, location) => {
    // Note: Working is covered by the dashboard dialog being open so we do
    // not need to handle it here.
    try {
      const uploadedFile = await s3API.uploadSuccess({ ...file, location }, fieldInfo, settings);
      uppy.setFileMeta(file.id, { ...file.meta, fid: uploadedFile.fid });
      filesStore.update((s) => [...s, uploadedFile]);
      embedFile(uploadedFile);
    } catch (err) {
      console.warn(err);
      window.snacksQueue.add({
        label: 'An error occurred finalizing the upload.',
        message: err,
        type: 'danger',
      });
    }
  };

  uppy.on('upload-success', (file, res) =>
    onUploadSuccess(file, deepGet(res, 'body.location', ''))
  );

  const removeFile = async (fid) => {
    working = true;

    try {
      await s3API.removeFile(fid, fieldInfo, settings);
      window.snacksQueue.add({
        label: 'File Removed',
        type: 'success',
      });
    } catch (err) {
      console.warn(err);
      window.snacksQueue.add({
        label: 'An error occurred removing the upload.',
        message: err,
        type: 'danger',
      });
    } finally {
      working = false;
    }
  };

  const onRemoveFileConfirmed = async (file) => {
    const hiddenEl = wrapperEl.querySelector('input[type="hidden"][value="' + file.fid + '"]');
    hiddenEl ? (hiddenEl.value = 0) : await removeFile(file.fid);
    closeRemoveDialog();
    filesStore.update((s) => s.filter((f) => f.fid !== file.fid));
  };

  const openRemoveDialog = (file) => {
    workingFile = { ...file };
    removeDialogOpen = true;
  };

  const closeRemoveDialog = () => {
    workingFile = undefined;
    removeDialogOpen = false;
  };

  uppy.on('file-removed', (file, reason) => {
    if (file.meta.fid && reason == 'removed-by-user') {
      removeFile(file.meta.fid);
      filesStore.update((s) => s.filter((f) => f.fid !== file.meta.fid));
    }
  });

  uppy.on('dashboard:modal-open', () => (working = true));
  uppy.on('dashboard:modal-closed', () => (working = false));
  uppy.on('dashboard:file-edit-start', () => (working = true));
  uppy.on('dashboard:file-edit-complete', () => (working = false));

  uppy.on('complete', () => uppy.reset());

  $: {
    maxFilesReached =
      restrictions.maxNumberOfFiles && $filesStore.length >= restrictions.maxNumberOfFiles;
  }

  const initialize = () => {
    const dashboardOptions = generateDashboardOptions({}, dashboardContainerEl);
    const webcamOptions = generateWebcamOptions({}, DashboardPlugin);
    const imageEditorOptions = generateImageEditorOptions({}, ImageEditorPlugin);
    const imageCompressionOptions = generateImageCompressorOptions({});
    const s3Options = generateS3Options({});

    dashboardOptions.trigger = `#${id}-trigger`;
    webcamOptions.target = DashboardPlugin;
    s3Options.getUploadParameters = (file) => getSignedUrl(file);

    uppy
      .use(DashboardPlugin, dashboardOptions)
      .use(ImageEditorPlugin, {
        ...imageEditorOptions,
        target: DashboardPlugin,
      })
      .use(ImageCompressorPlugin, imageCompressionOptions)
      .use(S3Plugin, s3Options);

    loadedPlugins.push(uppy.getPlugin(dashboardOptions.id));
    loadedPlugins.push(uppy.getPlugin(imageEditorOptions.id));
    loadedPlugins.push(uppy.getPlugin(s3Options.id));

    if (deepGet(settings, 'sources', []).includes('webcam')) {
      uppy.use(WebcamPlugin, { target: DashboardPlugin });
      loadedPlugins.push(uppy.getPlugin(webcamOptions.id));
    }

    if (deepGet(settings, 'sources', []).includes('screencapture')) {
      uppy.use(ScreenCapturePlugin, {
        id: 'ScreenCapture',
        target: DashboardPlugin,
      });
      loadedPlugins.push(uppy.getPlugin('ScreenCapture'));
    }

    wrapperEl = uppyWrapperEl.closest('.field-widget-lf-s3filefield');
  };
  const uninstall = () => loadedPlugins.each((plugin) => uppy.removePlugin(plugin));

  onMount(() => initialize());
  onDestroy(() => uninstall());
</script>

<label for="{wrapperId}" class="my-2 flex">{title}</label>
<div id="{wrapperId}" class="lf-s3filefield-uppy-wrapper" bind:this="{uppyWrapperEl}">
  {#if $filesStore.length > 0}
    <div class:pointer-events-none="{working}" class="lf-s3filefield-files-listing mb-4">
      {#each $filesStore as file (file.fid)}
        <FileLink menuOnly file="{file}" class="hover:bg-gray-50">
          <span slot="primaryAction"><CKEditorEmbed file="{file}" /></span>
          <div slot="menu" class="flex flex-col py-1">
            <CKEditorEmbed file="{file}" />
            <div class="lf-dashboard-separator border-t border-gray-50 my-2"></div>
            <Button on:click="{() => openRemoveDialog(file)}" usage="link" size="sm" color="danger">
              <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
                ><path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                  clip-rule="evenodd"></path></svg
              >
              Remove
            </Button>
          </div>
        </FileLink>
      {/each}
    </div>
  {/if}

  <Button
    working="{working}"
    disabled="{maxFilesReached}"
    id="{id}-trigger"
    usage="button"
    color="secondary"
    class="lf-s3filefield-uppy-button"
    size="xs">{maxFilesReached ? 'Limit Reached' : 'Add Files'}</Button
  >
  {#if description && !maxFilesReached}
    <div class="text-sm my-2">{@html description}</div>
  {/if}
  <div bind:this="{dashboardContainerEl}"></div>
</div>

<Dialog bind:isOpen="{removeDialogOpen}" isModal id="removeFile">
  <Cards.Card class="max-h-full max-w-screen-sm">
    <Cards.Header>
      <h3 class="m-0 text-xl font-medium text-gray-900 leading-6">Confirm to Remove File</h3>
    </Cards.Header>
    <Cards.Content>
      {#if deepGet(workingFile, 'uri', '').includes('/tmp/')}
        <div>The file has been uploaded to a temporary location.</div>
        <div class="text-danger-700">Removing it now deletes it permanently.</div>
      {:else}
        <div>The file will not be permanently deleted until the form is submitted.</div>
      {/if}
      <div class="w-full overflow-hidden text-secondary-500 mt-4">
        {deepGet(workingFile, 'filename', deepGet(workingFile, 'name'), '')}
      </div>
    </Cards.Content>
    <Cards.Actions>
      <Button disabled="{working}" on:click="{closeRemoveDialog}" usage="link" size="sm"
        >Cancel</Button
      >
      <Button
        on:click="{() => onRemoveFileConfirmed(workingFile)}"
        working="{working}"
        usage="button"
        color="danger"
        size="sm">Remove File</Button
      >
    </Cards.Actions>
  </Cards.Card>
</Dialog>

<style lang="postcss">:global(.field-widget-lf-s3filefield){border:1px dotted #ccc;margin-bottom:1rem;margin-top:1rem;padding:1rem;width:-moz-fit-content;width:fit-content}:global(.field-widget-lf-s3filefield .form-item){margin-bottom:0;margin-top:0}:global(.lf-s3filefield-wrapper){display:inline-block}:global(.uppy-Dashboard-isFixed){height:100vh!important;overflow:hidden!important}:global(.lf-s3filefield-label~*){display:none}:global(div[slot=menu]>.lf-dashboard-separator:first-child){display:none}:global(.cke_dialog_ui_checkbox){display:none!important}:global(.uppy-Dashboard-innerWrap .uppy-DashboardContent-bar),:global(.uppy-Dashboard-innerWrap .uppy-StatusBar){--bg-opacity:1;background-color:#f9fafb;background-color:rgba(249,250,251,var(--bg-opacity))}</style>
