<svelte:options immutable="{true}" />

<script>
  import { onMount } from 'svelte';
  import { deepGet, excludeProps, prefixFilter } from 'svelte-utilities';
  import {
    AudioIcon,
    Button,
    Dialog,
    DocumentIcon,
    ImageIcon,
    Menu,
    VideoIcon,
  } from 'svelte-tailwind-components';
  import Video from '../videos/Video.svelte';

  let className = '';
  export { className as class };
  export let file;
  export let menuOnly = false;
  export let isMenuOpen = false;

  let linkUrl;
  let isTemp;
  let sameDomain;

  const awsBucket = deepGet(Drupal.settings, 'lf_svelte.aws.bucket');
  const awsRegion = deepGet(Drupal.settings, 'lf_svelte.aws.region');
  const iconMap = {
    video: VideoIcon,
    document: DocumentIcon,
    image: ImageIcon,
    audio: AudioIcon,
  };

  let iconComponent = iconMap[file?.type] || DocumentIcon;

  let fileLinkEl;
  let isDialogOpen = false;
  let videoPlayer;

  // @todo using filename reactive variable since not every component is
  // using the file model yet.
  let filename;

  const openDialog = () => (isDialogOpen = ['video'].includes(file?.type));
  const closeDialog = () => {
    if (videoPlayer) {
      videoPlayer.dispose();
    }

    isDialogOpen = false;
  };

  $: {
    filename = file?.name ?? file?.filename;
    linkUrl = file?.url && file?.type && !['video'].includes(file.type) ? file.url : undefined;
    isTemp = file?.url && file.url.includes('/tmp/');
    sameDomain = linkUrl
      ? new URL(linkUrl).host == window.location.host ||
        (linkUrl.includes(awsBucket) && linkUrl.includes(awsRegion))
      : undefined;
  }

  onMount(() => {
    const menuEl = fileLinkEl.querySelector('.lf-filelink-menu div[slot="menu"]');

    if (menuEl) {
      menuEl.addEventListener('click', () => (isMenuOpen = false));
    }
  });
</script>

{#if false}
  <slot />
{/if}

<div
  bind:this="{fileLinkEl}"
  class="lf-filelink flex justify-between items-center pb-1 {className}"
  {...excludeProps($$props, ['use', 'class', 'file', 'button$'])}
>
  <div
    on:click="{() => (menuOnly ? (isMenuOpen = true) : null)}"
    class:mr-2="{!menuOnly}"
    class:flex-grow="{menuOnly}"
    class:cursor-pointer="{menuOnly}"
    class="flex flex-col items-start truncate"
  >
    {#if menuOnly || !linkUrl}
      <Button
        on:click
        on:click="{() => (!menuOnly && !linkUrl ? openDialog() : null)}"
        usage="link"
        size="sm"
        {...excludeProps(prefixFilter($$restProps, 'button$'), [])}
      >
        <span slot="leftIcon">
          <svelte:component this="{iconComponent}" class="w-5 h-5" />
        </span>
        <span class="flex items-center">
          <span class="flex-1 min-w-0 truncate">{filename}</span>
        </span>
      </Button>
    {:else}
      <Button
        on:click
        href="{linkUrl}"
        download="{sameDomain ? filename : false}"
        target="_blank"
        usage="link"
        size="sm"
        {...excludeProps(prefixFilter($$restProps, 'button$'), [])}
      >
        <span slot="leftIcon">
          <svelte:component this="{iconComponent}" class="w-5 h-5" />
        </span>
        <span class="flex items-center">
          <span class="flex-1 min-w-0 truncate">{filename}</span>
        </span>
      </Button>
    {/if}
    {#if file?.size || isTemp}
      <div
        class="lf-filelink__meta flex justify-start items-center flex-wrap text-xs leading-4 text-gray-600 ml-8 -mt-2"
      >
        {#if file?.friendlySize}
          <span class="lf-filelink__filesize mr-2">{file.friendlySize}</span>
        {/if}
        {#if isTemp}
          <span class="lf-filelink__temp-file text-danger-700">(save required)</span>
        {/if}
      </div>
    {/if}
  </div>
  {#if $$slots.menu}
    <Menu
      bind:isOpen="{isMenuOpen}"
      trigger$class="p-1"
      class="flex items-center {menuOnly ? 'ml-2' : 'ml-auto'} lf-filelink-menu"
    >
      <slot name="primaryAction" slot="primaryAction" />
      <slot name="menu" isMenuOpen="{isMenuOpen}" />
    </Menu>
  {/if}
</div>

{#if file?.type === 'video' && file?.url}
  <Dialog
    id="lf-video-dialog--{file.id}"
    includeCloseButton="{false}"
    isModal
    bind:isOpen="{isDialogOpen}"
  >
    <div class="relative lf-video-dialog__video-wrapper">
      <Video controls bind:videoPlayer file="{file}" />
      <div class="lf-video-dialog__close-wrapper flex justify-end">
        <Button on:click="{closeDialog}" usage="text" color="none" size="sm">Close</Button>
      </div>
    </div>
  </Dialog>
{/if}

<style lang="postcss">.lf-video-dialog__close-wrapper :global(button){color:#fff}</style>
