<script>
  // @todo pop-out / dialog.
  // @todo fullscreen (large dialog).
  // @todo html (textarea) mode.
  // @todo textarea (auto)resizing.
  // @todo on/off or opened/closed status that expands/collapses and creates
  // or destroys the tiptap instance as needed?
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import {
    deepGet,
    deepSet,
    excludeProps,
    prefixFilter,
  } from 'svelte-utilities';
  import { createTipTap } from './tiptap.controller.js';
  import TextFormatButton from './buttons/TextFormatButton.svelte';
  import UndoButton from './buttons/UndoButton.svelte';
  import RedoButton from './buttons/RedoButton.svelte';
  import BoldButton from './buttons/BoldButton.svelte';
  import ItalicButton from './buttons/ItalicButton.svelte';
  // import UnderlineButton from './buttons/UnderlineButton.svelte';

  let className = '';
  export { className as class };
  export let id;
  export let name;
  export let value = '';
  export let placeholder = '';

  export let params = {};
  export let tiptap = undefined;
  export let htmlMode = false;
  export let editorEl = undefined;

  export const resetContent = () => {
    try {
      editorEl && tiptap.commands.setContent(initialValue, true);
    } catch (err) {
      // An error may happen if the dom node is destroyed or recreated and we
      // lose track. So fail quietly.
      // @see https://github.com/ueberdosis/tiptap/issues/438
    }
  };

  const dispatch = createEventDispatcher();

  let initialValue = '';
  let tiptapEl;
  let isFormatMenuOpen = false;

  name = name ?? id;

  onMount(() => {
    initialValue = value;

    if (placeholder) {
      params = deepSet(
        params,
        'extensions.Placeholder.placeholder',
        placeholder
      );
    }

    tiptap = createTipTap(tiptapEl, value, params);
    editorEl = tiptapEl.querySelector('.ProseMirror');

    // Note: This is necessary to ensure reactivity, especially in sub
    // components, when the editor changes.
    tiptap.on('transaction', ({ editor }) => {
      tiptap = editor;
    });

    tiptap.on('update', ({ editor }) => {
      value = editor.getHTML();
      dispatch('input', value);
    });
  });

  onDestroy(() => {
    tiptap && tiptap.destroy();
  });
</script>

<div
  class:dna-tiptap-wrapper="{true}"
  {...excludeProps(prefixFilter($$restProps, 'wrapper$'), [])}
>
  <div
    class:dna-tiptap="{true}"
    class="flex flex-col md:mx-4 md:mb-4 md:elevation-4 md:hover:elevation-6 bg-white rounded {className}"
    {...excludeProps($$restProps, [
      'wrapper$',
      'contentWrapper$',
      'textarea$',
      'controlsMenu$',
      'formatMenu$',
    ])}
  >
    <slot name="preContent" />
    <div
      bind:this="{tiptapEl}"
      class:dna-tiptap__content-wrapper="{true}"
      class:hidden="{htmlMode}"
      class="flex-1 mb-2 overflow-y-auto border border-dotted border-gray-300
      {deepGet(
        prefixFilter($$restProps, 'contentWrapper$', {}),
        'class',
        ''
      )}"
      {...excludeProps(prefixFilter($$restProps, 'contentWrapper$'), ['class'])}
    ></div>
    <textarea
      id="{id}"
      name="{name}"
      bind:value
      on:input
      on:change
      on:click
      on:focus
      on:keydown
      on:keyup
      class:dna-tiptap__textarea="{true}"
      class:mx-3="{true}"
      class:hidden="{!htmlMode}"
      {...excludeProps(prefixFilter($$restProps, 'textarea$'), [])}></textarea>
    <slot name="errorMessage" />
    <div
      class:dna-tiptap__format-menu-wrapper="{true}"
      class:hidden="{!isFormatMenuOpen || htmlMode}"
      class="flex min-w-0 min-h-0 mx-4 mb-2"
    >
      <div
        class:dna-tiptap__format-menu="{true}"
        class="inline-flex items-center p-1 space-x-1 bg-white elevation-4"
        {...excludeProps(prefixFilter($$restProps, 'formatMenu$'), [])}
      >
        <UndoButton tiptap="{tiptap}" size="sm" />
        <RedoButton tiptap="{tiptap}" size="sm" />
        <BoldButton tiptap="{tiptap}" size="sm" />
        <ItalicButton tiptap="{tiptap}" size="sm" />
        <slot name="formatMenu" />
      </div>
    </div>

    <slot />

    <div
      class:dna-tiptap__fixed-menu="{true}"
      class="flex flex-col p-2"
      {...excludeProps(prefixFilter($$restProps, 'controlsMenu$'), [])}
    >
      <div
        class:dna-tiptap__controls-menu="{true}"
        class="inline-flex items-center px-2 space-x-1 min-w-0 min-h-0"
        {...excludeProps(prefixFilter($$restProps, 'formatMenu$'), [])}
      >
        <slot name="controlsMenuStart" />
        <TextFormatButton bind:isOpen="{isFormatMenuOpen}" tiptap="{tiptap}" />
        <slot name="controlsMenu" />
        <span class="flex-1"></span>
        <slot name="controlsMenuEnd" />
      </div>
    </div>
  </div>

  <div
    class:dna-tiptap__bubble-menu="{true}"
    class:hidden="{htmlMode}"
    class="flex items-center space-x-1"
    {...excludeProps(prefixFilter($$restProps, 'bubbleMenu$'), [])}
  >
    <BoldButton tiptap="{tiptap}" />
    <ItalicButton tiptap="{tiptap}" />
    <slot name="bubbleMenu" />
  </div>
</div>

<style lang="postcss">@media (min-width:768px){.dna-tiptap:focus-within{box-shadow:0 5px 5px -3px rgba(var(--boxShadowColor,0,0,0),.2),0 8px 10px 1px rgba(var(--boxShadowColor,0,0,0),.14),0 3px 14px 2px rgba(var(--boxShadowColor,0,0,0),.12)}}.dna-tiptap__content-wrapper :global(.ProseMirror),.dna-tiptap__textarea{outline:2px solid transparent;outline-offset:2px;padding:.75rem}.dna-tiptap__content-wrapper :global(.ProseMirror p.is-editor-empty:first-child:before){--text-opacity:1;color:#6b7280;color:rgba(107,114,128,var(--text-opacity));content:attr(data-placeholder);float:left;height:0;opacity:.75;pointer-events:none}.dna-tiptap__content-wrapper :global(.ProseMirror :first-child){margin-top:0}.dna-tiptap__content-wrapper :global(.ProseMirror :last-child){margin-bottom:0}</style>
