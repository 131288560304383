<script>
  // @todo this is built / styled to work with Lessonface.
  import { createEventDispatcher } from 'svelte';
  import _debounce from 'lodash-es/debounce.js';
  import { excludeProps, KEYS } from 'svelte-utilities';
  import Button from '../buttons/Button.svelte';

  let className = '';
	export { className as class };

  export let placeholder = 'Search...';
  export let searchText = '';
  export let expandable = true;

  const dispatch = createEventDispatcher();

  let isSearchOpen = !expandable;
  let searchEl;

  function isDirty(text) {
    return searchText !== text;
  }

  function clearSearch(keepFocus = false) {
    searchText = '';
    searchEl.value = '';
    dispatch('cleared');

    if (keepFocus) {
      searchEl.focus();
    }
  }

  function openSearch() {
    isSearchOpen = true;

    // Note: We have to wait until the transition completes before we can set
    // focus to the input element. The ontransitionend doesn't seem to pick up
    // the transition.
    if (expandable) {
      setTimeout(() => searchEl.focus(), 400);
    }
  }

  function closeSearch(doSearch = false) {
    isSearchOpen = !expandable

    if (expandable) {
      searchEl.blur();
    }

    if (doSearch) {
      dispatch('search', searchText);
    }
  }

  function toggleSearch() {
    isSearchOpen ? closeSearch(true) : openSearch();
  }

  function onSearchKeyDown(evt) {
    if (evt.keyCode === KEYS.ESC) {
      clearSearch();
    } else if (isDirty(evt.target.value)) {
      searchText = evt.target.value;
    }

    if ([KEYS.ENTER, KEYS.ESC, KEYS.TAB].includes(evt.keyCode)) {
      closeSearch(evt.keyCode === KEYS.ENTER);
    }
  }
</script>

<div
  class="dna-search-input-wrapper sm:relative w-12 h-12 flex-shrink-0 {className}"
  {...excludeProps($$restProps, ['class'])}>
  <form
    on:submit|preventDefault
    on:reset|preventDefault
    class:w-0="{!isSearchOpen}"
    class:w-full="{isSearchOpen}"
    class:sm:w-64="{isSearchOpen}"
    class="absolute right-0 mb-0 pr-12 mr-0 overflow-hidden dna-search-input-form"
  >
    <div class="flex justify-start items-center relative">
      <input
        bind:this="{searchEl}"
        on:keydown="{_debounce(onSearchKeyDown, 250)}"
        type="search"
        name="dna-search-input"
        class="dna-search-input h-12 pr-12"
        {placeholder}
      />
      <Button
        on:click="{clearSearch}"
        class="leading-none w-12 h-12 absolute top-0 right-0 dna-search-input-clear"
        title="Clear Search"
        disabled="{searchText.length === 0}"
        usage="text"
        size="md"
        color="danger"
        shape="tile"
        padding="{false}"
        flat="{true}"
      >
        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
          ><path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path></svg
        >
      </Button>
    </div>
    <Button
      on:click="{toggleSearch}"
      class="leading-none w-12 h-12 absolute top-0 right-0 dna-search-input-trigger"
      usage="button"
      size="md"
      color="{!isSearchOpen && searchText.length > 0
        ? 'danger'
        : 'secondary'}"
      shape="tile"
      padding="{false}"
      flat="{true}"
    >
      <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
        ><path
          fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"
        ></path></svg
      >
    </Button>
  </form>
</div>

<style lang="postcss">.dna-search-input-wrapper :global(.dna-search-input-form){margin-right:0;transition:width .3s}.dna-search-input-wrapper :global(.dna-search-input-form.w-0 .dna-search-input){padding:0}.dna-search-input-wrapper :global(.dna-search-input){font-size:1rem;width:100%}.dna-search-input-wrapper :global(.dna-search-input.h-12){height:3rem;padding:.5rem 3rem .5rem 1rem}.dna-search-input-wrapper :global(.dna-search-input-clear),.dna-search-input-wrapper :global(.dna-search-input-trigger){border-color:transparent;box-shadow:none;position:absolute}</style>
