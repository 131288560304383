<svelte:options immutable={true} />

<script>
  import { formatPlural } from 'svelte-utilities';
  import {
    AttachmentsIcon,
    StatusPinger,
    TimeAgo,
    tooltip,
  } from 'svelte-tailwind-components';
  import ReplyToMessage from './buttons/ReplyToMessage.svelte';
  import PictureInitials from '../users/PictureInitials.svelte';
  import Fullname from '../users/Fullname.svelte';

  let className = '';
  export { className as class };
  export let message;
  export let thread;
  export let expanded = false;
  export let isReplyOpen = undefined;

  let isUnread;
  let lastMessageId;

  $: {
    // Note: we track the changing of the message ID so that when the
    // message is marked 'read' this doesn't auto-update.
    if (message?.id > 0 && message.id !== lastMessageId) {
      lastMessageId = message.id;
      isUnread = undefined;
    }

    // Try to keep unread value even after thread is marked read.
    if (isUnread === undefined && message.isUnread) {
      isUnread = message.isUnread;
    }

    if (isUnread) {
      expanded = true;
    }
  }
</script>

<div
  class:lf-message-item={true}
  class="py-3 md:py-4 md:pr-2 space-y-2 {className}">
  <div
    class:lf-message-item__header={true}
    class="flex items-start space-x-3 lg:space-x-4">
    <div
      on:click={() => (expanded = !expanded)}
      class:lf-message-item__expander={true}
      class="flex-1 min-w-0 flex items-start space-x-3 lg:space-x-4"
      class:cursor-pointer={!expanded}>
      <div class:lf-message-item__image={true} class="flex-shrink-0 mt-1">
        <PictureInitials contact={message.author} size="xs" />
      </div>
      <div class:lf-message-item__header__body={true} class="flex-1 min-w-0">
        <div
          class="flex-1 min-w-0 text-sm leading-5 md:text-base font-semibold">
          <Fullname account={message.author} />
        </div>
        <div
          class="text-sm leading-5 md:leading-6 text-gray-500 overflow-hidden">
          <div class="truncate overflow-hidden">
            {#if !expanded && message.summary}
              {@html message.summary}
            {:else if message.recipients}
              to <Fullname account={message.recipients[0]} />
              {#if thread.isAuthor && message.numRecipients > 1}
                <span
                  use:tooltip={{
                    content: message.recipients
                      .map((r) => r.fullName)
                      .join(', '),
                  }}>
                  +{message.numRecipients - 1} more
                </span>
              {/if}
            {/if}
          </div>
        </div>
      </div>
      <div class:lf-message-item__meta={true} class="relative">
        <div
          class:font-semibold={isUnread}
          class:text-gray-700={isUnread}
          class:text-gray-600={!isUnread}
          class="absolute top-0 right-0 text-xs lg:text-sm flex items-center space-x-2">
          {#if isUnread}
            <StatusPinger pinging />
          {/if}
          <TimeAgo datetime={message.createdAt} />
        </div>
        <div class="flex flex-col mt-6">
          {#if message.numAttachments > 0}
            <AttachmentsIcon
              title={formatPlural(
                message.numAttachments,
                '{{ count }} attachment',
                '{{ count }} attachments'
              )}
              class="w-3.5 h-3.5 text-secondary-500" />
          {/if}
        </div>
      </div>
    </div>
    <div
      class:hidden={!expanded ||
        message.isAuthor ||
        !thread.isAuthor ||
        !thread.isGroupMessage}
      class:lf-message-item__reply-to={true}>
      <div class="-mt-2">
        <ReplyToMessage
          {message}
          {thread}
          disabled={isReplyOpen}
          on:replyToMessage />
      </div>
    </div>
  </div>
  <div
    class:hidden={!expanded || !message.body}
    class:lf-message-item__body={true}
    class="pt-3 pb-2 pl-1 md:pl-8 md:ml-1 lg:ml-2">
    {@html message.body}
  </div>
</div>

<style lang="postcss">.lf-message-item__body :global(p:last-of-type){margin-bottom:0}</style>
