<script>
  import { onMount, createEventDispatcher } from 'svelte';

  import { generatePayment } from './instructorPayments.api.js';
  import { logger } from '../../utils/logger.js';

  // @todo Dialog
  import Button from '../button/Button.svelte';
  import Overlay from '../overlay/Overlay.svelte';
  import Autocomplete from '../autocomplete/Autocomplete.svelte';

  import _get from 'lodash-es/get.js';

  export let pendingPaymentItems = [];
  export let isOpen = false;

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  let isInitialized = false;
  let isGenerating = false;

  let allOption = {
    value: 'all',
    label: 'All Instructors',
  };
  let selectedOption = '';
  let sortRules = ['text'];

  $: isWorking = isGenerating;
  $: isWorkingLabel = (selectedOption == 'all') ? 'Generating Queue' : 'Generating Payments';
  $: instructorOptions = pendingPaymentItems.reduce((acc, item) => {
    let uid = _get(item, 'instructor.id', _get(item, 'uid', 0));

    if (uid > 0) {
      acc[uid] = {
        value: uid,
        label: _get(item, 'instructor.fullname', `Instructor #${uid}`),
      };
      return acc;
    }
  }, {});
  $: options = [allOption, ...Object.values(instructorOptions)];

  async function generatePaymentsFor(instructorId) {
    isGenerating = true;

    try {
      const res = await generatePayment(instructorId);
      logger.debug('generatePaymentsFor', 'res', res);
      dispatch('paymentsGenerated', {
        instructorId: selectedOption,
        data: res,
      });
      return res;
    }
    catch (err) {
      logger.debug('generatePaymentsFor', 'err', err);
      return false;
    }
    finally {
      isGenerating = false;
    }
  }

  function onClick() {
    generatePaymentsFor(selectedOption);
  }

  function onCancel() {
    isOpen = false;
  }

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  class="
    lf-instructor-payments-generate-payments-form-wrapper
    {className}
    ">
  <Overlay fullscreen isActive />
  <form
    class="lf-instructor-payments-generate-payments-form"
    on:submit|preventDefault
    >
    <div class="lf-instructor-payments-generate-payments-form__header">
      <h3 class="lf-instructor-payments-generate-payments-form__title">Generate Payment Records</h3>
    </div>
    <div class="lf-instructor-payments-generate-payments-form__content">
      {#if isInitialized}
      <div class="lf-instructor-payments-generate-payments-form__meta">
        <div>
          Generating a payment record does not trigger the actual payment / transfer to the instructor. Once the payment record is created, depending on the instructor's payment method, a "Pay Now" option appears to trigger the payment to the instructor. If Automatic payments is enabled, then a background process / queue will initate the payment for any applicable funds pending payment record.
        </div>
      </div>
      <div
        class=" form-item form-type-select">
        <Autocomplete
          id="generatePaymentsFor"
          name="generatePaymentsFor"
          labelProp="label"
          {options}
          bind:checkedValues={selectedOption}
          label="Instructor"
          placeholder="{`Select instructor...`}"
          {sortRules}
          />
        <div class="description">
          Select the instructor to generate a payment record from the available pending payment items. Choosing "All Instructors" will add the instructors to a queue to generate payment records in the background.
        </div>
      </div>
      {/if}
    </div>
    <div class="lf-instructor-payments-generate-payments-form__footer">
      {#if isInitialized}
      <Button
        on:click={onClick}
        disabled={selectedOption.length == 0}
        bind:isWorking
        bind:isWorkingLabel
        class="button button--primary button--small">
        Generate Payments
      </Button>
      <Button
        on:click={onCancel}
        class="button button--link button--small">
        Cancel
      </Button>
      {/if}
    </div>
  </form>
</div>

<style lang="postcss">.lf-instructor-payments-generate-payments-form-wrapper{align-items:center;display:flex;height:100vh;justify-content:center;left:0;position:fixed;top:0;width:100vw}.lf-instructor-payments-generate-payments-form{background-color:#fff;max-width:600px;padding:1em 1.5em;position:relative}.lf-instructor-payments-generate-payments-form__header{padding:.5em 0}.lf-instructor-payments-generate-payments-form__title{margin:0}.lf-instructor-payments-generate-payments-form__content{padding:.5em 0}.lf-instructor-payments-generate-payments-form :global(.form-type-select .lf-svelte-autocomplete-wrapper){max-width:300px}.lf-instructor-payments-generate-payments-form__meta{background-color:#eee;border:1px dashed #ccc;margin-bottom:1em;padding:4px 8px}.lf-instructor-payments-generate-payments-form__meta label{color:#666;display:inline-block;font-weight:600;margin-bottom:0;min-width:80px}.lf-instructor-payments-generate-payments-form__footer{padding:.5em 0}</style>
