<script>
  import _get from 'lodash-es/get.js';

  export let rowData = {};
  export let column = {};
  export let meta = {};

  $: paymentMethod = rowData.payment_method;
  $: data = _get(rowData, 'data', {});
</script>

{#if paymentMethod == 'mailed_check'}
  {_get(data, 'address.formatted', '')}
{:else if paymentMethod == 'paypal'}
  <div>
    <span>Country: {_get(data, 'paypal.country', '')}</span>
    <span>Locked: {_get(data, 'paypal.locked', false) ? 'True' : 'False'}</span>
  </div>
{/if}

<style lang="postcss">div span+span{margin-left:1em}</style>
