<svelte:options immutable={true} />

<script>
  import { createEventDispatcher } from 'svelte';
  import { excludeProps } from 'svelte-utilities';
  import { Tooltip } from 'svelte-tailwind-components';
  import AjaxHandler from '../button/AjaxHandler.svelte';

  let className = '';
  export { className as class };
  export let state;
  export let item = undefined;
  export let useTooltip = false;
  export let isContextMenu = false;
  export let hoverWrapper = false;

  const dispatch = createEventDispatcher();

  let tooltipParams = {
    theme: 'lf-virtual-list-item-wrapper',
  };
  let tooltipId;
  let openTooltip;
  let closeTooltip;
  let activeShowing;
  let activeNotShowing;

  const onClick = (evt) => {
    useTooltip && !isContextMenu && openTooltip(evt);
    !isContextMenu
      ? dispatch('itemSelected', item)
      : dispatch('itemClicked', { item, openTooltip, target: evt.target });
  };

  const onContextMenu = (evt) => {
    isContextMenu && useTooltip && openTooltip(evt);
  };

  const onShowTooltip = () => dispatch('showTooltip', item.id);

  $: {
    activeShowing =
      useTooltip && state
        ? $state.tooltipItemId && $state.tooltipItemId === item.id
        : undefined;
    activeNotShowing =
      useTooltip && state
        ? $state.tooltipItemId && $state.tooltipItemId !== item.id
        : undefined;

    activeShowing && !tooltipId && openTooltip();
  }
</script>

<AjaxHandler on:ajaxDone={closeTooltip} />
<Tooltip
  {tooltipParams}
  autoOpen={false}
  bind:tooltipId
  bind:openTooltip
  bind:closeTooltip
  on:click={onClick}
  on:contextmenu={onContextMenu}
  on:showTooltip={onShowTooltip}
  on:hideTooltip
  on:tooltipClosed
  disableBodyScroll
  restrictWidthToContainer={false}
  class="lf-virtual-list-item-wrapper {hoverWrapper
    ? 'lf-virtual-list-item-hover-wrapper'
    : ''} {className}"
  {...excludeProps($$restProps, [])}>
  <div
    class:opacity-25={activeNotShowing}
    class:hover:opacity-75={activeNotShowing}
    class="transition-opacity ease-in-out duration-200 flex-1 min-w-0 flex items-center">
    <slot />
  </div>
  <slot
    name="tooltip"
    slot="tooltip"
    {openTooltip}
    {closeTooltip}
    {activeShowing} />
</Tooltip>

<style lang="postcss">:global(.lf-virtual-list-item-hover-wrapper:hover .lf-item-hover-indicator){--transform-scale-x:1;--transform-scale-y:1;opacity:.5}:global(.tippy-box[data-theme~=lf-virtual-list-item-wrapper] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-virtual-list-item-wrapper] .tippy-content){margin:1rem;padding:0}</style>
