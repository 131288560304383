<script>
  // Svelte imports, utilities, other components, component helpers.
  import { deepGet, excludeProps } from 'svelte-utilities';

  let className = '';
  export { className as class };

  export let columnDef = {};
  export let meta = {};
  export let i = 0;
  export let align;

  if (!align) {
    align = ['integer', 'float', 'number', 'price'].includes(columnDef.type) ? 'right' : 'left';
  }

  let currentSort = 'none';
  let compact = meta.config.styles.compact;
  let sortFieldName = deepGet(columnDef, 'data.sort', columnDef.data || columnDef.name);

  $: {
    currentSort = 'none';

    if (meta.current && meta.current.sort && meta.current.sort.fields.includes(sortFieldName)) {
      currentSort = meta.current.sort.order[meta.current.sort.fields.indexOf(sortFieldName)] || 'none';
    }
  }

  let props = excludeProps($$props, ['use', 'class', 'columnDef', 'meta', 'i']);
</script>

<th
  class:cursor-pointer={columnDef.sortable}
  class:hover:text-danger-500={columnDef.sortable}
  class:text-right={align == 'right'}
  class:px-4={!compact}
  class:py-3={!compact}
  class:md:px-6={!compact}
  class:p-2={compact}
  class:pl-3={compact && i === 0}
  class:pr-4={compact && i === meta.columnsVisibility.length - 1}
  class:sm:pl-6={i === 0}
  class:sm:pr-6={i === meta.numCols - 1}
  class="
    {columnDef.title && columnDef.title.length > 0 ? 'text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-no-wrap' : ''}
    {className}"
  on:click
  {...props}>
  <slot></slot>

  {#if columnDef.sortable}
    <svg
      class:text-gray-300={currentSort == 'none'}
      class:text-danger-500={currentSort != 'none'}
      class="inline w-4 h-4"
      viewBox="0 0 1792 1792" fill="currentColor">
      {#if currentSort.toLowerCase() == 'none'}
        <path fill-rule="eventodd" d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"></path>
      {:else if currentSort.toLowerCase() == 'asc'}
        <path fill-rule="eventodd" d="M1408 704q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"></path>
      {:else if currentSort.toLowerCase() == 'desc'}
        <path fill-rule="eventodd" d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
      {/if}
    </svg>
  {/if}
</th>

<style lang="postcss">svg{margin-bottom:2px;margin-right:-.5rem}th:hover svg.text-gray-300{--text-opacity:1;color:#9fa6b2;color:rgba(159,166,178,var(--text-opacity))}</style>
