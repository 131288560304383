<script>
  import { createEventDispatcher } from 'svelte';
  import _get from 'lodash-es/get.js';
  import { amountToDecimal, decimalToAmount } from 'svelte-utilities';
  import { Button, Cards, Form } from 'svelte-tailwind-components';
  import Flatpickr from 'svelte-flatpickr';
  import Textfield from '../textfield/Textfield.svelte';
  import { subscriptionsAPI as api } from './subscriptions.api.js';

  let className = '';
  export { className as class };

  export let subscription;

  const dispatch = createEventDispatcher();

  let productOptions = [
    {
      productId: parseInt(subscription.product_id),
      duration: subscription.duration / 60,
    },
  ];
  let promise = api.getAlternateProducts(subscription.id);

  promise.then((res) => {
    if (res.data) {
      productOptions = [];

      Object.keys(res.data).forEach((productId) => {
        productOptions.push({
          productId: parseInt(productId),
          duration: res.data[productId],
        });
      });
    }
  });

  let maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);

  let flatpickrOptions = {
    altInput: true,
    altFormat: 'M j, Y -- h:i K',
    enableTime: true,
    dateFormat: 'Z',
    minDate: subscription.created,
    maxDate: maxDate.toISOString(),
    onChange: (selectedDates, dateStr, instance) => {
      if (dateStr.length > 0) {
        subscription.start_time = dateStr;
      }
    },
  };

  let studentName = _get(subscription, 'student.fullname');
  let studentUID = _get(subscription, 'student.id');
  let unitPrice = amountToDecimal(subscription.unit_price.amount);

  function onUnitPriceChange(evt) {
    subscription.unit_price.amount = decimalToAmount(parseInt(evt.target.value));
  }
</script>

<svelte:head>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css" />
</svelte:head>

<Cards.Card class="lf-subscription-edit-form max-w-lg {className}">
  <Cards.Header class="flex-col">
    <h3 class="m-0 text-xl font-medium text-gray-900 lf-subscription-edit-form__title leading-6">
      <div class="text-sm uppercase text-danger-700">Edit Subscription</div>
      {subscription.title}
    </h3>
    {#if studentName}
      <div class="flex w-full mt-2 text-sm lf-subscription__student">
        <svg
          class="w-5 h-5 mr-2 lf-subscription__student__icon"
          viewBox="0 0 20 20"
          fill="currentColor"
          ><path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"></path></svg
        >
        <span>{studentName} (#{studentUID})</span>
      </div>
    {/if}
  </Cards.Header>
  <Cards.Content>
    <Form>
      <Textfield
        name="subscription-title"
        label="Title"
        required
        makeSpace="{false}"
        bind:value="{subscription.title}"
      />
      <div class="flex justify-between">
        <Textfield
          name="subscription-unit-price"
          label="Unit Price"
          required
          makeSpace="{false}"
          value="{unitPrice}"
          on:input="{onUnitPriceChange}"
          size="10"
          maxlength="10"
        />
        <div class="form-item form-type-date">
          <label for="subscription-start-time">Start Date / Time</label>
          <Flatpickr
            options="{flatpickrOptions}"
            value="{subscription.start_time}"
            id="subscription-start-time"
            class="text-full form-text lf-flatpickr-readonly"
          />
        </div>
        <div class="form-item form-type-select">
          <label for="subscription-product"> Duration </label>
          <select
            bind:value="{subscription.product_id}"
            id="subscription-product"
            class="form-select"
          >
            {#await promise}
              {#each productOptions as productOption (productOption.productId)}
                <option value="{productOption.productId}">{productOption.duration}</option>
              {/each}
            {:then}
              {#each productOptions as productOption (productOption.productId)}
                <option value="{productOption.productId}">{productOption.duration}</option>
              {/each}
            {/await}
          </select>
        </div>
      </div>
    </Form>
  </Cards.Content>
  <Cards.Actions>
    <Button on:click="{() => dispatch('closeEditForm')}" usage="text" color="primary" size="sm">
      Cancel
    </Button>
    <Button
      on:click="{() => dispatch('updateSubscription', { subscription })}"
      usage="button"
      color="primary"
      size="sm"
    >
      Update
    </Button>
  </Cards.Actions>
</Cards.Card>

<style lang="postcss">:global(input.lf-flatpickr-readonly[readonly]){background-color:#fff;cursor:unset;min-width:200px}.form-item{margin-bottom:.5em;margin-top:.25em}</style>
