<script>
  import Link from '../link/Link.svelte';
  import _get from 'lodash-es/get.js';

  export let tabId = 'payments';

  let className = '';
  export { className as class };

  let pageUserUID = _get(Drupal.settings, 'lf_svelte.pageUser.uid', 0);
  let currentUserUID= _get(Drupal.settings, 'lf_svelte.currentUser.uid', 0);
  let isActiveUser = (pageUserUID === currentUserUID);

  let tabs = {
    payments: {
      label: 'Payments',
      link: {
        path: isActiveUser ? '/payments' : `/user/${pageUserUID}/payments`,
      }
    },
    setup: {
      label: 'Receive Payments',
      link: {
        path: isActiveUser ? '/payments/setup' : `/user/${pageUserUID}/payments/setup`,
      }
    },
    help: {
      label: 'More Information about Payments',
      link: {
        path: '/teacherpayment',
        attributes: {
          target: '_blank',
        }
      }
    }
  };
</script>

<ul class="nav nav-pills {className}">
  {#each Object.keys(tabs) as tabKey}
    <li
      class:lf-tab-active={tabKey == tabId}>
      <Link
        path="{tabs[tabKey].link.path}"
        attributes={tabs[tabKey].link.attributes || {}}>
        {tabs[tabKey].label}
      </Link>
    </li>
  {/each}
</ul>

<style lang="postcss">li:hover :global(a){font-weight:700}.lf-tab-active :global(a){font-weight:700}</style>
