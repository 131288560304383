<script>
  import { classesAPI as api } from '../../components/groupClasses/groupClasses.api.js';
  import ClassNavigator from '../../components/groupClasses/ClassNavigator.svelte';

  // @todo make upcoming classes only.
  let params = {
    filters: [[ 'datetime.start', Date.now() / 1000, '>=' ]],
    attachData: { membership: true },
  };
  let promise = api.getClasses(params);
</script>

<ClassNavigator {promise} />

<style lang="postcss">:global(.page-svelte-dev .lf-class-navigator__bucket img){height:3rem;width:3rem}</style>
