<svelte:options immutable={true} />

<script>
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import { Button, MenuIcon, Tooltip } from 'svelte-tailwind-components';
  import ContactName from './ContactName.svelte';
  import ContactTimezone from './ContactTimezone.svelte';
  import ContactItemActions from './ContactItemActions.svelte';
  import PictureInitials from '../users/PictureInitials.svelte';
  import AjaxHandler from '../button/AjaxHandler.svelte';

  let className = '';
  export { className as class };
  export let contact;
  export let displayFavorite = true;
  export let hideActions = [];
  export let isChildTooltip = false;
  export let tooltipParams = {};
  export let hideTimezone = false;

  tooltipParams.theme = 'lf-mini-contact-card';
  let closeTooltip;

  const onAjaxDone = () => closeTooltip && closeTooltip();
</script>

<AjaxHandler on:ajaxDone={onAjaxDone} />
<Tooltip bind:closeTooltip {isChildTooltip} {tooltipParams} disableBodyScroll>
  <Button
    on:click
    usage="link"
    color="none"
    uppercase={false}
    class="lf-mini-contact-card-trigger {className}"
    {...excludeProps(prefixFilter($$restProps, 'trigger$'), [])}>
    <PictureInitials
      slot="leftIcon"
      size="sm"
      {contact}
      {displayFavorite}
      {...excludeProps(prefixFilter($$restProps, 'picture$'), ['contact'])} />
    <div
      class:flex={true}
      class:items-center={true}
      class:space-x-2={true}
      {...prefixFilter($$restProps, 'label$')}>
      <slot name="contactName">
        <div
          class:lf-mini-contact-card__contact-name={true}
          {...prefixFilter($$restProps, 'contactname$')}>
          <ContactName {contact} class="text-base leading-5 text-gray-800" />
        </div>
      </slot>
      {#if !hideTimezone}
        <slot name="timezone">
          <div
            class:lf-mini-contact-card__timezone={true}
            class:flex={true}
            class:items-center={true}
            class:space-x-2={true}
            class:whitespace-no-wrap={true}
            {...prefixFilter($$restProps, 'timezone$')}>
            <span>
              <MenuIcon
                class="w-4 h-4 text-secondary-500 transition-opacity ease-in-out duration-300 opacity-50 hover:opacity-100" />
            </span>
            <ContactTimezone
              {contact}
              class="text-sm leading-none text-gray-400" />
          </div>
        </slot>
      {/if}
    </div>
  </Button>
  <ContactItemActions
    slot="tooltip"
    activeShowing
    on:addNote
    on:addNote={(evt) => closeTooltip(evt)}
    on:sendMessage
    on:sendMessage={(evt) => closeTooltip(evt)}
    on:closeTooltip={(evt) => closeTooltip(evt)}
    {contact}
    {displayFavorite}
    {hideActions} />
</Tooltip>

<style lang="postcss">:global(.tippy-box[data-theme~=lf-mini-contact-card] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-mini-contact-card] .tippy-content){margin:1rem;padding:0}</style>
