<script>
  /*global Drupal*/
  import Link from '../../link/Link.svelte';
  import {
    buildUrl,
    deepGet,
    formatDateTime,
    getTimezoneIdentifier,
  } from 'svelte-utilities';

  export let rowData = {};
  export let column;
  export let meta;

  // @todo hack to hide warning for unused prop.
  column;
  meta;

  let description = '';
  let entityId = 0;
  let url;

  $: {
    description = deepGet(rowData, 'description', '');
    description =
      description.trim().length > 0
        ? description
        : deepGet(rowData, 'data.lesson.label', 'Missing Description');
    entityId = deepGet(rowData, 'entity_id', 0);

    if (entityId > 0 && deepGet(rowData, 'entity_type', '') === 'lf_lesson') {
      const instructorId = deepGet(rowData, 'instructor.id', 0);

      if (instructorId > 0) {
        url = buildUrl(`user/${instructorId}/lessons`, {
          query: { id: entityId },
        });
      }
    }
  }
</script>

{@html description}

{#if url}
  <span class="lf-instructor-payment__description__link">
    (<Link
      path={url.href}
      attributes={{
        target: '_blank',
      }}>#{entityId}</Link
    >)
  </span>
{/if}

<style lang="postcss">:global(.lf-instructor-payment__description__student){display:inline-block;margin-right:.5em;min-width:130px}</style>
