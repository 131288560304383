<script>
  // Svelte imports, utilities, other components, component helpers.
  import { createEventDispatcher } from 'svelte';
  import _get from 'lodash-es/get.js';
  import _filter from 'lodash-es/filter.js';
  import _sortBy from 'lodash-es/sortBy.js';
  import {
    getWeekday,
    getTime,
    getMonth,
    getDay,
    getTimezoneIdentifier,
    getOrdinal,
    formatCurrency,
    incDate,
  } from 'svelte-utilities';
  import { Button, Dialog, Cards, ProgressLinear } from 'svelte-tailwind-components';
  import { setSelectedCard, getUpcomingLessons, requestCancel } from './subscriptions.api.js';
  import CardsOnFileSelectInstance from '../commerce/CardsOnFileSelectInstance.svelte';

  export let className = '';
  export { className as class };

  export let subscription = {};
  export let cardsOnFile;

  const dispatch = createEventDispatcher();

  // @todo if an error occurs loading upcoming then display next billing cycle.
  let promise = getUpcomingLessons(subscription.id, { limit: 0 });
  let upcomingLessons = [];
  let hasFutureLessons = false;
  let viewCancelForm = false;

  promise.then((res) => {
    upcomingLessons = _sortBy(res, { 'datetime.start': 'ASC' }).map((lesson) => {
      lesson._meta = lesson._meta || {};
      lesson._meta.pastDue =
        lesson.charge_status == 'payment_due' && lesson.datetime.start < nextBillingStart;
      lesson._meta.inNextBill =
        lesson.charge_status == 'payment_due' &&
        lesson.datetime.start >= nextBillingStart &&
        lesson.datetime.end < nextBillingEnd;
      lesson._meta.inFutureBill = lesson.datetime.start >= nextBillingEnd;
      lesson._meta.monthShort = getMonth(lesson.datetime.start);
      lesson._meta.monthLong = getMonth(lesson.datetime.start, 'long');
      lesson._meta.day = getDay(lesson.datetime.start, '2-digit');
      lesson._meta.startTime = getTime(lesson.datetime.start);
      lesson._meta.endTime = getTime(lesson.datetime.end);

      if (lesson._meta.inFutureBill) {
        hasFutureLessons = true;
      }

      return lesson;
    });
  });

  let nextBillingStart = _get(Drupal.settings, 'lf_subscriptions.next_billing_cycle.start', 0);
  let nextBillingEnd = _get(Drupal.settings, 'lf_subscriptions.next_billing_cycle.end', 0);
  let nextBillingDate = _get(Drupal.settings, 'lf_subscriptions.next_billing_cycle.payment', 0);
  let nextBillingMonth = getMonth(incDate(nextBillingDate, 60 * 60 * 24 * 10), 'long');
  let disclaimer = _get(Drupal.settings, 'lf_subscriptions.disclaimer', '');

  let pageUserUID = _get(Drupal.settings, 'lf_svelte.pageUser.uid', 0);
  let studentName = pageUserUID !== subscription.student.id ? subscription.student.fullname : '';
  let showAllLessons = false;

  let day = getWeekday(subscription.start_time, 'long');
  let startTime = getTime(subscription.start_time);
  let endTime = getTime(incDate(subscription.start_time, subscription.duration));
  let tzLabel = getTimezoneIdentifier(subscription.start_time);

  let cancelFormMessage = _get(Drupal.settings, 'lf_subscriptions.cancel_message', '');
  let cancelRequest = '';

  subscription.next_billing_cycle = subscription.next_billing_cycle || {
    lessons: [],
    estimate: 0,
  };

  let nextBillingLessonIDs = _filter(Object.values(subscription.next_billing_cycle.lessons), {
    charge_status: 'payment_due',
  }).map((l) => l.id);

  async function changeSelectedCard(evt) {
    let res = await setSelectedCard(subscription.id, evt.detail.paymentMethodId);

    if (res.status) {
      dispatch('cardChanged', evt.detail);
      subscription.cardonfile = evt.detail.paymentMethodId;
    }
  }

  async function onSendCancelRequest() {
    viewCancelForm = false;

    try {
      await requestCancel(subscription.id, cancelRequest);
      // dispatch('canceled', { subscriptionId: subscription.id });
      window.snacksQueue.add({
        label: 'Subscription modification request has been sent.',
        type: 'success',
      });
    } catch (err) {
      window.snacksQueue.add({
        label: 'An error occurred requesting subscription modification.',
        message: 'Please contact Lessonface Support for assistance.',
        type: 'danger',
        settings: {
          persistent: true,
        },
      });
    }
  }
</script>

<Cards.Card class="lf-subscription max-w-lg {className}">
  <Cards.Header>
    <div class="flex flex-col">
      {#if subscription.next_billing_cycle.estimate > 0}
        <div
          class="flex items-start justify-start mb-1 text-sm uppercase rounded lf-subscription__payment-reminder text-danger-700"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 lf-subscription__payment-reminder__icon"
            viewBox="0 0 20 20"
            fill="currentColor"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"></path></svg
          >
          <span>Upcoming Payment Due</span>
        </div>
      {/if}
      <h3 class="m-0 text-xl font-medium text-gray-900 lf-subscription__title leading-6">
        {subscription.title}
        <span class="ml-2 text-xs text-gray-100 hover:text-gray-400">#{subscription.id}</span>
      </h3>
      {#if studentName}
        <div
          class="flex items-start justify-start mt-2 font-medium lf-subscription__student text-secondary-500"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-gray-400 lf-subscription__student__icon"
            viewBox="0 0 20 20"
            fill="currentColor"
            ><path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"></path></svg
          >
          <span>{studentName}</span>
        </div>
      {/if}
    </div>
  </Cards.Header>
  <div class="flex justify-between px-8 py-4 text-white bg-secondary-500 lf-subscription__details">
    <div class="flex flex-col lf-subscription__dow">
      <span class="text-xs uppercase">{subscription.frequency}</span>
      <span class="font-mono">{day}</span>
    </div>
    <div class="flex flex-col lf-subscription__time">
      <span class="text-xs uppercase">Lesson Time</span>
      <span class="font-mono">{startTime} {tzLabel}</span>
    </div>
    <div class="flex flex-col lf-subscription__duration">
      <span class="text-xs uppercase">Duration</span>
      <span class="font-mono">{subscription.duration / 60} min</span>
    </div>
  </div>
  {#if subscription.next_billing_cycle.estimate > 0}
    <div class="px-6 pt-4 pb-0 lf-subscription__payment">
      <div class="flex items-start justify-start lf-subscription__payment__details">
        <svg
          class="flex-shrink-0 w-5 h-5 mr-2 text-gray-400 lf-subscription__payment__icon"
          viewBox="0 0 20 20"
          fill="currentColor"
          ><path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path><path
            fill-rule="evenodd"
            d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
            clip-rule="evenodd"></path></svg
        >
        <div class="flex flex-col lf-subscription__payment__text">
          <h3
            class="mt-0 mb-0 text-lg font-medium text-gray-900 lf-subscription__payment__month leading-6"
          >
            {nextBillingMonth} Payment
          </h3>
          <div>
            {nextBillingLessonIDs.length} x {formatCurrency(subscription.unit_price.amount)} / lesson
            = {formatCurrency(subscription.next_billing_cycle.estimate)}
          </div>
          <div>
            Card automatically charged on {getMonth(nextBillingDate)}
            {getDay(nextBillingDate)}{getOrdinal(getDay(nextBillingDate))}
          </div>
        </div>
      </div>
      {#if disclaimer.length}
        <div class="mt-2 text-sm text-gray-600 lf-subscription__payment__disclaimer">
          {@html disclaimer}
        </div>
      {/if}
    </div>
  {/if}

  <div class="px-6 mt-4 lf_subscription__cardonfile">
    <CardsOnFileSelectInstance
      on:selectedCardChanged="{changeSelectedCard}"
      bind:cardsOnFile
      bind:paymentMethodId="{subscription.cardonfile}"
      subscriptionId="{subscription.id}"
    />
  </div>

  <Cards.Content>
    <div class="lf-subscription__upcoming-lessons">
      <h3
        class="mt-0 mb-0 text-lg font-medium text-gray-900 lf-subscription__upcoming-lessons__label leading-6"
      >
        Upcoming Lessons
      </h3>
      <div
        class="flex items-start justify-start mt-2 mb-4 font-medium text-gray-700 lf-subscription__recurring-summary"
      >
        <svg
          class="flex-shrink-0 w-5 h-5 mr-2 text-gray-400 lf-subscription__recurring-summary__icon"
          viewBox="0 0 20 20"
          fill="currentColor"
          ><path
            fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"></path></svg
        >
        <span
          >{subscription.frequency == 'biweekly' ? `Every other ${day}` : `${day}s`} from {startTime.split(
            ' '
          )[0]} to {endTime}
          {tzLabel}</span
        >
      </div>
      {#await promise}
        <ProgressLinear settings="{{ indeterminate: true }}" />
      {:then}
        {#each upcomingLessons as lesson (lesson.id)}
          <div
            class:hidden="{lesson._meta.inFutureBill && !showAllLessons}"
            class="flex items-center justify-between mt-2 lf-subscription__upcoming-lesson"
          >
            <div
              class:bg-danger-700="{lesson._meta.pastDue}"
              class:bg-danger-500="{lesson._meta.inNextBill}"
              class="flex flex-col items-center px-1 py-1 mr-3 leading-none text-white rounded lf-subscription__upcoming-lesson__date bg-secondary-500"
            >
              <div class="text-2xl lf-subscription__upcoming-lesson__day">
                {lesson._meta.day}
              </div>
              <div class="text-sm uppercase lf-subscription__upcoming-lesson__month">
                {lesson._meta.monthShort}
              </div>
            </div>
            <div
              class="flex flex-col items-start justify-center lf-subscription__upcoming-lesson__other"
            >
              <div
                class="flex items-center justify-start text-gray-700 lf-subscription__upcoming-lesson__time"
              >
                <div class="mr-2 lf-subscription__upcoming-lesson__start-time">
                  {lesson._meta.startTime}
                </div>
                <div>-</div>
                <div class="ml-2 lf-subscription__upcoming-lesson__end-time">
                  {lesson._meta.endTime}
                  {getTimezoneIdentifier(lesson.datetime.end)}
                </div>
              </div>
              <div
                class:text-gray-400="{!lesson._meta.pastDue && !lesson._meta.inNextBill}"
                class:text-danger-700="{lesson._meta.pastDue || lesson._meta.inNextBill}"
                class:uppercase="{lesson._meta.pastDue}"
                class:capitalize="{!lesson._meta.pastDue && !lesson._meta.inNextBill}"
                class="text-sm lf-subscription__upcoming-lesson__charge-status"
              >
                {#if lesson._meta.pastDue}
                  Past Due
                {:else if lesson._meta.inNextBill}
                  Payment due on {getMonth(nextBillingDate)}
                  {getDay(nextBillingDate)}{getOrdinal(getDay(nextBillingDate))}
                {:else if lesson.charge_status == 'payment_due'}
                  Future Payment
                {:else}
                  {lesson.charge_status.replace('_', ' ')}
                {/if}
              </div>
            </div>
            <div class="ml-auto lf-subscription__upcoming-lesson__link">
              <Button
                usage="text"
                size="sm"
                target="_blank"
                href="/user/{pageUserUID}/lessons"
                urlOptions="{{
                  query: { id: lesson.id },
                }}"
              >
                View
              </Button>
            </div>
          </div>
        {:else}
          <div class="lf-subscription__upcoming-lessons__empty">
            You have no upcoming lessons at this time.
          </div>
        {/each}
        {#if hasFutureLessons}
          <div
            class="flex items-center justify-end pt-2 lf-subscription__upcoming-lessons__show-all"
          >
            <Button usage="text" size="xs" on:click="{() => (showAllLessons = !showAllLessons)}"
              >Show All Lessons</Button
            >
          </div>
        {/if}
      {:catch}
        <div class="lf-subscriptions__upcoming-lessons__error">
          An error occurred loading your upcoming lessons. Please contact Lessonface Support.
        </div>
      {/await}
    </div>
  </Cards.Content>
  <Cards.Actions>
    <Button usage="text" color="danger" size="sm" on:click="{() => (viewCancelForm = true)}">
      Modify or Cancel
    </Button>
  </Cards.Actions>
</Cards.Card>

<Dialog
  id="lf-subscription-cancel-request--{subscription.id}"
  includeCloseButton="{false}"
  isModal
  bind:isOpen="{viewCancelForm}"
>
  <Cards.Card class="max-w-2xl lf-subscription-cancel-request-form">
    <Cards.Header>
      <h3
        class="m-0 text-xl font-medium text-gray-900 lf-subscription-cancel-request-form__title leading-6"
      >
        <div class="text-sm uppercase text-secondary-500">Modify or Cancel Subscription</div>
        {subscription.title}
      </h3>
      {#if studentName}
        <div class="lf-subscription__student">
          <svg class="lf-subscription__student__icon" viewBox="0 0 20 20" fill="currentColor"
            ><path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"></path></svg
          >
          <span>{studentName}</span>
        </div>
      {/if}
    </Cards.Header>
    <div class="lf-subscription-cancel-request-form__details">
      <div
        class="flex justify-between px-8 py-4 text-white bg-secondary-500 lf-subscription__details"
      >
        <div class="flex flex-col lf-subscription__dow">
          <span class="text-xs uppercase">{subscription.frequency}</span>
          <span class="font-mono">{day}</span>
        </div>
        <div class="flex flex-col lf-subscription__time">
          <span class="text-xs uppercase">Lesson Time</span>
          <span class="font-mono">{startTime} {tzLabel}</span>
        </div>
        <div class="flex flex-col lf-subscription__duration">
          <span class="text-xs uppercase">Duration</span>
          <span class="font-mono">{subscription.duration / 60} min</span>
        </div>
      </div>
    </div>
    <Cards.Content>
      {#if cancelFormMessage}
        <div class="mb-4 lf-subscription-cancel-request-form__info">
          {@html cancelFormMessage}
        </div>
        <div class="lf-subscription-cancel-request-form__message form-item form-type-textarea">
          <label for="cancel-request">
            Contact support to modify or cancel your subscription
            <span class="form-required" title="This field is required.">*</span>
          </label>
          <div class="form-textarea-wrapper resizable resizable-textarea">
            <textarea
              bind:value="{cancelRequest}"
              class="w-full mt-1 text-full form-textarea"
              rows="3"></textarea>
          </div>
        </div>
      {/if}
    </Cards.Content>
    <Cards.Actions>
      <Button on:click="{() => (viewCancelForm = false)}" usage="text" size="sm">Close</Button>
      <Button
        on:click="{onSendCancelRequest}"
        disabled="{cancelRequest.length === 0}"
        usage="button"
        color="primary"
        size="sm"
      >
        Send Request
      </Button>
    </Cards.Actions>
  </Cards.Card>
</Dialog>

<style lang="postcss">:global(.dna-dialog) .lf-subscription__details{--bg-opacity:1;--text-opacity:1;background-color:#f4f5f7;background-color:rgba(244,245,247,var(--bg-opacity));color:#161e2e;color:rgba(22,30,46,var(--text-opacity))}.lf-subscription__upcoming-lesson__date{flex:0 0 48px}</style>
