<script>
  import { onMount } from 'svelte';

  import { getCardsOnFile } from '../../../components/commerce/stripe.api.js';
  import { logger } from '../../../utils/logger.js';
  import _get from 'lodash-es/get.js';

  import StripeCardOnFile from '../../../components/commerce/StripeCardOnFile.svelte';
  import AddCard from '../../../components/commerce/AddCard.svelte';
  import Button from '../../../components/button/Button.svelte';
  import Spinner from '../../../components/spinner/Spinner.svelte';

  let className = '';
  export { className as class };

  let isInitialized = false;
  let cardsOnFile;
  let errorMessage = '';
  const cardActions = ['edit', 'delete', 'setDefault', 'change'];

  let pageUserUID = _get(Drupal.settings, 'lf_svelte.pageUser.uid', 0);

  let isAdding = false;

  function onCancelAddCard() {
    isAdding = false;
  }

  function onAddCard() {
    isAdding = true;
  }

  async function onSetupIntentCreated() {
    try {
      cardsOnFile = await getCardsOnFile(pageUserUID);
      return Promise.resolve(cardsOnFile);
    }
    catch (err) {
      errorMessage = err.message;
      return false;
    }
    finally {
      isAdding = false;
    }
  }

  async function updateCard(evt) {
    const savedCard = evt.detail.card || false;

    if (savedCard) {
      cardsOnFile = cardsOnFile.map(card => {
        if (card.id == savedCard.id) {
          card = {...savedCard};
        }

        return card;
      });
    }
  }

  async function onSetDefault(evt) {
    const defaultPaymentMethodId = evt.detail.defaultPaymentMethodId || false;

    if (defaultPaymentMethodId) {
      cardsOnFile = cardsOnFile.map(card => {
        card.isDefault = (card.id === defaultPaymentMethodId);
        return card;
      });
    }
  }

  async function deleteCard(evt) {
    const deletedCardID = evt.detail.cardID || false;

    if (deletedCardID) {
      cardsOnFile = cardsOnFile.filter(card => card.id !== deletedCardID);
    }
  }

  async function initialize() {
    cardsOnFile = await getCardsOnFile(pageUserUID);
    logger.debug('initialize', 'cardsOnFile', cardsOnFile);

    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  id="lf-stripe-cardsonfile"
  class:lf-stripe-cardsonfile--initializing={!isInitialized}
  class="lf-stripe-cardsonfile hoverable-on {className}">
  {#if !isInitialized}
    <Spinner size=48 variation="dark"/>
  {:else}
    {#if !isAdding}
      {#each cardsOnFile as cardOnFile (cardOnFile.id)}
        <StripeCardOnFile
          on:cardUpdated={updateCard}
          on:cardSetDefault={onSetDefault}
          on:cardDeleted={deleteCard}
          {cardOnFile}
          {cardActions}/>
      {/each}
      <div class="lf-stripe-cardsonfile--adding">
        <Button
          on:click={onAddCard}
          class="lf-stripe-cardsonfile-add-button button button--link button--small">Add Card</Button>
      </div>
    {:else}
      <div class="lf-stripe-cardsonfile--addcard">
        <AddCard
          on:setupIntentCreated={onSetupIntentCreated}
          on:cancelAddCard={onCancelAddCard} />
      </div>
    {/if}
  {/if}
  {#if !!+errorMessage.length}
    <div class="lf-stripe-cardsonfile-error">
      {errorMessage}
    </div>
  {/if}
</div>

<style lang="postcss">.lf-stripe-cardsonfile{--lf-spinner-color:#3f9d4b;background-color:#fff;max-width:650px;position:relative}.lf-stripe-cardsonfile--initializing{min-height:64px}.lf-stripe-cardsonfile--addcard{padding:.5em 1em}.lf-stripe-cardsonfile--adding{border-top:1px solid #d2d2d2;padding:.5em 1em}</style>
