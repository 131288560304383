<svelte:options immutable="{true}" />

<script>
  // @see https://css-tricks.com/responsive-iframes/
  import { onMount } from 'svelte';
  import Video from '../videos/VideoV2.svelte';
  import Audio from '../audio/Audio.svelte';

  let className = '';
  export { className as class };
  export let file;
  // If aspectRatio is provided then the width/height can be calculated
  // based on the wrapper element's size. If width or height is provided
  // as well then the remaining value will be calculated. If aspect ratio
  // is not provided then it will be calculated based on the width/height
  // props or the wrapper element size.
  export let aspectRatio = 0;
  export let maxWidth = 0;
  export let maxHeight = 0;

  let wrapperEl;
  let initialized = false;
  let dimensions = {};

  const nonZeroArray = (arr) => arr.filter((v) => v > 0);

  const calcAspectRatio = () => {
    // Determine the "true ratio" if the file metadata provides the width
    // and the height information. Then we calculate the actual width and
    // height we can use based on the file metadata, max props, and the
    // wrapper element's size. If a true ratio is not provided then we can
    // calc the fuzzy ratio based on that width/height combo.
    const fileWidth = file?.metadata?.width ?? 0;
    const fileHeight = file?.metadata?.height ?? 0;
    const trueRatio = fileWidth > 0 && fileHeight > 0 ? fileWidth / fileHeight : 0;

    // @note hack for sticky footer overlaying pdf.
    const parentHeight = wrapperEl.offsetParent.offsetHeight;
    const headerHeight = wrapperEl.parentElement.previousElementSibling.offsetHeight;
    const footerHeight = wrapperEl.parentElement.nextElementSibling.offsetHeight;

    const targetWidth = Math.min(
      ...nonZeroArray([fileWidth, maxWidth, wrapperEl.offsetParent.offsetWidth])
    );
    const targetHeight = Math.min(
      ...nonZeroArray([fileHeight, maxHeight, parentHeight - headerHeight - footerHeight])
    );
    const fuzzyRatio = targetWidth / targetHeight;

    aspectRatio = aspectRatio > 0 ? aspectRatio : trueRatio > 0 ? trueRatio : fuzzyRatio;

    // Now that we have the aspect ratio we need to figure out if the file
    // actually fits inside the wrapper element and maintains the ratio. An
    // image can scale but the iframe needs the width and height props. It is
    // also better to have the width and height on the image as well even as
    // it scales.
    // This first test determines if the needed height larger than the aspect
    // ratio height and then sets the height. If that fails then we change the width.
    dimensions =
      targetHeight > targetWidth / aspectRatio
        ? { width: targetWidth, height: targetWidth / aspectRatio }
        : { width: aspectRatio * targetHeight, height: targetHeight };
  };

  // A broken image or video might not look the greatest but we want to avoid
  // a broken iframe that brings up the Drupal 404 page.
  const isURLValid = async () => {
    if (file?.type !== 'document') {
      return undefined;
    }
    // Ignore aws urls as they currently return 403s.
    if (file?.url?.includes('amazonaws')) {
      return undefined;
    }

    try {
      const res = await fetch(file.url, { method: 'HEAD' });
      return res?.ok && res?.status >= 200 && res?.status < 300;
    } catch (err) {
      console.warn(err);
      return false;
    }
  };

  onMount(async () => {
    // @todo lazy load? (maybe for a separate iframe component).
    file?.id > 0 && calcAspectRatio();
    const isValid = await isURLValid();
    initialized =
      aspectRatio > 0 && dimensions.width > 0 && dimensions.height > 0 && isValid !== false;
  });
</script>

<div
  bind:this="{wrapperEl}"
  class:lf-file-embed-wrapper="{true}"
  class="{className}"
  style="--aspect-ratio: {aspectRatio}; --max-width: {dimensions.width}px; --max-height: {dimensions.height}px;">
  {#if initialized}
    {#if file?.type === 'document'}
      <iframe
        class="w-full h-full"
        src="{file.url}"
        title="{file.name}"
        {...dimensions}
        allow="fullscreen"
        loading="lazy"
        frameborder="0">
        <slot />
      </iframe>
    {:else if file?.type === 'image'}
      <img
        class="{file?.metadata?.width > 0 ? 'w-full h-full' : 'w-auto h-auto'}"
        src="{file.url}"
        alt="{file.name}"
        {...dimensions} />
    {:else if file?.type === 'video'}
      <Video file="{file}" />
    {:else if file?.type === 'audio'}
      <Audio file="{file}" />
    {:else if file?.type}
      <span class="hidden">@todo {file.type}</span>
    {/if}
  {/if}
</div>

<style lang="postcss">.lf-file-embed-wrapper{max-height:var(--max-height);max-width:var(--max-width)}:global(.lf-file-embed-wrapper[style*="--aspect-ratio"]>img){max-height:var(--max-height);max-width:var(--max-width)}@supports (--custom:property){:global(.lf-file-embed-wrapper[style*="--aspect-ratio"]){position:relative}:global(.lf-file-embed-wrapper[style*="--aspect-ratio"]:before){content:"";display:block;padding-bottom:calc(100%/(var(--aspect-ratio)))}:global(.lf-file-embed-wrapper[style*="--aspect-ratio"]>:first-child){left:0;position:absolute;top:0}}</style>
