<script>
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps, prefixFilter, Klasses } from 'svelte-utilities';

  import { tooltip } from '../tooltip/tooltip.js';
  import ripple from '../ripple';

  import NakedButton from './NakedButton.svelte';
  import Link from '../link/Link.svelte';
  // import ProgressCircular from '../progress/ProgressCircular.svelte';
  import CircleSpinnerIcon from '../icons/CircleSpinnerIcon.svelte';

  export let element = undefined;
  export let use = [];
  let className = '';
  export { className as class };

  // Styling props (default: elevated, button, rounded, primary).
  // Usage: button, text/link, icon, fab.
  export let usage = 'button';
  // Sizes: xs, sm, md, lg, xl.
  export let size = 'md';
  // Colors: primary, secondary, tertiary, success, warning, danger, info.
  // Note: color 'none' added to skip handling color classes.
  export let color = 'primary';
  export let colorScale = 500;
  // Shapes: rounded, pill, tile.
  export let shape = 'rounded';

  // Outlined: true / false.
  export let outlined = false;
  // Flat: true / false.
  export let flat = false;
  export let padding = true;
  export let uppercase = true;
  export let fullwidth = false;

  // @todo shortcuts.
  // Shortcut props. Easily configure style props with a single short-cut.
  // - naked: apply limited styles.
  // export let btnStyle = null;

  export let label = '';

  // Functional props (working, etc.).
  export let working = false;
  export let href = undefined;
  export let rippleSettings = {};
  export let tooltipContent = '';

  const colored = color !== 'none';
  const filled = ['button', 'fab'].includes(usage) && !outlined;
  const coloredText = usage !== 'button';
  const iconStyle = ['icon', 'fab'].includes(usage);

  const progressSizeMap = { xs: 20, sm: 24, md: 28, lg: 28, xl: 32 };
  // const progressColor = filled ? 'text-white' : `text-${color}-500`;

  const klasses = new Klasses();
  const leftIconKlasses = new Klasses();

  const tooltipParams = tooltipContent.length > 0 ? { content: tooltipContent } : undefined;

  if (tooltipParams) {
    use = [[tooltip, tooltipParams], ...use];
  }

  let component;

  if (usage === 'icon') {
    padding = false;
  }

  if (usage === 'fab') {
    shape = 'pill';
  }

  colorScale = parseInt(colorScale);
  flat = flat || coloredText;
  outlined = outlined || !coloredText;

  $$props.progress$settings = {
    ...{
      indeterminate: true,
      size: progressSizeMap[size],
      width: 2,
    },
    ...$$props.progress$settings,
  };

  // @todo support dynamic imports.
  // @see https://stackoverflow.com/questions/60024414/how-to-do-code-splitting-using-svelte-without-sapper

  // Dynamically load the sub component.
  // if (href === null) {
  //   import('./NakedButton.svelte').then(res => component = res.default);
  // }
  // else {
  //   import('../link/Link.svelte').then(res => component = res.default);
  // }

  let filledClasses;
  let textEmptyClasses;
  let outlineClasses;
  let filledClassesDisabled;
  let textDisabled;

  let usable;

  let classes = klasses
    .add('inline-flex', !className.split(' ').includes('flex'))
    .add('text-xs leading-5', size == 'xs')
    .add('text-sm leading-6', size == 'sm')
    .add('text-base leading-7', size == 'md')
    .add('text-lg leading-7', size == 'lg')
    .add('text-xl leading-8', size == 'xl')
    .add('py-1 px-2.5', !iconStyle && padding && size == 'xs')
    .add('py-1 px-3', !iconStyle && padding && size == 'sm')
    .add('py-1 px-4', !iconStyle && padding && size == 'md')
    .add('py-2 px-4', !iconStyle && padding && size == 'lg')
    .add('py-2 px-6', !iconStyle && padding && size == 'xl')
    .add('p-1', iconStyle && padding && size == 'xs')
    .add('p-1', iconStyle && padding && size == 'sm')
    .add('p-2', iconStyle && padding && size == 'md')
    .add('p-2', iconStyle && padding && size == 'lg')
    .add('p-3', iconStyle && padding && size == 'xl')
    .add('w-full', fullwidth)
    .setDefault()
    .get();
  let leftIconClasses = leftIconKlasses.setDefault().get();

  $: {
    component = href === null || href === undefined ? NakedButton : Link;

    $$props.disabled = $$props.disabled || false;
    usable = !$$props.disabled && !working;

    rippleSettings = { disabled: !ripple || !usable, centered: iconStyle };

    if (colored) {
      filledClasses = [
        Klasses.colorClass(['bg', color, colorScale]),
        Klasses.colorClass(['bg', color, colorScale - 100], 'hover'),
        Klasses.colorClass(['border', color, parseInt(colorScale) + 100], 'focus'),
        Klasses.colorClass(['shadow', 'outline', color], 'focus'),
        Klasses.colorClass(['bg', color, parseInt(colorScale) + 100], 'active'),
      ];
      textEmptyClasses = [
        Klasses.colorClass(['text', color, colorScale]),
        Klasses.colorClass(['text', color, parseInt(colorScale) - 100], 'hover'),
        Klasses.colorClass(['text', color, +colorScale + 100], 'focus'),
        Klasses.colorClass(['border', color, colorScale - 300], 'focus'),
        Klasses.colorClass(['text', color, parseInt(colorScale) + 200], 'active'),
      ];
      outlineClasses = [
        Klasses.colorClass(['border', color, colorScale + 100]),
        Klasses.colorClass(['border', color, colorScale - 100], 'focus'),
        Klasses.colorClass(['bg', color, 100], 'active'),
      ];
      filledClassesDisabled = [Klasses.colorClass(['bg', color, 100])];
      textDisabled = [
        Klasses.colorClass(['text', color, Math.max(colorScale - (filled ? 100 : 300), 100)]),
      ];
    }

    classes = klasses
      .reset()
      .add(`dna-button--${usage}`)
      .add(`dna-button--${size}`)
      .add(`dna-button--${shape}`)
      .add('dna-button--filled', filled)
      .add('rounded-xl', shape == 'rounded' && size == 'xs')
      .add('rounded-xl', shape == 'rounded' && size != 'xs')
      .add('rounded-full', usage === 'fab' || shape === 'pill')
      .add('elevation-2', !flat && !$$props.disabled)
      .add(
        'text-white visited:text-white hover:text-white focus:text-white',
        colored && filled && !$$props.disabled
      )
      .add(filledClasses, color && filled && usable)
      .add(textEmptyClasses, color && !filled && usable)
      .add(outlineClasses, color && !filled && outlined && !$$props.disabled)
      .add(textDisabled, color && $$props.disabled)
      .add(filledClassesDisabled, color && filled && $$props.disabled)
      .add('cursor-not-allowed', $$props.disabled)
      .add(Klasses.colorClass(['border', color, colorScale - 200]), colored && outlined && !usable)
      .add('pointer-events-none', working)
      .add(
        Klasses.colorClass(['text', color, colorScale - 100]),
        colored && color && !filled && working
      )
      .add(
        Klasses.colorClass(['bg', color, colorScale - 100]),
        colored && color && filled && working
      )
      .add('justify-center', !className.includes('justify-start'))
      .add('uppercase', uppercase)
      .add(className)
      .get();

    leftIconClasses = leftIconKlasses
      .reset()
      .add('-ml-1 mr-1', !iconStyle && ['xs', 'sm'].includes(size))
      .add('-ml-2 mr-2', !iconStyle && ['md', 'lg'].includes(size))
      .add('-ml-3 mr-3', !iconStyle && ['xl'].includes(size))
      .get();
  }
</script>

<svelte:component
  this="{component}"
  use="{[[ripple, rippleSettings], ...use]}"
  bind:element
  bind:disabled="{$$props.disabled}"
  on:click
  class="dna-button relative overflow-hidden items-center min-w-0 border border-transparent font-medium focus:outline-none whitespace-no-wrap transition ease-in-out duration-150 {classes}"
  {...excludeProps($$props, [
    'use',
    'class',
    'usage',
    'size',
    'color',
    'colorScale',
    'shape',
    'outlined',
    'flat',
    'padding',
    'btnStyle',
    'label',
    'working',
    'ripple',
    'uppercase',
    'tooltipContent',
    'progress$',
    'label$',
  ])}>
  {#if working || $$slots.leftIcon}
    <div class="flex flex-shrink-0 dna-button__icon {leftIconClasses}">
      {#if working}
        <slot name="progress">
          <CircleSpinnerIcon class="{size === 'md' ? 'w-5 h-5' : 'w-4 h-4'}" />
        </slot>
      {:else}
        <slot name="leftIcon" />
      {/if}
    </div>
  {/if}
  <div class:dna-button__label="{true}" {...prefixFilter($$restProps, 'label$')}>
    <slot>
      {#if label.length > 0}
        {@html label}
      {/if}
    </slot>
  </div>
</svelte:component>

<style lang="postcss">:global([class^=flex-gap-x],[class*=" flex-gap-x"]>.dna-button--text){margin-left:calc(var(--gap-x) - .75rem)}:global([class^=flex-gap-x],[class*=" flex-gap-x"]>.dna-button--text+.dna-button){margin-left:max(calc(var(--gap-x) - .75rem),.25rem)}:global([class*=-flex-gap-x]>.dna-button+.dna-button--text){margin-left:calc(var(--gap-x)*-1/1.5)}:global([class*=-flex-gap-x]>.dna-button--text+.dna-button){margin-left:calc(var(--gap-x)*-1/1.5)}:global(.dna-button[disabled]){filter:grayscale(.5)}</style>
