<svelte:options immutable={true} />

<script>
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import { Tooltip } from 'svelte-tailwind-components';
  import GoalsSimpleListItem from './GoalsSimpleListItem.svelte';
  import GoalPreview from './GoalPreview.svelte';

  let className = '';
  export { className as class };

  export let goals = [];
  export let label = undefined;

  export let tooltipParams = {
    placement: 'auto',
    theme: 'lf-goals-simple-item-preview',
  };

  let item;
  let isPreviewOpen;
  let openPreview;
  let closePreview;

  const onPreview = (evt, goal) => {
    evt.stopPropagation();
    item = goal;
    openPreview();
  };

  const onPreviewClosed = () => {
    item = undefined;
  };
</script>

<div
  class:lf-goals-simple-list={true}
  class={className}
  {...excludeProps($$restProps, ['list$', 'item$'])}>
  {#if $$slots.header || $$slots.title || label}
    <div class:lf-goals-simple-list__header={true}>
      <slot name="header">
        <div class:lf-goals-simple-list__title={true}>
          <slot name="title">{label}</slot>
        </div>
      </slot>
    </div>
  {/if}
  <div class:lf-goals-simple-list__goals={true} class="flex flex-col">
    {#each goals as goal (goal.id)}
      <GoalsSimpleListItem
        {goal}
        {...prefixFilter($$restProps, 'item$')}
        on:click={(evt) => onPreview(evt, goal)} />
    {/each}
  </div>
</div>

<Tooltip
  {tooltipParams}
  disableBodyScroll={!window.matchMedia('(min-width: 768px)').matches}
  bind:isOpen={isPreviewOpen}
  bind:openTooltip={openPreview}
  bind:closeTooltip={closePreview}
  on:tooltipClosed={onPreviewClosed}>
  <svelte:fragment slot="tooltip">
    {#if isPreviewOpen && item}
      <GoalPreview {item} />
    {/if}
  </svelte:fragment>
</Tooltip>

<style lang="postcss">:global(.tippy-box[data-theme~=lf-goals-simple-item-preview] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-goals-simple-item-preview] .tippy-content){margin:1rem;padding:0}</style>
