<svelte:options immutable={true} />

<script>
  import { createEventDispatcher } from 'svelte';
  import {
    deepGet,
    deepMerge,
    excludeProps,
    prefixFilter,
    replaceMerge,
    stripHTML,
    useActions,
  } from 'svelte-utilities';
  import { Button, MessageIcon, tooltip } from 'svelte-tailwind-components';
  import { filesAPI } from '../files/files.api.js';
  import { newMessageFx, replyToThreadFx } from './messages.controller.js';
  import TipTapEditor from '../tiptap/TipTapEditor.svelte';
  import TipTapRecipients from './TipTapRecipients.svelte';
  import Textfield from '../textfield/Textfield.svelte';
  import ElementErrorMessage from '../form/ElementErrorMessage.svelte';

  let className = '';
  export { className as class };
  export let subject = '';
  export let value = '';
  export let composeNewThread = false;
  export let contacts = undefined;
  export let thread = undefined;
  export let replyToMessageId = undefined;
  export let files = [];
  export let autofocus = true;
  export let working = false;

  export let uppyIdentifier = 'TIPTAPMESSAGE:ATTACHMENTS';
  export let uppyConfig;

  const dispatch = createEventDispatcher();

  const fixedSubject = subject.length > 0;

  const buildFullNames = ({ contacts, replyToMessageId, thread }) => {
    let items = [];

    const message = thread?.messages?.find((m) => m.id === replyToMessageId);

    // Contacts were provided so that defines the recipients.
    if (contacts?.length > 0) {
      items = [...contacts];
    }
    // If user unable to reply all then the other party is the recipient.
    else if (thread && !thread?.canReplyAll) {
      items = [thread.otherParty];
    }
    // If a message ID is provided then the message author is the recipient.
    else if (message?.author?.fullName) {
      items = [message.author];
    }
    // Replying to all thread recipients.
    else if (thread?.recipients?.length > 0) {
      items = [...thread.recipients];
    }

    return items.map((v) => v?.fullName);
  };
  const buildReplyingTo = (fullNames) => {
    const firstFullName = [...fullNames].shift();

    if (!firstFullName || firstFullName.length === 0) {
      return '';
    }

    return fullNames.length > 1
      ? `${firstFullName} +${fullNames.length} more`
      : firstFullName;
  };

  let fullNames = buildFullNames({ contacts, replyToMessageId, thread });
  let replyingTo = buildReplyingTo(fullNames);
  let replyingToTooltip =
    fullNames?.length > 0
      ? { content: fullNames.join(', ') }
      : { disabled: true };

  let resetTipTap;
  let editorWorking;
  let invalidSubject = false;
  let invalidBody = false;
  let invalidContacts = false;
  let focusEl;

  let newFxState;
  let replyFxState;

  let { settings, description } = deepGet(
    Drupal.settings,
    'lf_svelte.uppy.message.attachments',
    {}
  );
  const defaultUppyConfig = {
    settings,
    handlers: {
      getSignedUrl: filesAPI.getUppySignedURL,
      uploadSuccess: filesAPI.getUppyUploadSuccess,
    },
    plugins: {
      dashboard: { note: stripHTML(description) },
    },
  };
  uppyConfig = deepMerge(defaultUppyConfig, uppyConfig, {
    arrayMerge: replaceMerge,
  });

  const resetValidation = () => {
    invalidSubject = false;
    invalidBody = false;
    invalidContacts = false;
  };

  const resetMessage = () => {
    editorWorking = false;
    resetValidation();
    resetTipTap();
  };

  const sendMessage = () => {
    const fileIds = files.map((file) => file.fid);
    const recipientUserIds = (contacts || []).map((c) => c?.userId);
    editorWorking = true;
    newMessageFx({ subject, recipientUserIds, body: value, fileIds });
  };

  // @todo how to handle group replies / bcc?
  const replyToMessage = () => {
    const fileIds = files.map((file) => file.fid);
    editorWorking = true;
    replyToThreadFx({
      subject,
      threadId: thread.id,
      messageId: replyToMessageId,
      body: value,
      fileIds,
    });
  };

  const isMessageValid = () => {
    invalidBody = stripHTML(value).length === 0;
    invalidSubject = subject.length === 0;
    invalidContacts = composeNewThread && contacts.length === 0;
    return !invalidSubject && !invalidBody && !invalidContacts;
  };

  $: {
    newFxState = newMessageFx.state;
    replyFxState = replyToThreadFx.state;
    working = editorWorking || $newFxState || $replyFxState;
  }

  newMessageFx.watch(() => {
    resetMessage();
    dispatch('messageSent');
  });

  replyToThreadFx.watch(() => {
    resetMessage();
    dispatch('messageSent');
  });

  const onDiscard = () => dispatch('messageDisarded');

  const onSend = (evt) => {
    evt.stopPropagation();

    if (isMessageValid()) {
      contacts && contacts.length > 0 && sendMessage();
      (thread?.id || replyToMessageId > 0) && replyToMessage();
    }
  };
</script>

<TipTapEditor
  id="lf-tiptap-message"
  bind:value
  bind:files
  bind:resetTipTap
  bind:working={editorWorking}
  invalid={invalidBody}
  invalidMessage="The message body is required."
  on:discard={onDiscard}
  class="lf-tiptap-message {className}"
  {uppyIdentifier}
  {uppyConfig}
  {focusEl}
  on:uploadsCompleted
  {autofocus}
  {...excludeProps($$restProps, ['subject$'])}>
  <svelte:fragment slot="preContent">
    {#if composeNewThread}
      <TipTapRecipients bind:contacts class="mx-4 my-2" />
      <div class="ml-4">
        <ElementErrorMessage
          makeSpace={false}
          errorMessage={invalidContacts
            ? 'At least one contact is required.'
            : ''} />
      </div>
    {/if}
    {#if $$slots.replyingTo || replyingTo?.length > 0}
      <div class:lf-tiptap-message__replying-to={true} class="mx-4 mt-1">
        <span
          class="text-base leading-5 text-secondary-600 flex items-center space-x-2">
          <span>To:</span>
          <span class="flex-1 min-w-0 truncate">
            <slot name="replyingTo">
              <span use:useActions={[[tooltip, replyingToTooltip]]}>
                {@html replyingTo}
              </span>
            </slot>
          </span>
        </span>
      </div>
    {/if}
    <div class:lf-tiptap-message__subject={true} class="mx-4 my-2">
      {#if fixedSubject}
        <span class="text-base leading-none text-gray-600 flex items-center">
          <span class="flex-1 min-w-0 truncate">Re: {subject}</span>
        </span>
      {:else}
        <Textfield
          name="lf-tiptap-message__subject"
          bind:inputEl={focusEl}
          bind:value={subject}
          label="Subject"
          labelDisplay="invisible"
          placeholder="Subject"
          errorMessage={invalidSubject ? 'The message subject is required' : ''}
          makeSpace={false}
          {...excludeProps(prefixFilter($$restProps, 'subject$'), [])} />
      {/if}
    </div>
  </svelte:fragment>
  <svelte:fragment slot="controlsMenuStart">
    <Button
      on:click={onSend}
      bind:working
      color="primary"
      size="sm"
      class="mr-2">
      <MessageIcon slot="leftIcon" class="w-4 h-4" />
      Send
    </Button>
  </svelte:fragment>
  <slot name="controlsMenuInner" slot="controlsMenuInner" />
</TipTapEditor>

<style lang="postcss">:global(.lf-tiptap-message__recipients){--multiSelectInputPadding:0.75rem;--inputColor:inherit;--margin:0.5rem 1rem;--inputFontSize:1rem;border:0!important;cursor:pointer;line-height:1!important;margin:.5rem 1rem;padding:0!important}:global(.lf-tiptap-message__recipients input[type=text]){border:1px dotted rgba(210,214,220,var(--border-opacity))!important;outline:2px solid transparent;outline-offset:2px}:global(.lf-tiptap-message__recipients input[type=text]):focus{--border-opacity:1;border-color:#6b7280;border-color:rgba(107,114,128,var(--border-opacity));box-shadow:none;outline:2px solid transparent;outline-offset:2px}:global(.lf-tiptap-message__recipients .listContainer){box-shadow:0 3px 1px -2px rgba(var(--boxShadowColor,0,0,0),.2),0 2px 2px 0 rgba(var(--boxShadowColor,0,0,0),.14),0 1px 5px 0 rgba(var(--boxShadowColor,0,0,0),.12)}.lf-tiptap-message__subject :global(.form-item-textfield){margin-bottom:0;margin-top:0}.lf-tiptap-message__subject :global(input[type=text][name=lf-tiptap-message__subject]){--border-opacity:1;border:1px dotted #d2d6dc;border-color:rgba(210,214,220,var(--border-opacity));font-size:1rem;line-height:1;margin-top:0;outline:2px solid transparent;outline-offset:2px;padding:.75rem}.lf-tiptap-message__subject :global(input[type=text][name=lf-tiptap-message__subject]):focus{--border-opacity:1;border-color:#6b7280;border-color:rgba(107,114,128,var(--border-opacity));box-shadow:none;outline:2px solid transparent;outline-offset:2px}:global(.lf-tiptap-message .dna-tiptap__content-wrapper .ProseMirror:focus){--border-opacity:1;border-color:#6b7280;border-color:rgba(107,114,128,var(--border-opacity))}</style>
