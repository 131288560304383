<svelte:options immutable="{true}" />

<script>
  import { createSearcher } from 'svelte-utilities';
  import {
    Button,
    CancelIcon,
    CheckIcon,
    PlusIcon,
    SearchInputV2 as SearchInput,
    Tooltip,
    tooltip,
  } from 'svelte-tailwind-components';
  import { meUser } from '../../models/user.model.js';
  import { contactsStore } from '../contacts/contacts.store.js';
  import { toContactModel } from '../../models/contact.model.js';
  import PictureInitials from '../users/PictureInitials.svelte';

  let className = '';
  export { className as class };
  export let contacts = [];

  const searcher = createSearcher({
    options: { threshold: 0.2, keys: ['fullName'] },
  });

  let contactOptions = $contactsStore.map(toContactModel);
  let multiple = meUser.isAdmin || meUser.isInstructor;
  let tooltipParams = { theme: 'lf-recipients-select', placement: 'auto' };
  let tooltipContent;
  let closeTooltip;
  let clearSearch;
  let triggerLabel;
  let searchable = false;
  let selectedIds = [];
  let selectedFullNames = [];
  let searchEl;

  searcher.createIndex({ items: $contactsStore });

  const getSearchedContacts = (searchText, _contacts) => {
    const searchedItems = !searchable
      ? _contacts
      : searcher._search(searchText, undefined, contactOptions);
    const searchedIds = searchedItems.map((c) => c.id);
    const selectedItems =
      searchText.length === 0
        ? contacts.filter((c) => !searchedIds.includes(c.id)).map(toContactModel)
        : [];
    const results = [...searchedItems, ...selectedItems].slice(0, 10).map(toContactModel);
    return results;
  };

  $: {
    tooltipContent = multiple ? 'Add Contact(s)' : 'Add Contact';
    triggerLabel = contacts.length > 0 ? '' : tooltipContent;
    searchable = contactOptions.length > 10;
    selectedIds = contacts.map((c) => c.id);
    selectedFullNames = contacts.map((c) => c.fullName);
  }

  const onToggleContact = (contact) => {
    const _contacts = !multiple
      ? [contact]
      : selectedIds.includes(contact.id)
      ? contacts.filter((c) => c.id !== contact.id)
      : [...contacts, contact];
    contacts = _contacts.map(toContactModel);
    !multiple && closeTooltip();
  };

  const onRemoveContact = (contact, evt) => {
    evt && evt.stopPropagation();
    contacts = !multiple ? [] : contacts.filter((c) => c.id !== contact.id).map(toContactModel);
  };

  const onRemoveAllContacts = (evt) => {
    evt && evt.stopPropagation();
    contacts = [];
  };

  const onShowTooltip = (evt) => {
    evt.stopPropagation();
    searchEl && searchEl.focus();
  };
  const onCloseTooltip = (evt) => closeTooltip(evt);
  const onTooltipClosed = () => clearSearch && clearSearch();
</script>

{#if contactOptions.length > 0}
  <Tooltip
    tooltipParams="{tooltipParams}"
    bind:closeTooltip
    on:tooltipClosed="{onTooltipClosed}"
    on:showTooltip="{onShowTooltip}"
    class="lf-tooltip-message__recipients-wrapper {className}"
  >
    <div
      class:lf-tiptap-message__recipients-inner-wrapper="{true}"
      class="flex items-center space-x-4"
    >
      {#key contacts}
        {#if multiple || contacts.length === 0}
          <Button
            use="{[[tooltip, { content: tooltipContent }]]}"
            usage="{contacts.length === 0 ? 'button' : 'fab'}"
            color="secondary"
            size="sm"
          >
            <PlusIcon slot="leftIcon" class="w-5 h-5" />
            {triggerLabel}
          </Button>
        {/if}
      {/key}
      {#if contacts.length > 0}
        <div
          class:lf-tiptap-message__selected-recipients="{true}"
          class="flex items-center space-x-2"
        >
          {#each contacts.slice(0, 3) as contact, i (contact.id)}
            <div
              class:lf-tiptap-message__selected-recipient="{true}"
              class="whitespace-no-wrap rounded-full bg-gray-100 flex items-center space-x-2 pr-1 {i >
              0
                ? 'hidden md:flex'
                : ''}"
            >
              <PictureInitials
                slot="leftIcon"
                contact="{contact}"
                size="xs"
                class="flex-shrink-0"
              />
              <span class="text-sm text-gray-600 truncate">{contact.fullName}</span>
              <Button
                on:click="{(evt) => onRemoveContact(contact, evt)}"
                class="flex-shrink-0"
                usage="fab"
                color="gray"
                padding="{false}"
                size="xs"
              >
                <svelte:fragment slot="leftIcon">
                  <svg class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
                  </svg>
                </svelte:fragment>
              </Button>
            </div>
          {/each}
          {#if contacts.length > 1}
            <div
              use:tooltip="{{ content: selectedFullNames.join(', ') }}"
              class:lf-tiptap-message__selected-recipients-more="{true}"
              class="text-sm text-secondary-500"
            >
              <span class="md:hidden whitespace-no-wrap">+{contacts.length - 1} more</span>
              {#if contacts.length > 3}
                <span class="hidden md:inline whitespace-no-wrap">+{contacts.length - 3} more</span>
              {/if}
            </div>
          {/if}
        </div>
      {/if}
      {#if contacts.length > 1}
        <Button
          use="{[[tooltip, { content: 'Remove all contacts' }]]}"
          on:click="{onRemoveAllContacts}"
          usage="fab"
          color="danger"
          size="sm"
        >
          <CancelIcon slot="leftIcon" class="w-5 h-5" />
        </Button>
      {/if}
    </div>
    <div slot="tooltip">
      <div
        class:lf-tooltip-message__recipients-list-wrapper="{true}"
        class="relative flex flex-col space-y-1 bg-white elevation-6 px-0 py-2 max-w-sm"
      >
        <div
          class:lf-tooltip-message__recipients-header="{true}"
          class="px-3 pt-1 flex items-center space-x-2"
        >
          {#if searchable}
            <SearchInput
              searcher="{searcher}"
              bind:el="{searchEl}"
              bind:clearSearch
              name="lf-messages-recipients-search"
              placeholder="Find Contact..."
            />
          {:else}
            <span class="text-gray-400 text-sm font-semibold uppercase flex-1">Contacts</span>
          {/if}
          <div class="bg-secondary-500 flex-shrink-0">
            <Button
              on:click="{onCloseTooltip}"
              class="bg-secondary-500 text-white w-8 h-8"
              usage="icon"
              color="none"
              size="lg"
              flat
              padding="{false}"
              shape="tile"
            >
              <svelte:fragment slot="leftIcon">
                <CancelIcon class="w-6 h-6" />
              </svelte:fragment>
            </Button>
          </div>
        </div>
        <div class:lf-tooltip-message__recipients-list="{true}" class="flex flex-col">
          {#each getSearchedContacts($searcher, $contactsStore) as contact (contact.id)}
            <div class:lf-tooltip-message__recipient="{true}" class="px-1 hover:bg-secondary-200">
              <Button
                on:click="{() => onToggleContact(contact)}"
                class="flex justify-start w-full"
                label$class="flex items-center justify-between flex-1 space-x-2"
                uppercase="{false}"
                usage="link"
                size="sm"
                color="secondary"
              >
                <PictureInitials slot="leftIcon" contact="{contact}" size="xs" />
                <span class="text-gray-600">{contact.fullName}</span>
                <span class:lf-tooltip-message__recipient-check="{true}" class="w-5">
                  {#if selectedIds.includes(contact.id)}
                    <CheckIcon class="w-5 h-5 text-secondary-500" />
                  {/if}
                </span>
              </Button>
            </div>
          {/each}
        </div>
      </div>
    </div>
    <slot />
  </Tooltip>
{/if}

<style lang="postcss">:global(.tippy-box[data-theme~=lf-recipients-select] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-recipients-select] .tippy-content){margin:1rem;padding:0}@media (min-width:768px){.lf-tiptap-message__selected-recipient{flex-basis:25%}}</style>
