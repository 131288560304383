<script>
  import _get from 'lodash-es/get.js';

  export let rowData = {};
  export let column = {};
  export let meta = {};

  let options = { ...column.componentOptions };
  let activeValue = options.activeValue || 'active';
  let inactiveValue = options.inactiveValue || 'inactive';
  let activeIconClass = options.activeIconClass || 'icon-ok';
  let inactiveIconClass = options.inactiveIconClass || 'icon-remove';

  $: data = _get(rowData, column.data, '');
  $: isActive = data === activeValue;
  $: iconClass = isActive ? activeIconClass : inactiveIconClass;
</script>

{#if iconClass.length > 0}
  <i
    class="{iconClass}"
    class:lf-datatable--cell-active="{isActive}"
    class:lf-datatable--cell-inactive="{!isActive}"></i>
{/if}

<style lang="postcss">.lf-datatable--cell-active{--text-opacity:1;color:#328642;color:rgba(50,134,66,var(--text-opacity))}.lf-datatable--cell-inactive{--text-opacity:1;color:#532120;color:rgba(83,33,32,var(--text-opacity))}</style>
