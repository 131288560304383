<script>
  export let options = [10, 20, 50, 100];
  export let value = 20;
  export let label = 'Page Size';

  let className = '';
  export { className as class };

  let id = 'lf-datatable__page-size--' + Date.now();

  let sizeOptions = options.map((option, i) => {
    let newOption = {};

    if (typeof option === 'object') {
      newOption = {...option};
    }
    else {
      newOption = {
        value: option,
        label: option
      };
    }

    return newOption;
  });
</script>

<div
  class="
    lf-datatable__page-size
    form-item
    form-type-select
    {className}
    ">
  <label for="{id}">
    {label}
  </label>
  <select
    bind:value
    {id}
    class="form-select">
    {#each sizeOptions as sizeOption, i (sizeOption.value)}
    <option
      selected={sizeOption.value === value}
      value={sizeOption.value}>{sizeOption.label}</option>
    {/each}
  </select>
</div>

<style lang="postcss">.lf-datatable__page-size{align-items:center;display:flex;justify-content:flex-start;margin:0}.lf-datatable__page-size label{margin-bottom:0;margin-right:.5em}</style>
