<svelte:options immutable={true} />

<script>
  // @todo component.
  // @todo allow styling based on parent component.
  // export let name;
  export let errorMessage = '';
  export let makeSpace = true;

  let className = '';
  export { className as class };
</script>

{#if makeSpace || errorMessage?.length > 0}
  <div
    class="
      form-element-error-message
      {className}
    "
    class:form-element-has-error={!!+errorMessage.length}>
    {@html errorMessage}
  </div>
{/if}

<style lang="postcss">.form-element-error-message{color:#b94a48;font-size:.875em;line-height:1;margin-left:8px;min-height:14px}</style>
