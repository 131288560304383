<svelte:options immutable={true} />

<script>
  import { excludeProps } from 'svelte-utilities';
  import Dashboard from '../../components/uppy/Dashboard.svelte';
</script>

<Dashboard {...excludeProps($$restProps, ['use', 'class'])} />

<style lang="postcss">:global(.lf-s3filefield-audio.video-js .vjs-big-play-button){border-radius:1em;height:1.5em;left:calc(50% - .75em);line-height:1.5em;top:calc(50% - .75em);width:1.5em}</style>
