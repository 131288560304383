<script>
  // @todo snack actions.
  // @todo pause control?
  // @todo turn snackbar content div into a card?

  // Svelte imports, utilities, other components, component helpers.
  import { onMount, createEventDispatcher } from 'svelte';
  import { fade, scale } from "svelte/transition";
  import { excludeProps, Klasses } from 'svelte-utilities';

  import ProgressLinear from '../progress/ProgressLinear.svelte';
  import Button from '../buttons/Button.svelte';
  import ButtonsWrapper from '../buttons/ButtonsWrapper.svelte';

  let className = '';
  export { className as class };

  export let snack = {};
  export let settings = {};
  settings = {
    persistent: false,
    timeout: 5000,
    inProps: { duration: 300 },
    outProps: { duration: 200, delay: 150 },
    ...settings,
  };

  const dispatch = createEventDispatcher();

  const innerKlasses = new Klasses();
  const contentKlasses = new Klasses();
  const actionsKlasses = new Klasses();

  if (snack.type == 'error') {
    snack.type = 'danger';
  }

  let snackSettings = {...settings, ...snack.settings || {}};
  let persistent = snackSettings.persistent || snackSettings.timeout === false;
  let hasIcon = ['info', 'success', 'warning', 'danger'].includes(snack.type) || $$slots.typeIcon;
  let compact = !snack.message;
  let actionProps = {
    usage: 'text',
    size: 'sm',
    // padding: !!snack.message,
  };

  let innerClasses = innerKlasses
    .add('pb-3', !persistent || snack.actions)
    .add('pb-4', persistent && !snack.actions)
    .setDefault()
    .get();
  let contentClasses = contentKlasses
    .add('flex justify-between', compact)
    .setDefault()
    .get();
  let actionsClasses = actionsKlasses
    .add('mt-1', !snack.message)
    .setDefault()
    .get();
  let svgColor = Klasses.colorClass(['text', snack.type, 500]);

  let props = excludeProps($$props, ['use', 'class', 'snack', 'settings']);

  function eatSnack() {
    snack._eaten = true;
  }

  function onMouseenter() {
    if (!persistent) snack._timer.pause();
  }

  function onMouseleave() {
    if (!persistent) snack._timer.resume();
  }

  function initialize() {
    snack._eaten = false;
    if (!persistent) snack._timer.start().then(eatSnack);
  }

  function onOutroEnd() {
    dispatch('eaten', snack);
  }

  onMount(() => {
    initialize();
  });
</script>

{#if !snack._eaten}
<div
  class:dna-snackbar={true}
  class="max-w-sm w-full flex flex-col bg-white elevation-6 rounded-lg overflow-hidden pointer-events-auto {className}"
  in:scale={snackSettings.inProps}
  out:fade={snackSettings.outProps}
  on:mouseenter={onMouseenter}
  on:mouseleave={onMouseleave}
  on:outroend={onOutroEnd}
  {...props}>
  <div
    class:dna-snackbar__inner={true}
    class="px-4 pt-4 flex items-start flex-gap-x-3 {innerClasses}">
    {#if hasIcon}
      <div
        class:dna-snackbar__type={true}
        class="flex-shrink-0 mt-0.5">
        <slot name="typeIcon">
          {#if snack.type == 'info'}
            <svg class="dna-snackbar__icon w-5 h-5 {svgColor}" x-description="Info" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          {:else if snack.type == 'success'}
            <svg class="dna-snackbar__icon w-5 h-5 {svgColor}" x-description="Success" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          {:else if snack.type == 'warning'}
            <svg class="dna-snackbar__icon w-5 h-5 {svgColor}" x-description="Warning" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
          {:else if snack.type == 'danger'}
            <svg class="dna-snackbar__icon w-5 h-5 {svgColor}" x-description="Error" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          {/if}
        </slot>
      </div>
    {/if}
    <div
      class:dna-snackbar__content={true}
      class="flex-1 flex-wrap pt-0 -mt-2 {contentClasses}">
      {#if snack.label}
        <div class="mt-2 font-medium text-base text-gray-900 leading-5 dna-snackbar__label">
          {snack.label}
        </div>
      {/if}
      {#if snack.message}
        <div class="mt-1 mb-2 text-sm text-gray-500 leading-5 dna-snackbar__message">
          {@html snack.message}
        </div>
      {/if}
      <ButtonsWrapper
        justify="start"
        wrap={false}
        gapSize="2"
        class="dna-snackbar__actions {actionsClasses}">
        {#if snack.actions}
          {#each snack.actions as action}
            <Button
              on:click={action.onClick}
              on:click={eatSnack}
              {...{...actionProps, ...action.props}}>
              {@html action.label}
            </Button>
          {/each}
        {/if}
      </ButtonsWrapper>
    </div>
    <div class="flex flex-shrink-0 dna-snackbar__controls -mt-1 -mr-1">
      <Button
        usage="icon"
        padding={false}
        class="border-none"
        size="sm"
        on:click={eatSnack}>
        <svg class="w-5 h-5 dna-snackbar__control__icon" x-description="Close" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </Button>
    </div>
  </div>
  {#if !persistent}
    <ProgressLinear
      class="dna-snackbar__progress"
      color="{snack.type || 'info'}"
      bind:timer={snack._timer}
      settings={{
        duration: snackSettings.timeout,
        transparentBg: true,
      }} />
  {/if}
</div>
{/if}

<style lang="postcss">:global(.dna-snackbar__actions .dna-button){line-height:1.125rem}</style>
