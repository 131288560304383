<script>
  import { createEventDispatcher } from 'svelte';
  import Button from '../button/Button.svelte';
  import Overlay from '../overlay/Overlay.svelte';
  import {
    cardOnFileUpdate,
    cardOnFileSetDefault,
    cardOnFileDelete,
  } from './stripe.api.js';
  import { logger } from '../../utils/logger.js';
  import _get from 'lodash-es/get.js';

  export let cardOnFile;
  export let cardActions = [];

  let className = '';
  export { className as class };

  cardOnFile.deleted = cardOnFile.deleted || false;
  const origCard = { ...cardOnFile };

  let pageUserUID = _get(Drupal.settings, 'lf_svelte.pageUser.uid', 0);

  const dispatch = createEventDispatcher();
  let errorMessage = '';

  let isEditing = false;
  let isDeleting = false;
  let isSaving = false;
  let isSettingDefault = false;
  let isDetaching = false;

  let months = [];
  let years = [];
  let currentYear = new Date().getFullYear();

  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  for (let i = currentYear; i <= currentYear + 10; i++) {
    years.push(i);
  }

  $: isWorking = isSaving || isSettingDefault || isDetaching;

  function onEdit() {
    isEditing = true;
  }

  function onDelete() {
    isDeleting = true;
  }

  function onSelect() {
    dispatch('cardSelected', { card: cardOnFile });
  }

  async function onSave() {
    isSaving = true;

    try {
      await validate();
      await saveCard();
      errorMessage = '';
      return Promise.resolve(true);
    } catch (err) {
      errorMessage = err.message;
      return false;
    } finally {
      isEditing = false;
      isSaving = false;
      isDeleting = false;
    }
  }

  async function onCancel() {
    cardOnFile = { ...origCard };
    errorMessage = '';
    isEditing = false;
    isSaving = false;
    isDeleting = false;
  }

  async function validate() {
    if (!+cardOnFile.name.length) {
      throw new Error('Enter the name of the card holder.');
    }
    if (new Date(cardOnFile.expYear, cardOnFile.expMonth) < Date.now()) {
      throw new Error('Card expiration must be set in the future.');
    }

    errorMessage = '';
    return true;
  }

  async function saveCard() {
    try {
      await validate();
      const savedCard = await cardOnFileUpdate(cardOnFile);
      logger.debug('saveCard', 'savedCard', savedCard);
      dispatch('cardUpdated', { card: savedCard });
      errorMessage = '';
      return Promise.resolve(savedCard);
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async function onSetDefault() {
    isSettingDefault = true;

    try {
      const res = await cardOnFileSetDefault(pageUserUID, cardOnFile.id);
      logger.debug('onSetDefault', 'res', res);
      dispatch('cardSetDefault', {
        defaultPaymentMethodId: res.default_payment_method,
      });
      errorMessage = '';
      return Promise.resolve(res);
    } catch (err) {
      return false;
    } finally {
      isSettingDefault = false;
    }
  }

  async function onConfirmDelete() {
    isDetaching = true;

    try {
      const res = await cardOnFileDelete(cardOnFile.id);
      logger.debug('onConfirmDelete', 'res', res);
      dispatch('cardDeleted', { cardID: cardOnFile.id });
      errorMessage = '';
      return Promise.resolve(cardOnFile);
    } catch (err) {
      return false;
    } finally {
      isDetaching = false;
    }
  }
</script>

{#if !cardOnFile.deleted}
  <div
    class:lf-stripe-cardonfile--expired={cardOnFile.expired}
    class:lf-stripe-cardonfile--default={cardOnFile.isDefault}
    class:lf-stripe-cardonfile--editing={isEditing}
    class:lf-stripe-cardonfile--deleting={isDeleting}
    class="
    lf-stripe-cardonfile
    lf-stripe-cardonfile--{cardOnFile.brand.toLowerCase()}
    {className}">
    <div class="lf-stripe-cardonfile__image">
      {#if cardOnFile.id !== '_new'}
        <img
          src="/sites/all/modules/custom/lf_cartcheckout/images/cc/{cardOnFile.brand.toLowerCase()}.svg"
          class="lf-cc"
          width="24px"
          height="auto"
          alt={cardOnFile.brand.toUpperCase()} />
      {/if}
    </div>
    <div class="lf-stripe-cardonfile__last4">{cardOnFile.last4}</div>
    <div class="lf-stripe-cardonfile__name">
      {#if !isDeleting}
        {#if isEditing}
          <input
            type="text"
            class="lf-stripe-cardonfile__edit-name"
            name="name"
            bind:value={cardOnFile.name}
            required
            placeholder="Card holder's name"
            size="24"
            maxlength="255" />
        {:else}
          {cardOnFile.name}
        {/if}
      {/if}
    </div>
    <div class="lf-stripe-cardonfile__exp">
      {#if !isDeleting}
        <div class="lf-stripe-cardonfile__exp-month">
          {#if isEditing}
            <div class="m-0 form-item form-type-select">
              <select
                class="text-sm form-select lf-stripe-cardonfile__edit-exp-month"
                bind:value={cardOnFile.expMonth}
                name="expMonth">
                {#each months as month}
                  <option value={month}
                    >{month.toString().padStart(2, '0')}</option>
                {/each}
              </select>
            </div>
          {:else}
            {!!+cardOnFile.expMonth
              ? cardOnFile.expMonth.toString().padStart(2, '0')
              : ''}
          {/if}
        </div>
        {#if !!+cardOnFile.expMonth && !!+cardOnFile.expYear}
          <div class="lf-stripe-cardonfile__exp-spacer">/</div>
        {/if}
        <div class="lf-stripe-cardonfile__exp-year">
          {#if isEditing}
            <div class="m-0 form-item form-type-select">
              <select
                class="text-sm form-select lf-stripe-cardonfile__edit-exp-year"
                bind:value={cardOnFile.expYear}
                name="expYear">
                {#each years as year}
                  <option value={year}
                    >{year.toString().slice(-2).padStart(2, '0')}</option>
                {/each}
              </select>
            </div>
          {:else}
            {!!+cardOnFile.expYear
              ? cardOnFile.expYear.toString().slice(-2)
              : ''}
          {/if}
        </div>
      {/if}
    </div>
    {#if !!+cardActions.length}
      <div class="lf-stripe-cardonfile__actions">
        {#if !isEditing && cardActions.includes('edit')}
          <div
            class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--edit">
            <Button on:click={onEdit} class="button button--link button--tiny"
              >Edit</Button>
          </div>
        {/if}
        {#if !isEditing && cardActions.includes('select')}
          <div
            class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--select">
            <Button on:click={onSelect} class="button button--link button--tiny"
              >Select</Button>
          </div>
        {/if}
        {#if !isEditing && cardActions.includes('setDefault')}
          <div
            class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--setdefault">
            {#if !cardOnFile.isDefault}
              <Button
                on:click={onSetDefault}
                class="button button--link button--tiny">Set Default</Button>
            {:else}
              <span>(default)</span>
            {/if}
          </div>
        {/if}
        {#if isEditing}
          {#if !isDeleting}
            <div
              class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--save">
              <Button
                on:click={onSave}
                class="button button--primary button--tiny">Save</Button>
            </div>
            {#if !cardOnFile.subscription}
              <div
                class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--delete">
                <Button
                  on:click={onDelete}
                  class="button button--link button--tiny button--danger"
                  >Delete</Button>
              </div>
            {/if}
          {/if}
          {#if isDeleting}
            <div
              class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--confirmdelete">
              <Button
                on:click={onConfirmDelete}
                class="button button--link button--tiny button--danger"
                >Confirm Delete</Button>
            </div>
          {/if}
          <div
            class="lf-stripe-cardonfile__action lf-stripe-cardonfile__action--cancel">
            <Button on:click={onCancel} class="button button--link button--tiny"
              >Cancel</Button>
          </div>
        {/if}
      </div>
    {/if}
    {#if !!+errorMessage.length}
      <div class="lf-stripe-cardonfile-error">
        {errorMessage}
      </div>
    {/if}
    <Overlay bind:isActive={isWorking} showSpinner />
  </div>
{/if}

<style lang="postcss">.lf-stripe-cardonfile{align-items:center;display:flex;flex-wrap:wrap;font-size:.875rem;justify-content:space-between;line-height:32px;min-width:330px;padding:4px 12px}@media (min-width:768px){.lf-stripe-cardonfile{flex-wrap:nowrap}}.lf-stripe-cardonfile--editing{flex-wrap:wrap;min-width:unset}.lf-stripe-cardonfile__image{flex:0 0 24px;margin-right:8px;opacity:1}.lf-stripe-cardonfile__last4{flex:0 0 36px}.lf-stripe-cardonfile__name{flex:0 0 160px;margin:0 1em;white-space:nowrap}@media (max-width:767px){.lf-stripe-cardonfile--editing .lf-stripe-cardonfile__name{flex:1 0 auto;margin-bottom:.5em}}.lf-stripe-cardonfile__edit-name{background-color:#efefef;line-height:20px}.lf-stripe-cardonfile__exp{align-items:center;display:flex;justify-content:flex-end;margin-left:auto}@media (min-width:400px) and (max-width:767px){.lf-stripe-cardonfile--editing .lf-stripe-cardonfile__exp{margin-left:32px}}.lf-stripe-cardonfile__edit-exp-month{background-color:#efefef}.lf-stripe-cardonfile__exp-spacer{margin:0 4px}.lf-stripe-cardonfile__edit-exp-year{background-color:#efefef}.lf-stripe-cardonfile__actions{align-items:center;display:flex;flex:1 1 auto;flex-wrap:wrap;justify-content:flex-start;margin-left:24px}.lf-stripe-cardonfile--editing .lf-stripe-cardonfile__actions{justify-content:flex-end;margin-left:auto}.lf-stripe-cardonfile--deleting .lf-stripe-cardonfile__actions{justify-content:flex-start}.lf-stripe-cardonfile__action--setdefault span{color:#666;font-size:.875em;font-weight:700;padding:0 9px}.lf-stripe-cardonfile__action--save{margin-right:.5em}.lf-stripe-cardonfile+.lf-stripe-cardonfile{border-top:1px solid #eee}.lf-stripe-cardonfile-error{color:#b94a48;margin-left:32px}</style>
