<script context="module">
  import { crossfade, scale } from 'svelte/transition';

  const [send, receive] = crossfade({
    duration: 200,
    fallback: scale,
  });

  let dialogTracker = {};

  export { send, receive };
</script>

<script>
  // @todo keyboard close.
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import Portal from '../portal/Portal.svelte';
  import Overlay from '../overlay/Overlay.svelte';
  import Button from '../buttons/Button.svelte';

  let className = '';
  export { className as class };

  export let id;
  export let isOpen = false;
  export let isModal = false;
  // @todo not using native dialog at the moment.
  // export let native = (typeof HTMLDialogElement === 'function');
  export let includeCloseButton = false;

  // @todo size / style props?
  // export let fullscreen = false;

  $: {
    if (id) {
      dialogTracker[id] = isOpen;
    }

    document.body.style.overflow = Object.values(dialogTracker).includes(true)
      ? 'hidden'
      : null;
  }
</script>

<Portal id="dialogs" permanent>
  {#if isOpen}
    <div
      class:dna-dialog="{true}"
      class="fixed left-0 top-0 w-full h-full pointer-events-none pointer-events-auto flex justify-center items-center z-10 {className}"
      {...excludeProps($$props, [
        'use',
        'class',
        'isOpen',
        'isModal',
        'native',
        'includeCloseButton',
        'fullscreen',
        'overlay$',
        'close$',
        'content$',
      ])}
    >
      <Overlay
        bind:show="{isOpen}"
        on:click="{() => (isOpen = isModal ? isOpen : false)}"
        {...prefixFilter($$props, 'overlay$')}
      />
      <div
        class:dna-dialog__content="{true}"
        class:relative="{true}"
        class:flex="{true}"
        class:items-center="{true}"
        class:justify-around="{true}"
        class:overflow-hidden="{true}"
        class:h-full="{true}"
        class:pointer-events-none="{true}"
        in:receive="{{ key: id }}"
        out:send="{{ key: id }}"
        {...prefixFilter($$props, 'content$')}
      >
        <slot />
        {#if includeCloseButton || $$slots.close}
          <div class="absolute top-0 right-0 dna-dialog__close">
            <slot name="close">
              <Button
                usage="icon"
                size="xs"
                on:click="{() => (isOpen = false)}"
                {...prefixFilter($$props, 'close$')}
              >
                <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
                </svg>
              </Button>
            </slot>
          </div>
        {/if}
      </div>
    </div>
  {/if}
</Portal>

<style lang="postcss">.dna-dialog__content>:global(*){pointer-events:auto}</style>
