<script>
  import { excludeProps } from 'svelte-utilities';

  export let id;
  export let text = '';

  let className = '';
  export {className as class};
</script>

{#if !!+text.length}
<p
  {id}
  class="
    dna-form-field-help-text
    {className}"
  {...excludeProps($$props, ['class'])}
  >
  {@html text}
</p>
{/if}

<style>.dna-form-field-help-text{--text-opacity:1;color:#6b7280;color:rgba(107,114,128,var(--text-opacity));font-size:.875rem;margin-top:.5rem}:global(.dna-form-field--checkbox) .dna-form-field-help-text,:global(.dna-form-field--radio) .dna-form-field-help-text{margin-left:1.5rem;margin-top:0}:global(.dna-form-field--checkboxes) .dna-form-field-help-text,:global(.dna-form-field--radios) .dna-form-field-help-text{margin-top:0}</style>
