<svelte:options immutable={true} />

<script>
  import { excludeProps } from 'svelte-utilities';
  import { Dialog } from 'svelte-tailwind-components';
  import { toThreadModel } from '../../models';
  import TipTapMessage from './TipTapMessage.svelte';

  export let dialogController;
  export let isOpen = false;

  let thread = undefined;
  let replyToMessageId = undefined;
  let contacts = undefined;
  let subject = '';
  let props = {};
  // The composeNewThread prop indicates composing a new message that is not a
  // reply to a thread/message.
  let composeNewThread = false;

  let focusEditor;

  const canOpen = () =>
    composeNewThread ||
    replyToMessageId ||
    thread?.canReplyAll ||
    (contacts || []).length > 0;

  const openDialog = () => {
    isOpen = canOpen();
    focusEditor && focusEditor();
  };
  const closeDialog = () => resetDialog();
  const resetDialog = () => {
    isOpen = false;
    replyToMessageId = undefined;
    subject = '';
    props = {};
  };

  dialogController.open.watch((res) => {
    if (!isOpen) {
      let { message, props, compose, thread } = res;
      thread = thread ? toThreadModel(thread) : thread;
      contacts = res?.contacts;
      replyToMessageId =
        thread?.canReplyAll && !message
          ? undefined
          : message?.id ?? thread?.lastMessageId;
      subject = thread?.subject;
      props = props || {};
      composeNewThread = compose;

      openDialog();
    }
  });
  dialogController.close.watch(() => closeDialog());
  dialogController.reset.watch(() => resetDialog());
</script>

<Dialog id="lf-tiptap-message-dialog" bind:isOpen isModal>
  <div class:lf-tiptap-message-wrapper={true}>
    <TipTapMessage
      bind:focusEditor
      autofocus
      {composeNewThread}
      {thread}
      {replyToMessageId}
      {contacts}
      {subject}
      on:messageDisarded={resetDialog}
      on:messageSent={resetDialog}
      {...props}
      {...excludeProps($$restProps, [])} />
  </div>
</Dialog>

<style lang="postcss" global>:global(#lf-tiptap-message-dialog) :global(.lf-tiptap-message-wrapper){height:100%;width:100%}@media (min-width:768px){:global(#lf-tiptap-message-dialog) :global(.lf-tiptap-message-wrapper){height:auto}}@media (min-width:1024px){:global(#lf-tiptap-message-dialog) :global(.lf-tiptap-message-wrapper){max-width:768px}}:global(#lf-tiptap-message-dialog) :global(.dna-tiptap-wrapper){height:100%}@media (min-width:768px){:global(#lf-tiptap-message-dialog) :global(.dna-tiptap-wrapper){height:auto}}:global(#lf-tiptap-message-dialog) :global(.lf-tiptap-message){height:100%;padding-bottom:1rem;padding-top:1rem}@media (min-width:768px){:global(#lf-tiptap-message-dialog) :global(.lf-tiptap-message){height:auto;max-height:calc(100vh - 8rem);min-height:33vh}}@media (min-width:1024px){:global(#lf-tiptap-message-dialog) :global(.lf-tiptap-message){max-width:768px}}:global(#lf-tiptap-message-dialog) :global(.dna-tiptap__content-wrapper) :global(.ProseMirror){min-height:8rem}</style>
