<script>
  /*global paypal*/
  import { onMount, createEventDispatcher } from 'svelte';

  import { validateOrder } from './commerce.api.js';
  import { createOrderRequest, confirmOrderCapture } from './paypal.api.js';

  import { logger } from '../../utils/logger.js';

  export let order = {};
  let className = '';
  export { className as class };

  export let isSubmitting = false;

  const dispatch = createEventDispatcher();

  let isPaypalSubmitting = false;
  let hasSubscription = order.meta.hasSubscription || false;

  async function onCreateOrder(data, actions) {
    isSubmitting = true;
    isPaypalSubmitting = true;

    try {
      await validateOrder(order.id);
      return createOrder(data, actions);
    } catch (err) {
      window.snacksQueue.add({
        label: 'An error occurred',
        message: err,
        type: 'danger',
      });
      logger.debug('onValidateOrder', 'err', err);
      return Promise.reject(err);
    } finally {
      isSubmitting = false;
      isPaypalSubmitting = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function createOrder(data, actions) {
    try {
      const res = await createOrderRequest(order.id);
      return res.result.id;
    } catch (err) {
      logger.debug('createOrder', 'err', err);
      return Promise.reject(false);
    }
  }

  async function onApprove(data, actions) {
    isSubmitting = true;
    isPaypalSubmitting = true;

    try {
      const details = await actions.order.capture();
      const intent = details.intent || '';
      const status = details.status || '';

      if (intent != 'CAPTURE' && status != 'COMPLETED') {
        throw new Error('Failed to capture order.');
      }

      try {
        await confirmOrderCapture(details.id);
        return status;
      } catch (err) {
        // Capture / log the error. The confirmation may just be delayed or
        // handled server-side.
        logger.debug('confirmOrderCapture', 'err', err);
        return err;
      } finally {
        // @todo do we want to dispatch orderComplete even if
        // confirmOrderCapture fails?
        dispatch('orderComplete');
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      isSubmitting = false;
      isPaypalSubmitting = false;
    }
  }

  function handleError(err) {
    logger.debug('handleError', 'err', err);
    isSubmitting = false;
    isPaypalSubmitting = false;
    // @todo?
  }

  function initialize() {
    if (!hasSubscription) {
      paypal
        .Buttons({
          style: { height: 30 },
          createOrder: onCreateOrder,
          onApprove: onApprove,
          onError: handleError,
        })
        .render('#lf-paypal-button-container');
    }
  }

  onMount(() => {
    initialize();
  });
</script>

{#if !hasSubscription}
  <div
    id="lf-paypal-payment"
    class:lf-paypal-payment--is-submitting={isPaypalSubmitting}
    class="lf-paypal-payment {className}">
    <div id="lf-paypal-button-container" class="lf-paypal-button-container">
      <div class="lf-paypal-payment__paywith">or pay with</div>
    </div>
  </div>
{/if}

<style lang="postcss">.lf-paypal-payment--is-submitting{pointer-events:none}.lf-paypal-payment__paywith{color:var(--lfl-text-light);font-size:.875rem;line-height:1;white-space:nowrap}.lf-paypal-button-container{align-items:center;-moz-column-gap:.5rem;column-gap:.5rem;display:flex;justify-content:flex-end;margin:0 0 0 auto;width:200px}</style>
