<script>
  import { formatCurrency } from 'svelte-utilities';
  import _get from 'lodash-es/get.js';

  export let rowData = {};
  export let column = {};
  export let meta = {};

  $: data = _get(rowData, column.data, '');
  let currencyCode = rowData.currency_code || 'USD';

  $: fee = formatCurrency(data, currencyCode);
</script>

<div class="lf-fee">
  <div class="lf-fee__fee">{fee}</div>
  <div class="lf-fee__percentage">({rowData.fee_percentage}%)</div>
</div>

<style lang="postcss">.lf-fee__fee,.lf-fee__percentage{display:inline-block}.lf-fee__percentage{width:50px}</style>
