<script>
  import { formatCurrency } from 'svelte-utilities';

  export let amount;
  let currencyCode = 'USD';
  export { currencyCode as currency_code };
  export let components = [];
  // Available componentDisplay options:
  // - total: Total only.
  // - subtotal: Base price and Total. Total only if equal.
  // - all: Display all components.
  export let componentDisplay = 'total';

  const basePrice = components.length && components[0].name == 'base_price' ?
    components[0].amount : null;
</script>

<div class="lf-price">
  {#if (componentDisplay !== 'total' && basePrice !== null && basePrice !==
  amount)}
    <div class="lf-price__base">{formatCurrency(basePrice, currencyCode)}</div>
  {/if}
  <div class="lf-price__total">{formatCurrency(amount, currencyCode)}</div>
</div>

<style lang="postcss">.lf-price{color:var(--lfl-text);display:flex;flex-direction:column;line-height:1;row-gap:.25rem}.lf-price__base{color:var(--lfl-text-light);font-size:.75rem;text-decoration:line-through}.lf-price__total{font-weight:600}</style>
