<script>
  import { onMount } from 'svelte';

  export let id;
  export let value = '_none';
  export let filter = {};
  export let meta = {};

  export let label = '';
  // @todo
  // export let multiple = false;

  let className = '';
  export { className as class };

  let isInitialized = false;
  let unselectedOption = {
    value: '_none',
    label: label || `Select ${filter.label}...`,
  };

  let selectOptions = filter.options.map(option => {
    let newOption = {};

    if (typeof option === 'object') {
      newOption = {...option};
    }
    else {
      newOption = {
        value: option,
        label: option
      };
    }

    return newOption;
  });
  selectOptions = [unselectedOption, ...selectOptions];

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

{#if isInitialized}
<div
  class="
    {id}
    form-item
    form-type-select
    {className}
    ">
  <label for="{id}">
    {label}
  </label>
  <select
    bind:value
    on:change
    {id}
    class="form-select">
    {#each selectOptions as selectOption, i (selectOption.value)}
    <option
      selected={selectOption.value === value}
      value={selectOption.value}>{selectOption.label}</option>
    {/each}
  </select>
</div>
{/if}

<style lang="postcss">.form-type-select{align-items:center;display:flex;justify-content:flex-start;margin:0}.form-type-select label{margin-bottom:0;margin-right:.5em}</style>
