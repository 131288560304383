<script>
  import _get from 'lodash-es/get.js';
  import {
    Filterer,
    ItemsManager,
    Pager,
    Searcher,
    Sorter,
    Tracker,
  } from 'svelte-utilities';
  import { DataTablesV2 as DataTables } from 'svelte-tailwind-components';
  import { classesAPI as api } from '../../../../components/groupClasses/groupClasses.api.js';
  import { apiParams, apiParamsExport, config, columnsDef } from './config.js';
  import { prepareExport } from '../../../../components/exports/exports.utils.js';

  const itemsManager = new ItemsManager(
    {},
    {
      searcher: new Searcher({
        options: {
          threshold: 0.2,
          keys: [
            'title',
            'instructor.fullname',
            'category',
            'programs.title',
            'group_lesson.title',
            'product_displays.title',
            'product_displays.buckets',
          ],
        },
      }),
      filterer: new Filterer(),
      sorter: new Sorter(
        { postSort: { fields: ['datetime.start'], order: ['asc'] } },
        { fields: ['datetime.start'], order: ['asc'] }
      ),
      pager: new Pager({ default: { pageSize: 10 } }),
      tracker: new Tracker(),
    }
  );

  let working = false;
  let datatableState;

  async function onPrepareExport() {
    working = true;
    await prepareExport(await api.getClasses(apiParamsExport));
    working = false;
  }

  // Initial data load for DataTable.
  let promise = api.getClasses(apiParams).then((res) => {
    let firstUpcomingIndex = res.data.findIndex(
      (c) => _get(c, 'datetime.start', 0) >= new Date().toISOString()
    );

    itemsManager.add(res.data).pager().gotoIndex(firstUpcomingIndex);
    return itemsManager.apply();
  });
</script>

<DataTables.DataTable
  id="lf-group-classes-report"
  title="Group Classes"
  {config}
  {columnsDef}
  {itemsManager}
  {promise}
  state={datatableState}
  parentWorking={working}
  class="sm:mt-4"
  on:export={onPrepareExport} />

<style lang="postcss">:global(body.page-admin-reports-lessonface .lf-app__body){margin:0}</style>
