<script>
  import { onMount, createEventDispatcher } from 'svelte';

  import { updatePaymentMethod } from './instructorPayments.api.js';
  import _get from 'lodash-es/get.js'

  // @todo Dialog
  import Checkbox from '../checkbox/Checkbox.svelte';
  import Button from '../button/Button.svelte';
  import Overlay from '../overlay/Overlay.svelte';

  export let action = {};
  export let rowData = {};
  export let meta = {};

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  let isInitialized = false;
  let isUpdating = false;

  let paymentMethod = {
    _id: 0
  };

  $: if (rowData._id !== paymentMethod._id) {
    paymentMethod = {
      _id: rowData._id,
      uid: rowData.instructor.id,
      payment_method: rowData.payment_method,
      remote_account_id: rowData.remote_account_id,
      status: rowData.status == 'active',
      data: rowData.data,
    };

    if (rowData.payment_method == 'paypal') {
      paymentMethod.data.paypal.locked = paymentMethod.data.paypal.locked || false;
    }
  }

  // @todo figure out why applying changes to attributes directly updates the
  // table (rowData) but simply setting the rowData object does not trigger the
  // update.
  function applyUpdates(paymentMethod) {
    Object.keys(paymentMethod).forEach(key => {
      if (key !== '_id') {
        rowData[key] = paymentMethod[key];
      }
    });
  }

  function dispatchActionStatus() {
    dispatch('actionStatusChange', {
      id: 'update',
      dialog: true,
      isOpen: isUpdating,
    })
  }

  function onClick() {
    isUpdating = !isUpdating;
    dispatchActionStatus();
  }

  async function onSave() {
    try {
      const res = await updatePaymentMethod({ paymentMethod });
      applyUpdates(res.paymentMethod);
    }
    catch (err) {
      // @todo error.
    }
    finally {
      isUpdating = false;
      dispatchActionStatus();
    }
  }

  function onCancel() {
    isUpdating = false;
    dispatchActionStatus();
  }

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

{#if isInitialized}
  <Button
    on:click={onClick}
    class="{action.params.class}">
    {action.label}
  </Button>

  {#if isUpdating}
    <div
      id="lf-instructor-payment-method-form-wrapper"
      class="
        lf-instructor-payment-method-form-wrapper
        {className}
        ">
      <Overlay
        fullscreen
        isActive={isUpdating}
        />
      <form
        class="lf-instructor-payment-method-form"
        on:submit|preventDefault
        >
        <div class="lf-instructor-payment-method-form__header">
          <h3 class="lf-instructor-payment-method-form__title">Update Payment Method</h3>
        </div>
        <div class="lf-instructor-payment-method-form__content">
          <div class="lf-instructor-payment-method-form__meta">
            <div><label>Payment Method:</label> {rowData.payment_method}</div>
            <div><label>Instructor:</label> {rowData.instructor.fullname || rowData.instructor.id || ''}</div>
          </div>

          {#if ['stripe', 'paypal'].includes(rowData.payment_method)}
            <div class="form-item form-type-textfield">
              <label for="remote_account_id">Remote Account ID</label>
              <input
                bind:value={paymentMethod.remote_account_id}
                class="text-full form-text"
                type="text"
                id="remote_account_id"
                name="remote_account_id"
                size="60"
                maxlength="255">
              {#if rowData.payment_method == 'stripe'}
                <div class="description">Enter the Instructor's Stripe Connect account ID (e.g. acct__***)</div>
              {:else if rowData.payment_method == 'paypal'}
                <div class="description">Enter the Instructor's paypal email address.</div>
              {/if}
            </div>

            {#if rowData.payment_method == 'paypal'}
              <div class="form-item form-type-select">
                <label for="country">
                  Country
                </label>
                <select
                  bind:value={paymentMethod.data.paypal.country}
                  id="country"
                  class="form-select">
                  <option value="US">US</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <Checkbox
                id="locked"
                name="locked"
                label="Lock Country"
                bind:checked={paymentMethod.data.paypal.locked}
                />
            {/if}

          {:else if rowData.payment_method == 'mailed_check'}
            <div class="form-item form-type-textarea">
              <label for="address">
                Address
                <span class="form-required" title="This field is required.">*</span>
              </label>
              <div class="form-textarea-wrapper resizable resizable-textarea">
                <textarea
                  bind:value={paymentMethod.data.address.formatted}
                  class="text-full form-textarea"
                  id="address"
                  name="address"
                  required
                  cols="60"
                  rows="3"></textarea>
                <div class="grippie"></div>
              </div>
            </div>
          {/if}

          <Checkbox
            id="status"
            name="status"
            label="Active"
            bind:checked={paymentMethod.status}
            />

        </div>

        <div class="lf-instructor-payment-method-form__footer">
          <Button
            on:click={onSave}
            class="button button--primary button--small">
            Save
          </Button>
          <Button
            on:click={onCancel}
            class="button button--link button--small">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  {/if}
{/if}

<style lang="postcss">.lf-instructor-payment-method-form-wrapper{align-items:center;display:flex;height:100vh;justify-content:center;left:0;position:fixed;top:0;width:100vw}.lf-instructor-payment-method-form{background-color:#fff;padding:1em 1.5em;position:relative}.lf-instructor-payment-method-form__header{padding:.5em 0}.lf-instructor-payment-method-form__title{margin:0}.lf-instructor-payment-method-form__content{padding:.5em 0}.lf-instructor-payment-method-form__meta{background-color:#eee;border:1px dashed #ccc;margin-bottom:1em;padding:4px 8px}.lf-instructor-payment-method-form__meta label{color:#666;display:inline-block;font-weight:600;margin-bottom:0;min-width:80px}.lf-instructor-payment-method-form__footer{padding:.5em 0}</style>
