<script>
  import { onMount } from 'svelte';

  // @note the main.kit build must be run to generate the /dist folder where
  // this is imported from.
  import CatalogSimpleSearch from '../../../../node_modules/@lessonface/main.kit/dist/components/catalog/search/CatalogSimpleSearch.svelte';

  let wrapperEl;
  let inputEl;

  const onSearch = (evt) => {
    try {
      const searchText = evt.detail.searchText ?? '';

      if (window.posthog && searchText.length > 0) {
        window.posthog.capture('Products Searched', {
          query: searchText,
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  // Using the onFocus to help mobile devices, particularly iOS / Safari handle
  // displaying results with the virtual keyboard.
  const onFocus = () => {
    setTimeout(() => {
      window.scrollTo({
        top: inputEl.getBoundingClientRect().top + window.pageYOffset - 24,
        behavior: 'smooth',
      });
      // The event listener is removed and then added with onClickOutside to
      // avoid some janky movement on mobile devices.
      inputEl.removeEventListener('focus', onFocus);
    }, 500);
  };

  const onClickOutside = () => {
    const inputEl = wrapperEl?.querySelector('.autocomplete-input');

    if (inputEl) {
      inputEl.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
        })
      );
      inputEl.addEventListener('focus', onFocus);
    }
  };

  onMount(() => {
    wrapperEl = document.querySelector('.lf-catalog-search-wrapper');

    if (window.innerWidth < 768) {
      setTimeout(() => {
        inputEl = wrapperEl.querySelector('.autocomplete-input');
        inputEl?.addEventListener('focus', onFocus);
      }, 250);
    }
  });
</script>

<CatalogSimpleSearch on:clickOutside="{onClickOutside}" on:search="{onSearch}" />
<div class="lf-catalog-search__help-text">e.g. guitar, violin, watercolor, Italian</div>

<style lang="postcss">.lf-catalog-search__help-text{--text-opacity:1;color:#fff;color:rgba(255,255,255,var(--text-opacity));display:none;text-align:right}@media (min-width:768px){.lf-catalog-search__help-text{padding-right:8rem}}:global(body.front) .lf-catalog-search__help-text{display:block}:global(body.front #lf-hero .lf-catalog-simple-search__autocomplete){height:3rem}:global(body.front #lf-hero .autocomplete-list){--lf-catalog-simple-search-item-count:6}@media (min-width:768px){:global(body.front #lf-hero .autocomplete-list){--lf-catalog-simple-search-item-count:10}}:global(body.front #lf-hero .autocomplete-input){height:3rem;padding-left:2.75rem}@media (min-width:768px){:global(body.front #lf-hero .autocomplete-input){padding-left:1.75rem;padding-right:8rem}}:global(body.front #lf-hero .lf-catalog-simple-search__magnify){height:3rem;width:3rem}@media (min-width:768px){:global(body.front #lf-hero .lf-catalog-simple-search__magnify){grid-gap:.25rem;--bg-opacity:1;--text-opacity:1;--border-opacity:1;background-color:#3f9d4b;background-color:rgba(63,157,75,var(--bg-opacity));border-bottom-right-radius:.75rem;border-color:#3f9d4b;border-color:rgba(63,157,75,var(--border-opacity));border-top-right-radius:.75rem;color:#fff;color:rgba(255,255,255,var(--text-opacity));gap:.25rem;left:auto;padding-left:1.5rem;padding-right:1.5rem;right:-1px;width:auto}}@media (min-width:768px){:global(body.front #lf-hero .lf-catalog-simple-search__magnify span){display:inline}}</style>
