<script>
  import { excludeProps } from 'svelte-utilities';

  export let messages = [];

  let className = '';
  export { className as class };

  $: errorMessages = Array.isArray(messages) ? messages : [messages];
</script>

{#if errorMessages.length}
  <div
    class="
    dna-form-field-errors
    {className}"
    {...excludeProps($$props, ['class'])}
  >
    {#each errorMessages as errorMessage}
      {#if errorMessage.length}
        <p class="dna-form-field-error">
          {@html errorMessage}
        </p>
      {/if}
    {/each}
  </div>
{/if}

<style>.dna-form-field-error{--text-opacity:1;color:#a74341;color:rgba(167,67,65,var(--text-opacity));font-size:.875rem;margin-top:.5rem}:global(.dna-form-field--checkbox) .dna-form-field-error{margin-left:1.5rem;margin-top:0}:global(.dna-form-field--checkboxes) .dna-form-field-error{margin-top:0}</style>
