<svelte:options immutable="{true}" />

<script>
  // @todo move to using https://github.com/vime-js/vime.
  import { onMount, onDestroy } from 'svelte';
  import { excludeProps, prefixFilter } from 'svelte-utilities';

  let className = '';
  export { className as class };
  export let file;
  export let audioPlayer = undefined;
  export let audioConfig = {};

  const defaultConfig = {
    autoplay: false,
    controls: true,
    loop: false,
    muted: false,
    preload: 'auto',
    responsive: true,
  };

  onMount(() => {
    try {
      audioPlayer = file?.url
        ? videojs('lf-audio--' + file.id, {
            ...defaultConfig,
            ...audioConfig,
          })
        : undefined;
    } catch (err) {
      // Fail quietly... this tends to throw an error when the user is changing
      // folders.
    }
  });

  onDestroy(() => {
    try {
      audioPlayer && audioPlayer.dispose();
    } catch (err) {
      // Fail quietly.
    }
  });
</script>

<div class:lf-audio-wrapper="{true}" class="{className}" {...excludeProps($$restProps, ['audio$'])}>
  {#if file?.url}
    <audio
      id="lf-audio--{file.id}"
      controls
      controlslist="nodownload"
      preload="auto"
      class:lf-audio="{true}"
      class:video-js="{true}"
      class:vjs-fluid="{true}"
      class:vjs-default-skin="{true}"
      {...prefixFilter($$restProps, [])}>
      <source src="{file?.url}" type="{file?.mime ?? file?.filemime}" />
    </audio>
  {/if}
</div>

<style lang="postcss">.lf-audio-wrapper{max-width:min(640px,100vw);position:relative;text-align:center;width:100vw}@media (min-width:768px){.lf-audio-wrapper{max-width:min(640px,90vh)}}.lf-audio-wrapper :global(div.lf-audio){display:inline-block}</style>
