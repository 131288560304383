<script>
  import { prefixFilter } from 'svelte-utilities';

  import ElementErrorMessage from '../form/ElementErrorMessage.svelte';
  // @todo add a 'reveal' option for password types to switch between text and
  // password.
  export let name;

  export let type = 'text';
  export let value = '';
  export let label = '';
  export let labelDisplay = '';
  export let required = false;
  export let disabled = false;
  export let placeholder = '';
  export let helpText = '';
  export let size = 60;
  export let maxlength = 255;
  let className = '';
  export { className as class };

  export let errorMessage = '';
  export let makeSpace = true;

  let el = undefined;
  export { el as inputEl };

  function onChange(evt) {
    value = evt.target.value;
  }
</script>

<div
  class="
    form-item
    form-type-textfield
    form-item-{name}
    {className}">
  <label
    class="
      {labelDisplay == 'invisible' ? 'element-invisible' : ''}
    "
    for="edit-{name}">
    {label}
    {#if required}
      <span class="form-required" title="This field is required.">*</span>
    {/if}
  </label>
  <input
    bind:this={el}
    class:form-text={true}
    class:required
    on:change={onChange}
    on:change
    on:input
    id="edit-{name}"
    {type}
    {name}
    {value}
    {disabled}
    {size}
    {maxlength}
    {placeholder}
    {...prefixFilter($$props, 'input$')} />
  <ElementErrorMessage {errorMessage} {makeSpace} />
  <slot name="errorMessages" />
  {#if helpText?.length > 0}
    <div class="description">{@html helpText}</div>
  {/if}
</div>

<style lang="postcss">.form-item{margin-bottom:.5em;margin-top:.25em}.form-text{margin-bottom:.2em;padding:4px 8px;width:100%}.lf-form-item--invalid .form-text{border-color:#b94a48}</style>
