<svelte:options immutable={true} />

<script>
  import { excludeProps, formatDateTime } from 'svelte-utilities';

  import BackButton from '../app/BackButton.svelte';
  import EmbedFile from './EmbedFile.svelte';
  import FileTypeImage from './FileTypeImage.svelte';
  import PreviewFileButton from './PreviewFileButton.svelte';
  import DebugMenu from '../app/DebugMenu.svelte';
  import DebugButton from '../button/DebugButton.svelte';

  let className = '';
  export { className as class };
  export let file;
  export let app;

  const maxDimensionsMap = {
    video: { maxWidth: Math.min(600, window.innerWidth) },
  };
  const formatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
</script>

<article
  class:hidden={!file?.id}
  class:lf-file-detail={true}
  class="lg:h-full {className}"
  {...excludeProps($$restProps, [])}>
  <div
    class:lf-file-detail__header={true}
    class="bg-secondary-500 elevation-4 z-10">
    <div
      class:lf-file-detail__header-content={true}
      class="relative px-4 max-w-screen-lg 2xl:ml-0 2xl:ml-8 pt-2 pb-4 sm:px-6 md:pb-6 md:pt-0 lg:px-8 lg:pt-4 flex flex-col justify-end flex-shrink-0 lg:h-32">
      <div class="-ml-2 lg:hidden">
        <BackButton on:viewList flat usage="button" color="secondary">
          Media
        </BackButton>
      </div>
      {#if file?.id}
        <h1
          class="m-0 text-xl leading-6 md:text-2xl md:leading-7 text-white truncate">
          {file?.name}
        </h1>
        <div
          class="flex items-center space-x-4 text-sm text-secondary-200 leading-6 whitespace-no-wrap">
          <span>{file?.friendlySize}</span>
          <span>/</span>
          <span>{formatDateTime(file?.createdAt, formatOptions)}</span>
        </div>
        <FileTypeImage
          {file}
          class="absolute right-6 bottom-4 md:right-10 md:bottom-6 lg:right-8 xl:right-12 shadow-inner w-16 h-16 lg:w-20 lg:h-20 text-secondary-200"
          icon$class="w-12 h-12 lg:w-16 lg:h-16 opacity-25"
          icon$color="currentColor" />
      {/if}
    </div>
    <slot name="working" />
  </div>

  <div
    class:lf-file-detail__content-wrapper={true}
    class="flex flex-col flex-1 max-w-screen-xl">
    {#key file}
      <EmbedFile {file} {...maxDimensionsMap[file?.type]} />
    {/key}
  </div>
  <div
    class:lf-file-detail__controls-wrapper={true}
    class="min-h-0 min-w-0 flex-shrink-0 bg-white elevation-4 relative sticky bottom-0">
    <div
      class="relative max-w-screen-lg h-12 flex items-center px-4 md:px-8 lg:pl-6 2xl:pr-10">
      <PreviewFileButton {file} downloadOnly>Download</PreviewFileButton>
      <span class="flex-1" />
      <DebugMenu {app}>
        <svelte:fragment slot="items">
          <DebugButton
            usage="link"
            eventKey="FILE"
            on:click={() => console.log(file)}>
            Debug File
          </DebugButton>
        </svelte:fragment>
      </DebugMenu>
    </div>
  </div>
</article>

<style lang="postcss">.lf-file-detail{min-height:calc(100vh - var(--lf-header-height, 71px));overscroll-behavior-y:none}</style>
