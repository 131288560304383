<script>
  // @todo change to menu with > X actions.
  import Action from './Action.svelte';
  import Dropper from '../../dropper/Dropper.svelte';

  export let rowData = {};
  export let column = {};
  export let meta = {};

  let className = '';
  export { className as class };

  let actionsStatus = column.componentOptions.actions.map(action => {
    let status = {
      id: action.id,
      isOpen: false,
    }
    return status;
  });

  function updateActionStatus(actionID, isOpen) {
    actionsStatus = actionsStatus.map(action => {
      if (action.id == actionID) {
        action.isOpen = isOpen;
      }
      return action;
    });
  }

  $: actions = column.componentOptions.actions.map(action => {
    let newAction = {...action};
    let params = newAction.params ? {...newAction.params} : {};

    params.class = params.class || '';
    params.class += ' button button--tiny';
    newAction.params = {...params};
    newAction.component = newAction.component || Action;
    newAction.active = !Object.prototype.hasOwnProperty.call(action, 'conditions') || action.conditions(rowData);
    return newAction;
  });

  $: hasOpenAction = actionsStatus.filter(action => action.isOpen).length > 0;

  function onActionStatusChange(evt) {
    updateActionStatus(evt.detail.id, evt.detail.isOpen);
  }
</script>

{#if actions.length <= 2}
<div
  class="
    lf-datatable__row-actions
    {className}
    ">
  {#each actions as action}
    {#if action.active}
      <div class="lf-datatable__row-action">
        <svelte:component
          this={action.component}
          {action}
          bind:rowData
          {meta}/>
      </div>
    {/if}
  {/each}
</div>
{:else if actions.length > 2}
  <Dropper
    class="lf-datatable__row-actions-dropper {className}"
    openFrom="top right"
    trigger$class="button--text"
    bind:isCollapsed={hasOpenAction}
    >
    <span slot="triggerContent">
      <i class="icon-cog"></i>
    </span>
    <ul class="lf-datatable__row-actions-dropper__content">
      {#each actions as action (action.id)}
        {#if action.active}
           <li>
            <svelte:component
              this={action.component}
              bind:action
              bind:rowData
              {meta}
              on:actionStatusChange={onActionStatusChange}
              />
           </li>
        {/if}
      {/each}
    </ul>
  </Dropper>
{/if}

<style lang="postcss">.lf-datatable__row-actions{align-items:flex-start;display:flex;justify-content:flex-start}.lf-datatable__row-action+.lf-datatable__row_action{margin-left:.5em}:global(.lf-datatable__row-actions-dropper .lf-svelte-dropper__trigger button){--btn-text-color:#666;font-size:1rem;opacity:.7;padding:4px 12px}:global(.lf-datatable__row-actions-dropper .lf-svelte-dropper__trigger button):hover{background-color:unset;color:#3f9d4b;opacity:1}.lf-datatable__row-actions-dropper__content{list-style:none;margin:0;padding:4px 0}:global(.lf-datatable__row-actions-dropper__content>li>button){border-radius:0;display:block;line-height:2em;text-align:left;width:100%}:global(.lf-datatable__row-actions-dropper__content>li>button):hover{background-color:#3f9d4b;color:#fff;text-decoration:none}:global(.lf-svelte-dropper__panel--collapsed .lf-datatable__row-actions-dropper__content>li>button){display:none}</style>
