<svelte:options immutable={true} />

<script>
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import {
    AudioIcon,
    DocumentIcon,
    // FilmIcon,
    ImageIcon,
    VideoIcon,
    FileTypeIcons,
  } from 'svelte-tailwind-components';

  let className = '';
  export { className as class };
  export let file;

  const componentsMap = {
    audio: AudioIcon,
    document: DocumentIcon,
    image: ImageIcon,
    video: VideoIcon,
  };

  let wrapperEl;
  let component;
  let src;

  $: {
    component =
      file?.extension && FileTypeIcons[file.extension.toUpperCase()]
        ? FileTypeIcons[file.extension.toUpperCase()]
        : file?.type && componentsMap[file.type]
        ? componentsMap[file.type]
        : componentsMap['document'];
    src = file?.imageStyleURL('media_thumbnail');
  }

  const onThumbnailError = (evt) => {
    console.log('error', evt);
  };
</script>

{#key file?.id}
  <div
    bind:this={wrapperEl}
    class:lf-file-item__file-type={true}
    class:flex={true}
    class:justify-center={true}
    class:items-center={true}
    class:flex-shrink-0={true}
    class:p-1={true}
    class={className}
    {...excludeProps($$restProps, ['icon$'])}>
    {#if src}
      <img
        class:lf-file-item__thumbnail={true}
        {src}
        onerror={(evt) => onThumbnailError(evt)}
        width="100"
        height="100"
        alt={file?.name} />
    {/if}
    <svelte:component
      this={component}
      {...prefixFilter($$restProps, 'icon$')} />
    <slot />
  </div>
{/key}

<style lang="postcss">:global(.lf-file-item__thumbnail+svg){display:none}</style>
