<svelte:options immutable="{true}" />

<script>
  import { deepGet, excludeProps } from 'svelte-utilities';
  import {
    CalendarIcon,
    Cards,
    ContactsIcon,
    Dialog,
    MediaFolderIcon,
    Menu,
    MessageIcon,
    NoteIcon,
    Tabs,
  } from 'svelte-tailwind-components';
  import { updateContactFx } from './contacts.controller.js';

  import ContactAboutTab from './tabs/ContactAboutTab.svelte';
  import ContactLessonsTab from './tabs/ContactLessonsTab.svelte';
  import ContactMessagesTab from './tabs/ContactMessagesTab.svelte';
  import ContactNotesTab from './tabs/ContactNotesTab.svelte';
  import ContactMediaTab from './tabs/ContactMediaTab.svelte';
  import BackButton from '../app/BackButton.svelte';
  import PictureInitials from '../users/PictureInitials.svelte';
  import ToggleFavorite from './ToggleFavorite.svelte';
  import ToggleArchived from './ToggleArchived.svelte';
  import SendMessageButton from '../messages/buttons/SendMessageButton.svelte';
  import BookMoreLessonsButton from '../lessons/buttons/BookMoreLessonsButton.svelte';
  import InviteLessonButton from '../lessons/buttons/InviteLessonButton.svelte';
  import EditButton from '../button/EditButton.svelte';
  import SaveButton from '../button/SaveButton.svelte';
  import CancelButton from '../button/CancelButton.svelte';
  import ViewProfile from '../button/ViewProfile.svelte';
  import DebugButton from '../button/DebugButton.svelte';

  let className = '';
  export { className as class };
  export let contact;
  export let app;
  export let sizerTargetEl = undefined;
  export let working = false;

  const { itemsListManager } = app;

  let tabMap = {
    about: {
      label: 'About',
      icon: ContactsIcon,
      autoload: true,
      tab: ContactAboutTab,
    },
    lessons: { label: 'Lessons', icon: CalendarIcon, tab: ContactLessonsTab },
    messages: { label: 'Messages', icon: MessageIcon, tab: ContactMessagesTab },
    notes: { label: 'Notes', icon: NoteIcon, tab: ContactNotesTab },
    media: { label: 'Media', icon: MediaFolderIcon, tab: ContactMediaTab },
  };
  let tabsController = Tabs.createTabs('contacts', {
    tabIds: Object.keys(tabMap),
  });

  let isMenuOpen = false;
  let isEditing = false;
  let titleInputEl;
  let contactEdit = {};

  itemsListManager.events.selectItem.watch(() => {
    tabsController.events.selectTab('about');
  });

  $: {
    if (contact && !isEditing) {
      contactEdit = {
        id: contact.id,
        title: contact.fullName,
        short_note: contact.shortNote,
      };
    }
  }

  const onSave = () => {
    // Note: not using deepEqual here since the contactEdit object won't
    // have all of the properties of the contact.
    const editKeys = Object.keys(contactEdit);
    const hasChanges =
      editKeys.length > 0 &&
      editKeys.some((key) => deepGet(contactEdit, key) !== deepGet(contact, key));

    if (hasChanges) {
      updateContactFx(contactEdit);
    }
  };
</script>

{#if contact}
  <article
    class:hidden="{!contact.loaded}"
    class:lf-contact-detail="{true}"
    class="lg:h-full {className}"
    {...excludeProps($$restProps, ['use', 'class', 'contact'])}
  >
    <div class:lf-contact-detail__header="{true}" class="bg-white sticky top-0 md:-top-2 z-10">
      <div
        class:lf-contact-detail__header-image-wrapper="{true}"
        class="bg-secondary-100 h-0 relative pt-20 elevation-4 md:pt-28 lg:pt-0"
      ></div>
      <div
        bind:this="{sizerTargetEl}"
        class:lf-contact-detail__header-content="{true}"
        class:lf-contact-detail__container="{true}"
        class="w-full px-4 sm:px-6 lg:px-8"
      >
        <div class="absolute top-1 left-2 md:top-2 lg:hidden">
          <BackButton on:viewList flat usage="link" color="secondary">Contacts</BackButton>
        </div>
        <div class="-mt-7 sm:-mt-12 flex space-x-5">
          <div class="flex">
            {#key contact}
              <PictureInitials
                contact="{contact}"
                size="responsive"
                image$width="{16 * 8}"
                image$height="{16 * 8}"
                class="border-2 border-white h-20 w-20 text-2xl md:h-32 md:w-32 md:text-4xl lg:h-28 lg:w-28 lg:text-3xl"
              />
            {/key}
          </div>
          <div class="flex items-center mt-8 md:flex-1 md:space-x-4">
            <div class="flex items-center space-x-2 md:flex-1">
              <h1 class="m-0 text-xl md:text-2xl text-gray-700 truncate lf-contact-detail__title">
                {@html contact.fullName}
              </h1>
              <ToggleFavorite contact="{contact}" class="flex-shrink-0" icon$class="w-7 h-7" />
            </div>

            <div
              class="absolute top-6 right-12 xl:static xl:top-auto xl:right-auto xl:mt-4 flex items-center space-x-3"
            >
              <SendMessageButton
                contacts="{[contact]}"
                on:sendMessage="{(evt) => app.messageDialogController.open(evt.detail)}"
                label="Message"
                usage="button"
                color="secondary"
                size="sm"
                icon$class="w-4 h-4"
              />

              <span class="hidden md:inline">
                <ToggleArchived
                  contact="{contact}"
                  color="secondary"
                  on:click="{() => (isMenuOpen = false)}"
                  on:click
                />
              </span>
            </div>

            <div class="absolute top-7 right-4 pt-0.5 xl:static xl:top-auto xl:right-auto xl:mt-4 xl:pt-1.5">
              <Menu
                bind:isOpen="{isMenuOpen}"
                trigger$size="md"
                trigger$color="secondary"
                trigger$class=""
              >
                <div class="flex flex-col py-1">
                  <ViewProfile
                    class="md:hidden"
                    usage="link"
                    color="secondary"
                    contact="{contact}"
                    on:click="{() => (isMenuOpen = false)}"
                    title="View Profile"
                  >
                    View Profile
                  </ViewProfile>
                  <ToggleArchived
                    contact="{contact}"
                    color="secondary"
                    label={contact.isArchived ? 'Unarchive' : 'Archive'}
                    on:click="{() => (isMenuOpen = false)}"
                    on:click
                  />
                  <EditButton
                    icon$title="Edit Contact"
                    usage="link"
                    color="secondary"
                    on:click="{() => (isMenuOpen = false)}"
                    on:click="{() => (isEditing = true)}"
                  />
                  <DebugButton
                    usage="link"
                    on:click="{() => (isMenuOpen = false)}"
                    on:click="{() => console.log('DEBUG:CONTACT', { contact })}"
                    on:click
                  />
                </div>
              </Menu>
            </div>
          </div>
        </div>

        <div class="hidden space-x-1 ml-32 pl-2 -mt-7 md:flex lg:ml-28 lg:pl-3 items-center">
          <BookMoreLessonsButton
            label="Book Lessons"
            usage="link"
            color="secondary"
            size="sm"
            icon$class="w-4 h-4"
            contact="{contact}"
          />
          <InviteLessonButton
            label="Invite"
            usage="link"
            color="secondary"
            size="sm"
            icon$class="w-4 h-4"
            contact="{contact}"
          />
          <ViewProfile
            label="View Profile"
            usage="link"
            color="secondary"
            size="sm"
            title="View Profile"
            icon$class="w-4 h-4"
            contact="{contact}"
          />
        </div>
      </div>

      <div
        class:lf-contact-detail__tabs="{true}"
        class="relative w-full mt-2 border-b border-gray-100"
      >
        <slot name="working" />
        <div
          class:lf-contact-detail__tabs-scroller="{true}"
          class="w-full px-4 overflow-hidden overflow-x-auto"
        >
          <Tabs.Tabs
            tabsController="{tabsController}"
            class="-mb-px inline-flex space-x-2 md:space-x-3"
          >
            <svelte:fragment slot="tab" let:id let:selected>
              <Tabs.Tab
                tabsController="{tabsController}"
                class="h-12 md:h-16"
                id="{id}"
                size="sm"
                color="gray"
                colorScale="{500}"
              >
                <span class="flex items-center space-x-1 md:space-x-2">
                  <svelte:component
                    this="{tabMap[id].icon}"
                    title="{tabMap[id].label}"
                    class="w-4 h-4"
                  />
                  <span class:hidden="{!selected}" class="md:block">
                    {tabMap[id].label}
                  </span>
                </span>
              </Tabs.Tab>
            </svelte:fragment>
          </Tabs.Tabs>
        </div>
      </div>
    </div>

    <div
      class:lf-contact-detail__tabs-content-wrapper="{true}"
      class="flex flex-col w-full lg:h-full lg:overflow-hidden lg:overflow-y-auto"
    >
      <Tabs.ContentWrapper
        tabsController="{tabsController}"
        class="lf-contact-detail__tabs-content h-full"
      >
        <svelte:fragment slot="content" let:id let:selected>
          {#if selected || deepGet(tabMap[id], 'autoload', false)}
            <svelte:component
              this="{tabMap[id].tab}"
              bind:working
              on:addNote="{(evt) => app.noteDrawerController.open(evt.detail)}"
              on:noteDeleted="{() => app.createNoteDrawerController.reset()}"
              on:replyToThread="{(evt) => app.messageDialogController.open(evt.detail)}"
              contact="{contact}"
              tabsController="{tabsController}"
            />
          {/if}
        </svelte:fragment>
      </Tabs.ContentWrapper>
    </div>
  </article>
{/if}

<Dialog bind:isOpen="{isEditing}" isModal id="editContact">
  <Cards.Card class="max-h-full max-w-screen-sm">
    <Cards.Header>
      <h3 class="m-0">
        <div class="text-sm uppercase text-secondary-500 leading-6">Edit Contact</div>
        <div class="text-xl font-medium text-gray-900 leading-none">
          {contact.fullName}
        </div>
      </h3>
    </Cards.Header>
    <Cards.Content>
      <div class="flex flex-col space-y-4 py-2">
        <label>
          <span class="block mb-px text-xs uppercase font-semibold text-gray-600">Contact Name</span
          >
          <input
            bind:this="{titleInputEl}"
            type="text"
            bind:value="{contactEdit.title}"
            placeholder="Enter Contact Name..."
            class="hidden text-base px-4 py-2 w-full"
          />
        </label>
        <label>
          <span class="block mb-px text-xs uppercase font-semibold text-gray-600"
            >Personal Note</span
          >
          <textarea
            bind:value="{contactEdit.short_note}"
            placeholder="Enter a brief personal note..."
            rows="3"
            maxlength="128"
            class="text-base px-2 py-2 w-full"></textarea>
        </label>
      </div>
    </Cards.Content>
    <Cards.Actions>
      <CancelButton usage="link" size="sm" color="danger" on:click="{() => (isEditing = false)}" />
      <SaveButton
        usage="link"
        size="sm"
        icon$title="Save Contact"
        icon$class="w-4 h-4"
        on:click="{() => (isEditing = false)}"
        on:click="{onSave}"
      />
    </Cards.Actions>
  </Cards.Card>
</Dialog>

<style lang="postcss">.lf-contact-detail{min-height:calc(100vh - var(--lf-header-height, 71px));overscroll-behavior-y:none}@media (min-width:1024px){.lf-contact-detail__header-image-wrapper{height:min(6rem,12vh)}}.lf-contact-detail :global(.lf-contact-detail__container){max-width:1024px}@media (min-width:1536px){.lf-contact-detail :global(.lf-contact-detail__container){margin-left:0;margin-right:0;padding-left:2rem}}.lf-contact-detail__title{max-width:200px}@media (min-width:768px){.lf-contact-detail__title{max-width:20rem}}.lf-contact-detail__tabs-scroller{background-attachment:local,local,scroll,scroll;background-image:linear-gradient(90deg,#fff,#fff),linear-gradient(90deg,#fff,#fff),linear-gradient(90deg,rgba(0,0,0,.15),hsla(0,0%,100%,0)),linear-gradient(270deg,rgba(0,0,0,.15),hsla(0,0%,100%,0));background-position:0,100%,0,100%;background-repeat:no-repeat;background-size:1.25rem 100%,1.25rem 100%,1rem 100%,1rem 100%}.lf-contact-detail__tabs :global(button:focus){--border-opacity:0}</style>
