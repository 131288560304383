<svelte:options immutable="{true}" />

<script>
  import { getDate, getTime } from 'svelte-utilities';
  import { Tabs } from 'svelte-tailwind-components';
  import GoalsSimpleList from '../../goals/GoalsSimpleList.svelte';

  export let tabsController;
  export let contact;
  export let working = undefined;

  let id = 'about';

  let SOURCE_MAP = {
    community_manager: 'Community Manager',
    group: 'Class',
    inquiry: 'Inquiry',
    inquiry_question: 'Inquiry Question',
    inquiry_match: 'Inquiry',
    invite: 'Invite',
    lesson: 'Lesson',
    message: 'Private Message',
    parent_child: 'Parent / Child',
    program: 'Program',
    referral: 'Referral Link',
    spc: 'Self Paced Course',
    support: 'Lessonface Support',
    waitlist_user: 'Instructor Waitlist',
  };
</script>

<Tabs.Content
  tabsController="{tabsController}"
  id="{id}"
  class="lf-contact-detail__container relative px-6 pt-4 md:pt-8 lg:px-8 ml-2 flex flex-col space-y-6 xl:flex-row xl:space-y-0 xl:space-x-8">
  <!-- stats wrapper -->
  <div
    class="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-4 xl:flex-col xl:space-y-6 xl:space-x-0 xl:max-w-sm lf-contact-detail__stats-wrapper">
    <!-- stats column -->
    <div class="flex flex-col space-y-6 md:flex-1 xl:flex-none">
      {#if contact.shortNote}
        <div class="text-gray-700 italic">{contact.shortNote}</div>
      {/if}
      <div>
        <dt class="lf-contact-detail__label">
          {contact.isInstructor ? 'Instruments' : 'Interests'}
        </dt>
        <dd class="lf-contact-detail__content">
          {contact.interests ?? ''}
        </dd>
      </div>
      <div>
        <dt class="lf-contact-detail__label">Languages Spoken</dt>
        <dd class="lf-contact-detail__content">
          {contact.languages ?? ''}
        </dd>
      </div>
    </div>

    <!-- stats column -->
    <div class="flex flex-col space-y-6 md:flex-1 xl:flex-none">
      {#if contact.timezone}
        <div>
          <dt class="lf-contact-detail__label">Timezone</dt>
          <dd class="lf-contact-detail__content">
            {contact.timezone} ({contact.tzShort}) is {contact.tzDiffLabel}<br />
            Current time in {contact.tzShort} is {contact.currentTime}.
          </dd>
        </div>
      {/if}
      {#if contact.createdFrom && SOURCE_MAP[contact.createdFrom]}
        <div>
          <dt class="lf-contact-detail__label">Contact Source</dt>
          <dd class="lf-contact-detail__content whitespace-no-wrap">
            {SOURCE_MAP[contact.createdFrom]} on {getDate(contact.createdAt)}
          </dd>
        </div>
      {/if}
      {#if contact.lastContactedOn && contact.lastContactType && SOURCE_MAP[contact.lastContactType]}
        <div>
          <dt class="lf-contact-detail__label">Last Contact</dt>
          <dd class="lf-contact-detail__content whitespace-no-wrap">
            {SOURCE_MAP[contact.lastContactType]} on {getDate(contact.lastContactedOn)}
            {getTime(contact.lastContactedOn)}
          </dd>
        </div>
      {/if}
      {#if contact.referredByLabel}
        <div>
          <dt class="lf-contact-detail__label">Referred By</dt>
          <dd class="lf-contact-detail__content whitespace-no-wrap">
            {contact.referredByLabel}
          </dd>
        </div>
      {/if}
      <div>
        <dt class="lf-contact-detail__label">Contact ID</dt>
        <dd class="lf-contact-detail__content">
          <span class="text-sm text-gray-400">#{contact.id}</span>
        </dd>
      </div>
    </div>
  </div>

  <!-- bio column -->
  <div class="flex-1 lf-contact-detail__bio-wrapper flex flex-col space-y-2">
    <div class="lf-contact-detail__bio">
      <dt class="lf-contact-detail__label">About Me</dt>
      <dd class="lf-contact-detail__content">
        {@html contact?.bio ?? ''}
      </dd>
    </div>

    {#if contact.goals.length > 0}
      <div class="lf-contact-detail__goals">
        <GoalsSimpleList goals="{contact.goals}" class="inline-block">
          <svelte:fragment slot="title">
            <dt class="lf-contact-detail__label mb-3">Goals</dt>
          </svelte:fragment>
        </GoalsSimpleList>
      </div>
    {/if}
  </div>
</Tabs.Content>

<style lang="postcss">.lf-contact-detail__content :global(strong){font-weight:400}.lf-contact-detail__content :global(em){font-style:normal}:global(.lf-contact-detail__tabs-content) .lf-contact-detail__content{--text-opacity:1;color:#161e2e;color:rgba(22,30,46,var(--text-opacity));font-size:1rem;line-height:1.5rem;margin-left:0!important;margin-top:.25rem}:global(.lf-contact-detail__tabs-content) .lf-contact-detail__label{--text-opacity:1;color:#9fa6b2;color:rgba(159,166,178,var(--text-opacity));font-family:var(--lfl-headingfont,"latobold, sans-serif");font-size:.875rem;font-weight:400;margin-left:0!important;text-transform:uppercase}@media (min-width:1280px){.lf-contact-detail__stats-wrapper{flex:0 0 33%}}@media (min-width:768px){.lf-contact-detail__bio-wrapper{flex:1 1 auto}}</style>
