<script>
  // @todo DataTable generic logic to handle child rows.
  import { onMount, createEventDispatcher } from 'svelte';

  import {
    getPaymentItems
  } from '../instructorPayments.api.js';
  import {
    pendingColumnsDefault,
    pendingConfigDefault,
  } from './datatable.config.js';

  import _findIndex from 'lodash-es/findIndex.js';
  import _get from 'lodash-es/get.js';

  import Button from '../../button/Button.svelte';
  import Overlay from '../../overlay/Overlay.svelte';
  import DataTable from '../../datatable/DataTable.svelte';
  import Spinner from '../../spinner/Spinner.svelte';

  export let action = {};
  export let rowData = {};
  export let meta = {};

  const dispatch = createEventDispatcher();

  let data;

  let pendingColumns = [...pendingColumnsDefault];
  let pendingConfig = {...pendingConfigDefault};

  let isInitialized = false;
  let show = false;

  pendingColumns.splice(_findIndex(pendingColumns, { name: 'changed' }));
  pendingConfig.language.noData = 'There are no payment items associated with this payment.';

  function dispatchActionStatus() {
    dispatch('actionStatusChange', {
      id: 'update',
      dialog: true,
      isOpen: show,
    })
  }

  async function onClick() {
    if (!show) {
      data = await getPaymentItems({
        filters: [
          ['payment_id', rowData.id]
        ]
      });
      show = true;
    }
    else {
      data = [];
      show = false;
    }
    dispatchActionStatus();
  }

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

{#if isInitialized}
  <Button
    on:click={onClick}
    class="{action.params.class}">
    {action.label}
  </Button>

  {#if show}
    <div
      class="lf-instructor-payments-payment-items-wrapper">
      <Overlay
        on:click={onClick}
        fullscreen
        isActive={show}
        />
      <DataTable
        id="lf-instructor-payments-payment-{rowData.id}-items"
        {data}
        config={pendingConfig}
        columns={pendingColumns}
        >
        <div slot="actions">
          <Button
            on:click={onClick}
            class="{action.params.class}">
            Close
          </Button>
        </div>
      </DataTable>
    </div>
  {/if}
{/if}

<style lang="postcss">.lf-instructor-payments-payment-items-wrapper{align-items:center;display:flex;height:100vh;justify-content:center;left:0;position:fixed;top:0;width:100vw;z-index:1}.lf-instructor-payments-payment-items-wrapper :global(.lf-datatable-wrapper){background-color:#fff;padding:1em 1.5em;position:relative}</style>
