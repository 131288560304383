<svelte:options immutable={true} />

<script>
  import { afterUpdate, tick } from 'svelte';
  import { derived, writable } from 'svelte/store';
  import {
    deepGet,
    excludeProps,
    prefixFilter,
    prefixFilterItem,
  } from 'svelte-utilities';
  import { VirtualList } from 'svelte-tailwind-components';
  import { isSmallDevice } from '../app/app.controller.js';
  import {
    createFilterCountsStore,
    createFolderCountsStore,
    filtersApplied,
    getCurrentItemIndex,
    getFirstNonZeroFolder,
    itemInCurrentFolder,
  } from './items.utils.js';
  import ItemsListHeader from './ItemsListHeader.svelte';
  import ItemsListFooter from './ItemsListFooter.svelte';
  import VirtualListWrapper from '../app/VirtualListWrapper.svelte';
  import VirtualListItemWrapper from '../app/VirtualListItemWrapperV2.svelte';
  import ItemsNoResults from './ItemsNoResults.svelte';

  export let element = undefined;
  let className = '';
  export { className as class };
  // Inputs.
  export let itemsListManager;
  export let folderComponents;
  export let filterComponents = undefined;
  export let title = undefined;
  export let shortLabel = undefined;
  export let rowHeight = undefined;
  export let useTooltip = false;
  export let isContextMenu = false;
  export let hiddenElements = [];
  // Outputs.
  export let state;
  export let folderCounts;
  export let filterCounts = undefined;
  export let visibleItems = undefined;
  export let indexStart = undefined;
  export let indexEnd = undefined;
  export let scrollToIndex = undefined;
  export let headerEl = undefined;
  export let navEl = undefined;
  export let footerEl = undefined;

  export const setFolder = (folder) =>
    filterer.merge({ folder: { value: folder } });

  const localState = writable({
    tooltipItemId: undefined,
  });

  const {
    itemsStore,
    filteredItemsStore,
    searchedItemsStore,
    filterer,
    searcher,
    sorter,
  } = itemsListManager;

  const setTooltipItemId = (id) =>
    localState.update((draft) => {
      draft.tooltipItemId = id;
      return draft;
    });

  const selectItem = (item) => {
    itemsListManager.events.selectItem({ item });
  };

  let virtualState;
  let scrollToEnd;
  let scrollPrevPage;
  let scrollNextPage;
  let scrollToItem;

  const selectItemIndex = (index) => {
    setTooltipItemId();
    navEl.focus();
    index !== $state.currentIndex && selectItem($state.items[index]);
  };

  const selectFirstItem = () => {
    scrollToIndex(0);
    selectItemIndex(0);
  };
  const selectLastItem = () => {
    selectItemIndex($state.lastIndex);
    scrollToEnd();
  };
  const selectPrevItem = () => {
    if ($state.currentIndex > 0) {
      const newIndex = $state.currentIndex - 1;
      scrollToIndex(newIndex);
      selectItemIndex(newIndex);
    }
  };
  const selectNextItem = () => {
    if ($state.currentIndex >= 0 && $state.lastIndex > $state.currentIndex) {
      const newIndex = $state.currentIndex + 1;
      scrollToIndex(newIndex);
      selectItemIndex(newIndex);
    }
  };

  const selectPrevPageItem = () => {
    const index = scrollPrevPage();
    selectItemIndex(index);
  };
  const selectNextPageItem = () => {
    const index = scrollNextPage();
    selectItemIndex(index);
  };

  const openSelectedTooltip = () => setTooltipItemId($state.selectedId);
  const closeSelectedTooltip = () => {
    setTooltipItemId();
    navEl.focus();
  };

  const keyBindings = {
    selectFirstItem: { keys: 'home', fn: selectFirstItem },
    selectLastItem: { keys: 'end', fn: selectLastItem },
    selectPrevItem: { keys: 'up', fn: selectPrevItem },
    selectNextItem: { keys: 'down', fn: selectNextItem },
    selectPrevPageItem: { keys: 'pageup', fn: selectPrevPageItem },
    selectNextPageItem: { keys: 'pagedown', fn: selectNextPageItem },
    openSelectedTooltip: { keys: 'enter', fn: openSelectedTooltip },
    closeSelectedTooltip: { keys: 'esc', fn: closeSelectedTooltip },
  };

  const mousetrapParams = !isSmallDevice()
    ? { bindings: Object.values(keyBindings) }
    : { disabled: true };

  folderCounts = createFolderCountsStore(itemsStore, folderComponents);
  filterCounts = createFilterCountsStore(itemsStore, filterComponents);

  state = derived(
    [
      localState,
      itemsListManager,
      searchedItemsStore,
      filterer,
      searcher,
      sorter,
      folderCounts,
    ],
    ([
      $localState,
      $itemsListManager,
      $searchedItemsStore,
      $filterer,
      $searcher,
      $sorter,
      $folderCounts,
    ]) => {
      const { lastSelectedId, selectedId, selectedItem } = $itemsListManager;
      const currentFolder = deepGet($filterer, 'folder.value', 'all');
      const currentFolderLabel = deepGet(
        folderComponents[currentFolder],
        'label'
      );
      const sortDir = deepGet($sorter, 'order', [])[0] || 'asc';
      const currentIndex = getCurrentItemIndex(selectedId, $searchedItemsStore);

      return {
        ...$localState,
        lastSelectedId,
        selectedId,
        selectedItem,
        currentIndex,
        lastIndex: $searchedItemsStore.length - 1,
        // @todo note that mapping the ObjectModel to the itemsStore also
        // causes similar issues noted elsewhere. Seems to be when it passes
        // though the store (cloned?) it's being converted to a json object
        // and not able to automatically be converted back.
        items: $searchedItemsStore,
        currentFolder,
        currentFolderLabel,
        sortDir,
        filters: $filterer,
        filtered: filtersApplied(
          $filterer,
          deepGet(filterer, 'config.initialFilter', {})
        ),
        searchText: $searcher,
        folderCount: deepGet($folderCounts, currentFolder, 0),
        folderCounts: $folderCounts,
      };
    }
  );

  let folderSet = false;

  filterer.filter.on(filterer.updated, () => {
    return { items: $itemsStore };
  });
  sorter.sort.on(sorter.updated, () => {
    return { items: $filteredItemsStore };
  });
  searcher.search.on(searcher.updated, () => {
    return { items: $filteredItemsStore };
  });

  searcher.search.done.watch(async () => {
    const inCurrentFolder = itemInCurrentFolder(
      $state.selectedItem,
      $state.currentFolder
    );

    if (inCurrentFolder) {
      await tick();
      scrollToItem($state.selectedId);
    }
  });

  sorter.sort.done.watch(async () => {
    const inCurrentFolder = itemInCurrentFolder(
      $state.selectedItem,
      $state.currentFolder
    );

    if (inCurrentFolder) {
      await tick();
      scrollToItem($state.selectedId);
    }
  });

  itemsListManager.events.selectItem.watch(async ({ item }) => {
    // When filters/search change, the system checks if the list needs to
    // scroll. So if scroll is set here and the item is not in the current
    // folder then just change the folder. If scroll is set and the item
    // is in the current folder then scroll to it.
    const inCurrentFolder = itemInCurrentFolder(item, $state.currentFolder);

    if (!inCurrentFolder) {
      setFolder('all');
    }

    await tick();
    scrollToItem(item.id);
    navEl.focus();
  });

  const onItemSelected = (evt) => {
    const itemId = evt?.detail?.id;

    if (itemId && itemId !== $state?.selectedItem?.id) {
      // When not using the tooltip we want to 'selectItem' which has follow-on
      // events, including loadItem.
      if (!useTooltip) {
        // Note: finding the index and then using selectItemIndex reduces
        // the flick that the detail page was showing when using selectItem().
        // However we still need selectItem() for places like the dashboard.
        const selectedIndex = $state.items.findIndex(
          (item) => item?.id === itemId
        );
        selectedIndex >= 0 && selectItemIndex(selectedIndex);
      }
      // When using the tooltip we still want to load the item even if not
      // 'selecting' it.
      else {
        const item = $itemsStore.find((i) => i.id === itemId);
        itemsListManager.events.loadItem({ item });
      }
    }
  };
  const onShowTooltip = (evt) => setTooltipItemId(evt.detail);
  const onHideTooltip = () => setTooltipItemId();
  const onTooltipClosed = () => {
    setTooltipItemId();
    navEl.focus();
  };

  afterUpdate(() => {
    if (
      !folderSet ||
      (!$state.filtered &&
        $state.currentFolder !== 'all' &&
        $state.folderCount === 0)
    ) {
      const folder = getFirstNonZeroFolder($folderCounts);
      folder && folder !== $state.currentFolder && setFolder(folder);
      folderSet = folder !== undefined;
    }
  });
</script>

<div
  bind:this={element}
  class:lf-items-virtual-list={true}
  class="flex flex-col h-full {className}"
  {...excludeProps($$restProps, [
    'header$',
    'listWrapper$',
    'list$',
    'itemWrapper$',
    'footer$',
  ])}>
  {#if $$slots.header}
    <ItemsListHeader
      bind:element={headerEl}
      {state}
      {searcher}
      {hiddenElements}
      label={shortLabel ?? title}
      class={prefixFilterItem($$restProps, 'class', 'header$', '')}
      {...excludeProps(prefixFilter($$restProps, 'header$'), ['class'])}>
      <slot name="title" slot="title" />
      <slot name="primaryAction" slot="primaryAction" />
      <slot name="header" {folderCounts} {filterCounts} />
    </ItemsListHeader>
  {/if}

  <VirtualListWrapper
    bind:navEl
    {title}
    {mousetrapParams}
    class={prefixFilterItem($$restProps, 'class', 'listWrapper$', '')}
    {...excludeProps(prefixFilter($$restProps, 'listWrapper$'), ['class'])}>
    <slot name="preInner" slot="preInner" />
    <VirtualList
      items={$state.items}
      {rowHeight}
      bind:state={virtualState}
      bind:visibleRows={visibleItems}
      bind:indexStart
      bind:indexEnd
      bind:scrollToIndex
      bind:scrollToEnd
      bind:scrollPrevPage
      bind:scrollNextPage
      bind:scrollToItem
      let:rowIndex
      let:i={visibleIndex}
      let:rowData={item}
      class="h-full {prefixFilterItem($$restProps, 'class', 'list$', '')}"
      {...excludeProps(prefixFilter($$restProps, 'list$'), ['class'])}>
      <slot name="noResults" slot="noResults">
        {#if $itemsListManager.initialized && !$itemsListManager.working}
          {#if $state?.folderCounts?.all > 0 && $state?.items?.length === 0}
            <ItemsNoResults {state} ctx={{ label: shortLabel ?? title }} />
          {/if}
        {/if}
      </slot>
      <VirtualListItemWrapper
        {state}
        {item}
        {useTooltip}
        {isContextMenu}
        on:itemClicked
        on:itemSelected={onItemSelected}
        on:showTooltip={onShowTooltip}
        on:hideTooltip={onHideTooltip}
        on:tooltipClosed={onTooltipClosed}
        let:activeShowing
        let:closeTooltip
        class="h-full {prefixFilterItem(
          $$restProps,
          'class',
          'itemWrapper$',
          ''
        )}"
        {...excludeProps(prefixFilter($$restProps, 'itemWrapper$'), ['class'])}>
        <slot
          name="item"
          {item}
          isSelected={item?.id > 0 && item.id === $state.selectedId}
          {rowIndex}
          {visibleIndex} />
        <slot
          name="tooltip"
          slot="tooltip"
          {item}
          {activeShowing}
          {closeTooltip} />
      </VirtualListItemWrapper>
    </VirtualList>
    <slot name="postInner" slot="postInner" />
  </VirtualListWrapper>

  {#if $$slots.footer}
    <ItemsListFooter
      bind:element={footerEl}
      {state}
      {hiddenElements}
      on:debugListManager={() => console.log($itemsListManager)}
      class={prefixFilterItem($$restProps, 'class', 'footer$', '')}
      {...excludeProps(prefixFilter($$restProps, 'footer$'), ['class'])}>
      <slot name="footer" {folderCounts} {filterCounts} />
    </ItemsListFooter>
  {/if}
</div>

<style lang="postcss">.lf-items-virtual-list :global(.dna-search .dna-button__icon){--text-opacity:1;color:#6b7280;color:rgba(107,114,128,var(--text-opacity))}.lf-items-virtual-list :global(.lf-items-virtual-list-header__main-row .dna-button .dna-button__icon){--text-opacity:1;color:#6b7280;color:rgba(107,114,128,var(--text-opacity))}.lf-items-virtual-list :global(.lf-items-virtual-list-footer .dna-menu:not(.lf-debug-menu) .dna-button .dna-button__icon){--text-opacity:1;color:#6b7280;color:rgba(107,114,128,var(--text-opacity))}</style>
