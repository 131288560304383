<script>
  import { createEventDispatcher } from 'svelte';
  import Button from '../button/Button.svelte';

  export let cardsOnFile = [];
  export let selectedCard = '';
  export let helpText = '';

  const dispatch = createEventDispatcher();

  $: savedCardsCount = cardsOnFile.reduce((acc, card) => {
    acc = card.id != '_new' ? acc + 1 : acc;
    return acc;
  }, 0);
  $: selectedOption = cardsOnFile.filter((card) => selectedCard == card.id)[0];

  let className = '';
  export { className as class };

  let el;

  function openChangeCardList() {
    el.classList.add('lf-stripe-cardsonfile--change');
    dispatch('openChangeCard');
  }

  function closeChangeCardList() {
    el.classList.remove('lf-stripe-cardsonfile--change');
  }
</script>

{#if !!+savedCardsCount}
  <div
    bind:this={el}
    class="
    form-item
    lf-stripe-cardsonfile-wrapper
    {className}">
    <div
      id="lf-stripe-cardonfile-selected"
      class="lf-stripe-cardonfile-selected">
      <div
        class="
        lf-stripe-cardonfile__label
        lf-stripe-cardonfile--{selectedOption.brand.toLowerCase()}
        ">
        <div class="lf-stripe-cardonfile__image">
          {#if selectedOption.id !== '_new'}
            <img
              src="/sites/all/modules/custom/lf_cartcheckout/images/cc/{selectedOption.brand.toLowerCase()}.svg"
              class="lf-cc"
              width="24px"
              height="auto"
              alt={selectedOption.brand.toUpperCase()} />
          {/if}
        </div>
        <div class="lf-stripe-cardonfile__last4">{selectedOption.last4}</div>
        <div class="lf-stripe-cardonfile__exp-month">
          {!!+selectedOption.expMonth
            ? selectedOption.expMonth.toString().padStart(2, '0')
            : ''}
        </div>
        {#if !!+selectedOption.expMonth && !!+selectedOption.expYear}
          <div class="lf-stripe-cardonfile__exp-spacer">/</div>
        {/if}
        <div class="lf-stripe-cardonfile__exp-year">
          {!!+selectedOption.expYear
            ? selectedOption.expYear.toString().slice(-2)
            : ''}
        </div>
        <div class="lf-stripe-cardonfile__change">
          <Button
            on:click={openChangeCardList}
            class="button button--link button--tiny">Change</Button>
        </div>
      </div>
    </div>

    <div id="lf-stripe-cardsonfile" class="lf-stripe-cardsonfile">
      {#each cardsOnFile as cardOnFile, i (cardOnFile.id)}
        <label
          on:click={closeChangeCardList}
          class:lf-stripe-cardonfile--expired={cardOnFile.expired}
          class:lf-stripe-cardonfile--default={cardOnFile.isDefault}
          class:lf-stripe-cardonfile--selected={selectedCard == cardOnFile.id}
          class="lf-stripe-cardonfile-option">
          <input
            bind:group={selectedCard}
            type="radio"
            name="lf-stripe-cardonfile"
            value={cardOnFile.id} />
          <div
            class="
            lf-stripe-cardonfile__label
            lf-stripe-cardonfile--{cardOnFile.brand.toLowerCase()}
            ">
            <div class="lf-stripe-cardonfile__image">
              {#if cardOnFile.id !== '_new'}
                <img
                  src="/sites/all/modules/custom/lf_cartcheckout/images/cc/{cardOnFile.brand.toLowerCase()}.svg"
                  class="lf-cc"
                  width="24px"
                  height="auto"
                  alt={cardOnFile.brand.toUpperCase()} />
              {/if}
            </div>
            <div class="lf-stripe-cardonfile__last4">{cardOnFile.last4}</div>
            <div class="lf-stripe-cardonfile__exp-month">
              {!!+cardOnFile.expMonth
                ? cardOnFile.expMonth.toString().padStart(2, '0')
                : ''}
            </div>
            {#if !!+cardOnFile.expMonth && !!+cardOnFile.expYear}
              <div class="lf-stripe-cardonfile__exp-spacer">/</div>
            {/if}
            <div class="lf-stripe-cardonfile__exp-year">
              {!!+cardOnFile.expYear
                ? cardOnFile.expYear.toString().slice(-2)
                : ''}
            </div>
          </div>
        </label>
      {/each}
    </div>
    {#if !!+helpText.length}
      <div class="description">{@html helpText}</div>
    {/if}
  </div>
{/if}

<style lang="postcss">.lf-stripe-cardsonfile-wrapper{position:relative}.lf-stripe-cardonfile-selected{font-size:.875rem}.lf-stripe-cardonfile-selected .lf-stripe-cardonfile__label{background-color:#fff;border:1px solid #ccc}.lf-stripe-cardonfile-selected .lf-stripe-cardonfile__image{opacity:1}.lf-stripe-cardsonfile--change .lf-stripe-cardonfile-selected{display:none}.lf-stripe-cardsonfile{background-color:#fff;border:1px solid #ccc;display:none;font-size:.875rem}.lf-stripe-cardsonfile--change .lf-stripe-cardsonfile{display:block}.lf-stripe-cardonfile-option{color:#666;margin:0;padding:4px 8px}.lf-stripe-cardonfile-option+.lf-stripe-cardonfile-option{border-top:1px solid #eee}.lf-stripe-cardonfile-option:hover{background-color:rgba(63,157,75,.1);color:#333}.lf-stripe-cardonfile-option:hover .lf-stripe-cardonfile__image{opacity:1}.lf-stripe-cardonfile__label{align-items:center;display:flex;justify-content:space-between;line-height:24px;padding:4px 8px}.lf-stripe-cardonfile__image{height:auto;margin-right:8px;opacity:.7;width:24px}.lf-stripe-cardonfile__exp-month{margin-left:auto}.lf-stripe-cardonfile__exp-spacer{margin:0 4px}.lf-stripe-cardonfile__change{margin-left:8px}.lf-stripe-cardonfile--selected{font-weight:700}</style>
