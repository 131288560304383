<script>
  import { formatCurrency } from 'svelte-utilities';

  export let amount;
  let currencyCode = 'USD';
  export { currencyCode as currency_code };
  export let components = [];

  let className = '';
  export { className as class };
</script>

<div class="lf-order-total font-inter {className}">
  {#if components.length > 1}
    {#each components as component}
      <div class="lf-price-component lf-order-total__{component.name.replace('_', '-')}">
        <span class="lf-price-component__label">{component.label}</span>
        <span class="lf-price-component__price"
          >{formatCurrency(component.amount, currencyCode)}</span
        >
      </div>
    {/each}
  {/if}
  <div class="lf-order-total__total">
    <span class="lf-price-component__label">Order total</span>
    <span class="lf-price-component__price">{formatCurrency(amount, currencyCode)}</span>
  </div>
</div>

<style lang="postcss">.lf-order-total{display:flex;flex-direction:column;line-height:1.2em;row-gap:.5rem}.lf-order-total__total,.lf-price-component{align-items:flex-start;display:flex;justify-content:flex-start}.lf-order-total__total__price,.lf-price-component__price{margin-left:auto}.lf-price-component{font-size:.875rem}.lf-order-total__total{font-weight:600}.lf-order-total__total .lf-price-component__price{color:var(--lfl-text);font-size:1.25rem}</style>
