<script>
  import _get from 'lodash-es/get.js';
  import _orderBy from 'lodash-es/orderBy.js';
  import { excludeProps, getDate, incDate } from 'svelte-utilities';
  import { Button, Cards, Dialog, Menu, ProgressLinear } from 'svelte-tailwind-components';
  import UsageReport from '../../../components/programs/UsageReport.svelte';
  import ManageMembers from '../../../components/programs/ManageMembers.svelte';
  import ManageInvites from '../../../components/programs/ManageInvites.svelte';

  export let widgetDelta = 0;
  let className = '';
  export { className as class };

  const isAdmin = _get(Drupal.settings, 'lf_programs.isAdmin', false);

  const widgets = _get(Drupal.settings, 'lf_programs.programWidgets', []);
  const widget = _get(widgets, widgetDelta, {});
  const isManager = _get(widget, 'isManager', false);
  const program = _get(widget, 'programDetails', {});

  const title = _get(program, 'title', '');
  const allottedTokens = _get(program, 'allotted_tokens', 0);
  const remainingTokens = _get(program, 'remaining_tokens', 0);
  const programStart = _get(program, 'license_date.start');
  const programEnd = _get(program, 'license_date.end');
  const managers = _get(program, 'managers', []);
  const members = _get(program, 'members', []);
  const invites = _get(program, 'invites', []);
  const programURL = _get(program, 'url');

  const now = new Date().toISOString();
  const programDateWarningLevel = incDate(now, 60 * 60 * 24 * 30);
  const programDateWarning = programDateWarningLevel >= programEnd;

  const tokenWarningLevel = allottedTokens > 0 ? allottedTokens / 10 : 10;
  const tokenWarning = tokenWarningLevel >= remainingTokens;

  const formattedProgramStart = getDate(programStart, { month: 'long' });
  const formattedProgramEnd = getDate(programEnd, { month: 'long' });

  let element;
  let working = false;
  let showMenu = false;
  let managingProgram = false;
  let managingInvites = false;
  let showUsageReport = false;
</script>

<Cards.Card
  bind:this="{element}"
  class="lf-program-details w-full lf-svelte-ajaxify overflow-visible {className}"
  {...excludeProps($$props, ['use', 'class', 'widgetDelta'])}
>
  <Cards.Header class="relative">
    <h4
      class="m-0 whitespace-no-wrap overflow-hidden sm:whitespace-normal sm:overflow-visible"
      style="text-overflow: ellipsis; "
    >
      {title}
    </h4>
    {#if isManager || isAdmin}
      <Menu bind:isOpen="{showMenu}" class="flex items-center ml-auto -mt-2 -mb-2">
        <div class="flex flex-col py-1">
          <div class="mt-2 px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
            Reports
          </div>
          <Button
            on:click="{() => (showUsageReport = true)}"
            on:click="{() => (showMenu = false)}"
            usage="link"
            size="sm"
          >
            <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path
                fill-rule="evenodd"
                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z"
                clip-rule="evenodd"></path></svg
            >
            Usage
          </Button>

          <div class="mt-2 px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
            Manage
          </div>
          <Button
            on:click="{() => (managingProgram = true)}"
            on:click="{() => (showMenu = false)}"
            usage="link"
            size="sm"
          >
            <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path
                d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
              ></path></svg
            >
            Members</Button
          >
          <Button
            on:click="{() => (managingInvites = true)}"
            on:click="{() => (showMenu = false)}"
            usage="link"
            size="sm"
          >
            <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
              ><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
              ></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg
            >
            Invites</Button
          >

          {#if isAdmin && programURL}
            <Button
              usage="link"
              size="sm"
              href="{programURL}/edit"
              urlOptions="{{ query: { destination: window.location.href } }}"
            >
              <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
                ><path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                ></path></svg
              >
              Edit</Button
            >
          {/if}
        </div>
      </Menu>
    {/if}

    <div class:hidden="{!working}" class="absolute left-0 bottom-0 w-full">
      <ProgressLinear working="{working}" settings="{{ indeterminate: true }}" />
    </div>
  </Cards.Header>

  <Cards.Content>
    <div class="flex justify-start flex-gap-x-4 flex-gap-y-6 flex-wrap items-start">
      {#if isManager || isAdmin}
        <div class="flex-1 lf-program-details__remaining-tokens-wrapper uppercase">
          <div class="inline-block text-right">
            <div
              class="text-xs font-semibold leading-4 whitespace-no-wrap {tokenWarning
                ? 'text-danger-700'
                : 'text-secondary-500'}"
            >
              Remaining Tokens
            </div>
            <div class="text-sm leading-5 {tokenWarning ? 'text-danger-700 font-semibold' : ''}">
              {remainingTokens}
            </div>
          </div>
        </div>
        <div class="flex-1 lf-program-details__members-wrapper uppercase">
          <div class="inline-block text-right">
            <div class="text-xs font-semibold leading-4 text-secondary-500 whitespace-no-wrap">
              # Members
            </div>
            <div class="text-sm leading-5">{members.length}</div>
          </div>
        </div>
        <div class="flex-1 lf-program-details__invites-wrapper uppercase">
          <div class="inline-block text-right">
            <div class="text-xs font-semibold leading-4 text-secondary-500 whitespace-no-wrap">
              # Pending Invites
            </div>
            <div class="text-sm leading-5">{invites.length}</div>
          </div>
        </div>
        <div
          class="w-full md:w-auto md:flex-1 lf-program-details__dates-wrapper uppercase whitespace-no-wrap"
        >
          <div
            class="text-xs font-semibold leading-4 whitespace-no-wrap {programDateWarning
              ? 'text-danger-700'
              : 'text-secondary-500'}"
          >
            Program Dates
          </div>
          <div class="text-sm leading-5">
            {formattedProgramStart} -
            <span class="{programDateWarning ? 'text-danger-700 font-semibold' : ''}"
              >{formattedProgramEnd}</span
            >
          </div>
        </div>
      {/if}

      <div class="w-full">
        <div class="text-xs font-semibold leading-4 text-secondary-500 uppercase mb-1">
          Managers
        </div>
        <div class="flex flex-col flex-start items-start flex-gap-y-1 text-sm leading-5">
          {#each _orderBy(managers, ['fullname']) as manager (manager.id)}
            <div>{manager.fullname}</div>
          {/each}
        </div>
      </div>
    </div>
  </Cards.Content>
</Cards.Card>

<Dialog id="lf-program-details-usage-report" bind:isOpen="{showUsageReport}">
  <UsageReport on:close="{() => (showUsageReport = false)}" program="{program}" />
</Dialog>

<Dialog id="lf-program-details-manage-members" bind:isOpen="{managingProgram}">
  <ManageMembers on:close="{() => (managingProgram = false)}" program="{program}" />
</Dialog>

<Dialog id="lf-program-details-manage-invites" bind:isOpen="{managingInvites}">
  <ManageInvites on:close="{() => (managingInvites = false)}" program="{program}" />
</Dialog>

<style lang="postcss">:global(#lf-program-invites-manage),:global(#lf-program-members-manage),:global(#lf-program-usage-report){min-width:90vw}@media (min-width:640px){:global(#lf-program-invites-manage),:global(#lf-program-members-manage),:global(#lf-program-usage-report){min-width:400px}}</style>
