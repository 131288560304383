<svelte:options immutable={true} />

<script>
  import _orderBy from 'lodash-es/orderBy.js';
  import { writable, derived } from 'svelte/store';
  import { fade } from 'svelte/transition';
  import {
    fixedHeightAdjuster,
    sizeAdjusterObserver,
    toJSON,
  } from 'svelte-utilities';
  import { Tabs } from 'svelte-tailwind-components';
  import { notesStore } from '../../notes/notes.store.js';
  import { lessonsStore } from '../../lessons/lessons.store.js';
  import { createNoteDrawerController } from '../../notes/notes.controller.js';
  import { toNoteModel, toLessonModel } from '../../../models';
  import ContactNote from '../ContactNote.svelte';
  import NoteInPlaceDrawer from '../../notes/NoteInPlaceDrawer.svelte';
  import AddContactNoteButton from '../buttons/AddContactNoteButton.svelte';
  import DebugMenu from '../../app/DebugMenu.svelte';
  import DebugButton from '../../button/DebugButton.svelte';

  export let tabsController;
  export let contact;
  export let working = false;

  const noteDrawerController = createNoteDrawerController('contactNotesTab');
  const state = writable({ currentFolder: 'all', sortDir: 'asc' });

  let controlsEl;

  let id = 'notes';
  let contactNotes = derived(
    [notesStore, lessonsStore],
    ([$notesStore, $lessonsStore]) => {
      // Get the contact lesson IDs. Then look through all of the notes store
      // and return notes for this contact or for one of the contact's lessons.
      const contactLessons = contact?.userId
        ? $lessonsStore
            .map((lesson) => toLessonModel(lesson))
            .filter((l) => {
              return l.numNotes > 0 && l.withUser(contact.userId);
            })
            .reduce((acc, l) => {
              acc[l.id] = l;
              return acc;
            }, {})
        : {};
      const contactLessonIds = Object.keys(contactLessons).map((id) => +id);
      const _contactNotes = $notesStore
        .map((n) => toNoteModel(n))
        .filter((n) => {
          const isContactNote = n?.forContact && n.withUser(contact.userId);
          const isLessonNote =
            n?.forLesson && contactLessonIds.includes(n?.targetId);
          return isContactNote || isLessonNote;
        })
        .map((n) => {
          n.target = n.forLesson ? contactLessons[n.targetId] : contact;
          return n;
        });
      return _contactNotes;
    }
  );

  let folderNotes = derived(
    [contactNotes, state],
    ([$contactNotes, $state]) => {
      const _folderNotes = $contactNotes.filter((n) =>
        n?._meta?.filterer?.folders?.includes($state.currentFolder)
      );
      return _orderBy(_folderNotes, ['createdAt'], [$state.sortDir]);
    }
  );

  let notesScrollerEl;
  let isNoteOpen = false;
  let expandAll = true;
  let expandedIndex;

  const observerParams = {
    fn: fixedHeightAdjuster,
    elements: [
      document.querySelector('#admin-menu'),
      document.querySelector('#lf-header'),
      document.querySelector('.lf-contact-detail__header'),
      controlsEl,
    ],
    adjustment: -1,
  };

  const scrollToBottom = () => {
    notesScrollerEl.parentElement.scrollTop = notesScrollerEl.offsetHeight;
  };

  $: {
    expandedIndex =
      $folderNotes && $state.sortDir === 'asc' ? $folderNotes.length - 1 : 0;
  }

  const onOpenNewNote = (evt) => {
    evt.stopPropagation();
    scrollToBottom();
    noteDrawerController.open(evt.detail);
  };

  const onEditNote = (evt) => {
    evt.stopPropagation();
    scrollToBottom();
    noteDrawerController.open({ note: toJSON(evt.detail) });
  };

  const onNoteDeleted = (evt) => {
    evt.stopPropagation();
    noteDrawerController.reset();
  };
</script>

<Tabs.Content {id} {tabsController} class="lf-contact-detail__notes-tab">
  <div
    use:sizeAdjusterObserver={observerParams}
    class:lf-contact-detail__notes-drawer-wrapper={true}
    class="flex flex-col">
    <NoteInPlaceDrawer
      bind:isOpen={isNoteOpen}
      on:noteSaved
      class="flex-1 min-h-0 max-w-screen-lg"
      tiptapWrapper$class="2xl:pr-12"
      drawerController={noteDrawerController}>
      {#if ($folderNotes ?? []).length > 0}
        <div class:lf-notes-list__timeline={true} transition:fade />
      {/if}
      <div
        bind:this={notesScrollerEl}
        class:lf-contact-detail__notes={true}
        class="flex flex-col items-start space-y-8 pl-4 pr-2 py-4 mt-2 md:pl-6 md:pr-4 md:py-6 lg:pl-8 lg:py-8">
        {#each $folderNotes as note, i (note.id)}
          <div transition:fade class="w-full">
            <ContactNote
              {contact}
              {note}
              {working}
              isNoteEditorOpen={isNoteOpen}
              on:editNote={onEditNote}
              on:noteDeleted
              on:noteDeleted={onNoteDeleted}
              expanded={expandAll || i === expandedIndex} />
          </div>
        {:else}
          <AddContactNoteButton
            on:addNote={onOpenNewNote}
            {contact}
            disabled={isNoteOpen}
            label="Add Note"
            size="sm"
            icon$class="w-4 h-4" />
        {/each}
      </div>
    </NoteInPlaceDrawer>
    <div
      bind:this={controlsEl}
      class:lf-contact-detail__notes-controls-wrapper={true}
      class="min-h-0 min-w-0 flex-shrink-0 bg-white elevation-4">
      <div
        class:lf-contact-detail__controls={true}
        class="relative h-12 max-w-screen-lg flex items-center px-4 md:px-8 lg:pl-6">
        <AddContactNoteButton
          on:addNote={onOpenNewNote}
          {contact}
          disabled={isNoteOpen}
          label="Add Note"
          icon$class="w-6 h-6" />
        <span class="flex-1" />
        <DebugMenu>
          <svelte:fragment slot="items">
            <DebugButton
              usage="link"
              eventKey="NOTES"
              on:click={() => console.log($folderNotes)}>
              Debug Notes
            </DebugButton>
          </svelte:fragment>
        </DebugMenu>
      </div>
    </div>
  </div>
</Tabs.Content>

<style lang="postcss">:global(.lf-contact-detail__notes-tab .lf-tiptap-note-wrapper){margin-bottom:1rem;max-height:calc(100% - 2rem);z-index:20}.lf-notes-list__timeline{--border-opacity:1;border-left:1px;border-color:#004aad;border-color:rgba(0,74,173,var(--border-opacity));border-style:dotted;bottom:0;height:100%;left:2rem;opacity:.3;position:absolute;z-index:-1}@media (min-width:768px){.lf-notes-list__timeline{left:119px;position:fixed}}@media (min-width:1024px){.lf-notes-list__timeline{left:127px}}</style>
