<svelte:options immutable="{true}" />

<!--
  @component
  Date Time Picker.
-->
<script>
  import { addDays, nextMonday, startOfDay } from 'date-fns';
  import { excludeProps, prefixFilter, uuid } from 'svelte-utilities';
  import { formatDateTime } from './datetime.js';
  import Button from '../buttons/Button.svelte';
  import Flatpickr from './Flatpickr.svelte';
  import Menu from '../menus/MenuV3.svelte';
  import Paper from '../paper/Paper.svelte';
  import { CalendarIcon, CloseIcon } from '../icons';

  let className = '';
  export { className as class };
  export let id = uuid();
  export let label = 'Date';
  export let value = undefined;
  export let timeEnabled = false;
  export let dateList = [];
  export let pickerOptions = {};
  export let flatpickr = undefined;
  export let element = undefined;

  export let onChange = undefined;
  export let closeMenu = undefined;
  export let toggleTime = undefined;

  export let tooltipParams = {};

  const _pickerOptions = {
    altFormat: 'Y-m-d',
    // minDate: 'today',
    inline: true,
    ...pickerOptions,
    onChange: onChange ? [onChange] : [],
  };

  const todayMidnight = startOfDay(new Date());
  const yesterdayMidnight = addDays(todayMidnight, -1);
  const tomorrowMidnight = addDays(todayMidnight, 1);
  const _nextWeek = nextMonday(todayMidnight);
  const _nextNextWeek = nextMonday(_nextWeek);
  const nextWeek =
    new Date(_nextWeek).getDate() === new Date(tomorrowMidnight).getDate()
      ? _nextNextWeek
      : _nextWeek;
  const oneWeek = addDays(todayMidnight, 7);

  const dateListOptions = {
    yesterday: {
      id: new Date(yesterdayMidnight).toISOString(),
      label: 'Yesterday',
    },
    today: {
      id: new Date(todayMidnight).toISOString(),
      label: 'Today',
    },
    tomorrow: {
      id: new Date(tomorrowMidnight).toISOString(),
      label: 'Tomorrow',
    },
    nextWeek: {
      id: new Date(nextWeek).toISOString(),
      label: 'Next Week',
      props: { tooltipContent: 'Set due date to Monday of next week' },
    },
    oneWeek: {
      id: new Date(oneWeek).toISOString(),
      label: 'One Week',
      props: { tooltipContent: 'Set due date to one week from today' },
    },
  };
  const _dateList = dateList.map((d) => {
    return typeof d === 'string' && dateListOptions[d] ? dateListOptions[d] : d;
  });

  let isOpen;
  let triggerLabel;

  $: {
    triggerLabel =
      // Note: timestamps may come through 'value' as a zero, however
      // flatpickr may change that to an empty string.
      value === 0 || value?.trim().length === 0
        ? `Add ${label}`
        : formatDateTime(value, _pickerOptions.altFormat);
  }

  // Note: closing menu when selecting from a predefined date/time, otherwise
  // if the user is changing hours/minutes the onChange gets triggered
  // and closes too soon.
  const onSetDate = (date) => {
    date === 0 ? flatpickr.clear() : flatpickr.setDate(date, true);
    closeMenu();
  };

  const onToggleTime = () => {
    timeEnabled = !timeEnabled;
    toggleTime();

    if (!timeEnabled && value?.length > 0) {
      value = new Date(startOfDay(new Date(value))).toISOString();
    }

    // @todo this doesn't seem to be updating the text below.
    _pickerOptions.altFormat = flatpickr?.config?.altFormat;
  };
</script>

<Menu
  id="{id}"
  closeOnClick="{false}"
  bind:isOpen
  bind:closeMenu
  bind:element
  tooltipParams="{{
    appendTo: () => element.closest('.dna-drawer, .dna-dialog'),
    getReferenceClientRect: null,
    ...tooltipParams,
  }}"
  class="dna-date-picker {className}"
  {...excludeProps($$restProps, [])}
>
  <slot name="trigger" slot="trigger">
    {#if !isOpen}
      <Button
        usage="link"
        size="xs"
        color="secondary"
        tooltipContent="Set or update the {label}"
        {...prefixFilter($$restProps, 'trigger$')}
      >
        <CalendarIcon slot="leftIcon" class="w-3.5 h-3.5" />
        {triggerLabel}
      </Button>
    {/if}
  </slot>
  <Paper class="dna-date-picker__list-wrapper p-1 elevation-6">
    <div
      class:dna-date-picker__title="{true}"
      class="flex items-center justify-between text-sm uppercase text-gray-500 leading-none my-2 px-3"
    >
      <span>Select {label}</span>
      <Button usage="icon" size="xs" color="gray" on:click="{closeMenu}">
        <CloseIcon class="w-5 h-5" />
      </Button>
    </div>
    {#if _dateList.length > 0}
      <div
        class:dna-date-picker__list="{true}"
        class="flex items-center space-x-2 px-1 my-2"
      >
        {#each _dateList as date (date.id)}
          <Button
            usage="link"
            size="xs"
            color="secondary"
            shape="tile"
            on:click="{() => onSetDate(date.id)}"
            {...date?.props}
          >
            {date.label}
          </Button>
        {/each}
        <Button
          usage="link"
          size="xs"
          color="gray"
          shape="tile"
          tooltipContent="Remove the date"
          on:click="{() => onSetDate(0)}"
        >
          No Date
        </Button>
      </div>
    {/if}
    <Flatpickr
      bind:instance="{flatpickr}"
      bind:toggleTime
      id="dna-date-picker__custom-due-date"
      label="{label}"
      options="{_pickerOptions}"
      bind:value
      positionHack="{true}"
    />
    <div class:dna-date-picker__add-time="{true}">
      <Button
        usage="link"
        size="xs"
        color="secondary"
        on:click="{onToggleTime}"
      >
        {timeEnabled ? '-Date Only' : '+Include Time'}
      </Button>
    </div>
  </Paper>
</Menu>

<style lang="postcss">:global(.dna-date-picker__list-wrapper label[for=dna-date-picker__custom-due-date]){display:none}:global(.dna-date-picker__list-wrapper .dna-date-picker__add-time){display:flex;flex-direction:column;margin-bottom:.5rem;margin-top:.5rem;padding-left:.25rem;padding-right:.25rem;padding-top:.25rem}</style>
