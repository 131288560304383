<svelte:options immutable="{true}" />

<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { deepGet, excludeProps, prefixFilter, wait } from 'svelte-utilities';
  import {
    Button,
    MediaFolderIcon,
    ProgressLinearV2 as ProgressLinear,
    Tooltip,
  } from 'svelte-tailwind-components';
  import AttachmentsButton from './buttons/AttachmentsButton.svelte';
  import CloseTooltipButton from '../app/buttons/CloseTooltipButton.svelte';
  import LessonMediaList from './LessonMediaList.svelte';

  let className = '';
  export { className as class };
  export let lesson;
  export let uppy = undefined;
  export let label = '';
  export let working = undefined;
  export let tooltipParams = { placement: 'auto' };
  export let showControlRow = true;
  export let showOnCreate = false;

  const dispatch = createEventDispatcher();

  let timeoutId;
  let isOpen;

  const showOnCreateHandler = async () => {
    if (showOnCreate && (lesson?.numAttachments > 0 || lesson?.numRecordings > 0)) {
      await wait(1000);
      (lesson?.numAttachments > 0 || lesson?.numRecordings > 0) && openTooltip();
    }
  };

  const onLessonChange = () => {
    clearTimeout(timeoutId);
    lastLessonId = lesson.id;
    closeTooltip();
    timeoutId = setTimeout(showOnCreateHandler, 500);
  };

  let iconClass = 'w-5 h-5';

  let openTooltip;
  let closeTooltip;

  // The way this is currently used, the component stays rendered and the
  // lesson changes. This means we cannot use onMount to open the tooltip
  // if showOnCreate is set.
  let lastLessonId;

  $: {
    iconClass = deepGet(prefixFilter($$restProps, 'icon$', {}), 'class', iconClass);
  }

  const onAttachmentsClick = (evt) => {
    evt.stopPropagation();
    dispatch('openAttachments');
  };

  afterUpdate(() => {
    lesson?.id > 0 && lastLessonId !== lesson.id && onLessonChange();
  });
</script>

<Tooltip
  tooltipParams="{tooltipParams}"
  bind:isOpen
  bind:closeTooltip
  bind:openTooltip
  disableBodyScroll="{!window.matchMedia('(min-width: 768px)').matches}"
  class="{className}"
>
  <slot>
    <Button usage="icon" color="secondary" size="lg">
      <MediaFolderIcon
        slot="leftIcon"
        class="{iconClass}"
        {...excludeProps(prefixFilter($$restProps, 'icon$'), ['class'])}
      />
    </Button>
  </slot>
  <div slot="tooltip" class="flex flex-col divide-y divide-gray-100 max-w-screen-sm">
    <div class:lf-lesson-media-list-wrapper="{true}">
      <LessonMediaList lesson="{lesson}" />
    </div>
    {#if showControlRow && uppy}
      <div class="flex justify-between items-center p-2 pl-0">
        <AttachmentsButton
          on:click="{onAttachmentsClick}"
          working="{working}"
          disabled="{!uppy || $uppy.maxFilesReached}"
          label="{label}"
          size="sm"
          color="secondary"
          icon$class="w-6 h-6"
        />
        <span class="flex-1 min-w-0"></span>
        <CloseTooltipButton on:closeTooltip="{closeTooltip}" on:closeTooltip />
        <ProgressLinear
          working="{working}"
          class="absolute bottom-0 left-0 w-full"
          color="secondary"
          settings="{{ indeterminate: true }}"
          {...excludeProps(prefixFilter($$restProps, 'progress$'), [])}
        />
      </div>
    {/if}
  </div>
</Tooltip>

<style lang="postcss">.lf-lesson-media-list-wrapper{max-height:calc(100vh - var(--lf-header-height, 71px)*3);overflow-y:auto}</style>
