<script>
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps } from 'svelte-utilities';

  let className = '';
  export {className as class};

  export let shape = 'rounded';
  export let flat = false;

  let props = excludeProps($$props, ['use', 'class', 'rounded', 'flat', 'outlined']);
</script>

<span
  class:dna-button-group={true}
  class:elevation-2={!flat}
  class:rounded-md={shape == 'rounded'}
  class:rounded-full={shape == 'pill'}
  class="
    relative z-0 inline-flex items-center {className}"
  {...props}>
  <slot></slot>
</span>

<style lang="postcss">.dna-button-group :global(.dna-button):not(:focus){box-shadow:none}.dna-button-group :global(.dna-button):focus{z-index:10}.dna-button-group :global(.dna-button):not(:first-of-type){border-bottom-left-radius:0;border-top-left-radius:0;margin-left:-1px}.dna-button-group :global(.dna-button):not(:last-of-type){border-bottom-right-radius:0;border-top-right-radius:0}</style>
