<script>
  import { getDate, getTime, getTimezoneIdentifier } from 'svelte-utilities';
  import _get from 'lodash-es/get.js';

  export let rowData = {};
  export let column = {};
  export let meta = {};

  $: data = _get(rowData, column.data, '');

  // $: if (data) {
  //   date = !isNaN(data) ? data * 1000 : data;
  // } else {
  //   date = 0;
  // }
  $: date = !data ? 0 : (!isNaN(data) ? data * 1000 : data);

  // @todo handle date / component config options.
</script>

{#if date}
<span class="lf-datatable-date-wrapper">
  <span class="lf-datatable-date__date">
    {getDate(date)}
  </span>
  <span class="lf-datatable-date__time">
    {getTime(date)} {getTimezoneIdentifier(date)}
  </span>
</span>
{/if}

<style lang="postcss">.lf-datatable-date__time{white-space:nowrap}</style>
