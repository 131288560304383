<script>
  /*global Drupal*/
  import _get from 'lodash-es/get.js';
  import _orderBy from 'lodash-es/orderBy.js';
  import {
    Button,
    Cards,
    Dialog,
    Link,
    Menu,
    ProgressLinear,
    TagIcon,
  } from 'svelte-tailwind-components';
  import { classesAPI as api } from '../../../components/groupClasses/groupClasses.api.js';
  import { programsAPI } from '../../../components/programs/programs.api.js';
  import { upcomingFilter } from '../../../components/groupClasses/groupClasses.utils.js';
  import ClassNavigator from '../../../components/groupClasses/ClassNavigator.svelte';
  import ClassNavigatorItem from '../../../components/groupClasses/ClassNavigatorItem.svelte';

  let isViewAllOpen = false;
  let isFindClassesOpen = false;

  let pageUser = _get(Drupal.settings, 'lf_svelte.pageUser');
  let currentUser = _get(Drupal.settings, 'lf_svelte.currentUser');
  let isActiveUser = pageUser.uid === currentUser.uid;

  let upcomingLimit = 3;

  let promise = api.getMyClasses(pageUser.uid, {
    attachData: {
      attachments: true,
      categories: true,
      groups: {
        fields: ['id', 'title', 'image'],
        attach: ['images'],
      },
      membership: true,
      product_displays: {
        fields: ['id', 'title', 'buckets', 'image'],
        attach: {
          buckets: true,
          images: true,
        },
      },
      recordings: true,
    },
  });
  let programsPromise = programsAPI.getAccountPrograms(pageUser.uid, {
    fields: ['id', 'title', 'license_date.start', 'license_date.end'],
    orderBy: [['title', 'asc']],
    attachData: ['urls'],
  });
</script>

<Cards.Card class="lf-lessongroups-account-group-classes">
  <Cards.Header class="px-4">
    <div class="flex items-center space-x-2">
      <TagIcon class="w-6 h-6 text-gray-400" />
      <h3 class="m-0">
        <Link href="{isActiveUser ? '/courses-classes' : `/user/${pageUser.uid}/courses-classes`}"
          >Group Classes</Link
        >
      </h3>
    </div>
    {#await programsPromise then programsRes}
      <Menu
        bind:isOpen="{isFindClassesOpen}"
        class="flex items-center ml-auto lf-lessongroups-account-group-classes__find-menu"
      >
        <div class="flex flex-col py-1">
          <Button usage="link" size="sm" color="secondary" href="/masterclass">
            Explore Classes
          </Button>
          <Button
            usage="link"
            size="sm"
            color="secondary"
            href="{isActiveUser ? '/courses-classes' : `/user/${pageUser.uid}/courses-classes`}"
            >My Classes & Events</Button
          >
          {#each programsRes.data as program (program.id)}
            <Button
              usage="link"
              size="sm"
              color="secondary"
              label$class="max-w-xs truncate"
              href="{program.url}"
            >
              {program.title}
            </Button>
          {/each}
        </div>
      </Menu>
    {/await}
  </Cards.Header>
  <div class="flex flex-col lf-lessongroups-account-group-classes__content">
    {#await promise}
      <div class="px-4 py-6 sm:px-6">
        <ProgressLinear settings="{{ indeterminate: true }}" />
      </div>
    {:then groupClassesRes}
      {#each groupClassesRes.data
        .filter(upcomingFilter(60 * 60))
        .slice(0, upcomingLimit) as groupClass (groupClass.id)}
        <ClassNavigatorItem groupClass="{groupClass}" class="px-4 py-2 sm:px-6 hover:bg-gray-50" />
      {:else}
        <div class="flex flex-col items-center justify-center px-4 py-4 sm:px-6">
          <Link href="/masterclass">Explore Classes</Link>
          <Link href="{isActiveUser ? '/courses-classes' : `/user/${pageUser.uid}/courses-classes`}"
            >My Classes & Events</Link
          >
        </div>
      {/each}
      {#await programsPromise then programsRes}
        <div
          class="flex flex-col items-center justify-center px-4 py-4 sm:px-6 border-t border-gray-100"
        >
          {#each programsRes.data as program (program.id)}
            <Link class="truncate max-w-full" href="{program.url}">
              {program.title}
            </Link>
          {/each}
        </div>
      {/await}
    {:catch}
      <div class="px-4 py-4 text-danger-700 sm:px-6">
        An error occurred loading the class list. Contact Lessonface Support for assistance.
      </div>
    {/await}
  </div>
  {#await promise then groupClassesRes}
    {#if groupClassesRes.data.length > groupClassesRes.data
        .filter(upcomingFilter)
        .slice(0, upcomingLimit).length}
      <Cards.Actions>
        <Button on:click="{() => (isViewAllOpen = true)}" usage="text" color="secondary" size="sm"
          >View All</Button
        >
      </Cards.Actions>
    {/if}
  {/await}
</Cards.Card>

<Dialog
  id="lf-lessongroups-account-all-group-classes"
  includeCloseButton="{false}"
  bind:isOpen="{isViewAllOpen}"
>
  <ClassNavigator
    promise="{promise}"
    settings="{{ title: 'All Classes', hidden_elements: ['disclaimer'] }}"
    class="w-full max-h-full sm:max-w-screen-sm sm:max-h-9/10"
  >
    <span slot="actions">
      <Button on:click="{() => (isViewAllOpen = false)}" usage="text" size="sm">Close</Button>
    </span>
  </ClassNavigator>
</Dialog>

<style lang="postcss">:global(.lf-lessongroups-account-group-classes .lf-class-navigator-item){padding-left:1rem;padding-right:1rem}:global(.lf-lessongroups-account-group-classes .lf-class-navigator-item__meta){font-size:.75rem}:global(.lf-lessongroups-account-group-classes .lf-class-navigator-item__title){font-size:.875rem}</style>
