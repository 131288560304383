<script>
  // Svelte imports, utilities, other components, component helpers.
  import { deepGet, excludeProps, prefixFilter } from 'svelte-utilities';
  import Tooltip from '../tooltip/Tooltip.svelte';
  import Button from '../buttons/Button.svelte';
  import MenuIcon from '../icons/MenuIcon.svelte';

  let className = '';
  export { className as class };

  export let disabled = false;
  export let menuParams = {};
  export let isMenuOpen = undefined;

  export const openMenu = () => openTooltip && openTooltip();
  export const closeMenu = () => closeTooltip && closeTooltip();

  const leftJustifyButtons = deepGet(menuParams, 'leftJustifyButtons', true);

  const _defaulTooltipParams = {
    hideOnClick: true,
    interactiveBorder: 2,
    placement: 'auto',
    theme: 'dna-menu',
  };

  let tooltipShowing;
  let openTooltip;
  let closeTooltip;

  let tooltipParams = {
    ..._defaulTooltipParams,
    ...deepGet(menuParams, 'tooltip', {}),
  };

  $: isMenuOpen = tooltipShowing;

  const onContentClick = () =>
    deepGet(tooltipParams, 'hideOnClick') === true && closeTooltip();
</script>

<Tooltip
  class="dna-menu {className}"
  tooltipParams="{tooltipParams}"
  bind:tooltipShowing
  bind:openTooltip
  bind:closeTooltip
  {...excludeProps($$restProps, ['tooltipParams', 'trigger$', 'content$'])}
>
  <slot name="trigger">
    <Button
      disabled="{disabled}"
      on:click
      usage="icon"
      size="sm"
      flat
      {...prefixFilter($$restProps, 'trigger$')}
    >
      <slot name="icon" slot="leftIcon">
        <MenuIcon class="w-5 h-5" />
      </slot>
    </Button>
  </slot>
  <div
    slot="tooltip"
    on:click="{onContentClick}"
    class:dna-menu__content="{true}"
    class:dna-menu__content--btn-left="{leftJustifyButtons}"
    class:bg-white="{true}"
    class:elevation-4="{true}"
    {...excludeProps(prefixFilter($$restProps, 'content$'), [])}
  >
    <slot />
  </div>
</Tooltip>

<style lang="postcss">.dna-menu__content--btn-left :global(.dna-button){justify-content:flex-start}:global(.tippy-box[data-theme~=dna-menu] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=dna-menu] .tippy-content){margin:1rem;padding:0}</style>
