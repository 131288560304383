<svelte:options immutable="{true}" />

<script>
  import { onMount } from 'svelte';
  import {
    excludeProps,
    fixedHeightAdjuster,
    sizeAdjusterObserver,
    toJSON,
  } from 'svelte-utilities';
  import { GroupMessageIcon } from 'svelte-tailwind-components';
  import { createMessageDrawerController, threadMarkReadFx } from './messages.controller.js';
  import ThreadDetailControlsRow from './ThreadDetailControlsRow.svelte';
  import ThreadLastDateTime from './ThreadLastDateTime.svelte';
  import ThreadAttachmentsList from './ThreadAttachmentsList.svelte';
  import MessageInPlaceDrawer from './MessageInPlaceDrawer.svelte';

  import BackButton from '../app/BackButton.svelte';
  import FlagMessageButton from './buttons/FlagMessageButton.svelte';
  import ThreadSubject from './ThreadSubject.svelte';
  import MessageItem from './MessageItem.svelte';
  import MiniContactCard from '../contacts/MiniContactCard.svelte';
  import ContactExtraLinks from '../contacts/ContactExtraLinks.svelte';
  import DebugMenu from '../app/DebugMenu.svelte';
  import DebugButton from '../button/DebugButton.svelte';

  let className = '';
  export { className as class };
  export let thread;
  export let app;
  export let sizerTargetEl = undefined;

  const messageDrawerController = createMessageDrawerController('threadDetail');
  const sortDirMap = { 0: 'asc', 1: 'desc' };
  const { itemsListManager } = app;

  let articleEl;

  let isReplyOpen = false;
  let sortDesc = false;
  let sortDir;
  let expandedIndex;
  let expandAll = false;

  let initialized;
  let observerParams;

  $: {
    if (thread) {
      sortDir = sortDirMap[+sortDesc];
      expandedIndex = sortDir === 'asc' ? thread.numMessages - 1 : 0;

      if (thread.isUnread && thread.canMarkRead) {
        threadMarkReadFx({ thread: toJSON(thread) });
      }
    }
  }

  itemsListManager.events.clearSelectedItem.watch(() => messageDrawerController.reset());

  const onReplyToMessage = (evt) => messageDrawerController.open(evt.detail);
  const onReplyToThread = (evt) => messageDrawerController.open(evt.detail);
  const onSendMessage = (evt) => messageDrawerController.open(evt.detail);
  const onAddNote = (evt) => app.noteDrawerController.open(evt.detail);

  onMount(() => {
    observerParams = {
      fn: fixedHeightAdjuster,
      elements: [
        document.querySelector('#lf-header'),
        articleEl.querySelector('.lf-message-detail__header'),
      ],
      adjustment: -1,
      observedEl: articleEl,
    };
    initialized = true;
  });
</script>

<article
  bind:this="{articleEl}"
  class:hidden="{!thread?.id}"
  class:lf-message-detail="{true}"
  class="lg:h-full {className}"
  {...excludeProps($$restProps, [])}
>
  <div class:lf-message-detail__header="{true}" class="bg-secondary-100 elevation-4 z-10">
    <div
      bind:this="{sizerTargetEl}"
      class:lf-message-detail__header-content="{true}"
      class="relative px-4 max-w-screen-lg 2xl:ml-8 pt-2 pb-4 sm:px-6 md:pb-6 md:pt-0 lg:px-8 lg:pt-4 flex flex-col justify-end flex-shrink-0 lg:h-32"
    >
      <div class="-ml-2 lg:hidden">
        <BackButton on:viewList flat usage="link" color="primary">Messages</BackButton>
      </div>
      <ThreadLastDateTime
        thread="{thread}"
        class="mt-2 flex-1 min-w-0 text-sm leading-none text-gray-600 lg:flex-initial lg:space-x-2 xl:text-base font-inter"
      />
      <h1 class="m-0 mt-1 md:mt-2 text-base leading-none flex space-x-2">
        {#if thread?.isGroupMessage}
          <GroupMessageIcon class="w-5 h-5 text-secondary-400" />
        {/if}
        <ThreadSubject
          thread="{thread}"
          class="text-xl leading-none md:text-2xl text-secondary-500"
        />
      </h1>
      {#key thread?.id}
        {#if thread?.id}
          <div class="absolute top-3 right-6 text-right">
            {#if thread?.otherParty?.isInstructor && !thread.isGroupMessage}
              <ContactExtraLinks contact="{thread.otherParty}" />
            {/if}
          </div>
          <div
            class:top-3="{!thread?.otherParty?.isInstructor || thread.isGroupMessage}"
            class:top-12="{thread?.otherParty?.isInstructor && !thread.isGroupMessage}"
            class="absolute pt-1 right-6 text-right text-xs leading-none text-secondary-500 opacity-50"
          >
            #{thread?.id}
          </div>
        {/if}
      {/key}
      <div class="absolute top-8 right-6">
        <FlagMessageButton thread="{thread}" />
      </div>
    </div>
    <slot name="working" />
  </div>

  {#if initialized && thread}
    <div
      use:sizeAdjusterObserver="{observerParams}"
      class:lf-message-detail__messages-wrapper="{true}"
      class="flex flex-col"
    >
      <MessageInPlaceDrawer
        bind:isOpen="{isReplyOpen}"
        class="flex-1 min-h-0 max-w-screen-lg {thread?.numAttachments === 0
          ? 'xl:mt-2'
          : ''} 2xl:ml-8"
        drawerController="{messageDrawerController}"
      >
        {#if thread.hasDetail}
          <div
            class:lf-message-detail__messages="{true}"
            class="pl-4 pr-4 pt-2 divide-y divide-gray-50 md:pl-6 lg:pl-7"
          >
            {#each thread.getSortedMessages(sortDir) as message, i (message.id)}
              <MessageItem
                message="{message}"
                thread="{thread}"
                on:replyToMessage="{onReplyToMessage}"
                isReplyOpen="{isReplyOpen}"
                expanded="{expandAll || i === expandedIndex}"
              />
            {/each}
          </div>
          <ThreadAttachmentsList
            thread="{thread}"
            label="Attachments"
            class="elevation-4 max-w-full m-4 md:ml-8"
          />
        {/if}
      </MessageInPlaceDrawer>

      <div
        class:lf-message-detail__controls-wrapper="{true}"
        class="min-h-0 min-w-0 flex-shrink-0 bg-white elevation-4"
      >
        <ThreadDetailControlsRow
          thread="{thread}"
          on:replyToMessage="{onReplyToMessage}"
          on:replyToThread="{onReplyToThread}"
          on:toggleSort="{() => (sortDesc = !sortDesc)}"
          on:toggleExpandAll="{() => (expandAll = !expandAll)}"
          class="relative max-w-screen-lg px-4 h-12 md:pl-6 md:pr-6 2xl:ml-0 z-20"
          reply$icon$class="hidden md:block md:w-6 md:h-6"
          attachments$icon$class="w-6 h-6"
          archive$class="hidden md:inline-flex"
          archive$icon$class="w-6 h-6"
          settings$class="hidden md:inline-block"
          settings$trigger$color="secondary"
          settings$triggerIcon$class="w-6 h-6"
          settings$openFrom="bottom right"
          isReplyOpen="{isReplyOpen}"
          sortDesc="{sortDesc}"
          expandAll="{expandAll}"
        >
          <svelte:fragment slot="inner">
            {#if thread.otherParty?.isContact && (isReplyOpen || !thread.isGroupMessage)}
              <div class="flex items-center space-x-2 md:ml-8">
                <div class="md:rounded-md xl:hover:elevation-2">
                  <MiniContactCard
                    contact="{thread.otherParty}"
                    picture$size="xs"
                    contactname$class="hidden md:block"
                    timezone$class="hidden md:flex lg:hidden xl:flex"
                    on:addNote="{onAddNote}"
                    on:sendMessage="{onSendMessage}"
                  />
                </div>
                <ContactExtraLinks contact="{thread.otherParty}" />
              </div>
            {/if}
            <span class="flex-1"></span>
          </svelte:fragment>
          <svelte:fragment slot="end">
            <div class="hidden md:block">
              <DebugMenu app="{app}">
                <svelte:fragment slot="items">
                  <DebugButton
                    usage="link"
                    eventKey="THREAD"
                    on:click="{() => console.log(thread)}"
                  >
                    Debug Thread
                  </DebugButton>
                </svelte:fragment>
              </DebugMenu>
            </div>
          </svelte:fragment>
        </ThreadDetailControlsRow>
      </div>
    </div>
  {/if}
</article>

<style lang="postcss">.lf-message-detail{min-height:calc(100vh - var(--lf-header-height, 71px))}</style>
