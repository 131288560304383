<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  // @todo var to make checkbox (in)visible?
  export let option = {};
  // @todo highlight searchText.
  export let searchText = '';
  export let highlighted = false;
  export let meta = { multiple: false };

  export let textCallback = (option) => { option.text };
  export let valueCallback = (option) => { option.value };

  let className = '';
  export { className as class };

  let displayText = textCallback(option);

  function onClick() {
    if (!option.checked) {
      dispatch('optionSelected', option);
    }
    else if (meta.multiple && option.checked) {
      dispatch('optionDeselected', option);
    }
  }

  $: lcSearchText = searchText.trim().toLowerCase();
  $: {
    displayText = textCallback(option);

    if (lcSearchText.length > 0) {
      let searchTextIndexStart = textCallback(option).trim().toLowerCase().indexOf(lcSearchText);
      let searchTextIndexEnd = searchTextIndexStart + lcSearchText.length;

      if (searchTextIndexStart > -1) {
        displayText = textCallback(option).substring(0, searchTextIndexStart)
          + '<span class="lf-svelte-autocomplete-option__text--highlighted">'
          + textCallback(option).substring(searchTextIndexStart, searchTextIndexEnd)
          + '</span>'
          + textCallback(option).substring(searchTextIndexEnd);
      }
    }
  }
</script>

<label
  class:lf-svelte-autocomplete-option--multiple={meta.multiple}
  class:lf-svelte-autocomplete-option--highlighted={highlighted}
  class:lf-svelte-autocomplete-option--checked={option.checked}
  class="lf-svelte-autocomplete-option {className}"
  on:click={onClick}
  >
  <span class="lf-svelte-autocomplete-option__text">{@html displayText}</span>
</label>

<style lang="postcss">.lf-svelte-autocomplete-option{margin:0;padding:.25em .5em;white-space:nowrap}:global(.lf-svelte-autocomplete-option__text--highlighted){font-weight:700}</style>
