<script>
  // @todo cannot make slots for SearchInput, etc. until we can figure out
  // out best to handle events from the slotted components.
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import SearchButton from './SearchButton.svelte';
  import SearchInput from './SearchInputV2.svelte';

  let className = '';
  export { className as class };
  export let searchText = '';
  export let searcher;
  export let shape = 'pill';
  export let fullsize = false;

  let inputEl;
  let focused;
  let expanded;

  $: {
    expanded = !fullsize && (focused || searchText.length > 0);
  }

  const onClick = (evt) => {
    searchText.length === 0 && !fullsize && !expanded && inputEl.focus();
    [0, 1].includes(evt.which) &&
      searchText.length > 0 &&
      searcher &&
      searcher.set(searchText);
  };

  // @todo add a clickOutside handler as well to close an empty search
  // box and stop any clicks on links, etc. that can break the user
  // experience.
  const onBlur = () => {
    focused = false;
    searchText.length === 0 && searcher.clear();
  };

  const onCleared = (evt) => evt.detail.length === 0 && inputEl.blur();
</script>

<div
  class:dna-search="{true}"
  class:dna-search--fullsize="{fullsize}"
  class:dna-search--expanded="{expanded}"
  class="relative flex justify-end items-center dna-search--{shape} {className}"
  {...excludeProps($$restProps, [
    'input$',
    'button$',
    'buttonWrapper$',
    'expander$',
  ])}
>
  <div
    class:dna-search__input-wrapper="{true}"
    class:absolute="{true}"
    class:right-0="{true}"
    class:h-full="{true}"
    class:w-full="{fullsize}"
  >
    <SearchInput
      bind:el="{inputEl}"
      searcher="{searcher}"
      bind:value="{searchText}"
      on:focus
      on:focus="{() => (focused = true)}"
      on:blur
      on:blur="{onBlur}"
      on:keydown
      on:input
      on:cleared="{onCleared}"
      {...prefixFilter($$restProps, 'input$')}
    />
  </div>
  <SearchButton
    usage="icon"
    color="secondary"
    class="mr-px"
    on:click
    on:click="{onClick}"
    {...prefixFilter($$restProps, 'button$')}
  />
</div>

<style lang="postcss">.dna-search :global(input[type=search]){background-color:transparent;border-width:0;font-size:1rem;height:100%;line-height:1rem;outline:2px solid transparent;outline-offset:2px;padding:.75rem 0;transition-timing-function:cubic-bezier(.4,0,.2,1);transition:all .3s;width:0}.dna-search--pill,.dna-search--rounded{border-radius:9999px}.dna-search--pill :global(input[type=search]){border-radius:9999px}.dna-search--rounded :global(input[type=search]){border-radius:.5rem}.dna-search--fullsize :global(input[type=search]){padding-left:1rem;padding-right:2.5rem;width:100%}.dna-search--expanded :global(input[type=search]){--bg-opacity:1;background-color:#fff;background-color:rgba(255,255,255,var(--bg-opacity));box-shadow:0 3px 5px -1px rgba(var(--boxShadowColor,0,0,0),.2),0 6px 10px 0 rgba(var(--boxShadowColor,0,0,0),.14),0 1px 18px 0 rgba(var(--boxShadowColor,0,0,0),.12);padding-left:1rem;padding-right:2.5rem;width:20rem}.dna-search--expanded :global(.dna-search__button){margin-right:.5rem}.dna-search>:global(.dna-button:focus){--border-opacity:0;outline:2px solid transparent;outline-offset:2px}</style>
