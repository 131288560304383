<script>
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps } from 'svelte-utilities';

  let className = '';
  export { className as class };

  export let id;
</script>

<label
  for="{id}"
  class="relative inline-block flex justify-start items-center dna-form-field-label {className}"
  {...excludeProps($$restProps, ['use'])}
>
  <slot />
</label>

<style lang="postcss">:global(.dna-form-field--checkbox) .dna-form-field-label--floatable,:global(.dna-form-field--radio) .dna-form-field-label--floatable{align-items:center;display:inline-flex}:global(.dna-form-field--checkbox) .dna-form-field-label--floatable__text,:global(.dna-form-field--radio) .dna-form-field-label--floatable__text{margin-left:.5rem}</style>
