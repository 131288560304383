<script>
  /*global Drupal*/
  import _get from 'lodash-es/get.js';

  import {
    getCardsOnFile,
    getMySubscriptions
  } from '../../../components/subscriptions/subscriptions.api.js';

  import {
    Button,
    ProgressLinear,
  } from 'svelte-tailwind-components';
  import Subscription from '../../../components/subscriptions/Subscription.svelte';

  let pageUserUID = _get(Drupal.settings, 'lf_svelte.pageUser.uid', 0);
  let promise = getMySubscriptions(pageUserUID);
  let cardsOnFile = getCardsOnFile(pageUserUID);
  let subscriptions = [];

  promise.then(res => {
    subscriptions = res;

    if (subscriptions.length > 0) {
      cardsOnFile.then(data => {
        if (data.length === 0) {
          window.snacksQueue.add({
            label: 'No Card on File',
            message: 'We do not have any cards on file to use with the subscription.',
            type: 'danger',
            settings: {
              persistent: true,
            },
            actions: [
              {
                label: 'Manage Cards',
                onClick: () => window.location.href = '/cards',
              }
            ],
          });
        }
      });
    }
  });

  function onCardChanged(evt) {
    if (evt.detail.action == 'added') {
      // @todo turn to store so it propogates back down components.
      cardsOnFile = getCardsOnFile(pageUserUID);
    }
  }

  function onCanceled(evt) {
    let subId = evt.detail.subscriptionId || 0;

    if (subId) {
      subscriptions = subscriptions.filter(s => s.id != subId);
    }
  }
</script>

<div class="flex flex-wrap items-start justify-start lf-my-subscriptions flex-gap-8">
  {#await promise}
    <ProgressLinear settings={{ indeterminate: true }} />
  {:then}
    {#each subscriptions as subscription (subscription.id)}
      <Subscription
        {subscription}
        bind:cardsOnFile
        on:canceled={onCanceled}
        on:cardChanged={onCardChanged}
        />
    {:else}
      <div class="p-4 lf-subscriptions__empty">
        <div>You have no subscriptions at this time.</div>
        <div>
          <Button
            usage="button"
            href="/music-lessons">
            Find a Teacher
          </Button>
        </div>
      </div>
    {/each}
  {:catch}
    <div class="lf-subscriptions__error">
      An error occurred loading your subscriptions. Please contact Lessonface Support.
    </div>
  {/await}
</div>

<style lang="postcss">.lf-my-subscriptions :global(.lf-subscription){max-width:calc(50% - var(--gap))}</style>
