<script>
  /*global Drupal*/
  import { onMount } from 'svelte';

  import {
    pendingColumnsDefault,
    pendingConfigDefault,
    paymentsColumnsDefault,
    paymentsConfigDefault
  } from '../../../components/instructorPayments/datatable/datatable.config.js';
  import {
    getPayments,
    getPendingPaymentItems
  } from '../../../components/instructorPayments/instructorPayments.api.js';

  import _findIndex from 'lodash-es/findIndex.js';
  import _get from 'lodash-es/get.js';

  import PaymentsNav from '../../../components/instructorPayments/PaymentsNav.svelte';
  import Spinner from '../../../components/spinner/Spinner.svelte';
  import DataTable from '../../../components/datatable/DataTable.svelte';

  let className = '';
  export { className as class };

  let pageUserUID = _get(Drupal.settings, 'lf_svelte.pageUser.uid', 0);
  let activePaymentMethod = Drupal.settings.lf_instructor_payments.activePaymentMethod;
  let pendingPaymentsDesc = _get(Drupal.settings, 'lf_instructor_payments.pendingPaymentsDesc', '');
  let data = {};
  let isInitialized = false;

  let pendingColumns = [...pendingColumnsDefault];
  let pendingConfig = {...pendingConfigDefault};
  let paymentsColumns = [...paymentsColumnsDefault];
  let paymentsConfig = {...paymentsConfigDefault};

  pendingColumns.splice(_findIndex(pendingColumns, { name: 'changed' }));
  paymentsColumns.splice(_findIndex(paymentsColumns, { name: 'created' }));

  async function initialize() {
    try {
      let params = { accountId: pageUserUID };
      data.payments = await getPayments(params);
      data.pendingPayments = await getPendingPaymentItems(params);
      return Promise.resolve(data);
    }
    catch (err) {
      return false;
    }
    finally {
      isInitialized = true;
    }
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  class="
    lf-instructor-payments
    {className}
    ">
  {#if isInitialized}
    <PaymentsNav
      class="lf-instructor-payments__nav hoverable-on"
      tabId="{!!+activePaymentMethod.length ? 'payments' : 'setup'}"
    />

    <DataTable
      id="instructorPendingPayments"
      title="Pending Payment Items"
      class="lf-instructor-payments__pending-payments hoverable-on"
      data={data.pendingPayments}
      config={pendingConfig}
      columns={pendingColumns}>
      <div slot="header" class="lf-instructor-payments__header__description">
        {pendingPaymentsDesc}
      </div>
    </DataTable>

    <DataTable
      id="instructorPayments"
      title="Payments"
      class="lf-instructor-payments__payments hoverable-on"
      data={data.payments}
      config={paymentsConfig}
      columns={paymentsColumns}/>
  {:else}
    <Spinner size="48" variation="dark"/>
  {/if}
</div>

<style lang="postcss">.lf-instructor-payments{min-height:64px}.lf-instructor-payments__error{border:1px dotted #b94a48;color:#b94a48;font-size:.875rem;margin:1em 0;padding:.5em 1em}.lf-instructor-payments__header__description{flex:0 0 100%}.lf-instructor-payments :global(.lf-instructor-payments__nav){background-color:#fff;display:inline-block;margin:0 auto 20px 20px;min-width:300px;padding:.5em 0;width:calc(100% - 40px)}@media (min-width:768px){.lf-instructor-payments :global(.lf-instructor-payments__nav){display:block;margin:0 0 20px;padding:.5em;width:unset}}.lf-instructor-payments :global(ul.lf-instructor-payments__nav>li){float:unset}@media (min-width:768px){.lf-instructor-payments :global(ul.lf-instructor-payments__nav>li){float:left}}.lf-instructor-payments :global(.lf-instructor-payments__payments),.lf-instructor-payments :global(.lf-instructor-payments__pending-payments){background-color:#fff;font-size:.875rem;margin-bottom:20px;padding:1em}</style>
