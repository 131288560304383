<svelte:options immutable="{true}" />

<script>
  // @see https://svelte.dev/repl/1c36db7c1e7e4ef2bfb04874321412e5?version=3.20.1
  import { onMount, tick } from 'svelte';
  import { derived, writable } from 'svelte/store';
  import {
    debounce,
    deepGet,
    excludeProps,
    prefixFilter,
    useActions,
  } from 'svelte-utilities';

  export let use = [];
  let className = '';
  export { className as class };

  export let items;
  export let rowHeight = undefined;
  export let idProp = 'id';
  export let disableScroll = undefined;

  export let indexStart = 0;
  export let indexEnd = 0;
  export let visibleRows = [];

  let viewportEl;
  let viewportHeight = 0;
  let initialized;

  // In case the prop is passed in quotes we need to make sure it is an
  // integer.
  if (rowHeight) {
    rowHeight = parseInt(rowHeight);
  }

  export const scrollToIndex = async (index, opts = {}) => {
    if (!viewportEl) {
      return;
    }

    // The user is scrolling to the indexStart so clamp that value from zero
    // to the numItems - pageSize (since that will still show the last item).
    const _index = Math.min(
      Math.max(index, 0),
      $localState.numItems - $localState.pageSize
    );

    if (_index === $state.start) {
      return;
    }

    // Note: Delta > 0 means paging down, while a negative delta means
    // paging up.
    let _start = $state.start;
    let delta = _index - _start;
    let top;

    // If the distance to scroll is larger than X page sizes, then "jump" to
    // the page before/after and then scroll the remaining distance. This
    // should help reduce scroll lag but still provide the scroll animation.
    const isJumpTo = Math.abs(delta) >= 2 * $state.pageSize;

    if (isJumpTo) {
      // Move the list (scrollTop) one page out from the start. When paging
      // down, this is the previous page. When paging up it is the next page.
      _start =
        delta > 0 ? _getPrevPageIndex(_index) : _getNextPageIndex(_index);
      $localState.indexStart = _start;
    }

    // Since a user may be using keyboard navigation to select the prev/next
    // item, we do not always want to scroll the list.
    const endIndexTriggerScroll = _start + $state.pageSize - 1;

    if (isJumpTo || _start > _index || _index >= endIndexTriggerScroll) {
      // To make scrolling more precise, reset the scrollTop based on the _start
      // index.
      top = _start * rowHeight;
      viewportEl.scrollTop = top;

      await tick();

      // If the user is scrolling by one at a time then we do not need to do a
      // full "page" scroll.
      top =
        _index === endIndexTriggerScroll
          ? ($localState.indexStart + 1) * rowHeight
          : _index * rowHeight;
      // Note: Adding an extra pixel to the top to make sure it fully scrolls.
      top++;
      viewportEl.scrollTo({ left: 0, top, behavior: 'smooth', ...opts });
    }
  };

  export const scrollToItem = async (itemId) => {
    await tick();
    const index = items.findIndex((item) => deepGet(item, idProp) === itemId);
    scrollToIndex(index);
  };

  export const scrollToEnd = async () =>
    scrollToIndex($localState.numItems - $localState.pageSize);

  let numPrevPages = 0;
  let numNextPages = 0;

  const _getPrevPageIndex = (start, numPages = 1) =>
    Math.max(start - numPages * $state.pageSize, 0);
  const _getNextPageIndex = (start, numPages = 1) =>
    Math.min(
      start + numPages * $state.pageSize,
      $localState.numItems - $localState.pageSize
    );

  const pageScroll = debounce((index) => {
    numPrevPages = 0;
    numNextPages = 0;
    scrollToIndex(index);
  }, 200);

  export const scrollPrevPage = () => {
    numPrevPages = numPrevPages + 1;
    const index = _getPrevPageIndex($state.start, numPrevPages);
    pageScroll(index);
    return index;
  };

  export const scrollNextPage = () => {
    numNextPages = numNextPages + 1;
    const index = _getNextPageIndex($state.start, numNextPages);
    pageScroll(index);
    return index;
  };

  // Calculate the page size based on the viewportHeight and number of items.
  const calcPageSize = (viewportHeight, numItems) => {
    if (!viewportEl) {
      return 0;
    }

    const { scrollTop } = viewportEl;
    const start = Math.min(parseInt(scrollTop / rowHeight), numItems);
    const end = Math.min(
      parseInt((scrollTop + viewportHeight) / rowHeight) + 1,
      numItems
    );
    return end - start;
  };

  const calcScrollStartIndex = () => {
    const { scrollTop } = viewportEl;
    $localState.indexStart = Math.min(
      parseInt(scrollTop / rowHeight),
      $localState.numItems
    );
  };

  // Calculate the top/bottom padding based on the index start/end.
  const calcPadding = (idxStart, idxEnd) => {
    const top = idxStart * rowHeight;
    const bottom = (items.length - idxEnd) * rowHeight;
    return { top, bottom };
  };

  // Use the localState to alter the indexStart as the user pages or
  // scrolls/jumps to an index.
  const localState = writable({
    viewportHeight: 0,
    numItems: 0,
    pageSize: 0,
    indexStart: 0,
    firstItemId: 0,
  });

  // The derived state calculates the remaining pieces based on the indexStart
  // and the viewport.
  export const state = derived(localState, ($localState) => {
    const { indexStart: start, numItems } = $localState;
    const pageSize = $localState.pageSize;
    // Note: We add an extra item to the end to allow for items scrolling onto
    // and off of the screen where we may have partial elements showing.
    const end = Math.max(Math.min(start + pageSize + 1, numItems), 0);
    const { top, bottom } = calcPadding(start, end);
    const visibleRows = items.slice(start, end).map((rowData, i) => {
      const index = i + start;
      const id = deepGet(rowData, idProp, index);
      return { index, id, rowData };
    });

    return {
      pageSize,
      start,
      end,
      top,
      bottom,
      visibleRows,
    };
  });

  // The refresh is used to handle changes to the number of items.
  const _refresh = async (items, viewportHeight) => {
    await tick();

    const firstItemId = items.length > 0 ? deepGet(items[0], idProp, 0) : 0;

    if (
      items.length !== $localState.numItems ||
      $localState.indexStart > $localState.numItems ||
      viewportHeight !== $localState.viewportHeight ||
      firstItemId !== $localState.firstItemId
    ) {
      viewportEl.scrollTop = 0;

      $localState.indexStart = 0;
      $localState.numItems = items.length;
      $localState.viewportHeight = viewportHeight;
      $localState.firstItemId = firstItemId;
      $localState.pageSize = Math.min(
        calcPageSize(viewportHeight, items.length),
        items.length
      );
    }
  };

  const _handleScroll = () => {
    calcScrollStartIndex();
  };

  const initialize = () => {
    initialized = true;
  };

  $: {
    // Note: setting there here because they are exported.
    // @todo eventually have parent components use the state.
    indexStart = $state.start;
    indexEnd = $state.end;
    visibleRows = $state.visibleRows;
  }

  $: if (initialized) _refresh(items, viewportHeight);

  onMount(() => initialize());
</script>

<div
  use:useActions="{use}"
  bind:this="{viewportEl}"
  bind:offsetHeight="{viewportHeight}"
  on:scroll
  on:scroll="{_handleScroll}"
  class:dna-virtual-list="{true}"
  class:overflow-y-scroll="{!disableScroll}"
  class:overflow-y-hidden="{disableScroll}"
  class:pr-3.5="{disableScroll}"
  class:pr-0.5="{!disableScroll}"
  class:pl-0.5="{true}"
  class="relative {className}"
  {...excludeProps($$restProps, ['use', 'scroller$', 'row$'])}
>
  <div
    class:dna-virtual-list-scroller="{true}"
    style="padding-top: {$state.top}px; padding-bottom: {$state.bottom}px;"
    {...excludeProps(prefixFilter($$restProps, 'scroller$'), [])}
  >
    {#each visibleRows as row, i (row.id)}
      <div
        class:dna-virtual-list-row="{true}"
        class:overflow-hidden="{false}"
        {...excludeProps(prefixFilter($$restProps, 'row$'), [])}
      >
        <slot
          rowIndex="{row?.index}"
          rowId="{row?.id}"
          rowData="{row?.rowData}"
          i="{i}"
        />
      </div>
    {:else}
      <slot name="noResults" />
    {/each}
  </div>
</div>

<style lang="postcss">.dna-virtual-list{overscroll-behavior-y:contain}</style>
