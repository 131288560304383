<svelte:options immutable={true} />

<script>
  // @todo optional overlay.
  import { excludeProps, prefixFilter } from 'svelte-utilities';

  import Button from '../button/Button.svelte';

  export let isOpen = false;
  export let isCollapsed = false;
  export let openFrom = 'top right';

  let className = '';
  export { className as class };

  let wrapperEl;
  let triggerEl;
  let panelEl;

  function toggleClass(el, className, ifBool) {
    ifBool ? el.classList.add(className) : el.classList.remove(className);
  }

  function openPanel() {
    let triggerBoundingRect = triggerEl.getBoundingClientRect();

    let canOpenLeft =
      window.innerWidth - triggerBoundingRect.right >= panelEl.offsetWidth;
    let canOpenRight =
      window.innerWidth - triggerBoundingRect.left >= panelEl.offsetWidth;
    let canOpenUp =
      window.innerHeight - triggerBoundingRect.bottom >= panelEl.offsetHeight;
    let canOpenDown =
      window.innerHeight - triggerBoundingRect.top >= panelEl.offsetHeight;

    let openUp = openFrom.indexOf('bottom') > -1 && canOpenUp;
    let openRight = openFrom.indexOf('left') > -1 && canOpenRight;

    toggleClass(panelEl, 'lf-svelte-dropper__panel--open-up', openUp);
    toggleClass(panelEl, 'lf-svelte-dropper__panel--open-right', openRight);

    isOpen = true;
  }

  function closePanel() {
    isOpen = false;
  }

  function togglePanel() {
    isOpen ? closePanel() : openPanel();
  }

  function onClick() {
    togglePanel();
  }

  function onDocumentClick(evt) {
    if (!wrapperEl.parentElement.contains(evt.target)) {
      closePanel();
    }
  }
</script>

<svelte:body on:click={onDocumentClick} />

<div bind:this={wrapperEl} class="lf-svelte-dropper {className}">
  <div bind:this={triggerEl} class="lf-svelte-dropper__trigger">
    <Button {...prefixFilter($$props, 'trigger$')} on:click={onClick}>
      <slot name="triggerContent" />
    </Button>
  </div>
  <div
    bind:this={panelEl}
    class:lf-svelte-dropper__panel--open={isOpen}
    class:lf-svelte-dropper__panel--collapsed={isCollapsed}
    class="lf-svelte-dropper__panel hoverable-on">
    <slot />
  </div>
</div>

<style lang="postcss">.lf-svelte-dropper{display:inline-block;position:relative}.lf-svelte-dropper__panel{background-color:#fff;position:absolute;right:0;top:0;transform:scale(0);transform-origin:top right;transition:transform .2s ease-out;z-index:10}.lf-svelte-dropper__panel--open-right{left:0;right:unset;transform-origin:top left}.lf-svelte-dropper__panel--open-up{bottom:0;top:unset;transform-origin:bottom right}.lf-svelte-dropper__panel--open-right.lf-svelte-dropper__panel--open-left{transform-origin:bottom left}.lf-svelte-dropper__panel--open{transform:scale(1)}.lf-svelte-dropper__panel--collapsed{left:0;position:fixed;right:unset}</style>
