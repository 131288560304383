<script>
  import { createEventDispatcher } from 'svelte';
  import _get from 'lodash-es/get.js';
  import {
    excludeProps,
    getDay,
    getMonth,
    getOrdinal,
    getTime,
    getTimezoneIdentifier,
    getWeekday,
    getYear,
    trimLeft,
  } from 'svelte-utilities';
  import {
    AddToGoogle,
    Button,
    Cards,
    ICSEvent,
    LocationIcon,
    ProgressLinear,
    Menu,
  } from 'svelte-tailwind-components';
  import {
    isCurrentPage,
    getGroupURL,
    getClassStartStatus,
    canEnrollClass,
    getJoinURL,
    canJoinClass,
  } from '../groupClasses/groupClasses.utils.js';
  import FileLink from '../link/FileLink.svelte';

  const dispatch = createEventDispatcher();

  let className = '';
  export { className as class };
  export let groupClass = {};
  export let isWorking = false;

  let hasLicense = _get(groupClass, 'has_license', false);

  // eslint-disable-next-line no-undef
  const currentUser = _get(Drupal.settings, 'lf_svelte.currentUser', {});
  // eslint-disable-next-line no-undef
  const canEdit = _get(Drupal.settings, 'lf_lessongroups.adminControls.edit', false);

  const productDisplayTitle = _get(groupClass, 'product_displays.title');
  const title = _get(groupClass, 'title') || productDisplayTitle;
  const instructorId = _get(groupClass, 'instructor.id');
  const instructorName = _get(groupClass, 'instructor.fullname');
  const instructorImageURL = _get(groupClass, 'instructor.picture.url');
  const instructorURL = _get(groupClass, 'instructor.url');
  const isInstructor =
    instructorId && instructorId > 0 && instructorId === _get(currentUser, 'uid', 0);
  const description = _get(groupClass, 'description', '');
  const categoryName = _get(groupClass, 'category.name');
  const classLocation =
    _get(groupClass, 'in_person', 0) > 0 ? _get(groupClass, 'location', '') : '';

  const nowDT = new Date();
  const timezoneShort = getTimezoneIdentifier(nowDT.toISOString());

  const classStart = _get(groupClass, 'datetime.start');
  const classEnd = _get(groupClass, 'datetime.end');
  const month = getMonth(classStart);
  const day = getDay(classStart);
  const dayOrdinal = getOrdinal(day);
  const startTime = getTime(classStart);
  const endTime = getTime(classEnd);
  const weekday = getWeekday(classStart);
  const year = getYear(classStart);
  const isPast = getClassStartStatus(groupClass, true, true) == 'ended';

  const groupLesson = _get(groupClass, 'group_lesson');
  const groupLessonId = _get(groupLesson, 'id', groupLesson);
  const icsFilename = `lessonface-class-${groupLessonId}-${groupClass.id}`;
  const eventData = {
    location: 'Lessonface.com',
  };

  let isAddCalOpen = false;
  let hasAccess = isInstructor || hasLicense || canEdit;
  let canEnroll = !isInstructor && canEnrollClass(groupClass);
  let canJoin = canJoinClass(groupClass, hasAccess);
  let joinURL = canJoin ? getJoinURL(groupClass) : '';
  let calDescription = [];

  let groupURL = getGroupURL(groupClass, hasAccess);

  let recordings = hasAccess ? _get(groupClass, 'recordings', []) : [];
  let attachments = hasAccess ? _get(groupClass, 'attachments', []) : [];
  let showIndicators =
    (recordings && recordings.length > 0) || (attachments && attachments.length > 0);

  let props = excludeProps($$props, ['use', 'class', 'groupClass', 'settings', 'hasLicense']);

  $: {
    hasLicense = _get(groupClass, 'has_license', false);
    hasAccess = hasLicense || isInstructor || canEdit;
    canEnroll = !isInstructor && canEnrollClass(groupClass);
    canJoin = canJoinClass(groupClass, hasAccess);
    joinURL = canJoin ? getJoinURL(groupClass) : '';
    calDescription = [];

    if (joinURL?.length > 0) {
      calDescription = [joinURL];
    }

    if (classLocation?.length > 0) {
      calDescription = [...calDescription, classLocation];
    }

    if (description?.length > 0) {
      calDescription = [...calDescription, description];
    }

    calDescription = calDescription.join('\n\n');

    groupURL = getGroupURL(groupClass, hasAccess);

    recordings = hasAccess ? _get(groupClass, 'recordings', []) : [];
    attachments = hasAccess ? _get(groupClass, 'attachments', []) : [];
    showIndicators =
      (recordings && recordings.length > 0) || (attachments && attachments.length > 0);
  }
</script>

<Cards.Card class="lf-class-details {className}" {...props}>
  <Cards.Content
    class="overflow-hidden overflow-y-auto flex-gap-y-2 h-full bg-white
      {!showIndicators ? 'py-7 sm:py-7' : 'sm:py-7'}"
  >
    <div class="relative text-sm sm:text-base leading-5 text-center lf-class-details__datetime">
      {#if hasLicense}
        <span
          class="inline-flex items-center px-2.5 py-0.5 text-sm font-medium leading-4 uppercase text-primary-700 absolute top-0 left-0"
        >
          <span class="inline-block w-2 h-2 mr-2 bg-primary-500"></span>
          Enrolled
        </span>
      {/if}
      <div class="lf-class-details__date">
        {weekday}, {month}
        {day}{dayOrdinal}, {year}
      </div>
      <div class="lf-class-details__time">
        {startTime} to {endTime}
        {timezoneShort}
      </div>
      {#if _get(groupClass, 'in_person', 0) > 0 && classLocation.length > 0}
        <div
          class:lf-class-details__location="{true}"
          class="flex items-start justify-center space-x-2 my-3"
        >
          <span>
            <LocationIcon title="In person event" class="w-5 h-5 text-danger-700" />
          </span>
          <span class="whitespace-pre-line">{@html classLocation}</span>
        </div>
      {/if}
      <div class="absolute top-0 right-0 lf-class-details__addtocal">
        <Menu bind:isOpen="{isAddCalOpen}" class="flex items-center lf-class-details__controls">
          <div class="flex flex-col py-1">
            {#if canEdit && !isPast}
              <Button
                on:click="{() => dispatch('sendReminderEmail')}"
                on:click="{() => (isAddCalOpen = false)}"
                usage="link"
                size="sm"
              >
                <svg slot="leftIcon" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"
                  ><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                  ></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                  ></path></svg
                >
                Email Reminder
              </Button>
            {/if}
            <AddToGoogle
              title="{title}"
              startDate="{classStart}"
              endDate="{classEnd}"
              description="{calDescription}"
              eventData="{eventData}"
              button$usage="link"
              button$size="sm"
            />
            <ICSEvent
              filename="{icsFilename}"
              title="{title}"
              startDate="{classStart}"
              endDate="{classEnd}"
              description="{calDescription}"
              eventData="{eventData}"
              button$usage="link"
              button$size="sm"
            />
          </div>
        </Menu>
      </div>
    </div>
    {#if categoryName}
      <div
        class="flex items-center justify-center text-xs sm:text-sm leading-5 uppercase lf-class-details__category"
      >
        <span class="inline-block w-3 h-3 mr-2 bg-secondary-600 lf-class-details__category-square"
        ></span>
        {categoryName}
      </div>
    {/if}
    <h2 class="py-1 text-xl text-center leading-6 lf-class-details__title">
      {#if groupURL && !isCurrentPage(groupURL)}
        <a href="{groupURL}">{title}</a>
      {:else}
        {title}
      {/if}
    </h2>
    {#if instructorName}
      <div class="flex items-center justify-center lf-class-details__instructor">
        {#if instructorImageURL}
          <img
            src="{instructorImageURL}"
            alt="{instructorName}"
            width="32"
            height="32"
            class="mr-4 rounded-full elevation-2"
            loading="lazy"
          />
        {/if}
        <span class="text-sm sm:text-base leading-5 font-semibold text-gray-600 uppercase">
          {#if instructorURL}
            <a href="{instructorURL}">{instructorName}</a>
          {:else}
            {instructorName}
          {/if}
        </span>
      </div>
    {/if}
    {#if false && description}
      <div class="py-2 text-sm leading-5 text-center lf-class-details__description">
        {description}
      </div>
    {/if}
    {#if showIndicators}
      {#if recordings && recordings.length > 0}
        <div class="lf-class-details__recordings">
          <h3 class="m-0 text-sm text-gray-500 uppercase leading-6">Recordings</h3>
          {#each recordings as file (file.id)}
            <FileLink class="-ml-3" file="{file}" />
          {/each}
        </div>
      {/if}
      {#if attachments && attachments.length > 0}
        <div class="lf-class-details__attachments">
          <h3 class="m-0 text-sm text-gray-500 uppercase leading-6">Attachments</h3>
          {#each attachments as file (file.id)}
            <FileLink class="-ml-3" file="{file}" />
          {/each}
        </div>
      {/if}
    {/if}
  </Cards.Content>
  <Cards.Actions class="mt-auto bg-white">
    <span slot="working" class="absolute top-0 left-0 w-full">
      {#if isWorking}
        <ProgressLinear settings="{{ indeterminate: true }}" />
      {/if}
    </span>
    <Button on:click="{() => dispatch('closeDialog')}" usage="link" size="sm">Close</Button>
    {#if canEdit}
      <Button
        usage="link"
        size="sm"
        href="/classes/{groupClass.id}/edit"
        urlOptions="{{
          query: {
            destination: trimLeft(window.location.pathname, '/'),
          },
        }}">Edit</Button
      >
    {/if}
    {#if groupURL && !isCurrentPage(groupURL)}
      <Button usage="button" size="sm" color="secondary" href="{groupURL}">View Details</Button>
    {/if}
    {#if canEnroll}
      <Button
        on:click="{() => dispatch('enroll')}"
        bind:working="{isWorking}"
        usage="button"
        size="sm"
        color="primary">Enroll</Button
      >
    {:else if canJoin}
      <Button usage="button" size="sm" color="primary" href="{joinURL}" target="_blank">Join</Button
      >
    {/if}
  </Cards.Actions>
</Cards.Card>

<style lang="postcss">@media (min-width:640px){:global(.lf-class-details){min-width:28rem}}:global(.lf-class-details .dna-card__content){--bg-opacity:0.9;margin-top:0}</style>
