<script>
  import { onMount } from 'svelte';

  import Autocomplete from '../../autocomplete/Autocomplete.svelte';

  import _get from 'lodash-es/get.js';

  export let id;
  export let value = '_none';
  export let filter = {};
  export let meta = {};

  export let label = '';
  // @todo
  // export let multiple = false;

  let className = '';
  export { className as class };

  let sortRules = _get(filter, 'componentOptions.sortRules', []);
  let sortOptions = _get(filter, 'componentOptions.sortOptions', true);

  let isInitialized = false;

  let selectOptions = filter.options.map(option => {
    let newOption = {};

    if (typeof option === 'object') {
      newOption = {...option};
    }
    else {
      newOption = {
        value: option,
        label: option
      };
    }

    return newOption;
  });

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

{#if isInitialized}
<div
  class="
    {id}
    form-item
    form-type-select
    {className}
    ">
  <label for="{id}">
    {label}
  </label>
  <Autocomplete
    id="{`${id}__autocomplete`}"
    name="{`${id}__autocomplete`}"
    labelProp="label"
    options={selectOptions}
    bind:checkedValues={value}
    label={filter.label}
    labelDisplay="invisible"
    placeholder="{`Select ${filter.label}...`}"
    {sortRules}
    {sortOptions}
    />
</div>
{/if}

<style lang="postcss">.form-type-select{align-items:center;display:flex;justify-content:flex-start;margin:0}.form-type-select label{margin-bottom:0;margin-right:.5em}</style>
