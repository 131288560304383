<script>
  import { classNamer } from 'svelte-utilities';

  export let column = {};
  export let colspan = 1;
  export let dataType = 'string';

  let className = '';
  export { className as class };
</script>

<td
  on:click
  {colspan}
  class="
    lf-datatable__column--{classNamer(column.name)}
    lf-datatable__column-type--{classNamer(column.type || dataType)}
    {className}
  ">
  <slot/>
</td>

<style lang="postcss">td{border:unset}</style>
