<script>
  import Price from './Price.svelte';
  import { getWeekday, formatDateTime } from 'svelte-utilities';
  import { get as getObjProp } from 'lodash-es';

  export let lineItem = {};

  let label = lineItem.label;

  if (label.includes(' with ')) {
    const labelParts = label.split(' with ');
    labelParts[labelParts.length - 1] = `<span class="lf-line-item-summary__label__instructor-name">${labelParts[labelParts.length - 1]}</span>`;
    label = labelParts.join(' with ');
  }

  const imageUrl = getObjProp(lineItem, 'product.image.url', '');
  const productType = getObjProp(lineItem, 'product.type', null);
  const scheduleStatus = getObjProp(lineItem, 'meta.lesson.scheduleStatus', null);
  const lessonSchedule = getObjProp(lineItem, 'meta.lesson.schedule', null);

  const lessonUserName = getObjProp(lineItem, 'meta.lesson.user.fullname', null);
  const lessonStudentName = getObjProp(lineItem, 'meta.lesson.participant.fullname', null);
  const displayStudentName = lessonStudentName && lessonStudentName !== lessonUserName;

  let scheduleInfo = '';

  if (lineItem.quantity >= 1 && scheduleStatus === 'scheduled' && lessonSchedule) {
    const weekday = getWeekday(lessonSchedule.start, 'long');
    const startDate = formatDateTime(lessonSchedule.start, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    const frequency = lessonSchedule.frequency == 'biweekly' ? 'Every other ' : '';
    const starting = lineItem.quantity > 1 ? 'starting ' : '';
    // Tuesday 10:00 am, starting September 26th, 2023
    scheduleInfo = `${frequency}${weekday}, ${starting}${startDate}`;
  }

  let giftcardEmail = getObjProp(lineItem, 'meta.giftcard.email', '');
</script>

<div class="
    lf-line-item-summary
    lf-line-item-summary--{lineItem.type.replace('_', '-')}
    {productType ? `lf-line-item-summary--${productType.replace('_', '-')}` : ''}
  ">

  <div class="lf-line-item-summary__content-wrapper">
    {#if imageUrl.length}
      <div class="lf-line-item-summary__media">
        <img src="{imageUrl}" type="foaf:Image" alt="{lineItem.label}" />
      </div>
    {/if}

    <div class="lf-line-item-summary__content">
      <h6 class="lf-line-item-summary__label">
        {@html label}
        {#if lineItem.isSubscription}
          <span class="lf-line-item-summary--subscription">(Subscription)</span>
        {/if}
      </h6>

      {#if displayStudentName || !!+scheduleInfo.length || !!+giftcardEmail.length}
        <div class="lf-line-item-summary__details">
          {#if displayStudentName}
            <span class="lf-line-item-summary--student-name">Student: {lessonStudentName}</span>
          {/if}
          {#if !!+scheduleInfo.length}
            <span class="lf-line-item-summary--schedule-info">{scheduleInfo}</span>
          {/if}
          {#if !!+giftcardEmail.length}
            <span class="lf-line-item-summary--giftcard-email">To: {giftcardEmail}</span>
          {/if}
        </div>
      {/if}
    </div>
  </div>

  <div class="lf-line-item-summary__pricing">
    <div class="lf-line-item-summary__qty">
      <span class="lf-line-item-summary__qty__label">QTY</span>
      <span class="lf-line-item-summary__qty__value">{lineItem.quantity}</span>
    </div>

    <div class="lf-line-item-summary__price">
      <Price {...lineItem.lineTotal} componentDisplay="subtotal" />
    </div>
  </div>
</div>

<style lang="postcss">.lf-line-item-summary{display:flex;flex-direction:column;row-gap:.5rem}@media screen and (min-width:1024px){.lf-line-item-summary{align-items:flex-start;-moz-column-gap:1rem;column-gap:1rem;flex-direction:row}}.lf-line-item-summary:not(:first-of-type){border-top:1px solid var(--lfl-border);padding-top:1rem}.lf-line-item-summary__content-wrapper{align-items:flex-start;-moz-column-gap:.75rem;column-gap:.75rem;display:flex}@media screen and (min-width:1024px){.lf-line-item-summary__content-wrapper{flex:1 0 0%}}.lf-line-item-summary__media{border-radius:.5rem;flex:0 0 96px;overflow:hidden}.lf-line-item-summary__media img{border-radius:.5rem;width:100%}.lf-line-item-summary__content{flex:1 0 0%}.lf-line-item-summary__label{font-family:var(--lfl-headingfont,"latobold, sans-serif");font-size:1em;font-weight:600;line-height:1.5rem;margin:0}.lf-line-item-summary__label :global(span){white-space:nowrap}.lf-line-item-summary__details{color:var(--lfl-text-light);display:flex;flex-direction:column;font-size:.75rem;line-height:1.125rem}.lf-line-item-summary__pricing{align-items:flex-end;display:flex;justify-content:space-between}@media screen and (min-width:1024px){.lf-line-item-summary__pricing{-moz-column-gap:1rem;column-gap:1rem}}.lf-line-item-summary__qty{align-items:center;color:var(--lfl-text-light);-moz-column-gap:.5rem;column-gap:.5rem;display:flex;font-size:.75rem;line-height:1.125rem;white-space:nowrap}.lf-line-item-summary__qty__label{text-transform:uppercase}.lf-line-item-summary__price{margin-left:auto}@media screen and (min-width:1024px){.lf-line-item-summary__price{text-align:right;width:80px}}.lf-line-item-summary--subscription{font-size:.75rem}</style>
