<svelte:options immutable={true} />

<script>
  import { slide } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';
  import { excludeProps } from 'svelte-utilities';
  import { toThreadModel } from '../../models';
  import TipTapMessage from './TipTapMessage.svelte';

  export let className = '';
  export { className as class };
  export let drawerController;
  export let isOpen = false;

  let thread = undefined;
  let replyToMessageId = undefined;
  let contacts = undefined;
  let subject = '';
  let props = {};

  const canOpen = () =>
    replyToMessageId || thread?.canReplyAll || (contacts || []).length > 0;
  const openDrawer = () => {
    isOpen = canOpen();
  };
  const closeDrawer = () => resetDrawer();
  const resetDrawer = () => {
    isOpen = false;
    replyToMessageId = undefined;
    contacts = undefined;
    subject = '';
    props = {};
  };

  drawerController.open.watch((res) => {
    if (!isOpen) {
      let { message, props } = res;
      thread = toThreadModel(res?.thread);
      contacts = res?.contacts;
      // message = message ?? thread?.lastMessage;
      replyToMessageId =
        thread?.canReplyAll && !message
          ? undefined
          : message?.id ?? thread?.lastMessageId;
      subject = thread?.subject;
      props = props || {};
      openDrawer();
    }
  });
  drawerController.close.watch(() => closeDrawer());
  drawerController.reset.watch(() => resetDrawer());
</script>

<div class="flex flex-col {className}">
  <div class="min-h-0 overflow-hidden overflow-y-auto">
    <slot />
  </div>

  {#if isOpen}
    <div
      transition:slide={{ duration: 300, easing: quintOut }}
      class:lf-tiptap-message-wrapper={true}
      class="min-w-0 max-w-screen-lg elevation-6 py-2 md:elevation-0 2xl:ml-8 2xl:mr-6">
      <TipTapMessage
        autofocus
        {thread}
        {replyToMessageId}
        {contacts}
        {subject}
        on:messageDisarded={closeDrawer}
        on:messageSent={resetDrawer}
        class="h-full"
        wrapper$class="h-full"
        {...props}
        {...excludeProps($$restProps, [])} />
    </div>
  {/if}
</div>

<style lang="postcss">.lf-tiptap-message-wrapper{max-height:100%}@media (min-width:768px){.lf-tiptap-message-wrapper{max-height:60%}}</style>
