<script>
  import { classNamer } from 'svelte-utilities';
  import {
    generateConfig,
    cleanData,
    buildColumnInfo,
    setupFilters,
    generateFilterRules,
    generateSortRules,
    generateFooterColumns,
    getFooterDataLabel,
    generateFooterData,
  } from './datatable.utils.js';

  import _get from 'lodash-es/get.js';
  import _sumBy from 'lodash-es/sumBy.js';
  import _orderBy from 'lodash-es/orderBy.js';
  import _filter from 'lodash-es/filter.js';

  import {
    Button,
  } from 'svelte-tailwind-components';

  import TRowWrapper from './TRowWrapper.svelte';
  import THWrapper from './THWrapper.svelte';
  import TDWrapper from './TDWrapper.svelte';
  import CellWrapper from './CellWrapper.svelte';

  import PageSize from './PageSize.svelte';
  import Pager from './Pager.svelte';

  export let id;
  export let title = '';
  export let data = [];
  export let columns = [];
  export let filters = [];
  export let config = {};
  export let page = 1;

  let className = '';
  export { className as class };

  // Perform initial setup once.
  data = cleanData(data, config);
  columns = buildColumnInfo(columns, data, config);
  config = generateConfig(config);
  filters = setupFilters(filters, data, columns, config);

  // @todo reactive?
  let meta = {
    id,
    columns,
    config,
    filters,
  }

  let showFilters = false;

  $: hasFilters = !!+filters.length;
  $: filterRules = generateFilterRules(filters);
  $: filteredData = !!+Object.keys(filterRules).length ? _filter(data, filterRules) : data;
  // @todo multi-column sort.
  // @todo default sort.
  // @todo base sort (follows custom sort).
  $: sortRules = generateSortRules(columns);
  $: sortedData = !!+sortRules.sortKeys.length ? _orderBy(filteredData, sortRules.sortKeys, sortRules.sortValues) : filteredData;
  $: indexStart = config.pageSize.value * (page - 1);
  $: indexEnd = indexStart + config.pageSize.value;
  $: pagedData = config.pagination ? sortedData.slice(indexStart, indexEnd) : sortedData;
  $: footerColumns = generateFooterColumns(columns);

  function toggleFilters() {
    showFilters = !showFilters;
  }

  function onFilterChange() {
    page = 1;
  }
</script>

<div
  id={id}
  class="lf-datatable-wrapper {className}">

  <div class="lf-datatable__header">
    {#if !!+title.length}
      <h2 class="lf-datatable__title">{title}</h2>
    {/if}
    <div class="lf-datatable__actions">
      {#if hasFilters}
        <Button
          color="{!!+Object.keys(filterRules).length ? 'danger' : 'primary'}"
          size="xs"
          flat={showFilters}
          on:click={toggleFilters}>
          <i class="mr-2 icon-filter"></i>Filters
        </Button>
      {/if}
      <slot name="actions" />
    </div>
    {#if !!+data.length && hasFilters && showFilters}
      <form class="lf-datatable__filters">
        {#each filters as filter (filter.name)}
          <div class="lf-datatable__filter-wrapper">
            <svelte:component
              this={filter.component}
              bind:value={filter.filterOn}
              id="lf-datatable__filter--{classNamer(id)}--{classNamer(filter.name)}"
              class="
                lf-datatable__filter
                lf-datatable__filter--{classNamer(id)}--{classNamer(filter.name)}"
              {filter}
              {meta}
              on:change={onFilterChange}
              />
          </div>
        {/each}
      </form>
    {/if}
    <slot name="header" />
  </div>

  <div class="lf-datatable__table">
    <table width="100%" cellspacing="0">
      {#if !!+data.length}
        <thead>
          <TRowWrapper>
            {#each columns as column (column.name)}
              <THWrapper
                bind:column>
                <CellWrapper>
                  {@html column.title}
                </CellWrapper>
              </THWrapper>
            {/each}
          </TRowWrapper>
        </thead>
      {/if}

      <tbody>
        {#if !!+filteredData.length}
          {#each pagedData as rowData, rowIndex (rowData[config.rowIdentifier])}
            <TRowWrapper>
              {#each columns as column (column.name)}
                <TDWrapper
                  bind:column>
                  <CellWrapper>
                    {#if column.component}
                      <svelte:component
                        this={column.component}
                        bind:rowData
                        {column}
                        {meta}/>
                    {:else}
                      {@html _get(rowData, column.data, column.defaultValue || '')}
                    {/if}
                  </CellWrapper>
                </TDWrapper>
              {/each}
            </TRowWrapper>
          {/each}
        {:else}
          <TRowWrapper>
            <TDWrapper colspan={columns.length}>
              <CellWrapper>
                {!!+Object.keys(filterRules).length ? config.language.noFilteredData : config.language.noData}
              </CellWrapper>
            </TDWrapper>
          </TRowWrapper>
        {/if}
      </tbody>

      {#if !!+filteredData.length && !!+footerColumns.length}
        <tfoot>
          <TRowWrapper>
            {#each footerColumns as footerColumn (footerColumn.name)}
              {#if footerColumn.colspan > 0}
                <TDWrapper
                  bind:column={footerColumn}
                  colspan={footerColumn.colspan}>
                  <CellWrapper>
                    <span class="lf-datatable__cell__label">
                      {getFooterDataLabel(footerColumn)}:
                    </span>
                    <span class="lf-datatable__cell__value">
                    {#if footerColumn.component}
                      <svelte:component
                        this={footerColumn.component}
                        cellData={generateFooterData(filteredData, footerColumn)}
                        rowData={{}}
                        column={footerColumn}
                        {meta}/>
                    {:else}
                      {generateFooterData(data, footerColumn)}
                    {/if}
                    </span>
                  </CellWrapper>
                </TDWrapper>
              {/if}
            {/each}
          </TRowWrapper>
        </tfoot>
      {/if}
    </table>
  </div>

  {#if !!+data.length}
    <div class="lf-datatable__footer">
      {#if config.pageSize.value && config.pageSize.changeable && config.pagination}
        <PageSize
          options={config.pageSize.options}
          bind:value={config.pageSize.value}
          label={config.pageSize.label}
        />
      {/if}
      {#if config.pagination}
        <Pager
          bind:page={page}
          pageSize={config.pageSize.value}
          totalItems={filteredData.length}/>
      {/if}
    </div>
  {/if}
</div>

<style lang="postcss">.lf-datatable-wrapper thead{color:#666}.lf-datatable-wrapper thead+tbody{box-shadow:0 -4px 4px -4px rgba(0,0,0,.2)}.lf-datatable-wrapper :global(thead>tr>th:first-of-type){text-align:left}.lf-datatable-wrapper tbody{border-top:unset}.lf-datatable-wrapper :global(tbody>tr+tr){box-shadow:0 -3px 4px -4px rgba(0,0,0,.2)}.lf-datatable-wrapper :global(tbody>tr:hover){background-color:#f3f3f3;box-shadow:none}.lf-datatable-wrapper :global(tbody>tr>td:first-of-type){text-align:left}.lf-datatable-wrapper tfoot{box-shadow:0 -4px 4px -4px rgba(0,0,0,.2)}.lf-datatable-wrapper tfoot :global(.lf-datatable__cell-wrapper){font-weight:700}.lf-datatable-wrapper .lf-datatable__header{align-items:flex-start;display:flex;flex-wrap:wrap;justify-content:flex-start;margin-bottom:.5em}.lf-datatable-wrapper .lf-datatable__title{font-size:1.5rem;margin:0}.lf-datatable-wrapper .lf-datatable__actions{display:flex;margin-left:auto}.lf-datatable-wrapper .lf-datatable__actions :global(button+[slot=actions]){margin-left:.5em}.lf-datatable-wrapper .lf-datatable__actions :global(.button+.button){margin-left:.5em}.lf-datatable-wrapper .lf-datatable__filters{align-items:flex-start;display:flex;flex:1 1 100%;justify-content:flex-start;margin-top:.5em}.lf-datatable-wrapper .lf-datatable__filter-wrapper{margin-right:1em}.lf-datatable-wrapper .lf-datatable__footer{align-items:center;display:flex;justify-content:flex-end}.lf-datatable-wrapper .lf-datatable__cell__label{color:#666}.lf-datatable-wrapper .lf-datatable__cell__value{margin-left:.5em}.lf-datatable-wrapper .lf-datatable__page-size+.lf-datatable-wrapper .lf-datatable__pager{margin-left:1em}.lf-datatable-wrapper :global(.lf-datatable__column-type--number){text-align:right}</style>
