<script>
  // Svelte imports, utilities, other components, component helpers.
  import NakedInput from './NakedInput.svelte';
  import FormField from '../form-field/FormField.svelte';
  import Label from '../labels/Label.svelte';
  import LabelText from '../labels/LabelText.svelte';
  import HelpText from '../help-text/HelpText.svelte';
  import ErrorMessage from '../error-messages/ErrorMessages.svelte';

  import { excludeProps, prefixFilter } from 'svelte-utilities';

  let className = '';
  export { className as class };
  export let inputEl = undefined;
  export let name = undefined;
  export let id;
  export let label = '';
  export let value = '';
  export let type = 'text';
  export let invalid = false;
  export let disabled = false;

  // Shapes: rounded, square
  export let shape = 'square';

  if (!name) {
    name = id;
  }
</script>

<FormField
  type="textfield"
  invalid="{invalid}"
  disabled="{disabled}"
  class="{className}"
  {...excludeProps($$restProps, ['use', 'input$', 'label$', 'help$', 'error$'])}
>
  <div class="relative mt-1 dna-form-field-input-wrapper">
    <Label id="{id}" invalid="{invalid}" disabled="{disabled}" floatable>
      <slot name="inputPrefix" />
      <NakedInput
        bind:element="{inputEl}"
        id="{id}"
        name="{name}"
        type="{type}"
        value="{value}"
        invalid="{invalid}"
        disabled="{disabled}"
        shape="{shape}"
        {...prefixFilter($$restProps, 'input$')}
      />
      <LabelText
        floatable
        invalid="{invalid}"
        disabled="{disabled}"
        {...prefixFilter($$restProps, 'label$')}
      >
        {@html label}
      </LabelText>
      <slot name="inputSuffix" />
    </Label>
  </div>
  {#if invalid}
    <ErrorMessage {...prefixFilter($$props, 'error$')} />
    <slot name="errorMessage" />
  {:else}
    <HelpText id="{id + '-description'}" {...prefixFilter($$props, 'help$')} />
    <slot name="helpText" />
  {/if}
</FormField>

<style lang="postcss">:global(.dna-form-field-input):not(:focus)::-moz-placeholder{opacity:0}:global(.dna-form-field-input):not(:focus)::placeholder{opacity:0}</style>
