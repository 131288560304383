<script>
  // @todo allow for multiple selection (e.g. checkboxes).
  // @todo allow for nested options.
  // @todo allow for no search (e.g. if total options less than X).
  // @see https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.0pattern/combobox-autocomplete-list.html
  // @see https://adamsilver.io/articles/building-an-accessible-autocomplete-control/
  import { onMount, createEventDispatcher, tick } from 'svelte';
  import { prefixFilter, KEYS } from 'svelte-utilities';

  import Item from './Item.svelte';
  import Button from '../button/Button.svelte';

  import _get from 'lodash-es/get.js';
  import _filter from 'lodash-es/filter.js';
  import _orderBy from 'lodash-es/orderBy.js';

  const dispatch = createEventDispatcher();

  export let name = '';
  export let id = '';
  export let options = [];
  export let multiple = false;
  export let required = false;
  export let checkedValues = multiple ? [] : '';
  // @todo other components.
  export let itemComponent = Item;

  export let label = '';
  export let labelDisplay = '';
  export let placeholder = '';

  export let labelProp = 'text';
  export let valueProp = 'value';

  export let minChars = 1;
  export let showAll = true;
  // @todo
  export let maxItems = 7;
  export let caseSensitive = false;
  export let sortRules = [];
  export let sortOptions = true;
  export let showSelections = undefined;

  let className = '';
  export { className as class };

  let meta = {
    id,
    name,
    multiple,
  };

  let wrapperEl;
  let selectEl;
  let searchEl;
  let listEl;
  let selectedEl;

  let searchText = '';
  let isOpen = false;
  let holdOpen = false;
  let selectedIndex = 0;
  let selectedCheckedValues = [];

  // The checkedValues variable determines what is or is not selected. The use
  // of the option(s) variables are for presentation only. Setting an option as
  // checked does not make it checked from a values standpoint, only
  // presentational.
  $: options = options.map((option) => {
    option.checked = multiple
      ? checkedValues.includes(getOptionValue(option))
      : option[valueProp] == checkedValues;
    return option;
  });

  $: cleanedSearchText = caseSensitive ? searchText.trim() : searchText.trim().toLowerCase();
  $: canShowOptions = cleanedSearchText.length >= minChars || showAll;

  $: filteredOptions = canShowOptions
    ? _filter(options, (option) => {
        if (cleanedSearchText.length === 0) {
          return true;
        }

        if (cleanedSearchText.length < minChars || getOptionText(option).length === 0) {
          return false;
        }

        // @todo allow searching of alternate option properties.
        let cleanedOptionText = caseSensitive
          ? getOptionText(option).trim()
          : getOptionText(option).trim().toLowerCase();
        return cleanedOptionText.indexOf(cleanedSearchText) > -1;
      })
    : [...options];
  $: sortedOptions = sortOptions ? _orderBy(filteredOptions, sortRules) : [...filteredOptions];

  $: selectedItemHeight = selectedEl && selectedEl.offsetHeight > 0 ? selectedEl.offsetHeight : 34;
  $: listHeight = Math.min(sortedOptions.length + 1, maxItems) * selectedItemHeight;
  $: wrapperHeight = wrapperEl ? wrapperEl.offsetHeight + listHeight : listHeight;
  $: checkedOptions = _filter(options, { checked: true });

  $: {
    let checkedTexts = checkedOptions.map((option) => getOptionText(option));
    let displayText =
      showSelections || multiple || !checkedTexts || !checkedTexts.length
        ? ''
        : checkedTexts.length < 3
        ? checkedTexts.join(', ')
        : checkedTexts[0] + ', +' + checkedTexts.length - 1 + ' more';

    if (!isOpen && (!checkedTexts.length || displayText.length)) {
      searchText = displayText;
    }
  }

  function getOptionText(option) {
    return _get(option, labelProp);
  }

  function getOptionValue(option) {
    return _get(option, valueProp);
  }

  async function showMenu() {
    if (!isOpen) {
      isOpen = canShowOptions;
      selectedIndex = 0;

      if (isOpen) {
        await tick();
        searchEl.focus();
      }
    }
  }

  function hideMenu() {
    isOpen = holdOpen || false;
    selectedIndex = 0;
    holdOpen = false;
  }

  function toggleMenu() {
    if (isOpen) {
      hideMenu();
    } else {
      showMenu();
    }
  }

  async function triggerChange() {
    await tick();
    let evt = new CustomEvent('change', {
      bubbles: true,
      cancelable: true,
    });
    selectEl.dispatchEvent(evt);
  }

  async function positionSelectedEl() {
    await tick();
    listEl.scrollTop = selectedEl.offsetTop;
  }

  function incSelectedIndex(n) {
    n = n || 1;
    selectedIndex = Math.min(selectedIndex + n, sortedOptions.length - 1);
    positionSelectedEl();
  }

  function decSelectedIndex(n) {
    n = n || 1;
    selectedIndex = Math.max(selectedIndex - n, 0);
    positionSelectedEl();
  }

  function selectOption(option) {
    checkedValues = multiple
      ? [...new Set([...checkedValues, getOptionValue(option)])]
      : getOptionValue(option);
    dispatch('selectedOption', { option });
    positionSelectedEl();
    triggerChange();

    if (!multiple) {
      hideMenu();
    }
  }

  function deselectOption(option) {
    checkedValues = multiple ? _filter(checkedValues, (v) => v !== getOptionValue(option)) : '';

    dispatch('deselectedOption', { option });
    triggerChange();
  }

  function clearCheckedOptions() {
    checkedValues = multiple ? [] : '';
  }

  function onSearchFocus() {
    showMenu();
  }

  function onSearchKeyDown(evt) {
    switch (evt.keyCode) {
      case KEYS.DOWN:
        showMenu();

        if (isOpen) {
          incSelectedIndex();
        }
        break;

      case KEYS.UP:
        decSelectedIndex();
        break;

      case KEYS.ENTER:
        selectOption(sortedOptions[selectedIndex]);
        break;

      case KEYS.TAB:
      case KEYS.ESC:
        hideMenu();
        break;
    }
  }

  function onSearchKeyUp(evt) {
    switch (evt.keyCode) {
      case KEYS.DOWN:
      case KEYS.UP:
      case KEYS.ENTER:
      case KEYS.TAB:
      case KEYS.ESC:
        // Ignore all keys used in keydown.
        break;
      default:
        showMenu();
        break;
    }
  }

  function onOptionSelected(evt) {
    selectOption(evt.detail);
  }

  function onOptionDeselected(evt) {
    deselectOption(evt.detail);
  }

  function onSelectionRemoveClick(option) {
    holdOpen = true;
    deselectOption(option);
  }

  function onClearSelections() {
    clearCheckedOptions();
    hideMenu();
  }

  function onDocumentClick(evt) {
    if (!wrapperEl.parentElement.contains(evt.target)) {
      hideMenu();
    }
  }

  function initialize() {}

  onMount(() => {
    initialize();
  });
</script>

<svelte:body on:click="{onDocumentClick}" />

<div
  bind:this="{wrapperEl}"
  class:lf-svelte-autocomplete-wrapper--multiple="{multiple}"
  class="
    lf-svelte-autocomplete-wrapper
    {className}"
>
  <label
    for="{id}"
    class="lf-svelte-autocomplete__label"
    class:element-invisible="{labelDisplay === 'invisible'}"
  >
    {@html label}
  </label>

  {#if showSelections || (showSelections !== false && multiple && checkedOptions.length > 0)}
    <div class="lf-svelte-autocomplete__selections">
      {#each checkedOptions as option (option[valueProp])}
        <Button
          class="button--info button--tiny lf-svelte-autocomplete__selection"
          on:click="{() => onSelectionRemoveClick(option)}"
        >
          <i class="icon-remove"></i>
          <span>{getOptionText(option)}</span>
        </Button>
      {/each}
    </div>
  {/if}

  <div class="lf-svelte-autocomplete__search-wrapper">
    <select
      bind:this="{selectEl}"
      name="{name}"
      multiple="{multiple}"
      aria-hidden="true"
      tabindex="-1"
      class="element-invisible"
    >
      {#each checkedOptions as option (option[valueProp])}
        <option value="{getOptionValue(option)}" selected>{getOptionText(option)}</option>
      {/each}
    </select>
    <input
      id="{id}"
      type="text"
      class:lf-svelte-autocomplete__search="{true}"
      class:lf-svelte-autocomplete__search--clearable="{!showSelections && !multiple && !required}"
      role="combobox"
      aria-autocomplete="list"
      aria-expanded="{isOpen}"
      aria-haspopup="true"
      aria-owns="lf-svelte-autocomplete__options--{name}"
      aria-activedescendant="{id}__{selectedIndex}"
      autocapitalize="none"
      placeholder="{placeholder}"
      {...prefixFilter($$props, 'search$')}
      bind:this="{searchEl}"
      bind:value="{searchText}"
      on:focus="{onSearchFocus}"
      on:keydown="{onSearchKeyDown}"
      on:keyup="{onSearchKeyUp}"
    />
    {#if !showSelections && !multiple && !required}
      <Button
        class="button--link lf-svelte-autocomplete__clear"
        disabled="{!checkedOptions.length}"
        on:click="{onClearSelections}"
      >
        <i class="icon-remove"></i>
      </Button>
    {/if}
  </div>

  <div
    class:lf-svelte-autocomplete__options-wrapper--hidden="{!isOpen}"
    class="lf-svelte-autocomplete__options-wrapper"
  >
    <ul
      bind:this="{listEl}"
      id="lf-svelte-autocomplete__options--{name}"
      role="listbox"
      aria-label="{label}"
      style="max-height: {listHeight}px; overflow-y: {sortedOptions.length <= maxItems
        ? 'hidden'
        : 'auto'}"
      class="lf-svelte-autocomplete__options hoverable-on"
    >
      {#each sortedOptions as option, i (option[valueProp])}
        {#if i === selectedIndex}
          <li
            bind:this="{selectedEl}"
            id="{id}__{i}"
            role="option"
            aria-selected="true"
            class="lf-svelte-autocomplete__option"
            class:lf-svelte-autocomplete__option--checked="{option.checked}"
          >
            <svelte:component
              this="{itemComponent}"
              bind:option
              textCallback="{getOptionText}"
              valueCallback="{getOptionValue}"
              searchText="{searchText}"
              highlighted
              on:optionSelected="{onOptionSelected}"
              on:optionDeselected="{onOptionDeselected}"
              meta="{meta}"
            />
          </li>
        {:else}
          <li
            id="{id}__{i}"
            role="option"
            class="lf-svelte-autocomplete__option"
            class:lf-svelte-autocomplete__option--checked="{option.checked}"
          >
            <svelte:component
              this="{itemComponent}"
              bind:option
              textCallback="{getOptionText}"
              valueCallback="{getOptionValue}"
              searchText="{searchText}"
              on:optionSelected="{onOptionSelected}"
              on:optionDeselected="{onOptionDeselected}"
              meta="{meta}"
            />
          </li>
        {/if}
      {:else}
        <li
          id="{id}__no-results"
          role="option"
          aria-selected="false"
          tabindex="-1"
          class="lf-svelte-autocomplete__option--no-results"
        >
          No results available.
        </li>
      {/each}
    </ul>
    <div aria-live="polite" role="status" class="lf-svelte-autocomplete__status element-invisible">
      {options.length} results available.
    </div>
  </div>
</div>

<style lang="postcss">.lf-svelte-autocomplete-wrapper{box-sizing:border-box}.lf-svelte-autocomplete-wrapper *{box-sizing:inherit}.lf-svelte-autocomplete-wrapper{position:relative}.lf-svelte-autocomplete__selections{align-items:center;display:flex;flex-wrap:wrap;justify-content:flex-start;padding:0 .5em}:global(.lf-svelte-autocomplete__selection){margin-right:.5em;margin-top:.5em}.lf-svelte-autocomplete__search-wrapper{align-items:center;display:flex;justify-content:flex-start;position:relative}.lf-svelte-autocomplete__search-wrapper select{border:0;margin:0;padding:0}.lf-svelte-autocomplete__search{border-bottom-right-radius:0;border-top-right-radius:0;width:100%}.lf-svelte-autocomplete__search--clearable{padding-right:2em}.lf-svelte-autocomplete__options-wrapper{position:static;z-index:10}.lf-svelte-autocomplete__options-wrapper--hidden{display:none}.lf-svelte-autocomplete__options{background-color:#fff;list-style:none;margin:0;min-width:100%;overflow:hidden;overflow-y:auto;padding:0;position:absolute;width:auto;z-index:10}.lf-svelte-autocomplete__option{margin:0;padding:0}.lf-svelte-autocomplete__option:hover,.lf-svelte-autocomplete__option[aria-selected=true]{background-color:#3f9d4b;color:#fff}.lf-svelte-autocomplete__option--checked{background-color:#d4eed7;font-weight:700}:global(button.lf-svelte-autocomplete__clear){height:2em;margin:0;padding:0;position:absolute!important;right:.5em;width:2em}</style>
