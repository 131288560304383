<script>
  import { excludeProps } from 'svelte-utilities';
  import Spinner from '../spinner/Spinner.svelte';
  import ripple from '../ripple/ripple.js';

  export let type = "button"
  export let disabled = false;
  export let isWorking = false;
  export let isWorkingLabel = '';
  let className = '';
  export { className as class };
</script>

<button
  use:ripple
  on:click
  class="
    button
    {className}
  "
  {type}
  disabled={disabled || isWorking}
  {...excludeProps($$props, ['use', 'class', 'className', 'type', 'disabled', 'isWorking'])}
>
  {#if isWorking}
    <Spinner size=21 variation="single" center={!+isWorkingLabel} />
    {#if !!+isWorkingLabel.length}
      <span class="lf-button-spinner-label">{isWorkingLabel}</span>
    {/if}
  {:else}
    <slot></slot>
  {/if}
</button>

<style lang="postcss">button:disabled,button[disabled]{opacity:.3}.button,button{overflow:hidden;position:relative}.button :global(i),button :global(i){margin-right:0}.button :global(i+span),button :global(i+span){margin-left:.5em}.button :global(span+i),button :global(span+i){margin-left:.5em}.lf-button-spinner-label{margin-left:.5em}</style>
