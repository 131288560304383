<svelte:options immutable={true} />

<script>
  import { onMount } from 'svelte';
  import { excludeProps } from 'svelte-utilities';

  let className = '';
  export { className as class };
  export let item;

  let listItemEl;

  // The following mouse/focus handlers restrict focusing an item to
  // clicking with the left mouse (1) or focusing w/o the mouse (0).
  // Note: event order is mousedown, focus, mouseup, click. The focus-visible
  // css selector is not supported in all browsers yet.
  // @see https://www.darrenlester.com/blog/focus-only-on-tab
  // @todo make into svelte action?
  let focusable = true;

  const dispatchFocusedPlaceholder = () => {};

  const onMouseDown = (evt) => (focusable = [0, 1].includes(evt.which));
  // @todo not dispatching focused at this time.
  const onFocus = (evt) =>
    focusable && item ? dispatchFocusedPlaceholder() : evt && evt.target.blur();
  const onClick = (evt) => {
    // This dna-menu check is to avoid triggering the click if a dna-menu
    // exists inside of this item.
    if (!evt.target.closest('.dna-menu')) {
      focusable = true;
    }
  };

  onMount(() => {
    // console.log('listItem', listItemEl.offsetHeight);
  });
</script>

<div
  bind:this={listItemEl}
  class:lf-app-page-list-item={true}
  class="relative cursor-pointer focus:outline-none py-2 {className}"
  on:mousedown
  on:mousedown={onMouseDown}
  on:focus
  on:focus={onFocus}
  on:click
  on:click={onClick}
  {...excludeProps($$restProps, [])}>
  <slot />
</div>

<style lang="postcss">.lf-app-page-list-item:hover :global(.lf-app-item-hover-indicator){--transform-scale-x:1;--transform-scale-y:1;opacity:.35}.lf-app-page-list-item:hover :global(.lf-app-item-hover-wrapper){--boxShadowColor:var(--rbg-secondary-600,50,117,123);box-shadow:0 3px 1px -2px rgba(var(--boxShadowColor,0,0,0),.2),0 2px 2px 0 rgba(var(--boxShadowColor,0,0,0),.14),0 1px 5px 0 rgba(var(--boxShadowColor,0,0,0),.12)}</style>
