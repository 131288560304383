<script>
  import Spinner from '../spinner/Spinner.svelte';

  export let isActive = false;
  export let showSpinner = false;
  export let fullscreen = false;

  let className = '';
  export { className as class };
</script>

<div
  on:click
  class="lf-overlay {className}"
  class:lf-overlay--fullscreen={fullscreen}
  class:lf-overlay--is-active={isActive}
>
{#if showSpinner}
  <Spinner size=48/>
{/if}
</div>

<style lang="postcss">.lf-overlay{background:rgba(0,0,0,.2);height:0;left:0;opacity:0;position:absolute;top:0;transition:opacity .3s cubic-bezier(0,0,.3,1);width:0}.lf-overlay--is-active{height:100%;opacity:1;width:100%}.lf-overlay--fullscreen{position:fixed}.lf-overlay--fullscreen.lf-overlay--is-active{height:100vh;width:100vw}</style>
