<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import StripeCardsOnFile from './StripeCardsOnFile.svelte';
  import Textfield from '../textfield/Textfield.svelte';
  import ElementErrorMessage from '../form/ElementErrorMessage.svelte';
  import Button from '../button/Button.svelte';
  import Checkbox from '../checkbox/Checkbox.svelte';
  import Spinner from '../spinner/Spinner.svelte';
  import { validateOrder } from './commerce.api.js';
  import {
    setupStripe,
    createPaymentIntent,
    confirmPaymentIntent,
  } from './stripe.api.js';
  import { logger } from '../../utils/logger.js';

  export let order = {};
  export let isSubmitting = false;

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  let isStripeSubmitting = false;
  let isInitialized = false;
  let isStripeComplete = false;

  let selectedCard;
  let pk;
  let clientSecret;
  let stripe;
  let card;
  let cardsOnFileOptions;
  let cardEl;

  let form;
  let name = order.user.fullname || '';
  let saveCardOnFile = true;
  let hasSubscription = order.meta.hasSubscription || false;
  let ccListEl;

  let errorMessages = {
    name: '',
    card: '',
  };

  const cardImages = {
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'American Express',
    discover: 'Discover',
    diners: 'Diners Club',
    unionpay: 'UnionPay',
    jcb: 'JCB',
  };

  // This is triggered when a change happens on a field within the form except
  // for the Stripe Elements card. Use this function to account for field level
  // error handling.
  function onFormChange(evt) {
    if (evt.target.name == 'name') {
      errorMessages.name = !+name.length
        ? `The card holder's name is required.`
        : '';
    }
  }

  // Handle the form submission only from the stripe submit payment button.
  // The validation function is called to validate for any errors. This should
  // check each field in case any prefilled values are not already validated.
  // Then the submit function handles interacting with Stripe and LF APIs.
  async function handleSubmit() {
    isSubmitting = true;
    isStripeSubmitting = true;

    try {
      await doValidate();
      await doSubmit();
      return Promise.resolve(true);
    } catch (err) {
      return false;
    } finally {
      isSubmitting = false;
      isStripeSubmitting = false;
    }
  }

  // Validate each field custom field and verify that the user has completed the
  // Stripe Elements card widget.
  async function doValidate() {
    let isValid = true;

    try {
      await validateOrder(order.id);
    } catch (err) {
      window.snacksQueue.add({
        label: 'An error occurred',
        message: err,
        type: 'danger',
      });
      isValid = false;
    }

    if (!+name.length) {
      errorMessages.name = `The card holder's name is required.`;
      isValid = false;
    }
    if (selectedCard == '_new' && !isStripeComplete) {
      errorMessages.card = 'Your card details are incomplete.';
      isValid = false;
    }

    return isValid ? Promise.resolve(true) : Promise.reject(false);
  }

  // Trigger the stripe card confirmation and let Stripe handle capturing any
  // additional information.
  // Once the card confirmation is completed then confirm the payment intent via
  // the LF APIs. Once confirmed then trigger the orderComplete to allow for
  // the CheckoutPage logic to confirm the order is completed.
  async function doSubmit() {
    try {
      const futureUsage = 'off_session';
      const cardPayment =
        selectedCard != '_new'
          ? {
              payment_method: selectedCard,
            }
          : {
              payment_method: {
                card: card,
                billing_details: {
                  name: name,
                },
              },
              save_payment_method: saveCardOnFile,
              setup_future_usage: saveCardOnFile ? futureUsage : '',
            };
      logger.debug('doSubmit', 'cardPayment', cardPayment);

      const cardConfirmation = await stripe.confirmCardPayment(
        clientSecret,
        cardPayment
      );
      logger.debug('doSubmit', 'cardConfirmation', cardConfirmation);

      if (cardConfirmation.error) {
        logger.debug('doSubmit', 'cardConfirmation.error', {
          error: cardConfirmation.error,
        });
        throw cardConfirmation.error;
        // return Promise.reject(cardConfirmation.error);
      }

      if (cardConfirmation.paymentIntent.status !== 'succeeded') {
        return Promise.reject(cardConfirmation);
      }

      await confirmPaymentIntent(cardConfirmation.paymentIntent.id);

      dispatch('orderComplete');
      return Promise.resolve(cardConfirmation.paymentIntent.status);
    } catch (err) {
      logger.debug('doSubmit', 'err', err);
      errorMessages.card =
        err.message ||
        'An error occurred processing your card. Please contact an administrator.';
      return Promise.reject(false);
    } finally {
      isSubmitting = false;
      isStripeSubmitting = false;
    }
  }

  async function initialize() {
    try {
      const data = await createPaymentIntent({
        orderId: order.id,
      });

      pk = data.pk;
      clientSecret = data.clientSecret;
      cardsOnFileOptions = buildCardsOnFileOptions(data.cardsOnFile);

      const stripeData = await setupStripe(pk, cardEl);
      stripe = stripeData.stripe;
      card = stripeData.card;

      card.addEventListener('change', (evt) => {
        isStripeComplete = evt.complete || false;
        errorMessages.card = evt.error ? evt.error.message : '';
      });

      form.addEventListener('change', (evt) => {
        onFormChange(evt);
      });

      // Disable the form submit to avoid submitting when the user clicks enter in
      // a textfield. The Submit Payment button will handle the submission logic.
      form.addEventListener('submit', (evt) => {
        evt.preventDefault();
      });

      return Promise.resolve(stripeData);
    } catch (err) {
      errorMessages.card =
        'An error occurred processing your card. Please contact an administrator.';
      return false;
    } finally {
      isInitialized = true;
    }
  }

  function buildCardsOnFileOptions(cardsOnFile) {
    let options = cardsOnFile.map((card) => {
      card.expired = new Date(card.exp_year, card.exp_month) < Date.now();

      if (!card.expired && !selectedCard) {
        selectedCard = card.id;
      }

      if (!card.expired && card.isDefault) {
        selectedCard = card.id;
      }

      return card;
    });

    let newOption = {
      id: '_new',
      brand: 'new',
      last4: 'New Card',
      exp_year: false,
      exp_month: false,
      isDefault: selectedCard == '_new',
      expired: false,
    };

    options.push(newOption);

    selectedCard = selectedCard || '_new';

    return options;
  }

  onMount(() => {
    form = document.getElementById('commerce-checkout-form-checkout');

    initialize();
  });
</script>

<div
  id="lf-stripe-payment"
  class="lf-stripe-payment {className}"
  class:lf-stripe-payment--is-submitting={isStripeSubmitting}>
  {#if !isInitialized}
    <div class="lf-stripe-card-loading">
      <Spinner variation="dark" size="48" />
    </div>
  {/if}
  {#if isInitialized}
    <Textfield
      name="name"
      bind:value={name}
      bind:errorMessage={errorMessages.name}
      class={+errorMessages.name.length ? 'lf-form-item--invalid' : ''}
      label="Name on Credit Card"
      labelDisplay="invisible"
      placeholder="Name on Credit Card"
      makeSpace={false}
      required />
    <StripeCardsOnFile
      bind:selectedCard
      on:openChangeCard={() => (errorMessages.card = '')}
      cardsOnFile={cardsOnFileOptions}
      helpText="Use an existing card or create a new card." />
    {#key errorMessages}
      <ElementErrorMessage
        class={selectedCard == '_new' ? 'hidden' : ''}
        errorMessage={errorMessages.card}
        makeSpace={false} />
    {/key}
  {/if}
  <div
    class:lf-stripe-card-wrapper--hidden={selectedCard != '_new'}
    class="lf-stripe-card-wrapper form-item">
    <div bind:this={cardEl} id="lf-stripe-card-element" class="form-text" />
    {#key errorMessages}
      <ElementErrorMessage
        errorMessage={errorMessages.card}
        makeSpace={false} />
    {/key}
  </div>
  {#if isInitialized && selectedCard == '_new'}
    <div class="lf-stripe-save-cardonfile-wrapper">
      <Checkbox
        id="lf-stripe-card-on-file"
        name="cardonfile"
        bind:checked={saveCardOnFile}
        label="Save card information"
        disabled={hasSubscription}
        helpText={hasSubscription
          ? 'When purchasing a subscription, the card must be saved for recurring payments.'
          : ''}
        class="lf-stripe-cardonfile" />
    </div>
  {/if}
  <div bind:this={ccListEl} class="lf-stripe-payment__cc">
    {#each Object.keys(cardImages) as cardImageKey}
      <img
        src="/sites/all/modules/custom/lf_cartcheckout/images/cc/{cardImageKey}.svg"
        class="lf-cc"
        width="36px"
        height="auto"
        data-cc-type={cardImageKey}
        alt={cardImages[cardImageKey]}
        title={cardImages[cardImageKey]} />
    {/each}
  </div>
  <div class="lf-stripe-submit-button-wrapper">
    <Button
      on:click={handleSubmit}
      class="button--primary lf-stripe-submit-button"
      isWorking={isStripeSubmitting}
      isWorkingLabel="Processing..."
      disabled={!isInitialized || isStripeSubmitting}>Submit Payment</Button>
  </div>
</div>

<style lang="postcss">.lf-stripe-payment{display:flex;flex-direction:column;row-gap:1rem}.lf-stripe-payment--is-submitting{pointer-events:none}.lf-stripe-payment__cc{display:flex;justify-content:space-between}@media screen and (min-width:768px){.lf-stripe-payment__cc{-moz-column-gap:.5rem;column-gap:.5rem;justify-content:flex-start}}.lf-stripe-payment :global(div.form-item){margin-bottom:0!important;margin-top:0!important}.lf-stripe-payment :global(input.form-text){margin-bottom:0!important}.lf-stripe-save-cardonfile-wrapper{font-size:.875rem}.lf-stripe-cardonfile-required{font-size:.75rem;margin:.5em .5em .5em .75em}.lf-stripe-card-wrapper{position:relative}.lf-stripe-card-wrapper--hidden{display:none}.lf-stripe-submit-button-wrapper{--lf-spinner-color:#fff;display:flex;justify-content:flex-end}.lf-stripe-card-loading{--lf-spinner-color:red;height:128px;position:relative}:global(.StripeElement){background-color:#fff;border:1px solid var(--lfl-border);border-radius:.5rem;box-sizing:border-box;color:var(--lfl-text);font-family:Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:24px;padding:8px;transition:border .2s linear,box-shadow .2s linear;vertical-align:middle}:global(.StripeElement--focus){border-color:rgba(63,157,75,.8);box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(63,157,75,.6);outline:0}:global(.StripeElement--invalid){border-color:#b94a48}:global(.StripeElement--webkit-autofill){background-color:#fefde5!important}</style>
