<svelte:options immutable={true} />

<script>
  import { excludeProps, prefixFilter } from 'svelte-utilities';
  import { labsController } from '../../../../components/labs/labs.controller.js';
  import {
    Button,
    BeakerIcon,
    Tooltip,
    tooltip,
  } from 'svelte-tailwind-components';

  let className = '';
  export { className as class };
  export let feature = 'cml';
  export let working = undefined;

  const toggleMessage = Drupal?.settings?.lf_admin?.labs?.beta_toggle_message;

  let toggleLabel;
  let tooltipParams = { theme: 'lf-labs-toggle', placement: 'auto' };

  $: {
    toggleLabel = labsController.isBeta(feature) ? 'Turn Beta Off' : 'Try Beta';
  }

  const onToggle = () => {
    working = true;
    labsController.isBeta(feature)
      ? labsController.disableFeatureBeta(feature)
      : labsController.enableFeatureBeta(feature);
  };
</script>

<Tooltip {tooltipParams} class={className}>
  <slot>
    <Button
      use={[[tooltip, { content: 'Lessonface Beta' }]]}
      class="lf-labs-toggle-trigger"
      {working}
      usage="link"
      size="lg"
      uppercase={false}>
      <BeakerIcon
        slot="leftIcon"
        class="w-6 h-6 text-white"
        {...excludeProps(prefixFilter($$restProps, 'icon$'), ['class'])} />
      Lessonface Beta
    </Button>
  </slot>
  <div slot="tooltip">
    <div
      class="relative flex flex-col space-y-1 rounded-lg bg-white elevation-6 border-l-8 border-secondary-500 px-4 pt-4 pb-2 max-w-sm">
      <div class="text-base leading-5 font-semibold text-secondary-500">
        Lessonface Beta
      </div>
      <div class="text-sm leading-5 text-gray-600">{@html toggleMessage}</div>
      <div class="flex justify-end">
        <Button {working} usage="link" color="secondary" on:click={onToggle}
          >{toggleLabel}</Button>
      </div>
    </div>
  </div>
</Tooltip>

<style lang="postcss">:global(.tippy-box[data-theme~=lf-labs-toggle] .tippy-backdrop){background-color:transparent}:global(.tippy-box[data-theme~=lf-labs-toggle] .tippy-content){margin:1rem;padding:0}</style>
