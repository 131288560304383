<script>
  // Svelte imports, utilities, other components, component helpers.
  import { deepGet, excludeProps, prefixFilter } from 'svelte-utilities';
  import Button from '../buttons/Button.svelte';
  import MenuIcon from '../icons/MenuIcon.svelte';

  let className = '';
  export { className as class };

  export let isOpen = false;
  export let openFrom = 'top right';
  export let disabled = false;

  let isMenuOpen;
  let openUp = false;
  let openDown = false;
  let openRight = false;
  let openLeft = false;
  let openUpDown = false;
  let openLeftRight = false;

  let wrapperEl;
  let triggerWrapperEl;
  let panelEl;

  let triggerBoundingRect;
  let forceDirection;
  let canOpenRight;
  let canOpenLeft;
  let canOpenUp;
  let canOpenDown;
  let origin;
  let isVertical;
  let isHorizontal;
  let closerLeftRight;
  let closerTopBottom;

  $: {
    isMenuOpen = isOpen && !disabled;
  }

  const openPanel = () => {
    triggerBoundingRect = triggerWrapperEl.getBoundingClientRect();
    forceDirection = openFrom.includes('force');

    canOpenRight = window.innerWidth - triggerBoundingRect.left >= panelEl.offsetWidth;
    canOpenLeft = triggerBoundingRect.right >= panelEl.offsetWidth;
    canOpenUp = triggerBoundingRect.bottom >= panelEl.offsetHeight;
    canOpenDown = window.innerHeight - triggerBoundingRect.top >= panelEl.offsetHeight;

    closerLeftRight =
      window.innerWidth - triggerBoundingRect.right > triggerBoundingRect.left ? 'left' : 'right';
    closerTopBottom =
      window.innerHeight - triggerBoundingRect.bottom > triggerBoundingRect.top ? 'top' : 'bottom';

    openUpDown = openFrom.includes('top') || openFrom.includes('bottom');
    openLeftRight = openFrom.includes('left') || openFrom.includes('right');

    openDown =
      openUpDown && (openFrom.includes('top') || !canOpenUp) && (canOpenDown || forceDirection);
    openUp =
      openUpDown && (openFrom.includes('bottom') || !canOpenDown) && (canOpenUp || forceDirection);
    openRight =
      openLeftRight &&
      (openFrom.includes('left') || !canOpenLeft) &&
      (canOpenRight || forceDirection);
    openLeft =
      openLeftRight &&
      (openFrom.includes('right') || !canOpenRight) &&
      (canOpenLeft || forceDirection);
    isVertical = !openLeft && !openRight;
    isHorizontal = !openUp && !openDown;
    origin =
      openDown && !openRight && !openLeft
        ? 'top'
        : openDown && openLeft
        ? 'topright'
        : openDown && openRight
        ? 'topleft'
        : openUp && !openRight && !openLeft
        ? 'bottom'
        : openUp && openLeft
        ? 'bottomright'
        : openUp && openRight
        ? 'bottomleft'
        : 'unknown';

    /*
    console.log('menu', {
      openFrom,
      origin,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      panel: {
        width: panelEl.offsetWidth,
        height: panelEl.offsetHeight,
      },
      trigger: {
        left: triggerBoundingRect.left,
        right: triggerBoundingRect.right,
        top: triggerBoundingRect.top,
        bottom: triggerBoundingRect.bottom,
      },
      can: {
        right: canOpenRight,
        left: canOpenLeft,
        up: canOpenUp,
        down: canOpenDown,
      },
      openDown,
      openUp,
      openLeft,
      openRight,
    });
    */

    isOpen = true;
  };
  const closePanel = () => (isOpen = false);
  const togglePanel = () => (isOpen ? closePanel() : openPanel());
  const onClick = () => togglePanel();

  const onDocumentClick = (evt) =>
    isMenuOpen && !wrapperEl.parentElement.contains(evt.target) && closePanel();
</script>

<svelte:body on:click="{onDocumentClick}" />

<div
  bind:this="{wrapperEl}"
  class="relative inline-block dna-menu {className}"
  {...excludeProps($$restProps, ['use', 'class', 'isOpen', 'openFrom', 'trigger$', 'content$'])}
>
  <div
    on:click="{closePanel}"
    class:w-full="{isMenuOpen}"
    class:h-full="{isMenuOpen}"
    class:w-0="{!isMenuOpen}"
    class:h-0="{!isMenuOpen}"
    class="dna-menu__overlay fixed left-0 top-0 fixed cursor-default"
  ></div>
  <div bind:this="{triggerWrapperEl}" class="flex items-center justify-end dna-menu__trigger">
    <slot name="primaryAction" isOpen="{isOpen}" disabled="{disabled}" />
    <Button
      usage="icon"
      size="sm"
      color="gray"
      flat
      padding="{false}"
      disabled="{disabled}"
      on:click="{onClick}"
      {...prefixFilter($$restProps, 'trigger$')}
    >
      <slot name="triggerContent">
        <MenuIcon class="w-5 h-5" />
      </slot>
    </Button>
  </div>
  <div
    bind:this="{panelEl}"
    class:origin-top="{origin === 'top'}"
    class:origin-top-right="{origin === 'topright'}"
    class:origin-top-left="{origin == 'topleft'}"
    class:origin-bottom="{origin === 'bottom'}"
    class:origin-bottom-right="{origin === 'bottomright'}"
    class:origin-bottom-left="{origin === 'bottomleft'}"
    class:top-0="{openDown || (isHorizontal && closerTopBottom === 'top')}"
    class:bottom-0="{openUp || (isHorizontal && closerTopBottom === 'bottom')}"
    class:right-0="{openLeft || (isVertical && closerLeftRight === 'right')}"
    class:left-0="{openRight || (isVertical && closerLeftRight === 'left')}"
    class:scale-0="{!isMenuOpen}"
    class:scale-100="{isMenuOpen}"
    class:elevation-4="{isMenuOpen}"
    class:dna-menu--horizontal="{isHorizontal}"
    class:dna-menu--vertical="{isVertical}"
    class="absolute z-10 bg-white transition duration-200 ease-out transition-transform transform
    {deepGet(prefixFilter($$restProps, 'content$'), 'class', '')}
    dna-menu__content"
    {...excludeProps(prefixFilter($$restProps, 'content$'), ['class'])}
  >
    <slot />
  </div>
</div>

<style lang="postcss">:global(.dna-menu--horizontal){--transform-translate-y:-25%}:global(.dna-menu--vertical){--transform-translate-x:-25%}:global(.dna-menu--vertical.right-0){--transform-translate-x:25%}.dna-menu :global(.dna-button){justify-content:flex-start}</style>
