<script>
  let className = '';
  export { className as class };
</script>

<tr
  on:click
  class="{className}"
  >
  <slot/>
</tr>

<style lang="postcss">tr{background-color:#fff}</style>
