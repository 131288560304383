<script>
  // @see https://www.darrenlester.com/blog/focus-only-on-tab
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import {
    deepGet,
    excludeProps,
    prefixFilter,
    prefixFilterItem,
    wait,
  } from 'svelte-utilities';
  import { createTooltipInstance } from './tooltip.js';

  let className = '';
  export { className as class };
  export let tooltipParams = {};
  export let tooltipId = undefined;
  export let isOpen = undefined;
  export let element = undefined;
  export let focusable = true;
  export let disableBodyScroll = false;
  export let restrictWidthToContainer = false;
  export let isChildTooltip = false;
  // Note: autoOpen is used to control opening the tooltip when clicked vs
  // programmatically calling openTooltip elsewhere.
  export let autoOpen = true;
  // Note: showOnCreate is disabled in the tooltipParams, however if we want
  // the tooltip to be created after the component mounts, this prop allows
  // for that.
  // Note: It may be better to have the parent component use the openTooltip
  // function to better control when the tooltip is opened. If everything is
  // not mounted yet, the tooltip can show in odd places.
  export let showOnCreate = false;
  // Work-around to make current Tooltip work with right-click.
  export let isContextMenu = false;

  export let tooltipInstance = undefined;

  export const openTooltip = (evt) => {
    evt && evt.preventDefault();
    evt && evt.stopPropagation();
    tooltipInstance ? showTooltip() : createTooltip(evt);
  };

  export const closeTooltip = (evt) => {
    evt && evt.preventDefault();
    evt && evt.stopPropagation();
    destroyTooltip();
  };

  const dispatch = createEventDispatcher();

  let _params;
  let _destroyOnHide = isChildTooltip;

  let tooltipClickHandlerEl;
  let tooltipContentWrapperEl;
  // @todo is this used?
  let parentTooltipEl;

  const getTooltipParams = (evt) => {
    const _defaults = {
      hideOnClick: false,
      interactive: true,
      interactiveBorder: 16,
      interactiveDebounce: 100,
      maxWidth: restrictWidthToContainer
        ? tooltipClickHandlerEl.offsetWidth
        : 'none',
      offset: [0, 0],
      placement: 'top',
      showOnCreate: false,
      theme: 'light',
      trigger: 'manual',
      popperOptions: { strategy: 'fixed' },
    };
    let getReferenceClientRect = null;

    if (
      tooltipParams?.getReferenceClientRect !== null &&
      evt &&
      evt.clientX > 0 &&
      evt.clientY > 0
    ) {
      getReferenceClientRect = () => ({
        width: 0,
        height: 0,
        top: evt.clientY,
        bottom: evt.clientY,
        left: evt.clientX,
        right: evt.clientX,
      });
    }

    return {
      ..._defaults,
      ...tooltipParams,
      content: tooltipContentWrapperEl,
      onShow: (instance) => onShowTooltip(instance),
      onHide: (instance) => onHideTooltip(instance),
      onClickOutside: (instance, evt) => onClickOutsideTooltip(instance, evt),
      onCreate: (instance) => onCreateTooltip(instance),
      onDestroy: (instance) => onDestroyTooltip(instance),
      getReferenceClientRect,
    };
  };

  const setBodyScroll = (scrollable) =>
    scrollable && !parentTooltipEl
      ? document.body.classList.remove('dna-tooltip-disable-body-scroll')
      : document.body.classList.add('dna-tooltip-disable-body-scroll');

  const canUseTooltip = () =>
    $$slots.tooltip && deepGet(tooltipParams, 'disabled') !== true;
  const showAfterCreate = () =>
    _params.trigger === 'manual' && _params.showOnCreate === false;
  const destroyOnClickOutside = () => _params.trigger === 'manual';

  const createTooltip = (evt) => {
    if (tooltipInstance === undefined && canUseTooltip()) {
      evt && evt.preventDefault();
      evt && evt.stopPropagation();

      _params = getTooltipParams(evt);
      tooltipContentWrapperEl.style.display = 'block';
      createTooltipInstance(tooltipClickHandlerEl, _params);
      showAfterCreate() && showTooltip();
    }
  };

  const showTooltip = () => {
    tooltipInstance && tooltipInstance.show();
  };
  const destroyTooltip = () => {
    _destroyOnHide = false;
    tooltipInstance && tooltipInstance.destroy();
  };

  $: {
    isOpen = tooltipId !== undefined;
  }

  const onShowTooltip = (instance) => {
    disableBodyScroll && setBodyScroll(false);
    dispatch('showTooltip', instance.id);
  };

  const onHideTooltip = (instance) => {
    disableBodyScroll && setBodyScroll(true);
    dispatch('hideTooltip', instance.id);
    _destroyOnHide && destroyTooltip();
  };

  const onClickOutsideTooltip = (_instance, _evt) => {
    destroyOnClickOutside() && destroyTooltip();
  };

  const onCreateTooltip = (instance) => {
    tooltipInstance = instance;
    tooltipId = instance.id;
  };

  const onDestroyTooltip = (instance) => {
    tooltipInstance = undefined;
    tooltipId = undefined;
    dispatch('tooltipClosed', instance.id);
  };

  const dispatchFocusedPlaceholder = () => {};
  // The mousedown focusable trap is to control which mouseclicks focus the
  // element.
  const onMouseDown = (evt) => (focusable = [0, 1].includes(evt.which));
  const onFocus = (evt) =>
    focusable ? dispatchFocusedPlaceholder() : evt && evt.target.blur();
  const onClick = (evt) => !isContextMenu && autoOpen && openTooltip(evt);
  const onContextMenu = (evt) => isContextMenu && autoOpen && openTooltip(evt);

  onMount(async () => {
    showOnCreate && (await wait(500));
    showOnCreate && openTooltip();
  });

  onDestroy(() => {
    setBodyScroll(true);
    destroyTooltip();
  });
</script>

<div
  bind:this="{element}"
  class:dna-tooltip-wrapper="{true}"
  class="{className}"
  {...excludeProps($$restProps, ['innerWrapper$'])}
>
  <div
    bind:this="{tooltipClickHandlerEl}"
    class:dna-tooltip-click-handler="{true}"
    on:mousedown="{onMouseDown}"
    on:focus="{onFocus}"
    on:click="{onClick}"
    on:click
    on:contextmenu="{onContextMenu}"
    on:contextmenu
    class="relative cursor-pointer focus:outline-none flex-1 min-w-0
    {prefixFilterItem(
      $$restProps,
      'class',
      'innerWrapper$',
      ''
    )}"
    {...excludeProps(prefixFilter($$restProps, 'innerWrapper$'), ['class'])}
  >
    <slot closeTooltip="{closeTooltip}" openTooltip="{openTooltip}" />
  </div>

  <div
    bind:this="{tooltipContentWrapperEl}"
    class:hidden="{true}"
    class:dna-tooltip="{true}"
  >
    <slot name="tooltip" closeTooltip="{closeTooltip}" />
  </div>
</div>

<style lang="postcss">:global(body.dna-tooltip-disable-body-scroll){overflow-y:hidden}</style>
