<script>
  /*global Drupal*/
  import { onMount } from 'svelte';

  import {
    paymentMethodsColumnsDefault,
    paymentMethodsConfigDefault,
  } from '../../../../components/instructorPayments/datatable/datatable.config.js';
  import {
    getPaymentMethods,
    exportPaymentMethods,
  } from '../../../../components/instructorPayments/instructorPayments.api.js';

  import { Button } from 'svelte-tailwind-components';

  import UpdatePaymentMethod from '../../../../components/instructorPayments/UpdatePaymentMethod.svelte';
  import DataTable from '../../../../components/datatable/DataTable.svelte';
  import List from '../../../../components/datatable/filters/List.svelte';
  import Autocomplete from '../../../../components/datatable/filters/Autocomplete.svelte';
  import Account from '../../../../components/reports/Account.svelte';
  import Spinner from '../../../../components/spinner/Spinner.svelte';

  let className = '';
  export { className as class };

  let paymentMethods = {};
  let isInitialized = false;

  let paymentMethodsColumns = [...paymentMethodsColumnsDefault];
  let paymentMethodsConfig = { ...paymentMethodsConfigDefault };

  let colInstructor = {
    name: 'instructor',
    title: 'Instructor',
    data: 'instructor',
    sortable: {
      data: 'instructor.fullname',
    },
    component: Account,
  };

  paymentMethodsColumns.unshift({ ...colInstructor });
  paymentMethodsConfig.rowActions = [
    {
      id: 'edit',
      label: 'Edit',
      params: {
        class: 'button--link',
      },
      component: UpdatePaymentMethod,
    },
  ];

  let paymentMethodsFilters = [
    {
      name: 'instructor',
      label: 'Instructor',
      data: {
        value: 'instructor.id',
        label: 'instructor.fullname',
        type: 'number',
      },
      options: [],
      component: Autocomplete,
      componentOptions: {
        sortRules: ['text'],
      },
    },
    {
      name: 'payment_method',
      label: 'Payment Method',
      data: {
        value: 'payment_method',
      },
      options: [
        {
          value: 'stripe',
          label: 'Stripe',
        },
        {
          value: 'paypal',
          label: 'PayPal',
        },
        {
          value: 'mailed_check',
          label: 'Mailed Check',
        },
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'not_set',
          label: 'Not Set',
        },
      ],
      component: Autocomplete,
    },
    {
      name: 'status',
      label: 'Status',
      data: {
        value: 'status',
      },
      options: [],
      component: List,
    },
  ];

  async function onExportPaymentMethodsClick() {
    let res = await exportPaymentMethods();

    if (res.url && res.url.length > 0) {
      window.snacksQueue.add({
        label: 'CSV File',
        message: `${res.filename} has been generated.`,
        type: 'success',
        settings: {
          persistent: true,
        },
        actions: [
          {
            label: 'Download',
            onClick: () => (window.location.href = res.url),
          },
        ],
      });
    }
  }

  async function initialize() {
    try {
      paymentMethods = await getPaymentMethods();
      return paymentMethods;
    } catch (err) {
      return false;
    } finally {
      isInitialized = true;
    }
  }

  onMount(() => {
    initialize();
  });
</script>

<div
  class="
    lf-instructor-payment-methods-report
    hoverable-on
    {className}
  "
>
  {#if isInitialized}
    <DataTable
      id="reportInstructorPaymentMethods"
      title="Instructor Payment Methods"
      bind:data="{paymentMethods}"
      config="{paymentMethodsConfig}"
      columns="{paymentMethodsColumns}"
      bind:filters="{paymentMethodsFilters}"
    >
      <div slot="actions">
        <Button color="primary" size="xs" on:click="{onExportPaymentMethodsClick}">
          <i class="mr-2 icon-download-alt"></i>Generate CSV
        </Button>
      </div>
    </DataTable>
  {:else}
    <Spinner size="48" variation="dark" />
  {/if}
</div>

<style lang="postcss">.lf-instructor-payment-methods-report{font-size:.875rem;min-height:64px}:global(.lf-instructor-payment-methods-report){background-color:#fff;margin-bottom:20px;padding:1em}</style>
