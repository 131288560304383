<svelte:options immutable="{true}" />

<script>
  // @todo move to using https://github.com/vime-js/vime.
  import { onMount, onDestroy } from 'svelte';
  import { excludeProps, prefixFilter } from 'svelte-utilities';

  let className = '';
  export { className as class };
  export let file;
  export let videoPlayer = undefined;
  export let videoConfig = {};

  const defaultConfig = {
    autoplay: false,
    controls: true,
    loop: false,
    muted: false,
    preload: 'auto',
    responsive: true,
  };

  onMount(() => {
    try {
      videoPlayer = file?.url
        ? videojs('lf-video--' + file.id, {
            ...defaultConfig,
            ...videoConfig,
          })
        : undefined;
    } catch (err) {
      // Fail quietly... this tends to throw an error when the user is changing
      // folders.
    }
  });

  onDestroy(() => {
    try {
      videoPlayer && videoPlayer.dispose();
    } catch (err) {
      // Fail quietly.
    }
  });
</script>

<div class:lf-video-wrapper="{true}" class="{className}" {...excludeProps($$restProps, ['video$'])}>
  {#if file?.url}
    <video
      id="lf-video--{file.id}"
      controls
      controlslist="nodownload"
      preload="auto"
      class:lf-video="{true}"
      class:video-js="{true}"
      class:vjs-fluid="{true}"
      class:vjs-default-skin="{true}"
      {...prefixFilter($$restProps, [])}>
      <source src="{file?.url}" type="{file?.mime ?? file?.filemime}" />
    </video>
  {/if}
</div>

<style lang="postcss">.lf-video-wrapper{max-width:min(640px,100vw);position:relative;text-align:center;width:100vw}@media (min-width:768px){.lf-video-wrapper{max-width:min(640px,90vh)}}.lf-video-wrapper :global(div.lf-video){display:inline-block}:global(.video-js .vjs-big-play-button){border-radius:1em;height:2em;left:calc(50% - 1em);line-height:1.9em;top:calc(50% - 1em);width:2em}</style>
