<script>
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps } from 'svelte-utilities';
  import ButtonsWrapper from '../buttons/ButtonsWrapper.svelte';

  let className = '';
  export { className as class };

  export let justify = 'end';
  export let wrap = false;
</script>

<ButtonsWrapper
  justify="{justify}"
  wrap="{wrap}"
  class="relative flex-shrink-0
  {!className.includes('border')
    ? 'border-t border-gray-200'
    : ''}
  {!className.includes('px')
    ? 'px-4 sm:px-6'
    : ''}
  {!className.includes('py')
    ? 'py-2 sm:py-3'
    : ''}
  dna-card__actions {className}"
  {...excludeProps($$restProps, ['use'])}
>
  <slot name="working" />
  <slot />
</ButtonsWrapper>

<style lang="postcss">:global(.dna-card__actions[class*=-flex-gap-x] .dna-button--text:last-of-type){margin-right:.5rem}</style>
