<svelte:options immutable="{true}" />

<script>
  import { excludeProps } from 'svelte-utilities';
  import { StatusPinger, TimeUntil } from 'svelte-tailwind-components';
  import { statusMeta } from './lessons.utils.js';

  let className = '';
  export { className as class };
  export let lesson;

  const hasEnded = lesson.hasEnded();
</script>

<div class:lf-lesson__status="{true}" class="{className}" {...excludeProps($$restProps, [])}>
  {#if lesson?.canJoin}
    <span
      class:mr-2="{lesson?.isLastLesson}"
      class="text-primary-600 font-semibold flex items-center space-x-2"
    >
      <span>{statusMeta.inProgress.label}</span>
      <StatusPinger size="{2}" {...statusMeta.inProgress.pingerProps} />
    </span>
  {:else if lesson?.needsAttention && lesson?.stateLabel}
    <span class:mr-2="{lesson?.isLastLesson}" class="text-danger-700 flex items-center space-x-2">
      <span>{lesson?.stateLabel}</span>
      {#if lesson._stateInfo?.pingerProps}
        <StatusPinger size="{2}" {...lesson._stateInfo.pingerProps} />
      {/if}
    </span>
  {:else if lesson?.timeUntil}
    <TimeUntil
      datetime="{lesson.startTime}"
      maxHours="{6}"
      tokens="{{ hour: 'hr', hours: 'hrs', minute: 'min', minutes: 'mins' }}"
      class="text-danger-700 {lesson?.isLastLesson ? 'mr-2' : ''}"
    >
      <span slot="prefix">Starts</span>
    </TimeUntil>
  {:else if lesson?.isCancelled}
    <span class:mr-2="{lesson?.isLastLesson}" class="text-danger-700 flex items-center space-x-1">
      <span>Cancelled</span>
    </span>
  {:else if lesson?.isSubscription || (lesson?.isInvite && !hasEnded)}
    <span class:mr-2="{lesson?.isLastLesson}" class="text-gray-500">
      {#if lesson?.isSubscription}
        <span>Subscription</span>
      {:else if lesson?.isInvite}
        <span>Invite</span>
      {/if}
    </span>
  {/if}
  {#if lesson?.isLastLesson}
    <span class="text-danger-700 flex items-center space-x-2">
      <span>Last Lesson Scheduled</span>
      <StatusPinger size="{2}" pinging />
    </span>
  {:else if lesson?.currentStateInfo?.state === 'completed'}
    <span class="text-gray-500 flex items-center space-x-2">
      <span>Completed</span>
    </span>
  {/if}
  <slot />
</div>

<style>.lf-lesson__status>span+span:before{content:"/";display:block;padding-right:.5rem}</style>
