<script>
  import {
    Button,
    Cards,
    Dialog,
    ProgressLinearV2 as ProgressLinear,
  } from 'svelte-tailwind-components';

  import BasicReview from './BasicReview.svelte';
  import { reviewsAPI as api } from '../../../components/reviews/reviews.api.js';

  const instructorUID = Drupal?.settings?.lf_reviews?.instructorUID ?? 0;
  const instructorName = Drupal?.settings?.lf_reviews?.instructorName ?? 'Instructor';
  const maxDisplay = Drupal?.settings?.lf_reviews?.max_display ?? 0;

  let viewAllReviews = false;
  let allReviews = [];

  const queryParams = { instructorId: instructorUID };
  const loadReviews = async () => {
    const res = await api.getReviews(queryParams);
    allReviews = res?.data ?? [];
    return allReviews;
  };

  const onViewAllReviews = () => {
    viewAllReviews = true;
  };

  const onViewFeaturedReviews = () => {
    viewAllReviews = false;
  };

  const reviewsPromise = loadReviews();
</script>

<div id="lf-reviews-instructor-reviews">
  {#await reviewsPromise}
    <div class="text-sm text-gray-500">Loading Reviews...</div>
    <ProgressLinear working="{$api.working}" settings="{{ indeterminate: true }}" />
  {:then reviews}
    <ul
      class="w-auto py-1 m-0 text-sm md:text-base leading-5 md:leading-6 overflow-auto focus:outline-none focus:shadow-outline {reviews.length >
      5
        ? 'flex flex-wrap flex-gap-6'
        : 'flex-gap-y-6'}">
      {#each reviews.slice(0, maxDisplay) as review (review.id)}
        <li class="relative w-full overflow-hidden lf-reviews-list-item-wrapper">
          <BasicReview review="{review}" />
        </li>
      {:else}
        <li>
          <div class="text-sm text-gray-500">No reviews available.</div>
        </li>
      {/each}
    </ul>

    {#if maxDisplay > 0 && reviews?.length > maxDisplay && !$api.working}
      <div class="flex justify-end mt-4">
        <Button
          usage="text"
          size="sm"
          color="none"
          fullwidth="{true}"
          uppercase="{false}"
          outlined="{true}"
          class="border border-border"
          working="{viewAllReviews}"
          disabled="{viewAllReviews}"
          on:click="{onViewAllReviews}">
          View all reviews
        </Button>
      </div>
    {/if}
  {:catch}
    <div class="text-sm text-gray-500">No reviews available.</div>
  {/await}
</div>

<Dialog
  id="lf-reviews-all-reviews-dialog"
  includeCloseButton="{false}"
  bind:isOpen="{viewAllReviews}">
  <Cards.Card class="w-full max-h-full sm:max-w-screen-sm sm:max-h-9/10">
    <Cards.Header>
      <h3 class="flex flex-col m-0 text-2xl leading-6">
        <span class="order-2">{instructorName}</span>
        <span class="order-1 text-sm uppercase leading-5 text-secondary-500">Reviews</span>
      </h3>
    </Cards.Header>
    <Cards.Content
      class="overflow-hidden overflow-y-auto lf-reviews-all-instructor-reviews-wrapper">
      {#if viewAllReviews}
        <ul
          class="w-auto py-1 m-0 text-sm md:text-base leading-5 md:leading-6 overflow-auto focus:outline-none focus:shadow-outline flex flex-wrap flex-gap-6">
          {#each allReviews as review (review.id)}
            <li class="relative w-full overflow-hidden lf-reviews-list-item-wrapper">
              <BasicReview review="{review}" />
            </li>
          {:else}
            <li>
              <div class="text-sm text-gray-500">No reviews available.</div>
            </li>
          {/each}
        </ul>
      {/if}
    </Cards.Content>
    <Cards.Actions>
      <Button usage="text" size="sm" on:click="{onViewFeaturedReviews}">Close</Button>
    </Cards.Actions>
  </Cards.Card>
</Dialog>

<style lang="postcss">@media (min-width:640px){:global(.lf-reviews-list-item-wrapper){flex:0 1 calc(50% - var(--gap))}}</style>
