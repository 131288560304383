<script>
  // @todo figure out how to extend .form-textarea.
  import FormField from '../form-field/FormField.svelte';
  import Label from '../labels/Label.svelte';
  import HelpText from '../help-text/HelpText.svelte';
  import ErrorMessage from '../error-messages/ErrorMessages.svelte';

  import { excludeProps, prefixFilter } from 'svelte-utilities';

  export let name = undefined;
  export let id;
  export let value = '';
  export let invalid = false;

  // Shapes: rounded, square
  export let shape = 'square';

  let className = '';
  export { className as class };

  let rows = prefixFilter($$props, 'textarea$').rows || 5;
  let cols = prefixFilter($$props, 'textarea$').cols || undefined;

  if (!name) {
    name = id;
  }
</script>

<FormField
  type="textarea"
  invalid="{invalid}"
  class="{className}"
  {...excludeProps($$props, [
    'use',
    'class',
    'name',
    'id',
    'value',
    'invalid',
    'shape',
    'textarea$',
    'label$',
    'help$',
    'error$',
  ])}
>
  <div
    class="
    dna-form-field-textarea-wrapper
    dna-form-field-textarea-wrapper--{shape}
    "
  >
    <Label
      id="{id}"
      invalid="{invalid}"
      float
      {...prefixFilter($$props, 'label$')}
    >
      <textarea
        id="{id}"
        name="{name}"
        bind:value
        rows="{rows}"
        cols="{cols}"
        class="
          form-textarea
          dna-form-field-textarea
          dna-form-field-textarea--{shape}
        "
        class:dna-form-field-textarea--invalid="{invalid}"
        class:dna-form-field--with-value="{value.length > 0}"
        on:input
        on:change
        on:click
        on:focus
        on:keydown
        on:keyup
        {...excludeProps(prefixFilter($$props, 'textarea$'), ['rows', 'cols'])}
      ></textarea>
    </Label>
  </div>
  {#if invalid}
    <ErrorMessage {...prefixFilter($$props, 'error$')} />
    <slot name="errorMessage" />
  {:else}
    <HelpText id="{id + '-description'}" {...prefixFilter($$props, 'help$')} />
    <slot name="helpText" />
  {/if}
</FormField>

<style lang="scss">.dna-form-field-textarea-wrapper{margin-top:.25rem;position:relative}.dna-form-field-textarea-wrapper--rounded{border-radius:.375rem}.dna-form-field-textarea{box-shadow:0 0 0 3px rgba(159,166,178,.45);display:block;transition-duration:.3s;transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;transition-timing-function:cubic-bezier(.4,0,.2,1)}@media (min-width:640px){.dna-form-field-textarea{font-size:.875rem;line-height:1.25rem}}.dna-form-field-textarea--square{border-radius:0}.dna-form-field-textarea:not(:disabled):not(.dna-form-field-textarea--invalid):focus{box-shadow:0 0 0 3px rgba(63,157,75,.35)}.dna-form-field-textarea--invalid{box-shadow:0 0 0 3px hsla(0,83%,84%,.45)}.dna-form-field-textarea:disabled{cursor:not-allowed}</style>
