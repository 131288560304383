<script>
  import Checkbox from '../../checkbox/Checkbox.svelte';

  export let rowData;
  export let column;
  export let colIndex;
  export let meta;

  let id = 'lf-datatable-row-checkbox--' + meta.rowIndex;
  let name = id;
</script>

<Checkbox bind:checked={rowData._meta.checked} {id} {name} />

<style global lang="postcss">:global(.lf-datatable-cell-wrapper) :global(.form-type-checkbox){font-size:.75rem;margin:.25em 0 0}</style>
