<script>
  // Variations include:
  // - light: light on dark.
  // - dark: dark on light.
  // - single: color from var.
  // - multi: color from vars.
  export let variation = 'light';
  export let size = 0;
  export let center = true;

  let className = '';
  export { className as class };

  let sizeProp = size ? `${size - 2}px` : '100%';
</script>

<div
  class:lf-spinner-center="{center}"
  class="lf-spinner-container lf-spinner--{variation} {className}"
>
  <svg
    class="lf-spinner"
    width="{sizeProp}"
    height="{sizeProp}"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"
    ></circle>
  </svg>
</div>

<style lang="scss">.lf-spinner-container{left:50%;opacity:var(--lf-spinner-opacity,.7);position:absolute;top:50%;transform:translateX(-50%) translateY(-50%)}.lf-spinner-container:before{content:"";float:left;height:0;margin-left:1px;padding-top:100%;width:1px}.lf-spinner-container:after{clear:both;content:"";display:table}.lf-spinner{animation:rotator 1.4s linear infinite}@keyframes rotator{0%{transform:rotate(0deg)}to{transform:rotate(270deg)}}.lf-spinner--light{--lf-spinner-color:#fff}.lf-spinner--dark{--lf-spinner-color:#3f9d4b}.path{stroke-dasharray:187;stroke-dashoffset:0;transform-origin:center}.lf-spinner--dark .path,.lf-spinner--light .path,.lf-spinner--single .path{animation:dash 1.4s ease-in-out infinite,singlecolor 5.6s ease-in-out infinite}.lf-spinner--multi .path{animation:dash 1.4s ease-in-out infinite,colors 5.6s ease-in-out infinite}@keyframes singlecolor{0%{stroke:var(--lf-spinner-color,#3f9d4b)}25%{stroke:var(--lf-spinner-color,#3f9d4b)}50%{stroke:var(--lf-spinner-color,#3f9d4b)}75%{stroke:var(--lf-spinner-color,#3f9d4b)}to{stroke:var(--lf-spinner-color,#3f9d4b)}}@keyframes colors{0%{stroke:var(--lf-spinner-color-0,#3f9d4b)}25%{stroke:var(--lf-spinner-color-25,#3f9d4b)}50%{stroke:var(--lf-spinner-color-50,#3f9d4b)}75%{stroke:var(--lf-spinner-color-75,#3f9d4b)}to{stroke:var(--lf-spinner-color-100,#3f9d4b)}}@keyframes dash{0%{stroke-dashoffset:187}50%{stroke-dashoffset:46.75;transform:rotate(135deg)}to{stroke-dashoffset:187;transform:rotate(450deg)}}</style>
