<script>
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps, Klasses, Timer } from 'svelte-utilities';

  let className = '';
  export {className as class};

	export let settings = {};
  settings = {
		width: 3,
		size: 70,
		indeterminate: false,
		displayPercentage: true,
		// The following settings are for non-indeterminate progress bars.
    duration: 0,
    numSteps: 100,
    // @todo
    pctSteps: 20,
		...settings,
  };

  export const timer = Timer(settings.duration, settings.numSteps);

  export let color = 'primary';
	export let percentComplete = 0;

	const klasses = new Klasses();

  let classes = klasses
	  .add(Klasses.colorClass(['text', color, 500]), color != 'white')
	  .add('text-white', color == 'white')
	  .add(className)
	  .setDefault()
	  .get();

  let props = excludeProps($$props, ['use', 'class', 'settings', 'timer', 'color', 'percentComplete']);

  let progress = 0;
	let strokeProps = '';

  $: {
		if (settings.indeterminate) {
			progress = 1;
			percentComplete = 0;
		}
		else {
			progress = settings.duration ? (settings.duration - $timer) / settings.duration : 0;
			percentComplete = progress ? parseInt(progress * 100) : 0;
			strokeProps = `
			  stroke-dasharray: ${150000 - percentComplete * 1000};
				stroke-dashoffset: -${124 - (percentComplete * 124) / 100};
			`;
		}
  }
</script>

<div
  role="progressbar"
	class:dna-progress-circular={true}
	class:dna-progress-circular--indeterminate={settings.indeterminate}
	class="relative inline-block {classes}"
  {...props}>
	<svg
	  class="relative animate-spin"
		style="width: {settings.size}px; height: {settings.size}px;">
		<circle
			cx={settings.size / 2}
			cy={settings.size / 2}
			fill="none"
			r={(settings.size / 2) - (settings.size / 5)}
			stroke-width={settings.width}
			stroke-miterlimit="10"
	    style="{strokeProps}"
			/>
	</svg>
	<div
		class:hidden={settings.indeterminate || !settings.displayPercentage}
	  class:dna-progress-circular__percentage={true}
		style="font-size: {Math.round(((settings.size / 2) - (settings.size / 5)) / 2)}px;">
		{percentComplete}%
	</div>
</div>

<style>@keyframes dash{0%{stroke-dasharray:1,200;stroke-dashoffset:0}50%{stroke-dasharray:89,200;stroke-dashoffset:-35}to{stroke-dasharray:89,200;stroke-dashoffset:-124}}.dna-progress-circular circle{stroke:currentColor;stroke-linecap:round;animation:none}.dna-progress-circular--indeterminate circle{stroke-dasharray:1,200;stroke-dashoffset:0;animation:dash 1.5s ease-in-out infinite}.dna-progress-circular__percentage{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}</style>
