<script>
  import { onMount, createEventDispatcher } from 'svelte';

  import { updatePayment } from './instructorPayments.api.js';
  import _get from 'lodash-es/get.js'

  // @todo Dialog
  import Flatpickr from 'svelte-flatpickr';
  import Button from '../button/Button.svelte';
  import Overlay from '../overlay/Overlay.svelte';

  export let action = {};
  export let rowData = {};
  export let meta = {};

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  let isInitialized = false;
  let isUpdating = false;
  let nowISO = new Date().toISOString();
  let maxDate = new Date();

  maxDate.setDate(maxDate.getDate() + 5);

  let defaultStatusDesc = '';
  let statusDescMap = _get(Drupal.settings, 'lf_instructor_payments.statusDescriptions', {});
  let flatpickrOptions = {
    altInput: true,
    altFormat: "F j, Y",
    enableTime: true,
    dateFormat: 'Z',
    minDate: new Date('2016-1-1').toISOString(),
    maxDate: maxDate.toISOString(),
    onChange: (selectedDates, dateStr, instance) => {
      if (dateStr.length > 0) {
        payment.payment_date = dateStr;
      }
    }
  }

  let payment = {
    id: 0
  };

  $: if (rowData.id !== payment.id) {
    payment = {
      id: rowData.id,
      status: rowData.status,
      status_description: rowData.status_description,
      payment_date: rowData.payment_date,
    };
    defaultStatusDesc = statusDescMap[payment.status];

    if (payment.status_description == defaultStatusDesc) {
      payment.status_description = '';
    }
  }

  // @todo figure out why applying changes to attributes directly updates the
  // table (rowData) but simply setting the rowData object does not trigger the
  // update.
  function applyUpdates(payment) {
    Object.keys(payment).forEach(key => {
      if (key !== 'id') {
        rowData[key] = payment[key];
      }
    });
  }

  function dispatchActionStatus() {
    dispatch('actionStatusChange', {
      id: 'update',
      dialog: true,
      isOpen: isUpdating,
    })
  }

  function onClick() {
    isUpdating = !isUpdating;
    dispatchActionStatus();
  }

  async function onSave() {
    try {
      const res = await updatePayment(payment);
      applyUpdates(res.payment);
    }
    catch (err) {
      // @todo error.
    }
    finally {
      isUpdating = false;
      dispatchActionStatus();
    }
  }

  function onCancel() {
    isUpdating = false;
    dispatchActionStatus();
  }

  function initialize() {
    isInitialized = true;
  }

  onMount(() => {
    initialize();
  });
</script>

<svelte:head>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">
</svelte:head>

{#if isInitialized}
  <Button
    on:click={onClick}
    class="{action.params.class}">
    {action.label}
  </Button>

  {#if isUpdating}
    <div
      id="lf-instructor-payment-form-wrapper"
      class="
        lf-instructor-payment-form-wrapper
        {className}
        ">
      <Overlay
        fullscreen
        isActive={isUpdating}
        />
      <form
        class="lf-instructor-payment-form"
        on:submit|preventDefault
        >
        <div class="lf-instructor-payment-form__header">
          <h3 class="lf-instructor-payment-form__title">Update Payment</h3>
        </div>
        <div class="lf-instructor-payment-form__content">
          <div class="lf-instructor-payment-form__meta">
            <div><label>Payment ID:</label> #{payment.id}</div>
            <div><label>Instructor:</label> {rowData.instructor.fullname || rowData.instructor.id || ''}</div>
          </div>

          <div class="form-item form-type-select">
            <label for="status">
              Status
            </label>
            <select
              bind:value={payment.status}
              id="status"
              class="form-select">
              <option value="funds_pending">Funds Pending</option>
              <option value="in_progress">In-Progress</option>
              <option value="paid">Paid</option>
              <option value="manual">Manual</option>
            </select>
          </div>

          <div class="form-item form-type-textfield">
            <label for="status_description">Status Description</label>
            <input
              bind:value={payment.status_description}
              class="text-full form-text"
              placeholder={defaultStatusDesc}
              type="text"
              id="status_description"
              name="status_description"
              size="60"
              maxlength="255">
            <div class="description">Enter a description to append to the default description used for each status.</div>
          </div>

          <div
            class="form-item form-type-date">
            <label for="payment_date">Payment Date</label>
            <Flatpickr
              options="{ flatpickrOptions }"
              value={payment.payment_date}
              id="payment_date"
              class="text-full form-text lf-flatpickr-readonly"
              />
          </div>

        </div>

        <div class="lf-instructor-payment-form__footer">
          <Button
            on:click={onSave}
            class="button button--primary button--small">
            Save
          </Button>
          <Button
            on:click={onCancel}
            class="button button--link button--small">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  {/if}
{/if}

<style lang="postcss">:global(input.lf-flatpickr-readonly[readonly]){background-color:#fff;cursor:unset;min-width:200px}.lf-instructor-payment-form-wrapper{align-items:center;display:flex;height:100vh;justify-content:center;left:0;position:fixed;top:0;width:100vw}.lf-instructor-payment-form{background-color:#fff;padding:1em 1.5em;position:relative}.lf-instructor-payment-form__header{padding:.5em 0}.lf-instructor-payment-form__title{margin:0}.lf-instructor-payment-form__content{padding:.5em 0}.lf-instructor-payment-form__meta{background-color:#eee;border:1px dashed #ccc;margin-bottom:1em;padding:4px 8px}.lf-instructor-payment-form__meta label{color:#666;display:inline-block;font-weight:600;margin-bottom:0;min-width:80px}.lf-instructor-payment-form__payment-method-wrapper{margin-bottom:1em;padding:.5em}.lf-instructor-payment-form__payment-method-wrapper :global(button){padding:0}.lf-instructor-payment-form__payment-method-wrapper--expanded{border:1px dotted #b94a48;padding:.5em .5em 0}.lf-instructor-payment-form__footer{padding:.5em 0}</style>
