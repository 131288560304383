<script>
  // Svelte imports, utilities, other components, component helpers.
  import { excludeProps } from 'svelte-utilities';

  let className = '';
  export { className as class };

  export let visible = true;
  export let disabled = false;
  export let invalid = false;
  export let floatable = true;
</script>

<span
  class:sr-only="{!visible}"
  class:text-gray-700="{!invalid}"
  class:text-danger-500="{invalid}"
  class:opacity-75="{disabled}"
  class:cursor-not-allowed="{disabled}"
  class:dna-form-field-label__text--floatable="{floatable}"
  class:dna-form-field-label__text--invalid="{invalid}"
  class="dna-form-field-label__text
    {floatable
    ? 'absolute top-0 left-0 px-2 ml-2 transform translate-x-0 translate-y-3 transition-transform duration-100'
    : ''}
    {className}"
  {...excludeProps($$restProps, ['use'])}
>
  <slot />
</span>

<style lang="postcss">:global(.dna-form-field-input--has-value+.dna-form-field-label__text--floatable),:global(.dna-form-field-input:focus+.dna-form-field-label__text--floatable){--bg-opacity:1;--transform-translate-x:0;--transform-translate-y:0;--transform-rotate:0;--transform-skew-x:0;--transform-skew-y:0;--transform-scale-x:1;--transform-scale-y:1;--transform-scale-x:.875;--transform-scale-y:.875;--transform-translate-x:-0.5rem;--transform-translate-y:-0.75rem;background-color:#fff;background-color:rgba(255,255,255,var(--bg-opacity));transform:translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))}:global(.dna-form-field-input:focus+.dna-form-field-label__text--floatable:not(.dna-form-field-label__text--invalid)){--text-opacity:1;color:#3f9d4b;color:rgba(63,157,75,var(--text-opacity))}</style>
