<script>
  import _get from 'lodash-es/get.js';

  export let rowData = {};
  export let column = {};
  export let meta = {};

  $: data = _get(rowData, column.data, '');
  // let iconClass = '';

  // $: if (data == 'paid') {
  //   iconClass = 'icon-ok';
  // } else {
  //   iconClass = '';
  // }
  $: iconClass = data === 'paid' ? 'icon-ok' : '';
</script>

{#if !!+iconClass.length}
  <i class="lf-instructor-payment__status--{data} {iconClass}"></i>
{/if}
{data}

<style lang="postcss">.lf-instructor-payment__status--paid{color:darken(#3f9d4b,10%)}</style>
